import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';

import {
  selectIsLoading,
  selectStartupCount,
  selectStartups,
  selectStartupFilters,
  selectStartupFiltersActive,
} from 'redux/selectors/startups';

import {
  getStartups,
  getStartupCount,
  setStartupFilters,
} from 'redux/actions/startups';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  startups: selectStartups(state),
  startupCount: selectStartupCount(state),
  filters: selectStartupFilters(state),
  filtersActive: selectStartupFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartups,
    getStartupCount,
    setStartupFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
