import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';

import {
  getTechnicalLicenseInvestmentStages,
  getTechnicalLicenseSectors,
  setTechnicalLicenseFilters,
  getTechnicalLicenseStages,
} from 'redux/actions/technicalLicense'

import {
  selectTechnicalLicenseFilters,
  selectStageOptions,
  selectSectorOptions,
} from 'redux/selectors/technicalLicenses';

const mapStateToProps = (state: StateType) => ({
  stagesOptions: selectStageOptions(state),
  filters: selectTechnicalLicenseFilters(state),
  sectorOptions: selectSectorOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getTechnicalLicenseStages,
    getTechnicalLicenseSectors,
    getTechnicalLicenseInvestmentStages,
    setTechnicalLicenseFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
