import { createSelector } from 'reselect';

import { StateType } from 'types';
import { ServicesStateType } from 'types/reduxTypes/StateTypes';
import { ServicesType } from 'types/services';
import { ServiceCountPayload } from 'types/reduxTypes/ActionTypes/ServicesType';

const selectServicesState = (state: StateType) => state.servicesReducer;

export const selectServices = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.services,
);

export const selectIsLoading = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.isLoading,
);

export const selectServiceDetails = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.details.service,
);

export const selectServiceAndSpActiveTab = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.activeTab,
);

export const selectTabCount = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.counter,
);

export const selectServicesCount = createSelector(
  selectTabCount,
  (state: ServiceCountPayload) => state.servicesCount,
);

export const selectServiceName = createSelector(
  selectServiceDetails,
  (state: ServicesType) => state.name,
);

export const selectServiceFilters = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.filters,
);

export const selectServiceCategories = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.categories,
);

export const selectServiceSubCategories = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.subCategories,
);

export const selectInsuranceTypes = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.insuranceTypesOptions,
);

export const selectCurrentServicesFilterTab = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.servicesFilterTab,
);

export const selectCurrentSubCategoryTab = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.servicesSubCategoryFilterTab,
);

export const selectServiceCategoryDetails = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.serviceCategory,
);

export const selectServiceSubCategoryDetails = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.serviceSubCategory,
);

export const selectHasMainCategory = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.details.hasMainCategory,
);

export const selectCategoryID = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.categoryID,
);

export const selectServiceTimeUnits = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.units,
);

export const selectServicesFilters = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.filters,
);

export const selectFiltersActive = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.filtersActive,
);

export const selectServiceBulkErrors = createSelector(
  selectServicesState,
  (state: ServicesStateType) => state.bulkErrors,
)
