import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';

import { RenderIf } from 'components/components';
import { isTechnicalLicense } from 'utils';
import Header from './components/Header';
import NotificationsList from './components/NotificationsList';
import { ActivitySidebarProps } from '../../types/activities';
import identity from '../../utils/identity';
import ShowMoreButton from './components/ShowMoreButton';

import './ActivitySidebar.scss';

const ActivitySidebar = (props: ActivitySidebarProps) => {
  const [activeTab, setActiveTab] = useState<'documents' | 'serviceRequests'>('documents');

  const {
    notificationsCount, isLoading, actions, userId, allFetched, userType,
  } = props;

  useEffect(() => () => actions.resetNotifications(), []);

  useEffect(() => {
    if (!userId) {
      return;
    }

    if (identity.isTruthyNumber(userId)) {
      if (!isTechnicalLicense(userType)) {
        actions.getNewNotificationsCount({ type: activeTab });
        actions.getNotifications({ type: activeTab });
      }
    }
  }, [userId, activeTab]);

  const showMore = () => {
    actions.setNextPage();
    actions.getNotifications({ type: activeTab });
  };

  const handleOnChange = (activeKey: string) => {
    actions.changeTypeNotification();
    setActiveTab(activeKey as 'documents' | 'serviceRequests');
  }

  return (
    <div className="activity-sidebar">
      <Header />

      <div>
        <Tabs
          tabBarGutter={0}
          activeKey={activeTab}
          onChange={handleOnChange}
          className="tab-buttons-lg"
        >
          <Tabs.TabPane tab="Documents " key="documents" />

          <Tabs.TabPane tab="Service Requests" key="serviceRequests" />
        </Tabs>
      </div>

      <NotificationsList />

      <RenderIf condition={identity.isTruthyNumber(notificationsCount) && !allFetched}>
        <ShowMoreButton isLoading={isLoading} onShowMore={showMore} />
      </RenderIf>

      <RenderIf condition={!identity.isTruthyNumber(notificationsCount) && !isLoading}>
        <div className="activity-sidebar__no-data">
          <p>There are no notifications to be displayed.</p>
        </div>
      </RenderIf>
    </div>
  );
}

export default ActivitySidebar;
