import { ROLES } from 'utils';
import identity from 'utils/identity';
import {
  ChangePasswordApiRequestPayload,
  ChangePasswordRequestPayload,
  LoginApiResponseType,
  LoginApiResponseUserType,
  LoginRequestPayload,
  LoginResponseType, ResetPasswordOtpApiRequestPayload, ResetPasswordOtpRequestPayload,
  UserAuthType,
  UserRoleType,
} from '../types'

/* BE role mapping is not exact so mapping from string to type is needed while only allowing alphabet chars */
const mapUserRole = (role: string): UserRoleType => {
  const unformattedRoleString = role.toLowerCase().replace(/[^A-Z]+/ig, '');

  switch (unformattedRoleString) {
    case 'superadmin': // "Super admin"
      return ROLES.superAdmin;
    case 'admin': // "admin"
      return ROLES.admin;
    case 'startupadmin': // "Startup admin"
      return ROLES.startupAdmin;
    case 'startupfounder': // "Startup founder"
      return ROLES.startupFounder;
    case 'startupteammember': // "Startup team member"
      return ROLES.startupTeamMember;
    case 'serviceprovider': // "Service provider"
      return ROLES.serviceProvider;
    case 'employee': // "employee"
      return ROLES.employee;
    case 'partner': // "Partner"
      return ROLES.partner;
    case 'angelinvestoradmin': // "Angel investor admin"
      return ROLES.angelInvestorAdmin;
    case 'angelinvestorfounder': // "Angel investor founder"
      return ROLES.angelInvestorFounder;
    case 'angelinvestormember': // "Angel investor member"
      return ROLES.angelInvestorMember;
    case 'technicallicense':
      return ROLES.technicalLicense;
    default:
      return '';
  }
};

export const convertAuthUserApiResponse = (responseUser: LoginApiResponseUserType, email: string) => {
  const fallbackUserType: UserRoleType = responseUser.admin ? ROLES.superAdmin : '';
  const userMapped: UserAuthType = {
    userName: `${responseUser.first_name} ${responseUser.last_name}`,
    userType: mapUserRole(responseUser.role) || fallbackUserType,
    userId: responseUser.userId!.toString(),
    email,
    photo: responseUser.photo,
    serviceProviderId: responseUser.service_provider_id!,
    startupId: responseUser.startup_id,
    angelInvestorId: responseUser.angel_investor_id,
    technicalLicenseId: responseUser.technical_license_id,
    partnerId: responseUser.partner_id,
  };

  return userMapped;
};

export const convertLoginApiResponse = (response: LoginApiResponseType | undefined, request: LoginRequestPayload)
: LoginResponseType | undefined => {
  if (!identity.isObjWithChildren(response)) {
    return undefined;
  }

  return {
    accessToken: response?.jwt!,
    sessionId: response?.session!,
    user: convertAuthUserApiResponse(response?.user!, request.username),
  };
};

export const convertChangePasswordRequestPayload = (request: ChangePasswordRequestPayload) => {
  const apiRequest: ChangePasswordApiRequestPayload = {
    old_password: request.oldPassword,
    new_password: request.password,
  };
  return apiRequest;
};

export const convertResetPasswordRequestPayload = (
  request: ResetPasswordOtpRequestPayload,
): ResetPasswordOtpApiRequestPayload => ({
  ...request,
  confirm_password: request.confirmPassword,
})
