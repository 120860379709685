import { Rule } from 'antd/lib/form';
import React, { ReactElement } from 'react';

import {
  Input,
} from 'components/components';

import { requiredValidation, validatePhoneNumber } from 'utils/constants/validationRules';

interface FormFieldConfig {
  type: 'field';
  label: string;
  name: string;
  rules?: Rule[];
  component: ReactElement;
  required?: boolean
}

interface FormHeadingConfig {
  type: 'heading';
  title: string;
  name: string;
}

type FormElementConfig = FormFieldConfig | FormHeadingConfig;

export const editTechnicalLicenseFormElements: FormElementConfig[] = [
  {
    type: 'heading',
    title: 'Technical license Details',
    name: 'heading-technical-license',
  },
  {
    type: 'field',
    label: 'Name',
    name: 'name',
    rules: [requiredValidation],
    required: true,
    component: <Input.InputField type="text" />,
  },
  {
    type: 'field',
    label: 'Description',
    name: 'about',
    component: <Input.InputField.TextArea rows={3} />,
  },
  {
    type: 'field',
    label: 'Website',
    name: 'website',
    component: <Input.InputField type="text" />,
  },
  {
    type: 'heading',
    title: 'Founder Details',
    name: 'heading-founder',
  },
  {
    type: 'field',
    label: 'First Name',
    name: 'contactFirstName',
    rules: [requiredValidation],
    required: true,
    component: <Input.InputField type="text" />,
  },
  {
    type: 'field',
    label: 'Last Name',
    name: 'contactLastName',
    rules: [requiredValidation],
    required: true,
    component: <Input.InputField type="text" />,
  },
  {
    type: 'field',
    label: 'Email',
    name: 'contactEmail',
    rules: [requiredValidation],
    required: true,
    component: <Input.InputField type="email" />,
  },
  {
    type: 'field',
    label: 'Phone',
    name: 'contactPhone',
    rules: [validatePhoneNumber],
    component: <Input.InputField type="text" />,
  },
]

export default editTechnicalLicenseFormElements;
