import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import {
  selectCurrentSubCategoryTab,
  selectServiceFilters,
  selectServices,
  selectServicesCount,
} from 'redux/selectors/services';
import { selectRequests, selectUnsentRequests } from 'redux/selectors/request';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';
import {
  deleteService,
  getServiceById,
  getServices,
  getServicesCount,
} from 'redux/actions/services';
import { getUnsentRequests } from 'redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  services: selectServices(state),
  unsentRequests: selectUnsentRequests(state),
  requests: selectRequests(state),
  servicesCount: selectServicesCount(state),
  serviceSubCategory: selectCurrentSubCategoryTab(state),
  filters: selectServiceFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setDrawerBody,
    setIsDrawerOpen,
    getServiceById,
    getServices,
    getUnsentRequests,
    getServicesCount,
    deleteService,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
