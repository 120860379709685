import { createSelector } from 'reselect';

import {
  MembersStateType,
  AngelInvestorMembersStateType,
} from '../../../types/reduxTypes/StateTypes';
import { StateType } from '../../../types';

const selectMembersState = (state: StateType) => state.membersReducer;
const selectAIMembersState = (state: StateType) => state.angelInvestorMembersReducer;

export const selectEventFilterType = createSelector(
  selectMembersState,
  (state: MembersStateType) => state.selectedMember.eventFilter,
);

export const selectMemberDetails = createSelector(
  selectMembersState,
  (state: MembersStateType) => state.selectedMember.data,
);

export const selectAIMemberDetails = createSelector(
  selectAIMembersState,
  (state: AngelInvestorMembersStateType) => state.selectedMember.data,
);

export const selectPastEvents = createSelector(
  selectMembersState,
  (state: MembersStateType) => state.selectedMember.pastEvents,
);

export const selectUpcomingEvents = createSelector(
  selectMembersState,
  (state: MembersStateType) => state.selectedMember.upcomingEvents,
);
