import httpClient, {
  ApiResponseType,
  GetResourceSuccessResponseType,
  HttpRequestConfig,
  NumberResponseType,
  queryBuilder,
} from 'packages/http_client';
import { WALLET_API, WALLET_TRANSACTION_API } from './utils';
import {
  BudgetType, BudgetTypeResponse,
  GetAmountSumsBe,
  GetAmountSumsBySpAndStartupQuery,
  // GetAmountSumsBySpAndAngelInvestorQuery,
  GetAmountSumsResponse,
  GetWalletQueryParams,
  GetAdminWalletQueryParams,
  GetWalletTransactionResponse,
  GetWalletTransactionsQueryParams,
  ResponseWalletTransactionType,
  WalletTransactionType,
  WalletType,
} from './types'
import {
  convertGetAmountSumsResponse, convertGetBudgetSumsResponse,
  convertGetWalletTransactionListResponse,
} from './utils/converters'

const getWalletApi = async (params: GetWalletQueryParams) => {
  const url = WALLET_API;

  const config: HttpRequestConfig = {
    params: queryBuilder({
      startup_id: params.startup_id,
    })
      .select(params.fieldSelection)
      .toObject(),
  };

  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<WalletType[]>>>(url, config);
};

const getBudgetApi = async (params: GetAdminWalletQueryParams) => {
  const url = WALLET_API;
  const { type, fieldSelection } = params;
  const { cohorts, startups, angelInvestors } = params.filters;

  const filterObject = {
    ...(cohorts?.length ? { 'startups.cohort_id': cohorts.join() } : {}),
  };

  const inObject = {
    ...(startups?.length
      ? { startup_id: startups }
      : type === 'startup'
        ? { '!startup_id': [0] }
        : {}
    ),
    ...(angelInvestors?.length
      ? { angel_investor_id: angelInvestors }
      : type === 'angel-investor'
        ? { '!angel_investor_id': [0] }
        : {}
    ),
  };

  const joinArray = type === 'startup' && cohorts?.length
    ? [{ tableName: 'startups', onId: 'startup_id' }]
    : [];

  const query = queryBuilder(filterObject)
    .in(inObject)
    .select(fieldSelection)
    .join(joinArray)
    .toObject();

  const config: HttpRequestConfig = {
    params: query,
  };

  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<BudgetTypeResponse[]>>>(url, config);
};

const getWalletTransactionsApi = async (params: GetWalletTransactionsQueryParams) => {
  const url = WALLET_TRANSACTION_API;
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetWalletTransactionResponse>>(url, config);
};

const getAmountSumsBySpAndStartup = async (params: GetAmountSumsBySpAndStartupQuery) => {
  const url = WALLET_TRANSACTION_API;
  const config: HttpRequestConfig = {
    params: queryBuilder({ service_provider_id: params.serviceProviderId, startup_id: params.startupId, hide: 0 })
      .select(['type', { field: 'amount', aggregation: 'sum' }])
      .in({ type: [1, 3] })
      .groupBy('type')
      .toObject(),
  }
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<GetAmountSumsBe[]>>>(url, config);
}

// const getAmountSumsBySpAndAngelInvestor = async (params: GetAmountSumsBySpAndAngelInvestorQuery) => {
//   const url = WALLET_TRANSACTION_API;
//   const config: HttpRequestConfig = {
//     params: queryBuilder({
//       service_provider_id: params.serviceProviderId,
//       angel_investor_id: params.angelInvestorId,
//       hide: 0,
//     })
//       .select(['type', { field: 'amount', aggregation: 'sum' }])
//       .in({ type: [1, 3] })
//       .groupBy('type')
//       .toObject(),
//   }
//   return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<GetAmountSumsBe[]>>>(url, config);
// }

export const getWallet = async (queryParams: GetWalletQueryParams): Promise<ApiResponseType<WalletType>> => {
  const response = await getWalletApi(queryParams);
  const { data, error, httpStatus } = response;

  return { error, httpStatus, data: data?.result![0] };
};

export const getBudget = async (queryParams: GetAdminWalletQueryParams): Promise<ApiResponseType<BudgetType>> => {
  const response = await getBudgetApi(queryParams);
  const { data, error, httpStatus } = response;

  const rawItem = data?.result?.[0];

  return { error, httpStatus, data: convertGetBudgetSumsResponse(rawItem) };
};

export const getWalletTransactions = async (queryParams: GetWalletTransactionsQueryParams):
  Promise<ApiResponseType<WalletTransactionType[]>> => {
  const response = await getWalletTransactionsApi(queryParams);
  const { data, error, httpStatus } = response;
  return {
    data: convertGetWalletTransactionListResponse(data?.result as ResponseWalletTransactionType[]), error, httpStatus,
  };
};

export const getWalletTransactionsCount = async (query: GetWalletTransactionsQueryParams):
  Promise<NumberResponseType> => {
  const response = await getWalletTransactionsApi({ ...query, $f: 'id__count' });
  const { data, error, httpStatus } = response;
  return { data: (data?.result! as { id__count: number }[])[0].id__count, error, httpStatus };
}

export const getAmountSumsBySpAndStartupApi = async (query: GetAmountSumsBySpAndStartupQuery):
  Promise<ApiResponseType<GetAmountSumsResponse>> => {
  const response = await getAmountSumsBySpAndStartup(query);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAmountSumsResponse(data?.result) };
}

// export const getAmountSumsBySpAndAngelInvestorApi = async (query: GetAmountSumsBySpAndAngelInvestorQuery):
//   Promise<ApiResponseType<GetAmountSumsResponse>> => {
//   const response = await getAmountSumsBySpAndAngelInvestor(query);
//   const { data, error, httpStatus } = response;
//   return { error, httpStatus, data: convertGetAmountSumsResponse(data?.result) };
// }
