import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { MemberType, AngelInvestorMemberType, TechnicalLicenseMemberType } from 'types/memberDetails';
import { PriceType } from 'types/services';
import { ServiceRequestItemDetailsType, ServiceRequestDocumentType, ServiceRequestType } from 'packages/service_request_repository';
import { initialState, reducer } from './reducer';
import {
  setService as setServiceAC,
  setQuantity as setQuantityAC,
  setNoOfDesk as setNoOfDeskAC,
  setPriceType as setPriceTypeAC,
  setTeamMember as setTeamMemberAC,
  setAITeamMember as setAITeamMemberAC,
  setThlTeamMember as setThlTeamMemberAC,
  setPlan as setPlanAC,
  setFurnished as setFurnishedAC,
  setAccommodationType as setAccommodationTypeAC,
  setOfficeType as setOfficeTypeAC,
  setTotalPrice as setTotalPriceAC,
  setRate as setRateAC,
  setDetails as setDetailsAC,
  setIsUserUAERes as setIsUserUAEResAC,
  setRelationToTM as setRelationToTMAC,
  setIsDependantUAE as setIsDependantUAEAC,
  setDependantGender as setDependantGenderAC,
  setDependantFullName as setDependantFullNameAC,
  setDependantDateOfBirth as setDependantDateOfBirthAC,
  setDependantChildPlaceOfBirth as setDependantChildPlaceOfBirthAC,
  setIsApplyingForDependant as setIsApplyingForDependantAC,
  setPassportFile as setPassportFileAC,
  setVisaFile as setVisaFileAC,
  setEmiratesIDFile as setEmiratesIDFileAC,
  setVisaProofFile as setVisaProofFileAC,
  setRequestDocuments as setRequestDocumentsAC,
  setDisplayDocuments as setDisplayDocumentsAC,
  setETAWithSignFile as setETAWithSignFileAC,
  setEmiratesIDNo as setEmiratesIDNoAC,
  setPassportNo as setPassportNoAC,
} from './actions';

const useRequestServiceHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setService = (payload: DataPayload<ServiceRequestType>) => {
    dispatch(setServiceAC(payload));
  }

  const setQuantity = (payload: DataPayload<number>) => {
    dispatch(setQuantityAC(payload));
  }

  const setNoOfDesk = (payload: DataPayload<number>) => {
    dispatch(setNoOfDeskAC(payload));
  }

  const setPriceType = (payload: DataPayload<PriceType>) => {
    dispatch(setPriceTypeAC(payload));
  }

  const setTeamMember = (payload: DataPayload<MemberType | undefined>) => {
    dispatch(setTeamMemberAC(payload));
  }

  const setAITeamMember = (payload: DataPayload<AngelInvestorMemberType | undefined>) => {
    dispatch(setAITeamMemberAC(payload));
  }

  const setThlTeamMember = (payload: DataPayload<TechnicalLicenseMemberType | undefined>) => {
    dispatch(setThlTeamMemberAC(payload));
  }

  const setPlan = (payload: DataPayload<string>) => {
    dispatch(setPlanAC(payload));
  }

  const setFurnished = (payload: DataPayload<boolean>) => {
    dispatch(setFurnishedAC(payload));
  }

  const setAccommodationType = (payload: DataPayload<string>) => {
    dispatch(setAccommodationTypeAC(payload));
  }

  const setOfficeType = (payload: DataPayload<string>) => {
    dispatch(setOfficeTypeAC(payload));
  }

  const setTotalPrice = (payload: DataPayload<number>) => {
    dispatch(setTotalPriceAC(payload));
  }

  const setRate = (payload: DataPayload<number>) => {
    dispatch(setRateAC(payload));
  }

  const setDetails = (payload: DataPayload<ServiceRequestItemDetailsType>) => {
    dispatch(setDetailsAC(payload));
  }

  const setIsUserUAERes = (payload: DataPayload<string>) => {
    dispatch(setIsUserUAEResAC(payload));
  }

  const setRelationToTM = (payload: DataPayload<string>) => {
    dispatch(setRelationToTMAC(payload));
  }

  const setIsDependantUAE = (payload: DataPayload<string>) => {
    dispatch(setIsDependantUAEAC(payload));
  }

  const setDependantGender = (payload: DataPayload<string>) => {
    dispatch(setDependantGenderAC(payload));
  }

  const setDependantFullName = (payload: DataPayload<string>) => {
    dispatch(setDependantFullNameAC(payload));
  }

  const setDependantDateOfBirth = (payload: DataPayload<string>) => {
    dispatch(setDependantDateOfBirthAC(payload));
  }

  const setChildPlaceOfBirth = (payload: DataPayload<string>) => {
    dispatch(setDependantChildPlaceOfBirthAC(payload));
  }

  const setIsApplyingForDependant = (payload: DataPayload<boolean>) => {
    dispatch(setIsApplyingForDependantAC(payload));
  }

  const setPassportFile = (payload: DataPayload<string | Blob>) => {
    dispatch(setPassportFileAC(payload));
  }

  const setPassportNo = (payload: DataPayload<string>) => {
    dispatch(setPassportNoAC(payload));
  }

  const setEmiratesIDNo = (payload: DataPayload<string>) => {
    dispatch(setEmiratesIDNoAC(payload));
  }

  const setVisaFile = (payload: DataPayload<string | Blob>) => {
    dispatch(setVisaFileAC(payload));
  }

  const setVisaProofFile = (payload: DataPayload<string | Blob>) => {
    dispatch(setVisaProofFileAC(payload));
  }

  const setEmiratesIDFile = (payload: DataPayload<string | Blob>) => {
    dispatch(setEmiratesIDFileAC(payload));
  }
  const setRequestDocuments = (payload: DataPayload<ServiceRequestDocumentType[]>) => {
    dispatch(setRequestDocumentsAC(payload));
  }

  const setDisplayDocuments = (payload: DataPayload<boolean>) => {
    dispatch(setDisplayDocumentsAC(payload));
  }

  const setETAWithSignFile = (payload: DataPayload<string | Blob>) => {
    dispatch(setETAWithSignFileAC(payload));
  }

  return {
    state,
    setService,
    setQuantity,
    setNoOfDesk,
    setPriceType,
    setTeamMember,
    setAITeamMember,
    setThlTeamMember,
    setPlan,
    setFurnished,
    setAccommodationType,
    setOfficeType,
    setTotalPrice,
    setRate,
    setDetails,
    setIsUserUAERes,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setIsApplyingForDependant,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setRequestDocuments,
    setDisplayDocuments,
    setETAWithSignFile,
    setPassportNo,
    setEmiratesIDNo,
  };
}

export default useRequestServiceHook;
