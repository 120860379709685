import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import showNotification from 'services/utils/showNotification';
import { CreateHub71EmployeePayload } from 'packages/people_repository/types/hub71_employee';
import {
  PeopleCountPayload,
  PeopleQuery,
  UpdateMemberPayload,
  UpdateAngelInvestorMemberPayload,
  UpdateTechnicalLicenseMemberPayload,
} from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { identity, isPartner } from 'utils';
import {
  CreateTeamMemberPayload,
  getInvolvementTypesList,
  createStartupTeamMember,
  createAngelInvestorTeamMember,
  createHub71Employee,
  getStartupTeamMemberCount,
  getStartupTeamMembers,
  getAngelInvestorMembers,
  GetTeamMembersParams,
  MemberCount,
  putStartupTeamMember,
  getStartupTeamMemberSelectedData,
  putAngelInvestorMember,
  putTechnicalLicenseMember,
  resendInvitationTeamMember,
} from 'packages/people_repository';
import history from 'history_instance';
import { PeopleFilters, ProfileType } from 'types/people';
import routes from 'routes';
import {
  setFounders,
  setIsLoading,
  setInvolvementTypes,
  setPeopleCount,
  setTeamMembers,
  setAITeamMembers,
  setAllMembers,
  types,
  setPeopleRoles,
  setPeopleSkills,
  getTeamMembers,
  getFounders,
  getAllMembers,
  setPeopleFilters,
  getPeopleCount, getAITeamMembers,
} from '../../actions/people'
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from '../../actions/workspace';
import { selectActiveTab } from '../../selectors/people';
import { getMyProfileData } from '../../actions/auth';
import { peopleFiltersDefault } from '../../utils';
import config from '../../../assets/config'
import { UserRoleType } from '../../../types/auth'
import { selectUserType } from '../../selectors/auth'
import { selectIsRowTableLayout } from '../../selectors/workspace'
import { getAngelInvestorMembers as getAngelInvestorMembersAC } from '../../actions/angelInvestors'

function * handleGetFounders(action: PayloadAction<PeopleQuery>) {
  const { query } = action.payload;
  const params: GetTeamMembersParams = { founder: 1, ...query };

  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getStartupTeamMembers, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setFounders({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetTeamMembers(action: PayloadAction<PeopleQuery>) {
  const { query } = action.payload;
  const params: GetTeamMembersParams = { founder: 0, ...query };

  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getStartupTeamMembers, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTeamMembers({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetAngelInvestorTeamMembers(action: PayloadAction<PeopleQuery>) {
  const { query } = action.payload;
  const params: GetTeamMembersParams = { ...query };

  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getAngelInvestorMembers, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAITeamMembers({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetPeopleCount(action: PayloadAction<PeopleFilters>) {
  const params: GetTeamMembersParams = { filters: action.payload };
  const { data: allData, error: allError, httpStatus: allHttpStatus } = yield call(getStartupTeamMemberCount, params);
  const payload: PeopleCountPayload = { all: 0, founders: 0, teamMembers: 0 };

  if (identity.isObjWithChildren(allError)) {
    showNotification(`Something went wrong: ${allError.message}`, true, allHttpStatus);
  } else {
    payload.all = allData[0].id__count;
    params.groupBy = 'founder';
    params.fieldSelection = ['founder'];

    const { data, error, httpStatus } = yield call(getStartupTeamMemberCount, params);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
    } else {
      for (const i in <MemberCount>data) {
        if (identity.isZero(data[i].founder)) {
          payload.teamMembers = data[i].id__count;
        } else {
          payload.founders = data[i].id__count;
        }
      }
    }
    yield put(setPeopleCount(payload));
  }
}

function * handleAddMember(action: PayloadAction<CreateTeamMemberPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(createStartupTeamMember, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    showNotification('Member successfully added.');
    const currentTab: ProfileType = yield select(selectActiveTab);
    yield put(setPeopleFilters({ data: peopleFiltersDefault }));
    yield put(getPeopleCount());
    switch (currentTab) {
      case 'team-members': {
        yield put(getTeamMembers({ query: {} }));
        break;
      }
      case 'founders': {
        yield put(getFounders({ query: {} }));
        break;
      }
      case 'all': {
        yield put(getAllMembers({ query: {} }));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleResendInvitation(action: PayloadAction<{ id: number }>) {
  yield put(setIsLoading({ isLoading: true }));
  const { id } = action.payload;

  const { error, httpStatus } = yield call(resendInvitationTeamMember, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Member successfully added.')
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleAddAIMember(action: PayloadAction<CreateTeamMemberPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { angelInvestorId } = action.payload;

  const { error, httpStatus } = yield call(createAngelInvestorTeamMember, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));

    showNotification('Member successfully added.');
    const currentTab: ProfileType = yield select(selectActiveTab);

    yield put(setPeopleFilters({ data: peopleFiltersDefault }));

    switch (currentTab) {
      case 'team-members': {
        yield put(getAITeamMembers({ query: {} }));
        break;
      }
      case 'founders': {
        yield put(getFounders({ query: {} }));
        break;
      }
      case 'all': {
        yield put(getAITeamMembers({ query: {} }));
        break;
      }
      default: {
        break;
      }
    }

    const userType: UserRoleType = yield select(selectUserType);
    const isRowTableLayout: boolean = yield select(selectIsRowTableLayout);

    const angelInvestorQuery = {
      id: angelInvestorId!,
      limit: (isRowTableLayout && !isPartner(userType)) ? config.TABLE_DEFAULT_LIMIT : config.GRID_TABLE_DEFAULT_LIMIT,
      offset: 0,
    };

    yield put(getAngelInvestorMembersAC(angelInvestorQuery));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleAddHub71Employee(action: PayloadAction<CreateHub71EmployeePayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { error } = yield call(createHub71Employee, action.payload);

  if (!identity.isFalsy(error)) {
    showNotification(error, true, error.status);
  } else {
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    showNotification('Hub71 Employee successfully added.');
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateMemberById(action: PayloadAction<UpdateMemberPayload>) {
  const { member, route } = action.payload;

  const { data: { rows_count }, httpStatus, error } = yield call(putStartupTeamMember, member.id, member);

  if (identity.isObjWithChildren(error) || identity.isZero(rows_count)) {
    showNotification(`Something went wrong: ${error.message || 'Could not update member.'}`, true, httpStatus);
  } else {
    showNotification(`${member.firstName} ${member.lastName}'s profile updated successfully!`);

    if (route === routes.myProfileUrl) {
      yield put(getMyProfileData());
    }

    history.push(route);
  }
}

function * handleUpdateAngelInvestorMemberById(action: PayloadAction<UpdateAngelInvestorMemberPayload>) {
  const { member, route } = action.payload;

  const { data: { rows_count }, httpStatus, error } = yield call(putAngelInvestorMember, member.id, member);

  if (identity.isObjWithChildren(error) || identity.isZero(rows_count)) {
    showNotification(`Something went wrong: ${error.message || 'Could not update member.'}`, true, httpStatus);
  } else {
    showNotification(`${member.firstName} ${member.lastName}'s profile updated successfully!`);

    if (route === routes.myProfileUrl) {
      yield put(getMyProfileData());
    }

    history.push(route);
  }
}

function * handleUpdateTechnicalLicenseMemberById(action: PayloadAction<UpdateTechnicalLicenseMemberPayload>) {
  const { member, route } = action.payload;

  const { data: { rows_count }, httpStatus, error } = yield call(putTechnicalLicenseMember, member.id, member);
  if (identity.isObjWithChildren(error) || identity.isZero(rows_count)) {
    showNotification(`Something went wrong: ${error.message || 'Could not update member.'}`, true, httpStatus);
  } else {
    showNotification(`${member.firstName} ${member.lastName}'s profile updated successfully!`);
    if (route === routes.myProfileUrl) {
      yield put(getMyProfileData());
    }
    history.push(route);
  }
}

function * handleGetInvolvementTypes() {
  const { data, error, httpStatus } = yield call(getInvolvementTypesList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setInvolvementTypes({ data }));
  }
}

function * handleGetAllMembers(action: PayloadAction<PeopleQuery>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getStartupTeamMembers, action.payload.query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAllMembers({ data }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetPeopleSkills() {
  const params: GetTeamMembersParams = { fieldSelection: ['skills'] };
  const { data, error, httpStatus } = yield call(getStartupTeamMemberSelectedData, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setPeopleSkills({ data }));
  }
}

function * handleGetPeopleRoles() {
  const params: GetTeamMembersParams = { fieldSelection: ['job_title'] };
  const { data, error, httpStatus } = yield call(getStartupTeamMemberSelectedData, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setPeopleRoles({ data }));
  }
}

export default function * peopleSagas() {
  yield all([
    takeLatest(types.GET_INVOLVEMENT_TYPES, handleGetInvolvementTypes),
    takeLatest(types.GET_FOUNDERS, handleGetFounders),
    takeLatest(types.GET_TEAM_MEMBERS, handleGetTeamMembers),
    takeLatest(types.GET_AI_TEAM_MEMBERS, handleGetAngelInvestorTeamMembers),
    takeLatest(types.ADD_MEMBER, handleAddMember),
    takeLatest(types.ADD_AI_MEMBER, handleAddAIMember),
    takeLatest(types.ADD_HUB71_EMPLOYEE, handleAddHub71Employee),
    takeLatest(types.UPDATE_MEMBER_BY_ID, handleUpdateMemberById),
    takeLatest(types.UPDATE_ANGEL_INVESTOR_MEMBER_BY_ID, handleUpdateAngelInvestorMemberById),
    takeLatest(types.UPDATE_TECHNICAL_LICENSE_MEMBER_BY_ID, handleUpdateTechnicalLicenseMemberById),
    takeLatest(types.GET_PEOPLE_COUNT, handleGetPeopleCount),
    takeLatest(types.GET_ALL_MEMBERS, handleGetAllMembers),
    takeLatest(types.GET_PEOPLE_SKILLS, handleGetPeopleSkills),
    takeLatest(types.GET_PEOPLE_ROLES, handleGetPeopleRoles),
    takeLatest(types.RESEND_INVITATION, handleResendInvitation),
  ]);
}
