import { PayloadAction } from '@reduxjs/toolkit';

import { StartupFiltersType } from 'types/startups';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { types } from './actions';

const initialState: StartupFiltersType = {
  industries: [],
  locations: [],
  cohorts: [],
  stage: 0,
  capitalRaised: '',
  incentiveAmount: '',
  fieldSelection: [],
  searchString: '',
  status: [],
  excludeStatus: [],
}

const handleSetIndustries = (state: StartupFiltersType, payload: DataPayload<number[]>) => ({
  ...state, industries: payload.data,
});

const handleSetCohorts = (state: StartupFiltersType, payload: DataPayload<number[]>) => ({
  ...state, cohorts: payload.data,
});

const handleSetStage = (state: StartupFiltersType, payload: DataPayload<number>) => ({
  ...state, stage: payload.data,
});

const handleSetCapitalRaised = (state: StartupFiltersType, payload: DataPayload<string>) => ({
  ...state, capitalRaised: payload.data,
});

const handleSetIncentiveAmount = (state: StartupFiltersType, payload: DataPayload<string>) => ({
  ...state, incentiveAmount: payload.data,
});

const handleSetFilters = (state: StartupFiltersType, payload: StartupFiltersType) => payload;

const reducer = (
  state: StartupFiltersType,
  action: PayloadAction<DataPayload<number[] | number | string> | StartupFiltersType | object>,
) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_INDUSTRIES: {
      return handleSetIndustries(state, <DataPayload<number[]>>payload);
    }
    case types.SET_COHORTS: {
      return handleSetCohorts(state, <DataPayload<number[]>>payload);
    }
    case types.SET_STAGE: {
      return handleSetStage(state, <DataPayload<number>>payload);
    }
    case types.SET_CAPITAL_RAISED: {
      return handleSetCapitalRaised(state, <DataPayload<string>>payload);
    }
    case types.SET_INCENTIVE_AMOUNT: {
      return handleSetIncentiveAmount(state, <DataPayload<string>>payload);
    }
    case types.SET_FILTERS: {
      return handleSetFilters(state, <StartupFiltersType>payload);
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
