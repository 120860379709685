import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { TechnicalLicenseFiltersType } from 'types/technicalLicenses';

export const types = {
  RESET_FILTERS: 'RESET_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  SET_ACCEPTANCE_LETTER: 'SET_ACCEPTANCE_LETTER',
  SET_INCENTIVE_AGREEMENT: 'SET_INCENTIVE_AGREEMENT',
  SET_SECTOR: 'SET_SECTOR',
};

export const resetFilters = (payload: object = {}) => ({
  type: types.RESET_FILTERS,
  payload,
});

export const setFilters = (payload: TechnicalLicenseFiltersType) => ({
  type: types.SET_FILTERS,
  payload,
});

export const setSector = (payload: DataPayload<number[]>) => ({
  type: types.SET_SECTOR,
  payload,
});
