import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Form, Button } from 'antd';

import {
  identity, parseNumberToPrice, isAdmin, isSuperAdmin, requiredValidation,
} from 'utils';
import { IncentiveAgreementDetailsDrawerProps } from 'types/angelInvestors/editAngelInvestor';
import { Input, RenderIf, Text } from 'components/components'
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getAngelInvestorFile } from '../../utils';
import {
  DetailsDrawerRow,
} from '..';

import './IncentiveAgreementDetailsDrawer.scss';
import approvedRejectedOptions from '../../../AngelInvestorsOverview/utils/constants'

const getStatusMessage = (statusId: number, name: string) => {
  switch (statusId) {
    case 5:
    case 52:
      return 'Incentive Agreement Sent. Awaiting Hub71 CEO\'s signature.';
    case 51:
      return `Incentive Agreement Sent. Awaiting ${name}'s signature.`;
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
      return 'Approved.';
    case 11:
      return `Acceptance Letter Sent. Awaiting ${name}'s signature.`;
    default:
      return '';
  }
};

const IncentiveAgreementDetailsDrawer = (props: IncentiveAgreementDetailsDrawerProps) => {
  const [form] = Form.useForm();
  const statusFieldValue = Form.useWatch('status', form);

  const {
    incentiveAgreement,
    angelInvestorID,
    incentiveAgreementStatus,
    info,
    actions,
    userType,
  } = props;

  const isAdminOrSuperAdmin = isAdmin(userType) || isSuperAdmin(userType);

  const {
    startDate: iaStartDate,
    endDate: iaEndDate,
    file,
    walletAmount,
  } = incentiveAgreement;

  const {
    updateAngelInvestorById,
    setIsDrawerOpen,
    getIncentiveAgreementById,
  } = actions;

  const { id } = info;
  const statusId = info.status.id;

  const startDate = useMemo(() => (
    identity.isTruthyString(iaStartDate)
      ? moment(iaStartDate).format(formatSlashMDY)
      : 'Not specified'
  ), [iaStartDate]);

  const endDate = useMemo(() => (
    identity.isTruthyString(iaEndDate)
      ? moment(iaEndDate).format(formatSlashMDY)
      : 'Not specified'
  ), [iaEndDate]);

  const status = getStatusMessage(statusId, info.name);

  const handleSubmit = ({
    status: statusAdgm,
    reason,
  }: {
    status: number,
    reason: string
  }) => {
    updateAngelInvestorById({
      id,
      angelInvestor: {
        incentiveAgreementApprovedStatus: statusAdgm,
        incentiveAgreementRejectReason: reason,
      },
    });
  }

  const handleCancel = () => {
    form.resetFields();
    setIsDrawerOpen(false);
  }

  useEffect(() => {
    if (identity.isPositiveNumber(angelInvestorID)) {
      getIncentiveAgreementById();
    }
  }, [angelInvestorID]);

  const options = [
    ...approvedRejectedOptions.map((
      { title, value },
    ) => ({ label: title, value })),
  ]

  return (
    <div>
      <RenderIf condition={identity.isTruthyString(status)}>
        {isAdminOrSuperAdmin && statusId > 2 ? (
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              status: incentiveAgreementStatus || undefined,
              reason: '',
            }}
            className="incentive-agreement-details-drawer"
            validateTrigger="onChange"
          >
            <Input
              name="status"
              label="Status"
              layout="horizontal"
            >
              <Input.Select
                labelInValue
                options={options}
                placeholder={status}
                allowClear
              />
            </Input>

            <DetailsDrawerRow label="Start Date" item={startDate} />
            <DetailsDrawerRow label="End Date" item={endDate} />
            <DetailsDrawerRow label="Wallet Amount" item={parseNumberToPrice(walletAmount, 'AED')} />

            <Input.DraggerUpload
              previewType="image"
              defaultFileList={[...getAngelInvestorFile(file)]}
              isRoundableButton
            />

            {statusFieldValue === 2 && (
              <Input
                name="reason"
                label="Rejection Reason"
                layout="vertical"
                required
                requiredMark
                rules={[requiredValidation]}
              >
                <Input.InputField.TextArea
                  size="large"
                  rows={4}
                  placeholder="Enter Rejection Reason"
                />
              </Input>
            )}

            <div className="drawer-buttons" style={{ marginTop: 16 }}>
              <Button
                type="default"
                className="secondary-button"
                onClick={handleCancel}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                className="primary-button"
                onClick={form.submit}
              >
                Save
              </Button>
            </div>
          </Form>
        ) : (
          <div className="incentive-agreement-details-drawer">
            <Text text={`Status: ${status}`} weight={400} />

            <DetailsDrawerRow label="Start Date" item={startDate} />
            <DetailsDrawerRow label="End Date" item={endDate} />
            <DetailsDrawerRow label="Wallet Amount" item={parseNumberToPrice(walletAmount, 'AED')} />

            <Input.DraggerUpload
              previewType="image"
              defaultFileList={[...getAngelInvestorFile(file)]}
              isRoundableButton
            />
          </div>
        )}
      </RenderIf>
    </div>
  )
}

export default IncentiveAgreementDetailsDrawer;
