import React from 'react'
import { Button } from 'antd';

import { NotificationIcon } from 'assets/icons';

import './Title.scss';

const Title = () => (
  <div className="activity-header-title">
    <h2 className="activity-header-title__text">Notifications</h2>

    <Button
      icon={<NotificationIcon />}
      type="text"
      size="large"
      shape="circle"
      className="activity-header-title__bell"
    />
  </div>
)

export default Title;
