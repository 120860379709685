import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { identity, requiredValidation, greaterThanZeroOnly } from 'utils';
import { AddFixedPricingOptionProps } from 'types/services/addService';
import { Input } from '../../../components';

import './RangeOptions.scss';

const RangeOptions = (props: AddFixedPricingOptionProps) => {
  const { setPriceUnit, units, selectedUnit } = props;
  const [amountMax, setAmountMax] = useState('');
  const [amountMin, setAmountMin] = useState('');
  const [unit, setUnit] = useState<number>(selectedUnit!);

  useEffect(() => {
    if (identity.isTruthyString(selectedUnit)) {
      setUnit(selectedUnit!);
    }
  }, [selectedUnit]);

  const validatePriceRange = async () => {
    if (parseInt(amountMin, 10) >= parseInt(amountMax, 10)) {
      throw new Error();
    }
  }

  return (
    <div>
      <Row className="range-option-form">
        <Col span={12}>
          <Input
            required
            layout="vertical"
            label="Price From"
            name="priceFrom"
            rules={[requiredValidation, greaterThanZeroOnly, { validator: validatePriceRange, message: 'Min price is larger than max' }]}
          >
            <Input.InputField
              type="number"
              className="input_fields"
              onChange={(e) => setAmountMin(e.target.value)}
              placeholder="Enter Price From"
            />
          </Input>
        </Col>

        <Col span={12}>
          <Input
            required
            layout="vertical"
            label="Price To"
            name="priceTo"
            rules={[requiredValidation, greaterThanZeroOnly, { validator: validatePriceRange, message: 'Max price is lesser than min' }]}
          >
            <Input.InputField
              type="number"
              className="input_fields"
              onChange={(e) => setAmountMax(e.target.value)}
              placeholder="Enter Price To"
            />
          </Input>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Input
            required
            layout="vertical"
            name="unit"
            label="Unit"
            rules={[requiredValidation]}
          >
            <Input.Select
              options={units?.map((priceUnit) => ({
                value: priceUnit.id, label: priceUnit.name, type: priceUnit.type, id: priceUnit.id,
              }))}
              value={unit}
              onChange={(e, option) => {
                setUnit(e);
                setPriceUnit(option);
              }}
              placeholder="Select Unit"
            />
          </Input>
        </Col>
      </Row>
    </div>
  );
}

RangeOptions.defaultProps = {
  selectedUnit: 0,
}

export default RangeOptions;
