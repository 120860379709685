import React, { useEffect } from 'react';

import { AdminStartupWalletProps } from 'types/componentTypes/Dashboard/AdminStartupWallet';
import AdminBudget from '../AdminBudget';

export const AdminStartupWallet = ({
  budget,
  filters,
  actions: {
    getAdminWallet,
  },
}: AdminStartupWalletProps) => {
  const {
    blocked, consumed, total, balance,
  } = budget;

  useEffect(() => {
    getAdminWallet({
      fieldSelection: [
        { field: 'blocked_amount', aggregation: 'sum' },
        { field: 'consumed_amount', aggregation: 'sum' },
        { field: 'total_wallet_amount', aggregation: 'sum' },
      ],
      type: 'startup',
      filters,
    });
  }, [filters])

  return (
    <AdminBudget
      blocked={blocked}
      consumed={consumed}
      total={total}
      balance={balance}
      type="startup"
    />
  )
}

export default AdminStartupWallet;
