/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableProps } from 'antd/es/table';

import { FilterType } from 'types/componentTypes/FilterButtonsProps';
import { identity } from 'utils';
import { ServiceCategoryType } from 'packages/service_repository';

const getFilterButtonsData = (
  dataSource: TableProps<any>['dataSource'],
  tableType: 'service-provider' | 'startup' | 'angel-investor'| 'technical-license-holder',
) : FilterType[] => {
  /* So Basically this Variable is added just to filter the empty filter button because of getting empty data from API */
  let filterArray: FilterType[] = [];
  if (identity.isFullArray(dataSource)) {
    switch (tableType) {
      case 'service-provider':
        filterArray = dataSource!.map((data: ServiceCategoryType) => ({
          id: data.id,
          type: 'category',
          value: data?.name || '',
        }));
        filterArray = filterArray.filter((data) => data.value !== '');
        return filterArray;
      case 'startup':
      case 'angel-investor':
      case 'technical-license-holder':
        filterArray = dataSource!.map((data) => ({
          id: data.value,
          type: 'industry',
          value: data.label,
        }));
        filterArray = filterArray.filter((data) => data.value !== '');
        return filterArray;
      default:
        return []
    }
  }
  return [];
};

export default getFilterButtonsData;
