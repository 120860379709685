import React, { useMemo } from 'react';
import type { MenuProps } from 'antd';

import Input from 'components/components/Input';

import './TableActionChangeUserRole.scss';

type TableActionChangeUserRoleProps = {
  onChangeRole: (role: string) => void
  founder?: boolean
  roleId?: number
}

const allItems: MenuProps['items'] = [
  { label: 'Founder', key: 'founder' },
  { label: 'Admin', key: 'admin' },
  { label: 'Member', key: 'member' },
];

export const TableActionChangeUserRole = ({
  onChangeRole,
  founder,
  roleId,
}: TableActionChangeUserRoleProps) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => onChangeRole(e.key);

  const items = useMemo(
    () => allItems.filter((item) => {
      if (!item) return false;
      if (item.key === 'founder' && founder) return false;
      if (item.key === 'admin' && roleId === 1) return false;

      return !(item.key === 'member' && !founder && roleId !== 1);
    }),
    [founder, roleId],
  );

  return (
    <Input className="navbar-dropdowns" layout="vertical">
      <Input.Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
      >
        Select new role
      </Input.Dropdown>
    </Input>
  )
};

TableActionChangeUserRole.defaultProps = {
  founder: false,
  roleId: 0,
}

export default TableActionChangeUserRole;
