import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import { greaterThanZeroOnly, requiredValidation } from 'utils';
import { Input, RenderIf } from 'components/components';
import { AddPricingOptionFormProps } from 'types/services/addService';
import { CloseIcon } from 'assets/icons';
import { enterYourPriceMessage } from '../../../../utils';

import './PricingOptionForm.scss';

const PricingOptionForm = (props: AddPricingOptionFormProps) => {
  const {
    pricingOption,
    onDeletePricingOption,
    hideDeleteOption,
    error,
    units,
  } = props;

  const [formValue, setFormValue] = useState(pricingOption);

  useEffect(() => {
    setFormValue(pricingOption);
  }, [pricingOption]);

  const onDeleteHandle = () => {
    onDeletePricingOption(pricingOption.id);
  }

  return (
    <Row>
      <Col span={24}>
        <Row className="pricing-option-form" gutter={8}>
          <Col span={12}>
            <Input
              required
              layout="vertical"
              name="amount"
              label="Price"
              rules={[greaterThanZeroOnly, requiredValidation]}
            >
              <Input.InputField
                type="number"
                className="input_fields"
                placeholder="Enter Price"
              />
            </Input>
            <RenderIf condition={error && formValue.amount.length < 1}>
              <p className="field-error">{enterYourPriceMessage}</p>
            </RenderIf>
          </Col>

          <Col span={12}>
            <Input
              layout="vertical"
              name="unit"
              label="Unit"
              required
              rules={[requiredValidation]}
            >
              <Input.Select
                options={units?.map((unit) => ({
                  value: unit.id, label: unit.name, type: unit.type, id: unit.id,
                }))}
                placeholder="Select Unit"
              >
                {formValue.option}
              </Input.Select>
            </Input>
          </Col>
          <RenderIf condition={!hideDeleteOption}>
            <Col onClick={onDeleteHandle} span={2}>
              <div className="delete-icon">
                <CloseIcon />
              </div>
            </Col>
          </RenderIf>
        </Row>
      </Col>
    </Row>
  )
};

export default PricingOptionForm;
