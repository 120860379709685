import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { selectMyProfileId, selectUserId, selectUserType } from 'redux/selectors/auth';
import {
  selectEventFilterType,
  selectMemberDetails,
  selectAIMemberDetails,
  selectPastEvents,
  selectUpcomingEvents,
} from 'redux/selectors/members';
import { StateType } from 'types';
import { selectStartupDetails } from 'redux/selectors/startups';

import { getStartupById } from 'redux/actions/startups';
import {
  getAngelInvestorMemberById,
} from '../../redux/actions/angelInvestorMemberDetails';
import {
  getMemberById,
  getPartnerById,
  getPastEvents,
  getUpcomingEvents,
} from '../../redux/actions/memberDetails';
import { getTechnicalLicenseMemberById } from '../../redux/actions/technicalLicenseMemberDetails';

import { getMyProfileData } from '../../redux/actions/auth';

const mapStateToProps = (state: StateType) => ({
  info: selectStartupDetails(state),
  selectedFilter: selectEventFilterType(state),
  selectedMember: selectMemberDetails(state),
  selectedAIMember: selectAIMemberDetails(state),
  pastEvents: selectPastEvents(state),
  upcomingEvents: selectUpcomingEvents(state),
  userType: selectUserType(state),
  userId: selectUserId(state),
  profileId: selectMyProfileId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getMemberById,
    getAngelInvestorMemberById,
    getPastEvents,
    getUpcomingEvents,
    getPartnerById,
    getMyProfileData,
    getStartupById,
    getTechnicalLicenseMemberById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
