import Input from '../../Input';

const {
  InputField, CheckboxGroup, InputNumber, UploadImage, Select, RadioTags, Switch,
} = Input;

export const inputTypes = {
  text: 'text',
  password: 'password',
  email: 'email',
  checkboxGroup: 'checkbox group',
  number: 'number',
  image: 'image',
  select: 'select',
  selectTags: 'select-tag',
  textArea: 'textarea',
  addButton: 'add-button',
  dragger: 'dragger',
  datetime: 'datetime',
  radio: 'radio',
  radioTags: 'radioTags',
  selectDrawer: 'selectDrawer',
  selectWithSearch: 'selectWithSearch',
  switch: 'switch',
};

export const inputComponents = {
  [inputTypes.text]: InputField,
  [inputTypes.email]: InputField,
  [inputTypes.password]: InputField,
  [inputTypes.checkboxGroup]: CheckboxGroup,
  [inputTypes.number]: InputNumber,
  [inputTypes.image]: UploadImage,
  [inputTypes.select]: Select,
  [inputTypes.selectWithSearch]: Select,
  [inputTypes.selectTags]: Input.SelectTags,
  [inputTypes.textArea]: InputField.TextArea,
  [inputTypes.addButton]: Input.AddButton,
  [inputTypes.dragger]: Input.DraggerUpload,
  [inputTypes.datetime]: Input.DatePicker,
  [inputTypes.radioTags]: RadioTags,
  [inputTypes.switch]: Switch,
};
