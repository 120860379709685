import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Form, Alert } from 'antd'

import {
  identity, isAdmin, isSuperAdmin, isStartupFounder, requiredValidation,
} from 'utils';
import { Input, Text } from 'components/components'
import { ADGMLicenseDetailsDrawerProps } from 'types/startups/editStartup';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getStartupFile } from '../../utils';
import ADGMLicenseUploadForm from '../ADGMLicenseUploadForm';
import DetailsDrawerRow from '../DetailsDrawerRow/View';

import './ADGMLicenseDetailsDrawer.scss';
import approvedRejectedOptions from '../../../AngelInvestorsOverview/utils/constants'
import { EditIcon } from '../../../../assets/icons'

const ADGMLicenseDetailsDrawer = (props: ADGMLicenseDetailsDrawerProps) => {
  const [form] = Form.useForm();
  const statusFieldValue = Form.useWatch('status', form);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {
    adgm, userStartupId, id, info, userType, actions,
  } = props;

  const isAdminOrSuperAdmin = isAdmin(userType) || isSuperAdmin(userType);
  const {
    getADGMLicenseByStartupId,
    updateStartupById,
    setIsDrawerOpen,
  } = actions;

  const { currentADGMLicense, aDGMApprovedStatus, currentADGMLicenseID } = info;

  const isCreateOrEditMode = useMemo(() => (
    !identity.isTruthyString(adgm?.aDGMLicenseUrl)
    // || (identity.isZero(adgm?.approvedRejectedStatus) && identity.isTruthyString(adgm?.approvedRejectedDate)
    || isEdit
  ), [adgm, isEdit, aDGMApprovedStatus]);

  const startupId = useMemo(
    () => (
      identity.isTruthyNumber(userStartupId) ? userStartupId : id),
    [id, userStartupId],
  );

  const status = useMemo(() => {
    let message = ''

    if (!currentADGMLicense) {
      message = 'Waiting for the ADGM License';
    }

    if (currentADGMLicense && aDGMApprovedStatus === 0) {
      message = 'Waiting for Hub71 review';
    }

    if (currentADGMLicense && aDGMApprovedStatus === 1) {
      message = 'Approved';
    }

    if (currentADGMLicense && aDGMApprovedStatus === 2) {
      message = 'Rejected. Waiting for the ADGM License.';
    }

    return message;
  }, [currentADGMLicense])

  const handleSubmit = ({
    status: statusAdgm,
    reason,
  }: {
    status: number,
    reason: string
  }) => {
    updateStartupById({
      id,
      startup: {
        currentADGMLicenseID,
        aDGMApprovedStatus: statusAdgm,
        adgmRejectReason: reason,
      },
    });

    setIsDrawerOpen(false);
  }

  const handleCancel = () => {
    form.resetFields();
    setIsDrawerOpen(false);
  }

  const options = [
    ...approvedRejectedOptions.map((
      { title, value },
    ) => ({ label: title, value })),
  ]

  useEffect(() => {
    getADGMLicenseByStartupId({ id: startupId });
  }, [startupId]);

  return isCreateOrEditMode ? (
    <ADGMLicenseUploadForm
      adgm={adgm}
      startupId={startupId}
      isEdit={isEdit}
      setIsEdit={setIsEdit}
    />
  ) : (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        status: aDGMApprovedStatus || undefined,
        reason: '',
      }}
      className="adgm-license-details-drawer"
      validateTrigger="onChange"
    >
      <div className="adgm-license-details-drawer adgm-license-details-drawer__status">
        {isAdminOrSuperAdmin && currentADGMLicense && !isEdit ? (
          <Input
            name="status"
            label="Status"
            layout="horizontal"
          >
            <Input.Select
              labelInValue
              options={options}
              placeholder={status}
              allowClear
            />
          </Input>
        ) : (
          <Text text={`Status: ${status}`} weight={400} />
        )}
      </div>

      {
        ((isAdminOrSuperAdmin && currentADGMLicense?.approvedRejectedReason)
        || (!isAdminOrSuperAdmin && aDGMApprovedStatus === 2 && currentADGMLicense?.approvedRejectedReason))
        && (
          <Alert
            message={`Reason: ${currentADGMLicense?.approvedRejectedReason}`}
            description={isAdminOrSuperAdmin ? '' : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Click
                <Button
                  type="link"
                  style={{ padding: '0 5px' }}
                  onClick={() => setIsEdit(true)}
                >
                  Edit
                </Button>
                to update ADGM info
              </div>
            )}
            type="warning"
            showIcon
          />
        )
      }

      <div className="adgm-license-details-drawer">
        {aDGMApprovedStatus !== 1 && isStartupFounder(userType) && (
          <div className="adgm-license-details-drawer__action">
            <Button className="ghost-button" icon={<EditIcon />} onClick={() => setIsEdit(true)}>
              Edit
            </Button>
          </div>
        )}

        <DetailsDrawerRow
          label="ADGM License No."
          item={adgm.aDGMLicenseNo || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Name"
          item={adgm.legalADGMName || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Signatory Name"
          item={adgm.legalSignatoryName || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Signatory Title"
          item={adgm.legalSignatoryTitle || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Signatory Email"
          item={adgm.legalSignatoryEmail || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="Registered WeWork Address"
          item={adgm.address || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Issue Date"
          item={
            identity
              .isTruthyString(adgm.issueDate) ? moment(adgm.issueDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <DetailsDrawerRow
          label="ADGM Expiry Date"
          item={
            identity
              .isTruthyString(adgm.expiryDate) ? moment(adgm.expiryDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <DetailsDrawerRow
          label="HUB71 Acceptance Letter Date"
          item={
            identity
              .isTruthyString(adgm.acceptanceDate)
              ? moment(adgm.acceptanceDate).format(formatSlashMDY)
              : NOT_SPECIFIED
          }
        />

        <Input.DraggerUpload
          previewType="image"
          defaultFileList={[...getStartupFile(adgm?.aDGMLicenseUrl!)]}
          isRoundableButton
        />
      </div>

      {isAdminOrSuperAdmin && currentADGMLicense && !isEdit && (
        form.isFieldsTouched(true) && (
          <>
            {statusFieldValue === 2 && (
              <Input
                name="reason"
                label="Rejection Reason"
                layout="vertical"
                required
                requiredMark
                rules={[requiredValidation]}
              >
                <Input.InputField.TextArea
                  size="large"
                  rows={4}
                  placeholder="Enter Rejection Reason"
                />
              </Input>
            )}

            <div className="drawer-buttons" style={{ marginTop: 16 }}>
              <Button
                type="default"
                className="secondary-button"
                onClick={handleCancel}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                className="primary-button"
                onClick={form.submit}
              >
                Save
              </Button>
            </div>
          </>
        ))}
    </Form>
  );
}

export default ADGMLicenseDetailsDrawer;
