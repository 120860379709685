import React, { useEffect, useMemo, useState } from 'react'
import { Spin, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';

import { DashboardType } from 'types/componentTypes/Dashboard';
import routes from 'routes';
import {
  isAngelInvestor,
  isEitherAdmin,
  isStartup,
  isTechnicalLicense,
  isServiceProvider,
} from 'utils';
import AngelInvestorWallet from 'components/components/AngelInvestorWallet';
import { RenderIf, StartupWallet } from 'components/components';
import {
  AdminAngelInvestorLifecycle,
  AdminAngelInvestorWallet,
  AdminStartupWallet,
  AdminStartupLifecycle,
  DashboardCards,
  RecentRequests,
  DashboardHeader,
} from './components';
import StaticNotification from '../components/Notification/StaticNotification';

import './Dashboard.scss';

const Dashboard = (props: DashboardType) => {
  const [activeTab, setActiveTab] = useState<'startups' | 'angelInvestors' | 'technicalLicense' | 'requests'>('startups');

  const {
    isLoading,
    userType,
    wallet,
    aiWallet,
    startupId,
    angelInvestorId,
    servicesCount,
    serviceProvidersCount,
  } = props;

  const {
    getWallet,
    getAIWallet,
    getDashboardData,
    getServicesSPCount,
  } = props.actions;

  const history = useHistory();

  const notificationMessage = useMemo(() => (
    `${servicesCount} services available for request from ${serviceProvidersCount} service providers.`
  ), [servicesCount, serviceProvidersCount]);

  const handleViewServices = () => {
    props.actions.setActiveTab({ activeTab: 'services' });
    if (isEitherAdmin(userType)) {
      history.push(routes.servicesAndSpsOverviewUrl);
    } else if (!isTechnicalLicense(userType)) {
      history.push(routes.servicesUrl);
    }
  }

  const handleViewServiceProviders = () => {
    props.actions.setActiveTab({ activeTab: 'service-providers' });
    if (isEitherAdmin(userType)) {
      history.push(routes.servicesAndSpsOverviewUrl);
    } else if (!isTechnicalLicense(userType)) {
      history.push(routes.servicesUrl);
    }
  }

  const handleOnChange = (activeKey: string) => {
    setActiveTab(activeKey as 'startups' | 'angelInvestors' | 'technicalLicense' | 'requests');
  }

  useEffect(() => {
    if (!isTechnicalLicense(userType)) {
      getDashboardData();
      getServicesSPCount();
    }
  }, []);

  useEffect(() => {
    if (isStartup(userType)) {
      getWallet({ startup_id: startupId, limit: 10, offset: 0 });
    }
  }, [startupId, userType]);

  useEffect(() => {
    if (isAngelInvestor(userType)) {
      getAIWallet({ angel_investor_id: angelInvestorId, limit: 10, offset: 0 });
    }
  }, [angelInvestorId, userType]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="dashboard">
          <DashboardHeader />

          <div className="dashboard-content">
            <RenderIf condition={isEitherAdmin(userType)}>
              <DashboardCards />

              <Tabs
                tabBarGutter={0}
                activeKey={activeTab}
                onChange={handleOnChange}
                className="tab-buttons-lg"
              >
                <Tabs.TabPane tab="Startups " key="startups" />
                <Tabs.TabPane tab="Angel Investors" key="angelInvestors" />
                {/* <Tabs.TabPane tab="Technical License Holders" key="technicalLicense" /> */}
                <Tabs.TabPane tab="Service Requests" key="requests" />
              </Tabs>

              {activeTab === 'startups' && (
                <>
                  <AdminStartupWallet />
                  <AdminStartupLifecycle />
                </>
              )}

              {activeTab === 'angelInvestors' && (
                <>
                  <AdminAngelInvestorWallet />
                  <AdminAngelInvestorLifecycle />
                </>
              )}

              {activeTab === 'requests' && (
                <>
                  <StaticNotification
                    type="info"
                    message={notificationMessage}
                    primaryButton={{ onClick: handleViewServices, text: 'View services' }}
                    secondaryButton={{ onClick: handleViewServiceProviders, text: 'View service providers' }}
                  />

                  <RecentRequests />
                </>
              )}
            </RenderIf>

            <RenderIf condition={isStartup(userType) || isAngelInvestor(userType)}>
              <DashboardCards />

              <RenderIf condition={isStartup(userType)}>
                <StartupWallet showInfo wallet={wallet} startupId={startupId} showTransactionButton includeGraph />

                <StaticNotification
                  type="info"
                  message={notificationMessage}
                  primaryButton={{ onClick: handleViewServices, text: 'View services' }}
                  secondaryButton={{ onClick: handleViewServiceProviders, text: 'View service providers' }}
                />
              </RenderIf>

              <RenderIf condition={isAngelInvestor(userType)}>
                <AngelInvestorWallet
                  showInfo
                  wallet={aiWallet}
                  angelInvestorId={angelInvestorId}
                  showTransactionButton
                  includeGraph
                />
              </RenderIf>

              <RecentRequests />
            </RenderIf>

            <RenderIf condition={isTechnicalLicense(userType)}>
              <RecentRequests />
            </RenderIf>

            <RenderIf condition={isServiceProvider(userType)}>
              <DashboardCards />

              <RecentRequests />
            </RenderIf>
          </div>
        </div>
      </Spin>
    </div>
  )
};

export default Dashboard;
