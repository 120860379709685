import React, { useCallback } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';

import RenderIf from 'components/components/RenderIf';
import { PreviewContentProps } from 'types/componentTypes';

const PreviewContent = (props: PreviewContentProps) => {
  const {
    fileName, fileSizeMb, previewType, fileType,
  } = props;

  const Component = useCallback(() => {
    switch (previewType) {
      case 'image': {
        return (
          <AiOutlineFileText className="file-icon" />
        );
      }
      case 'text':
      default: {
        return null;
      }
    }
  }, [previewType]);

  return (
    <div className="file-info">
      <Component />
      <div className="file-info--container">
        <p className="file-name" title={fileName}>{ fileName }</p>
        <RenderIf condition={fileSizeMb?.length !== 0}>
          <span className="file-size-container">{ `${fileSizeMb} MB • ${fileType}` }</span>
        </RenderIf>
      </div>
    </div>
  )
}

PreviewContent.defaultProps = {
  fileName: '',
  fileSizeMb: '',
  previewType: 'text',
  fileType: '',
}

export default PreviewContent;
