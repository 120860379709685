import React, { useEffect, useState, useMemo } from 'react';
import './ServiceRequestTotal.scss';
import { getTotalPrice } from 'packages/service_request_repository/api';
import { TotalPriceType } from 'types/services/servicesDrawer';

type DataPayload<DataType> = {
  data: DataType
}

const ServiceRequestTotal = (props: TotalPriceType) => {
  const [isLoading, setIsLoading] = useState(false)
  const [price, setPrice] = useState<number>()
  const [errorMsg, setErrorMsg] = useState<string>('')

  const {
    setTotalPrice,
    setPlan,
    setRate,
    noOfDesk,
    quantity,
    officeType,
    isStartupUser,
    isAngelInvestorUser,
    isTechnicalLicenseUser,
    memberId,
    passport,
    plan,
  } = props;

  const MEMBER_ID = useMemo(() => {
    if (isStartupUser) {
      return `memberID=${memberId}`
    }

    if (isAngelInvestorUser) {
      return `aiMemberID=${memberId}`
    }

    if (isTechnicalLicenseUser) {
      return `aiMemberID=${memberId}`
    }

    return ''
  }, [isStartupUser, isAngelInvestorUser, isTechnicalLicenseUser, memberId])

  useEffect(() => {
    setIsLoading(true)
    const accessToken = localStorage.getItem('access_token')
    const PLAN = plan ? `&plan=${plan}` : null
    const PASSPORT = passport ? `&passportNo=${passport}` : null
    const EMIRATE = '&emirate=Abu Dhabi'

    if (accessToken && MEMBER_ID && PASSPORT && PLAN) {
      const fetchData = async () => {
        setIsLoading(true)
        const etp = {} as DataPayload<number>
        setTotalPrice(etp)

        const data = await getTotalPrice({
          searchParams: `${MEMBER_ID}${PLAN}${PASSPORT}${EMIRATE}`,
          accessToken,
        })

        if (data.error) {
          setIsLoading(false)
          setErrorMsg(data.error.message)
          setPlan({ data: '' })
        } else if (data?.data) {
          const tp = {
            data: data?.data?.amount,
          } as DataPayload<number>

          setIsLoading(false)
          setErrorMsg('')

          if (data?.data?.amount) {
            setPrice(data.data.amount)
            setRate({
              data: data.data.rate,
            })
            setTotalPrice(tp)
          }
        }
      }

      fetchData().then();
    } else {
      setIsLoading(false)
    }
  }, [memberId, passport, plan, props.price, MEMBER_ID]);

  return (
    <div className="service-request-total--container">
      <div className="service-request__total">
        <div className="service-request__total-text">Total</div>
        <div className="service-request__total-amount">
          {
            isLoading && (
              <div className="service-request__spinner">
                <div className="service-request__loader" />
              </div>
            )
          }
          {price && !errorMsg && quantity && `AED ${price * quantity}`}
          {officeType && noOfDesk && quantity && `AED ${props.price! * noOfDesk * quantity}`}
          {!price && quantity && !officeType && !errorMsg && `AED ${props.price! * quantity}`}
          {!errorMsg && <span className="vat-text">&nbsp;(ex. VAT)</span>}
          {errorMsg && `${errorMsg}`}
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestTotal;
