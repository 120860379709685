import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { setIsDrawerOpen, setDrawerBody, setTableLayout } from 'redux/actions/workspace'
import {
  getTechnicalLicenses,
  getTechnicalLicenseCount,
  getTechnicalLicenseSectors,
  setTechnicalLicenseFilters,
  setTechnicalLicenseId,
} from 'redux/actions/technicalLicense'
import {
  selectTechnicalLicenseHolders,
  selectTechnicalLicenseFilters,
  selectTechnicalLicenseFiltersActive,
  selectTechnicalLicenseCount,
  selectIsLoading,
  selectSectorOptions,
} from 'redux/selectors/technicalLicenses';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  technicalLicenses: selectTechnicalLicenseHolders(state),
  technicalLicensesCount: selectTechnicalLicenseCount(state),
  sectorOptions: selectSectorOptions(state),
  isLoading: selectIsLoading(state),
  userType: selectUserType(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  filters: selectTechnicalLicenseFilters(state),
  filtersActive: selectTechnicalLicenseFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getTechnicalLicenses,
    getTechnicalLicenseCount,
    getTechnicalLicenseSectors,
    setIsDrawerOpen,
    setDrawerBody,
    setTableLayout,
    setTechnicalLicenseFilters,
    setTechnicalLicenseId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
