import React, { useState } from 'react'
import { useLocation } from 'react-router';
import { Button } from 'antd';

import { ServiceProviderInfoProps } from 'types/serviceProviders/ServiceProviderDetails';
import { composeEditServiceProviderUrl, composeServiceProviderMoreDetailsUrl } from 'routes/routeComposers';
import { isEitherAdmin, identity } from 'utils';
import routes from 'routes';
import {
  TitleWithActions, ProfileOverview, RenderIf, ConfirmModal,
} from 'components/components';

import StaticNotification from 'components/components/Notification/StaticNotification';

import './ServiceProviderInfo.scss';

const NOT_SPECIFIED = 'Not Specified';

const ServiceProviderInfo = (props: ServiceProviderInfoProps) => {
  const {
    name, category, websiteLink, imageLink, id, status,
  } = props.serviceProviderInfo;

  const {
    firstName, lastName, email, phone, role,
  } = props.contactPerson;

  const { setStatusServiceProviderDataById } = props.actions;

  const { userType, userServiceProviderId } = props;
  const [open, setOpen] = useState<boolean>(false);

  const location = useLocation();

  const isMyCompanyProfile = identity.isTruthyNumber(userServiceProviderId)
    && location.pathname === routes.companyProfileUrl;

  const editUrl = isEitherAdmin(userType) ? composeEditServiceProviderUrl(id) : routes.editCompanyProfileUrl;

  const handleSetStatusServiceProvider = () => {
    setStatusServiceProviderDataById({
      id,
      status: status ? 0 : 1,
    });

    setOpen(false);
  };

  return (
    <>
      <div className="service-provider-details__info-container">
        <TitleWithActions
          title="Service provider overview"
          buttonTitle={(isEitherAdmin(userType) || isMyCompanyProfile) ? 'profile' : 'More details'}
          link={(isEitherAdmin(userType) || isMyCompanyProfile) ? editUrl : composeServiceProviderMoreDetailsUrl(id)}
          isEditButton={isEitherAdmin(userType) || isMyCompanyProfile}
          showButton
        >
          <RenderIf condition={isEitherAdmin(userType)}>
            <Button
              className="secondary-button md"
              onClick={() => setOpen(true)}
            >
              {status ? 'Deactivate' : 'Activate'}
            </Button>
          </RenderIf>
        </TitleWithActions>

        {!status && (
          <div style={{ margin: '10px 0' }}>
            <StaticNotification
              type="warning"
              message="This Service Provider has been deactivated. Its services are no longer visible to users, except for Admins."
            />
          </div>
        )}

        <ProfileOverview
          imageUrl={imageLink}
          heading={name}
          subheading={`Service Provider | ${name}`}
          rightContent={[
            { label: 'category', value: category || NOT_SPECIFIED },
            { label: 'website', value: websiteLink || NOT_SPECIFIED },
            { label: 'contact person', value: firstName ? `${firstName} ${lastName}` : NOT_SPECIFIED },
            { label: 'email', value: email || NOT_SPECIFIED },
            { label: 'phone number', value: phone || NOT_SPECIFIED },
            { label: 'job title', value: role || NOT_SPECIFIED },
          ]}
          userType={userType}
        />
      </div>

      <ConfirmModal
        isOpen={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleSetStatusServiceProvider}
        okText={status ? 'Deactivate' : 'Activate'}
        title={status ? 'Deactivate Service Provider' : 'Activate Service Provider'}
        text={status
          ? `Are you sure you want to delete "${name}?" This action is irreversible. However, all
            previous Service Requests and their related data from this Provider will remain on the
            Portal.`
          : (
            `Are you sure you want to Activate "${name}?"`
          )}
      />
    </>
  );
}

export default ServiceProviderInfo;
