import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import routes from 'routes';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import {
  Breadcrumb,
  RenderIf,
  ExpandableTable,
  TitleWithActions,
  ProfileOverview,
  AboutTLH, Requests,
} from 'components/components'
import {
  identity,
  getRowKey,
  getImageLink,
  isEitherAdmin,
  isExpired,
} from 'utils';
import { TechnicalLicenseDetailsProps } from 'types/technicalLicenses/technicalLicenseDetails';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import {
  getTechnicalLicenseById as getStatusTechnicalLicense,
  TechnicalHolderType,
  TechnicalHolderStatusType,
} from 'packages/technical_holder_repository'
import { serviceProvidersColumnsTLH } from '../utils/constants/columns'

import './TechnicalLicenseDetails.scss';

const TechnicalLicenseDetails = (props: TechnicalLicenseDetailsProps) => {
  const {
    aboutTechnicalLicense,
    info,
    teamMembers,
    serviceRequests,
    userType,
    isLoading,
    userTechnicalLicenseId,
    technicalLicenseServiceProviders,
  } = props;

  const {
    getTechnicalLicenseById,
    getTechnicalLicenseMembers,
    getRequestsByOwnerId,
    getServiceRequestByOwnerId,
    getTechnicalLicenseServiceProviders,
  } = props.actions

  const params: { id?: string } = useParams();
  const { id } = params;

  const [technicalLicenseStatus, setTechnicalLicenseStatus] = useState<TechnicalHolderStatusType>();
  const [technicalLicenseDetails, setTechnicalLicenseDetails] = useState<TechnicalHolderType>();

  const {
    facebook,
    instagram,
    twitter,
    linkedIn,
    name,
    imageLink,
    location,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    about,
    website,
    companySector,
  } = info;

  const cleanDescriptionText = about.replace(/<\/?[^>]+(>|$)/g, '')

  const rightContent = useMemo(() => ([
    {
      label: 'Company Sector',
      value: identity.isTruthyString(companySector) ? companySector : NOT_SPECIFIED,
    },
    {
      label: 'Founder',
      value: (identity.isTruthyString(contactFirstName) || identity.isTruthyString(contactLastName)) ? `${contactFirstName} ${contactLastName}` : NOT_SPECIFIED,
    },
    {
      label: 'Founder Email',
      value: identity.isTruthyString(contactEmail) ? contactEmail : NOT_SPECIFIED,
    },
    {
      label: 'Founder Contact Phone',
      value: identity.isTruthyString(contactPhone) ? contactPhone : NOT_SPECIFIED,
    },
    {
      label: 'Description',
      value: identity.isTruthyString(cleanDescriptionText) ? cleanDescriptionText : NOT_SPECIFIED,
    },
  ]), [teamMembers, info]);

  const socialMediaLinks = useMemo(() => ({
    facebook, instagram, twitter, linkedIn, website,
  }), [facebook, instagram, twitter, linkedIn, website]);

  const technicalLicenseId = useMemo(
    () => (identity.isTruthyNumber(userTechnicalLicenseId) ? userTechnicalLicenseId : parseInt(id!, 10)),
    [id],
  );

  useEffect(() => {
    async function getTechnicalLicenseDetails() {
      if (identity.isTruthyNumber(technicalLicenseId)) {
        const res = await getStatusTechnicalLicense(technicalLicenseId!, true);
        setTechnicalLicenseStatus(res?.data?.status)
        setTechnicalLicenseDetails(res?.data)
      }
    }

    getTechnicalLicenseDetails().then()

    if (identity.isTruthyNumber(technicalLicenseId)) {
      getTechnicalLicenseById({ id: technicalLicenseId, owner: 'technical-license', isUpdate: true });
      getTechnicalLicenseMembers({ id: technicalLicenseId });
      getRequestsByOwnerId({ id: technicalLicenseId, owner: 'technical-license' });
      getTechnicalLicenseServiceProviders({ query: { technicalLicenseId, isPreload: true } });

      getServiceRequestByOwnerId({ id: technicalLicenseId, owner: 'technical-license' });
    }
  }, [technicalLicenseId]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="technicallicense-details">
          <RenderIf condition={technicalLicenseId !== userTechnicalLicenseId}>
            <Breadcrumb navigateTo={routes.technicalLicenseHoldersUrl} routeName="Technical License" />
          </RenderIf>

          <RenderIf condition={isExpired(technicalLicenseStatus?.code!)}>
            <StaticNotification
              message="The incentives period for this Technical license has ended and they no longer can apply for any incentives."
              type="warning"
            />
          </RenderIf>

          <TitleWithActions title="Technical License overview" />

          <ProfileOverview
            heading={name}
            imageUrl={getImageLink(imageLink!)}
            subheadingIsLocation={identity.isTruthyString(location)}
            subheading={location}
            rightContent={rightContent}
            socialMediaLinks={socialMediaLinks}
            userType={userType}
            type="grid"
          />

          <AboutTLH
            title="Documents"
            isTechnicalLicense
            description={aboutTechnicalLicense}
            technicalLicenseDetails={technicalLicenseDetails}
          />

          <RenderIf condition={isEitherAdmin(userType)}>
            <Requests component="angelinvestor" requests={serviceRequests!} />
          </RenderIf>

          <ExpandableTable
            className="service-providers"
            collapseHeader={`Service Providers working with ${name}`}
            dataSource={technicalLicenseServiceProviders}
            columns={serviceProvidersColumnsTLH}
            rowKey={getRowKey(technicalLicenseServiceProviders, 'technical-license-details-service-provider', 'id')}
          />
        </div>
      </Spin>
    </div>
  );
}

export default TechnicalLicenseDetails;
