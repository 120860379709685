import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectContactPerson, selectServiceProviderInfo } from 'redux/selectors/serviceProviders';
import { selectUserServiceProviderId, selectUserType } from 'redux/selectors/auth';
import { setStatusServiceProviderDataById } from 'redux/actions/serviceProviders';

const mapStateToProps = (state: StateType) => ({
  serviceProviderInfo: selectServiceProviderInfo(state),
  contactPerson: selectContactPerson(state),
  userType: selectUserType(state),
  userServiceProviderId: selectUserServiceProviderId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setStatusServiceProviderDataById,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
