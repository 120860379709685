import React from 'react';

import Filters from './components/Filters';
import Title from './components/Title';

import './Header.scss';

const Header = () => (
  <div className="activity-header">
    <Title />

    <Filters />
  </div>
)

export default Header;
