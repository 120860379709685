import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { composePeopleDetailsUrl } from 'routes/routeComposers';
import { TeamMembersProps } from 'types/teamMembers';
import { PeopleType } from 'types/people';
import {
  DRAWERS,
  getOffset,
  getRowKey,
  useTableHook,
  isAngelInvestor,
  isStartup,
  isAngelInvestorFounder,
  isStartupFounder,
  isTechnicalLicense,
} from 'utils';
import config from 'assets/config';
import {
  RenderIf,
  Table,
  gridPaginationOptions,
  paginationOptions,
} from '../components';
import { peopleOverviewColumns, aIPeopleOverviewColumns, thlPeopleOverviewColumns } from '../utils/constants/columns';
import { FILTERS_MAP } from '../components/SearchBar/utils/constants';

const TeamMembers = (props: TeamMembersProps) => {
  const {
    userType,
    aITeamMembers,
    teamMembers,
    technicalLicenseTeamMembers,
    actions,
    angelInvestorId,
    startupId,
    aITeamMembersCount,
    teamMembersCount,
    technicalLicenseTeamMembersCount,
    aIIsLoading,
    isLoading,
    technicalLicenseIsLoading,
    isRowTableLayout,
    filtersActive,
    filters,
  } = props;
  const history = useHistory();

  const addTeamMember = () => {
    actions.setDrawerBody({ drawerBody: DRAWERS.addMemberStartup });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
  }

  const addAITeamMember = () => {
    actions.setDrawerBody({ drawerBody: DRAWERS.addMemberAngelInvestor });
    actions.setIsDrawerOpen({ isDrawerOpen: true });
  }

  const openPeopleDetails = (record: PeopleType) => history.push(composePeopleDetailsUrl(record.id, 'startup'));
  const openAIPeopleDetails = (record: PeopleType) => history.push(composePeopleDetailsUrl(record.id, 'angel-investor'));
  const openTechnicalLicensePeopleDetails = (record: PeopleType) => history.push(composePeopleDetailsUrl(record.id, 'technical-licenses'));

  const {
    state,
    setLimit,
    setCurrentPage,
  } = useTableHook();

  const { limit, currentPage } = state;

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    let limitDefault;

    if (!isRowTableLayout) {
      limitDefault = paginationOptions.includes(limit) ? config.GRID_TABLE_DEFAULT_LIMIT : limit;
    } else {
      limitDefault = gridPaginationOptions.includes(limit) ? config.TABLE_DEFAULT_LIMIT : limit;
    }

    setLimit(limitDefault);
    setCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    if (
      (isRowTableLayout && paginationOptions.includes(limit))
      || (!isRowTableLayout && gridPaginationOptions.includes(limit))
    ) {
      const offset = getOffset(limit, currentPage);

      if (isAngelInvestor(userType)) {
        actions.getAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: true })
        actions.getAngelInvestorMembers({
          id: angelInvestorId, limit, offset, filters,
        });

        actions.getAngelInvestorTeamMembersCount(filters);
      }

      if (isStartup(userType)) {
        actions.getStartupTeamMembers({
          id: startupId, limit, offset, filters,
        });
        actions.getTeamMembersCount(filters);
      }

      if (isTechnicalLicense(userType)) {
        actions.getTechnicalLicenseMembers({
          id: startupId, limit, offset, filters,
        });
        actions.getTechnicalLicenseTeamMembersCount(filters);
      }
    }
  }, [
    startupId,
    angelInvestorId,
    limit,
    currentPage,
    filters,
    isRowTableLayout,
  ]);

  return (
    <div className="team-members content-container">
      <RenderIf condition={isStartup(userType)}>
        <Table
          isLoading={isLoading}
          onRow={(record) => ({ onClick: () => openPeopleDetails(record) })}
          onGridCardClick={openPeopleDetails}
          showSearchBar
          leftContent="add-button"
          addButtonText="member"
          placeholder="people"
          dataSource={teamMembers}
          columns={peopleOverviewColumns}
          showDisplayButtons={isStartupFounder(userType)}
          onAddButtonClick={addTeamMember}
          rowKey={getRowKey(teamMembers, 'members-overview-team-member', 'id')}
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          showCustomPagination
          total={teamMembersCount}
          filterButtonType="dropdown"
          filterDropdownElements={FILTERS_MAP.people}
          filtersActive={filtersActive}
        />
      </RenderIf>

      <RenderIf condition={isAngelInvestor(userType)}>
        <Table
          isLoading={aIIsLoading}
          onRow={(record) => ({ onClick: () => openAIPeopleDetails(record) })}
          onGridCardClick={openAIPeopleDetails}
          showSearchBar
          leftContent="add-button"
          addButtonText="member"
          placeholder="people"
          dataSource={aITeamMembers}
          columns={aIPeopleOverviewColumns}
          showDisplayButtons={isAngelInvestorFounder(userType)}
          onAddButtonClick={addAITeamMember}
          rowKey={getRowKey(aITeamMembers, 'members-overview-team-member', 'id')}
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          showCustomPagination
          total={aITeamMembersCount}
          filterButtonType="dropdown"
          filterDropdownElements={FILTERS_MAP.people}
          filtersActive={filtersActive}
        />
      </RenderIf>

      <RenderIf condition={isTechnicalLicense(userType)}>
        <Table
          isLoading={technicalLicenseIsLoading}
          onRow={(record) => ({ onClick: () => openTechnicalLicensePeopleDetails(record) })}
          onGridCardClick={openTechnicalLicensePeopleDetails}
          showSearchBar
          placeholder="people"
          dataSource={technicalLicenseTeamMembers}
          columns={thlPeopleOverviewColumns}
          showDisplayButtons
          onAddButtonClick={addTeamMember}
          rowKey={getRowKey(technicalLicenseTeamMembers, 'members-overview-team-member', 'id')}
          setLimit={setLimit}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          showCustomPagination
          total={technicalLicenseTeamMembersCount}
          filterButtonType="dropdown"
          filterDropdownElements={FILTERS_MAP.people}
          filtersActive={filtersActive}
        />
      </RenderIf>
    </div>
  );
}

export default TeamMembers;
