import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { composeStartupDetailsUrl } from 'routes/routeComposers';
import { StartupOverviewProps } from 'types/startups/startupOverview';
import { StartupType } from 'types/startups';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import {
  DRAWERS,
  getRowKey,
  isEitherAdmin,
  getOffset,
  useTableHook,
  identity,
  isPartner,
} from 'utils';
import { startupsColumns } from '../utils/constants/columns';
import {
  Table,
  RenderIf,
  gridPaginationOptions,
  paginationOptions,
  GridTable,
  FiltersWithIcons,
} from '../components';
import { FILTERS_MAP } from '../components/SearchBar/utils/constants';

import './StartupsOverview.scss';

const StartupOverView = (props: StartupOverviewProps) => {
  const {
    startups,
    sectorOptions,
    userType,
    startupCount,
    isLoading,
    isRowTableLayout,
    filters,
    filtersActive,
  } = props;
  const {
    getStartups,
    setIsDrawerOpen,
    setDrawerBody,
    getStartupCount,
    getStartupSectors,
    setStartupId,
    setStartupFilters,
    setTableLayout,
  } = props.actions;

  const { state, setLimit, setCurrentPage } = useTableHook();

  const { limit, currentPage, openFilters } = state;

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && identity.isEmptyArray(sectorOptions)) {
      getStartupSectors();
    }
  }, [isLoading]);

  useEffect(() => {
    let limitDefault;
    if (isRowTableLayout && !isPartner(userType)) {
      limitDefault = gridPaginationOptions.includes(limit) ? 10 : limit;
    } else {
      limitDefault = paginationOptions.includes(limit) ? 12 : limit;
    }
    setLimit(limitDefault);
    setCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    if ((isRowTableLayout && !isPartner(userType) && paginationOptions.includes(limit))
      || (gridPaginationOptions.includes(limit))) {
      getStartups({
        isPreload: true,
        limit,
        offset: getOffset(limit, currentPage),
        filters,
      });
      getStartupCount({ filters });
    }
  }, [limit, currentPage, filters, isRowTableLayout]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    setTableLayout({ isRowTableLayout: false })
  }, []);

  const openStartupDetails = (record: StartupType) => history.push(composeStartupDetailsUrl(record.id));

  const onAddButtonClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addStartup });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  const setSector = (payload: DataPayload<number | 'all'>) => {
    let industries: number[] | undefined = identity.isFullArray(filters.industries) ? [...filters.industries] : [];
    if (payload.data !== 'all') {
      const hasId = industries!.includes(payload.data as number);
      if (hasId) {
        industries = industries!.filter((item) => item !== payload.data as number);
      } else {
        industries!.push(payload.data as number);
      }
    } else {
      industries = undefined;
    }
    setStartupFilters({ ...filters, industries });
    setCurrentPage(1);
  }

  const openGetInTouchDrawer = (record: StartupType) => {
    setDrawerBody({ drawerBody: DRAWERS.getInTouchDrawer });
    setIsDrawerOpen({ isDrawerOpen: true });
    setStartupId({ data: record.id });
  }

  const setSearchString = (searchString: string) => {
    setStartupFilters({ ...filters, searchString });
    setCurrentPage(1);
  }

  return (
    <div className="content-container startups-overview">
      <RenderIf condition={!isPartner(userType)}>
        <Table
          searchString={filters.searchString}
          isLoading={isLoading}
          onRow={(record) => ({ onClick: () => openStartupDetails(record) })}
          onGridCardClick={openStartupDetails}
          showSearchBar
          showSearchField
          leftContent={isEitherAdmin(userType) ? 'add-button' : undefined}
          addButtonText="startup"
          placeholder="startup"
          dataSource={startups}
          columns={startupsColumns}
          showCustomPagination
          limit={limit}
          sectorOptions={sectorOptions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
          total={startupCount}
          showDisplayButtons
          tableLocation="startup"
          onAddButtonClick={onAddButtonClick}
          rowKey={getRowKey(startups, 'startup', 'id')}
          activeFilters={filters.industries}
          setFilter={setSector}
          filtersActive={filtersActive}
          setSearchString={setSearchString}
          filterButtonType="dropdown"
          filterDropdownElements={FILTERS_MAP.startups}
          showExportButton={isEitherAdmin(userType)}
          exportDataModel="startup"
          component="startup-two"
        />
      </RenderIf>

      <RenderIf condition={isPartner(userType)}>
        <FiltersWithIcons
          categories={[]}
          icons={[]}
          displayFilters
          filters={filters}
          open={openFilters}
          setFilter={setSector}
          setFilters={setStartupFilters}
          activeFilters={filters.industries}
          filterType="startup"
        />

        <GridTable
          isLoading={isLoading}
          dataSource={startups}
          columns={startupsColumns}
          rowKey={getRowKey(startups, 'startup', 'id')}
          component="startup"
          onGridCardClick={() => null}
          firstButtonTitle="View profile"
          secondButtonTitle="Get in touch"
          firstButtonAction={(record: StartupType) => history.push(composeStartupDetailsUrl(record?.id!))}
          secondButtonAction={(record: StartupType) => openGetInTouchDrawer(record)}
          hasDeleteOption={false}
          showCustomPagination
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
          total={startupCount}
        />
      </RenderIf>
    </div>
  );
}

export default StartupOverView;
