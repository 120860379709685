import React from 'react';
import { RcFile } from 'antd/lib/upload'

import { AddEmployeeFieldsCreatorParams, AddEmployeeMemberFormProps } from 'types/people';
import FormBuilder from '../../../components/FormBuilder';
import { addEmployeeFields, defaultValues, MEMBER_TYPES } from '../../utils/constants';

const AddEmployeeForm = (props: AddEmployeeMemberFormProps) => {
  const { beforeUpload } = props;

  const memberFieldsCreateData: AddEmployeeFieldsCreatorParams = {
    beforeHeadshotUpload: (file: RcFile) => beforeUpload('headshot', file),
  };

  return (
    <FormBuilder
      formFragment
      fields={addEmployeeFields(memberFieldsCreateData)}
      defaultValues={defaultValues[MEMBER_TYPES.hub71]}
    />
  )
}

export default AddEmployeeForm;
