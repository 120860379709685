import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { queryBuilder } from 'packages/http_client/utils/queryBuilder';
import config from 'assets/config';
import {
  addPriceForService,
  createNewService,
  createNewServiceBulk,
  getService,
  getAllServiceCategories,
  getServiceCategoriesFilter,
  getServicePriceForService,
  getServicesCount,
  getServicesList,
  getServiceSubCategories,
  getServiceSubCategoriesAll,
  updateService,
  createNewServiceCategory,
  createNewSubServiceCategory,
  ServiceCategoryParams,
  deleteCategories,
  deleteSubCategories,
  deleteCategoryById,
  ServiceCategoryType,
  ServiceSubCategoryType,
  updateServiceCategoryById,
  deleteSubCategoryById,
  updateServiceSubCategoryById,
  getPriceTypeFromUnit,
  getServiceTimeUnits,
  editServicePrice,
  ServicePriceType,
} from 'packages/service_repository';
import { ServiceFiltersType, ServicesQuery } from 'types/services';
import { getUnsentRequests } from 'redux/actions/request';
import showNotification from 'services/utils/showNotification';
import { UpdateServiceByIdPayload } from 'types/reduxTypes/ActionTypes/ServicesType';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import { AddServiceCategoryPayload, AddServiceSubCategoryPayload } from 'types/reduxTypes/ActionTypes/ServiceTypes';
import { UserRoleType } from 'types/auth';
import {
  identity, isServiceProvider, isStartup, isAngelInvestor, isTechnicalLicense,
} from 'utils';
import { AddServiceBulkType, AddServiceType } from 'types/services/addService'
import { selectDeleteIds } from 'redux/selectors/settingsOverview';
import { setDeleteIds, setIsLoading as setIsLoadingSettingsOverview } from 'redux/actions/settingsOverview';
import { buildServicePricePayload } from 'redux/utils';
import { selectUserServiceProviderId, selectUserType } from '../../selectors/auth';
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from '../../actions/workspace';
import {
  getServices,
  setAllServiceSubCategories,
  setIsLoading,
  setServiceById,
  setServiceCategories,
  setServices,
  setServicesCount,
  setServiceSubCategories,
  types,
  getServiceCategories,
  getAllServiceSubCategories,
  setServiceUnitsList,
  getServicesCount as getServicesCountAC,
  setServiceBulkErrors,
} from '../../actions/services';
import { selectServiceFilters } from '../../selectors/services';

function * handleGetServiceById(action: PayloadAction<IdPayload>) {
  yield all([
    setIsDrawerLoading({ isLoading: true }),
    setIsLoading({ isLoading: true }),
  ]);

  const { id } = action.payload;
  const { data: service, error, httpStatus } = yield call(getService, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service details: ${error.message}`, true, httpStatus);
  } else {
    const {
      data: servicePrice, error: pricingError, httpStatus: pricingErrorStatus,
    } = yield call(getServicePriceForService, id);

    if (identity.isObjWithChildren(pricingError)) {
      showNotification(`Error fetching service pricing details: ${pricingError.message}`, true, pricingErrorStatus);
    } else {
      service.servicePrice = servicePrice;
      service.priceFrom = servicePrice?.fromPrice?.toString()!;
      service.priceTo = servicePrice?.toPrice?.toString()!;
      service.priceType = getPriceTypeFromUnit(servicePrice?.unit);
      if (!identity.isTruthyString(service.priceUnits)) {
        service.priceUnits = service.priceType;
      }
    }

    yield put(setServiceById({ service }));
  }
  yield all([
    setIsDrawerLoading({ isLoading: false }),
    setIsLoading({ isLoading: false }),
  ]);
}

function * handleUpdateServiceById(action: PayloadAction<UpdateServiceByIdPayload>) {
  const { id, service } = action.payload;
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateService, id, service);
  const serviceProviderId: number = yield select(selectUserServiceProviderId);
  const serviceFilters: ServiceFiltersType = yield select(selectServiceFilters);
  const { servicePriceId } = service;

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error updating service: ${error.message}`, true, httpStatus);
  } else {
    const servicePrice: ServicePriceType = {
      fromPrice: service.offerPrice === 'fixed' ? Number(service?.amount) : Number(service.priceFrom),
      toPrice: service.offerPrice === 'fixed' ? Number(service?.amount) : Number(service.priceTo),
      serviceID: service.id,
      unitID: service.unit.id || 0,
      id: servicePriceId,
    };

    const {
      error: editPriceError, httpStatus: editPriceStatus,
    } = yield call(editServicePrice, servicePriceId, servicePrice);

    if (identity.isObjWithChildren(editPriceError)) {
      showNotification(`Error updating service price: ${editPriceError.message}`, true, editPriceStatus);
    }

    const serviceProviders = identity.isTruthyNumber(serviceProviderId) ? [serviceProviderId!] : [];

    yield all([
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
      put(getServices({
        limit: config.GRID_TABLE_DEFAULT_LIMIT,
        offset: 0,
        isPreload: true,
        filters: { ...serviceFilters, serviceProviderId: serviceProviders },
      })),
      put(getServicesCountAC({ filters: { ...serviceFilters, serviceProviderId: serviceProviders } })),
    ]);
    showNotification(`${service.name} updated successfully!`);
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleAddService(action: PayloadAction<DataPayload<AddServiceType>>) {
  const { data: servicePayload } = action.payload;

  yield put(setIsDrawerLoading({ isLoading: true }));

  const selectedServiceProviderId: number = (yield select(selectUserServiceProviderId)) ?? 0;
  const loginServiceProviderId: number = selectedServiceProviderId !== 0
    ? selectedServiceProviderId
    : (servicePayload.serviceProviderId || 0);

  if (!identity.isPositiveNumber(loginServiceProviderId)) {
    showNotification('Service provider is undefined', true);
  } else {
    if (!identity.isPositiveNumber(servicePayload.serviceProviderId)) {
      servicePayload.serviceProviderId = loginServiceProviderId;
    }

    const { data: serviceId, error, httpStatus } = yield call(createNewService, servicePayload);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Error adding new service: ${error.message}`, true, httpStatus);
    } else {
      const servicePricePayload = buildServicePricePayload(serviceId, servicePayload);
      const { error: priceError, httpStatus: status } = yield call(addPriceForService, servicePricePayload);

      if (identity.isObjWithChildren(priceError)) {
        showNotification(`Error adding service price: ${error.message}`, true, status);
      }

      yield all([
        put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
        put(setIsDrawerOpen({ isDrawerOpen: false })),
        put(getServices({
          limit: config.GRID_TABLE_DEFAULT_LIMIT,
          offset: 0,
          isPreload: true,
          filters: identity.isTruthyNumber(loginServiceProviderId)
            ? { serviceProviderId: [loginServiceProviderId!] }
            : {},
        })),
      ]);

      showNotification(`Service "${servicePayload.name}" successfully added`);
    }
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleAddServiceBulk(action: PayloadAction<DataPayload<AddServiceBulkType>>) {
  const { data: servicePayload } = action.payload;

  yield put(setIsDrawerLoading({ isLoading: true }));

  const { error, errors } = yield call(createNewServiceBulk, servicePayload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error adding new service: ${error.message}`, true, 400);

    yield put(setServiceBulkErrors({ errors }));
  } else {
    yield all([
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
    ]);

    showNotification('Services successfully added');
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetServices(action: PayloadAction<ServicesQuery>) {
  yield put(setIsLoading({ isLoading: true }));
  const { filters } = action.payload;

  const userType: UserRoleType = yield select(selectUserType);
  const serviceProviderId: number | undefined = isServiceProvider(userType)
    ? yield select(selectUserServiceProviderId)
    : undefined;

  const queryObject = {
    ...action.payload,
    filters: {
      ...filters,
      ...((isAngelInvestor(userType) || isTechnicalLicense(userType)) && { isAvailableForAiCommunity: 1 }),
    },
  };

  const { data: services, error, httpStatus } = yield call(
    getServicesList,
    queryObject,
    serviceProviderId,
  );

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching services: ${error.message}`, true, httpStatus);
  } else {
    if (isStartup(userType) || isAngelInvestor(userType)) {
      yield put(getUnsentRequests());
    }

    yield put(setServices({ services }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetServiceCategories() {
  const userType: UserRoleType = yield select(selectUserType);

  yield all([
    put(setIsDrawerLoading({ isLoading: true })),
    put(setIsLoadingSettingsOverview({ isLoading: true })),
  ]);

  let data;
  let error;
  let httpStatus;

  if (isAngelInvestor(userType) || isTechnicalLicense(userType)) {
    ({ data, error, httpStatus } = yield call(getServiceCategoriesFilter, 'angel-investor'));
  } else {
    ({ data, error, httpStatus } = yield call(getAllServiceCategories));
  }

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service categories: ${error.message}`, true, httpStatus);
  } else {
    yield put(setServiceCategories({ data }))
  }

  yield all([
    put(setIsDrawerLoading({ isLoading: false })),
    put(setIsLoadingSettingsOverview({ isLoading: false })),
  ]);
}

function * handleGetServiceSubCategories(action: PayloadAction<IdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { id } = action.payload;

  const { data, error, httpStatus } = yield call(getServiceSubCategories, id);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service sub-categories: ${error.message}`, true, httpStatus);
  } else {
    yield put(setServiceSubCategories({ data }))
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetAllServiceSubCategories() {
  yield all([
    put(setIsDrawerLoading({ isLoading: true })),
    put(setIsLoadingSettingsOverview({ isLoading: true })),
  ]);

  const { data, error, httpStatus } = yield call(getServiceSubCategoriesAll);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service sub-categories: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAllServiceSubCategories({ data }))
  }
  yield all([
    put(setIsLoadingSettingsOverview({ isLoading: false })),
    put(setIsDrawerLoading({ isLoading: false })),
  ]);
}

function * handleGetServicesCount(action: PayloadAction<ServicesQuery>) {
  const userType: UserRoleType = yield select(selectUserType);
  const { filters } = action.payload;

  const serviceProviderId: number | undefined = isServiceProvider(userType)
    ? yield select(selectUserServiceProviderId)
    : undefined;

  const params: ServicesQuery = {
    ...action.payload,
    filters: {
      ...filters,
      ...((isAngelInvestor(userType) || isTechnicalLicense(userType)) && { isAvailableForAiCommunity: 1 }),
    },
  };

  const { servicesCount, error, httpStatus } = yield call(getServicesCount, params, serviceProviderId);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setServicesCount({ servicesCount }));
  }
}

function * handleAddServiceCategory(action: PayloadAction<AddServiceCategoryPayload>) {
  const { data } = action.payload;
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { error: serviceError, data: serviceId } = yield call(createNewServiceCategory, data);
  if (serviceError || identity.isFalsy(serviceId)) {
    showNotification(serviceError?.message || 'Error creating a category.', true);
  } else {
    yield all([
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
      put(getServiceCategories({})),
    ]);
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleAddServiceSubCategory(action: PayloadAction<AddServiceSubCategoryPayload>) {
  const { data } = action.payload;
  yield put(setIsDrawerLoading({ isLoading: true }));
  const { error: startupError, data: startupId } = yield call(createNewSubServiceCategory, data);
  if (startupError || identity.isFalsy(startupId)) {
    showNotification(startupError?.message || 'Error creating a category.', true);
  } else {
    yield all([
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
      put(setDeleteIds({ data: [] })),
      put(getAllServiceSubCategories({})),
    ]);
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleDeleteServiceCategory() {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);

  const params: ServiceCategoryParams = queryBuilder()
    .in({ id: deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteCategories, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield all([
      put(setDeleteIds({ data: [] })),
      put(getServiceCategories()),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteServiceSubCategory() {
  yield put(setIsLoading({ isLoading: true }));
  const deleteIds: number[] = yield select(selectDeleteIds);

  const params: ServiceCategoryParams = queryBuilder()
    .in({ id: deleteIds })
    .toObject();

  const { error, httpStatus } = yield call(deleteSubCategories, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield all([
      put(setDeleteIds({ data: [] })),
      put(getAllServiceSubCategories()),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteServiceCategoryById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;

  const { error, httpStatus } = yield call(deleteCategoryById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(getServiceCategories());
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateServiceCategoryById(action: PayloadAction<ServiceCategoryType>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = action.payload;
  const { error, httpStatus } = yield call(updateServiceCategoryById, id, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield all([
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
      put(setDeleteIds({ data: [] })),
      put(getServiceCategories()),
    ]);
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleDeleteServiceSubCategoryById(action: PayloadAction<IdPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { id } = action.payload;

  const { error, httpStatus } = yield call(deleteSubCategoryById, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(getAllServiceSubCategories());
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateServiceSubCategoryById(action: PayloadAction<ServiceSubCategoryType>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = action.payload;
  const { error, httpStatus } = yield call(updateServiceSubCategoryById, id, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield all([
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
      put(setDeleteIds({ data: [] })),
      put(getAllServiceSubCategories()),
    ])
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetServiceUnits() {
  const { data, error, httpStatus } = yield call(getServiceTimeUnits);
  if (identity.isObjWithChildren(error)) {
    showNotification(error?.message, true, httpStatus);
  } else {
    yield put(setServiceUnitsList({ units: data }))
  }
}

export default function * servicesSagas() {
  yield all([
    takeLatest(types.GET_SERVICES, handleGetServices),
    takeLatest(types.GET_SERVICE_BY_ID, handleGetServiceById),
    takeLatest(types.UPDATE_SERVICE_BY_ID, handleUpdateServiceById),
    takeLatest(types.ADD_SERVICE, handleAddService),
    takeLatest(types.ADD_SERVICE_BULK, handleAddServiceBulk),
    takeLatest(types.GET_SERVICE_CATEGORIES, handleGetServiceCategories),
    takeLatest(types.GET_SERVICE_SUB_CATEGORIES, handleGetServiceSubCategories),
    takeLatest(types.GET_SERVICE_COUNT, handleGetServicesCount),
    takeLatest(types.GET_ALL_SERVICE_SUB_CATEGORIES, handleGetAllServiceSubCategories),
    takeLatest(types.ADD_SERVICE_CATEGORY, handleAddServiceCategory),
    takeLatest(types.ADD_SERVICE_SUBCATEGORY, handleAddServiceSubCategory),
    takeLatest(types.DELETE_SERVICE_CATEGORY, handleDeleteServiceCategory),
    takeLatest(types.DELETE_SERVICE_SUB_CATEGORY, handleDeleteServiceSubCategory),
    takeLatest(types.DELETE_SERVICE_CATEGORY_BY_ID, handleDeleteServiceCategoryById),
    takeLatest(types.UPDATE_SERVICE_CATEGORY_BY_ID, handleUpdateServiceCategoryById),
    takeLatest(types.DELETE_SERVICE_SUB_CATEGORY_BY_ID, handleDeleteServiceSubCategoryById),
    takeLatest(types.UPDATE_SERVICE_SUB_CATEGORY_BY_ID, handleUpdateServiceSubCategoryById),
    takeLatest(types.GET_SERVICE_UNITS_LIST, handleGetServiceUnits),
  ]);
}
