import React from 'react';
import { Button } from 'antd';

import { ResendInvitationButtonProps } from 'types/people';

export const ResendInvitationButton = ({
  userId,
  actions: {
    resendInvitation,
  },
}: ResendInvitationButtonProps) => {
  const onResendInvitation = () => {
    resendInvitation({ id: userId })
  }

  if (userId === -1) {
    return null;
  }

  return (
    <Button
      className="secondary-button md"
      onClick={onResendInvitation}
    >
      Resend Invitation
    </Button>
  );
}

export default ResendInvitationButton;
