import React, { useEffect } from 'react';
import { Form, Typography } from 'antd'

import Title from 'components/components/TitleWithActions/components/Title'
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { FormBuilder } from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { CreateTechnicalLicenseHolderByNumberPayload } from 'packages/technical_holder_repository';
import { AddTechnicalLicenseHolderDrawerProps } from 'types/technicalLicenseHolders/addTechnicalLicenseHolder';

import {
  addTechnicalLicenseHolderNumberFormFields,
} from './utils';

const { Text } = Typography;

const AddTechnicalLicenseHolderDrawer = ({
  error,
  isLoading,
  actions: {
    createTechnicalLicenseHolderByNumber,
  },
}: AddTechnicalLicenseHolderDrawerProps) => {
  const [form] = Form.useForm();

  const handleSubmit = (data: CreateTechnicalLicenseHolderByNumberPayload) => {
    createTechnicalLicenseHolderByNumber({ data })
  }

  useEffect(() => {
    if (error) {
      form.setFields([{
        name: 'technicalLicenseNumber',
        errors: [error],
      }]);
    }
  }, [error, form, isLoading]);

  return (
    <Form
      className="add-technical-license-holder-drawer form-standalone"
      form={form}
      onFinish={handleSubmit}
      onFinishFailed={scrollToFirstError}
      validateTrigger="onChange"
    >
      <Title title="Tech License Holder Details" size="m" />

      <Text type="secondary">
        Please provide a Technical License Number from the Airtable form.
        The system will automatically retrieve the relevant data, generate the Tech License
        Holder profile, and create the associated Founder profile.
      </Text>

      <FormBuilder
        oneColumn
        formFragment
        fields={addTechnicalLicenseHolderNumberFormFields}
      />

      <DrawerButton>Add Tech License Holder</DrawerButton>
    </Form>
  )
}

export default AddTechnicalLicenseHolderDrawer;
