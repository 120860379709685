import { ROLES } from 'utils'

export const ROLE_BASED_COMPONENT_TYPE: {[key: string]: string} = {
  [ROLES.superAdmin]: 'startup-sp',
  [ROLES.admin]: 'startup-sp',
  [ROLES.startupAdmin]: 'startup-services',
  [ROLES.startupFounder]: 'startup-services',
  [ROLES.startupTeamMember]: 'startup-services',
  [ROLES.serviceProvider]: 'services',
}

export default null;
