import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DashboardCardsProps } from 'types/componentTypes/Dashboard';
import {
  PeopleIcon,
  RequestIcon,
} from 'assets/icons';
import { RenderIf } from 'components/components';
import {
  isEitherAdmin,
  isStartup,
  isAngelInvestor,
} from 'utils';
import routes from 'routes';
import DashboardCard from './DashboardCard';

import './DashboardCards.scss';

export const DashboardCards = (props: DashboardCardsProps) => {
  const { count, userType } = props;
  const { getDashboardCount } = props.actions;

  const history = useHistory();

  useEffect(() => {
    getDashboardCount();
  }, []);

  return (
    <div className="dashboard-cards__container">
      <RenderIf condition={isStartup(userType)}>
        <DashboardCard
          icon={<PeopleIcon />}
          title="Team Members"
          count={count.members}
          buttonText="View team members"
          onClick={() => history.push(routes.teamMembersUrl)}
          color="purple"
        />
      </RenderIf>

      <RenderIf condition={isAngelInvestor(userType)}>
        <DashboardCard
          icon={<PeopleIcon />}
          title="Team Members"
          count={count.members}
          buttonText="View team members"
          onClick={() => history.push(routes.teamMembersUrl)}
          color="orange"
        />
      </RenderIf>

      <RenderIf condition={isEitherAdmin(userType)}>
        <DashboardCard
          icon={<RequestIcon />}
          title="Startups"
          count={count.startups}
          buttonText="View startups"
          onClick={() => history.push(routes.startupsUrl)}
          color="blue"
        />

        <DashboardCard
          icon={<RequestIcon />}
          title="Angel Investor"
          count={count.angelInvestor}
          buttonText="View Angel Investor"
          onClick={() => history.push(routes.angelInvestorsUrl)}
          color="blue"
        />

        {/* ToDo: requires implementation of login for Technical License Holders */}
        {/*   <DashboardCard */}
        {/*     icon={<RequestIcon />} */}
        {/*     title="Technical License Holders" */}
        {/*     count={count.technicalLicense} */}
        {/*     buttonText="View Technical License Holders" */}
        {/*     onClick={() => history.push(routes.technicalLicenseHoldersUrl)} */}
        {/*     color="blue" */}
        {/*   /> */}
      </RenderIf>

      <DashboardCard
        icon={<RequestIcon />}
        title="Requests waiting for your approval"
        count={count.requests}
        buttonText="View requests"
        onClick={() => history.push(routes.requestsUrl)}
        color="blue"
      />
    </div>
  );
}

export default DashboardCards;
