import {
  CreateStartupPayload,
  SectorType,
  StageType,
  InvestmentStageType,
  StartupQuery,
  CreateADGMLicensePayload,
  IncentiveAgreementType, UpdateAdgmLicenseByStartupIdPayload,
} from 'packages/startup_repository'
import {
  SetStartupsPayload,
  GetProviderServicesWithStartupPayload,
  GetStartupsByOwnerIdPayload,
  SetStartupCountPayload,
  SetStartupById,
  UpdateStartupDocumentStatusPayload,
  SetStartupForReviewPayload,
  UpdateStartupPayload, GetStartupByIdPayload,
} from 'types/reduxTypes/ActionTypes/StartupsTypes'
import {
  DataPayload,
  GetAllQueryPayload,
  IdPayload,
  IsLoadingPayload,
} from 'types/reduxTypes/ActionTypes';
import { ChangeRequestStatusPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { GetTeamMembersPayload, SetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import { ADGMLicenseType } from 'packages/startup_repository/types/adgm_license';
import { PeopleFilters } from 'types/people';
import {
  CreateIncentiveAgreementPayload,
  StartupFiltersType,
  UpdateIncentiveAgreementPayload,
} from 'types/startups';
import { ParamsPayload } from 'packages/http_client';
import { ServiceProviderType } from 'types/serviceProviders';
import { GetAmountSumsResponse } from 'packages/wallet_repository';

export const types = {
  CREATE_STARTUP_ADGM_LICENSE: 'CREATE_STARTUP_ADGM_LICENSE',
  GET_INCENTIVE_AGREEMENT_BY_ID: 'GET_INCENTIVE_AGREEMENT_BY_ID',
  SET_INCENTIVE_AGREEMENT_BY_ID: 'SET_INCENTIVE_AGREEMENT_BY_ID',
  GET_STARTUPS: 'GET_STARTUPS',
  SET_STARTUPS: 'SET_STARTUPS',
  SET_STARTUPS_COUNT: 'SET_STARTUPS_COUNT',
  GET_STARTUP_BY_ID: 'GET_STARTUP_BY_ID',
  SET_STARTUP_BY_ID: 'SET_STARTUP_BY_ID',
  GET_ADGM_LICENSE_BY_STARTUP_ID: 'GET_ADGM_LICENSE_BY_STARTUP_ID',
  SET_ADGM_LICENSE_BY_STARTUP_ID: 'SET_ADGM_LICENSE_BY_STARTUP_ID',
  SET_STARTUP_REQUESTS: 'SET_STARTUP_REQUESTS',
  SET_REQUEST_STATUS_CHANGE: 'SET_REQUEST_STATUS_CHANGE',
  CREATE_STARTUP: 'CREATE_STARTUP',
  GET_STARTUP_TEAM_MEMBERS: 'GET_STARTUP_TEAM_MEMBERS',
  SET_STARTUP_TEAM_MEMBERS: 'SET_STARTUP_TEAM_MEMBERS',
  UPDATE_STARTUP_BY_ID: 'UPDATE_STARTUP_BY_ID',
  UPDATE_ADGM_LICENSE_BY_STARTUP_ID: 'UPDATE_ADGM_LICENSE_BY_STARTUP_ID',
  SET_STARTUP_SERVICE_PROVIDER_BY_ID: 'SET_STARTUP_SERVICE_PROVIDER_BY_ID',
  GET_PROVIDER_SERVICES_WITH_STARTUP: 'GET_PROVIDER_SERVICES_WITH_STARTUP',
  GET_STARTUPS_BY_OWNER_ID: 'GET_STARTUPS_BY_OWNER_ID',
  GET_SECTORS: 'GET_SECTORS',
  GET_STAGES: 'GET_STAGES',
  GET_INVESTMENT_STAGES: 'GET_INVESTMENT_STAGES',
  SET_SECTOR_OPTIONS: 'SET_SECTOR_OPTIONS',
  SET_STAGES_OPTIONS: 'SET_STAGES_OPTIONS',
  SET_INVESTMENT_STAGE_OPTIONS: 'SET_INVESTMENT_STAGE_OPTIONS',
  SET_STARTUPS_IS_LOADING: 'SET_STARTUPS_IS_LOADING',
  GET_STARTUPS_COUNT: 'GET_STARTUPS_COUNT',
  GET_STARTUPS_IN_COHORT: 'GET_STARTUPS_IN_COHORT',
  SET_STARTUPS_IN_COHORT: 'SET_STARTUPS_IN_COHORT',
  GET_STARTUP_TEAM_MEMBERS_COUNT: 'GET_STARTUP_TEAM_MEMBERS_COUNT',
  SET_STARTUP_TEAM_MEMBERS_COUNT: 'SET_STARTUP_TEAM_MEMBERS_COUNT',
  SET_STARTUP_ID: 'SET_STARTUP_ID',
  CHANGE_STARTUP_DOCUMENT_STATUS: 'CHANGE_STARTUP_DOCUMENT_STATUS',
  SET_STARTUP_FOR_REVIEW: 'SET_STARTUP_FOR_REVIEW',
  SET_STARTUP_FILTERS: 'SET_STARTUP_FILTERS',
  RESET_STARTUPS: 'RESET_STARTUPS',
  GET_STARTUP_SERVICE_PROVIDERS: 'GET_STARTUP_SERVICE_PROVIDERS',
  SET_STARTUP_SERVICE_PROVIDERS: 'SET_STARTUP_SERVICE_PROVIDERS',
  SET_SUMS_FOR_SERVICE_PROVIDERS: 'SET_SUMS_FOR_SERVICE_PROVIDERS',
  CREATE_INCENTIVE_AGREEMENT: 'CREATE_INCENTIVE_AGREEMENT',
  UPDATE_INCENTIVE_AGREEMENT: 'UPDATE_INCENTIVE_AGREEMENT',
};

export const setSumsForServiceProviders = (payload: DataPayload<GetAmountSumsResponse[]>) => ({
  type: types.SET_SUMS_FOR_SERVICE_PROVIDERS,
  payload,
});

export const setStartupServiceProviders = (payload: DataPayload<ServiceProviderType[]>) => ({
  type: types.SET_STARTUP_SERVICE_PROVIDERS,
  payload,
});

export const getStartupServiceProviders = (payload: GetAllQueryPayload<ParamsPayload>) => ({
  type: types.GET_STARTUP_SERVICE_PROVIDERS,
  payload,
});

export const resetStartups = (payload: object = {}) => ({
  type: types.RESET_STARTUPS,
  payload,
});

export const updateADGMLicenseByStartupId = (payload: UpdateAdgmLicenseByStartupIdPayload) => ({
  type: types.UPDATE_ADGM_LICENSE_BY_STARTUP_ID,
  payload,
});

export const getADGMLicenseByStartupId = (payload: IdPayload) => ({
  type: types.GET_ADGM_LICENSE_BY_STARTUP_ID,
  payload,
});

export const setADGMLicenseByStartupId = (payload: DataPayload<ADGMLicenseType>) => ({
  type: types.SET_ADGM_LICENSE_BY_STARTUP_ID,
  payload,
});

export const setTeamMembersCount = (payload: DataPayload<number>) => ({
  type: types.SET_STARTUP_TEAM_MEMBERS_COUNT,
  payload,
});
export const getTeamMembersCount = (payload: PeopleFilters) => ({
  type: types.GET_STARTUP_TEAM_MEMBERS_COUNT,
  payload,
});

export const getStartupCount = (payload: object = {}) => ({
  type: types.GET_STARTUPS_COUNT,
  payload,
});

export const getStartups = (payload: StartupQuery = {}) => ({
  type: types.GET_STARTUPS,
  payload,
});

export const setStartups = (payload: SetStartupsPayload) => ({
  type: types.SET_STARTUPS,
  payload,
});

export const setStartupsCount = (payload: SetStartupCountPayload) => ({
  type: types.SET_STARTUPS_COUNT,
  payload,
});

export const getStartupById = (payload: GetStartupByIdPayload) => ({
  type: types.GET_STARTUP_BY_ID,
  payload,
});

export const setStartupById = (payload: SetStartupById) => ({
  type: types.SET_STARTUP_BY_ID,
  payload,
});

export const setRequestStatusChange = (payload: ChangeRequestStatusPayload) => ({
  type: types.SET_REQUEST_STATUS_CHANGE,
  payload,
});

export const createStartup = (payload: DataPayload<CreateStartupPayload>) => ({
  type: types.CREATE_STARTUP,
  payload,
});

export const getStartupTeamMembers = (payload: GetTeamMembersPayload) => ({
  type: types.GET_STARTUP_TEAM_MEMBERS,
  payload,
});

export const setStartupTeamMembers = (payload: SetTeamMembersPayload) => ({
  type: types.SET_STARTUP_TEAM_MEMBERS,
  payload,
});

export const updateStartupById = (payload: UpdateStartupPayload) => ({
  type: types.UPDATE_STARTUP_BY_ID,
  payload,
});

export const getProviderServicesWithStartup = (payload: GetProviderServicesWithStartupPayload) => ({
  type: types.GET_PROVIDER_SERVICES_WITH_STARTUP,
  payload,
});

export const getStartupsByOwnerId = (payload: GetStartupsByOwnerIdPayload) => ({
  type: types.GET_STARTUPS_BY_OWNER_ID,
  payload,
});

export const getStartupSectors = (payload: object = {}) => ({
  type: types.GET_SECTORS,
  payload,
});

export const getStartupStages = (payload: object = {}) => ({
  type: types.GET_STAGES,
  payload,
});

export const getStartupInvestmentStages = (payload: object = {}) => ({
  type: types.GET_INVESTMENT_STAGES,
  payload,
});

export const setSectorOptions = (payload: DataPayload<SectorType[]>) => ({
  type: types.SET_SECTOR_OPTIONS,
  payload,
});

export const setStageOptions = (payload: DataPayload<StageType[]>) => ({
  type: types.SET_STAGES_OPTIONS,
  payload,
});

export const setInvestmentStageOptions = (payload: DataPayload<InvestmentStageType[]>) => ({
  type: types.SET_INVESTMENT_STAGE_OPTIONS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_STARTUPS_IS_LOADING,
  payload,
});

export const getStartupsInCohort = (payload: IdPayload) => ({
  type: types.GET_STARTUPS_IN_COHORT,
  payload,
});

export const setStartupId = (payload: DataPayload<number>) => ({
  type: types.SET_STARTUP_ID,
  payload,
});

export const changeStartupDocumentStatus = (payload: UpdateStartupDocumentStatusPayload) => ({
  type: types.CHANGE_STARTUP_DOCUMENT_STATUS,
  payload,
});

export const setStartupForReview = (payload: SetStartupForReviewPayload) => ({
  type: types.SET_STARTUP_FOR_REVIEW,
  payload,
});

export const createStartupADGMLicense = (payload: CreateADGMLicensePayload) => ({
  type: types.CREATE_STARTUP_ADGM_LICENSE,
  payload,
});

export const getIncentiveAgreementById = (payload: object = {}) => ({
  type: types.GET_INCENTIVE_AGREEMENT_BY_ID,
  payload,
});

export const setIncentiveAgreementById = (payload: DataPayload<IncentiveAgreementType>) => ({
  type: types.SET_INCENTIVE_AGREEMENT_BY_ID,
  payload,
});

export const setStartupFilters = (payload: StartupFiltersType) => ({
  type: types.SET_STARTUP_FILTERS,
  payload,
});

export const createIncentiveAgreement = (payload: CreateIncentiveAgreementPayload) => ({
  type: types.CREATE_INCENTIVE_AGREEMENT,
  payload,
});

export const updateIncentiveAgreement = (payload: UpdateIncentiveAgreementPayload) => ({
  type: types.UPDATE_INCENTIVE_AGREEMENT,
  payload,
});
