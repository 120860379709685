import React, { useMemo } from 'react';
import { Steps, Button } from 'antd';

import { OnboardingProgressStepperProps } from 'types/onboardingProgressStepper';
import {
  identity,
  DRAWERS,
  isCommunity,
  isStartup,
  isAngelInvestor,
} from 'utils'
import './OnboardingProgressStepper.scss';
import StaticNotification from '../components/Notification/StaticNotification';

const { Step } = Steps;

const OnboardingProgressStepper = ({
  userType,
  startupStatus,
  angelInvestorStatus,
  actions,
}: OnboardingProgressStepperProps) => {
  const { setDrawerBody, setIsDrawerOpen } = actions;

  const status = startupStatus || angelInvestorStatus;

  const step = useMemo(() => {
    if (status === 'waiting_adgm_license') {
      return 1;
    }

    if (status === 'adgm_license') {
      return 2;
    }

    if (status === 'incentives_agreement_sent') {
      return 3;
    }

    if (status === 'incentives_agreement_signed_by_part1') {
      return 4;
    }

    return 0;
  }, [status])

  const startupIsNotCommunity = useMemo(() => (
    identity.isTruthyString(startupStatus) && !isCommunity(startupStatus) && isStartup(userType)
  ), [startupStatus, userType]);

  const angelInvestorIsNotCommunity = useMemo(() => (
    identity.isTruthyString(angelInvestorStatus) && !isCommunity(angelInvestorStatus) && isAngelInvestor(userType)
  ), [angelInvestorStatus, userType]);

  const handleUploadADGMLicense = () => {
    const drawerBody = isStartup(userType) ? DRAWERS.startupAdgmLicenseDetails : DRAWERS.aIAdgmLicenseDetails;

    setIsDrawerOpen({ isDrawerOpen: true });
    setDrawerBody({ drawerBody })
  }

  if (!startupIsNotCommunity && !angelInvestorIsNotCommunity) {
    return null;
  }

  return (
    <div className="onboarding-progress">
      <div className="onboarding-progress-info">
        <StaticNotification
          type="warning"
          message="Onboarding in Progress: Some features may be unavailable. Complete the steps below to unlock all features:"
        />
      </div>

      <div className="onboarding-progress-stepper">
        <Steps
          current={step}
          labelPlacement="vertical"
        >
          <Step title="Sign Acceptance Letter" />
          <Step
            title={(
              <>
                {status === 'waiting_adgm_license' ? (
                  <Button type="link" onClick={handleUploadADGMLicense}>Upload</Button>
                ) : 'Upload'}
                <span> ADGM License</span>
              </>
            )}
          />
          <Step title="Hub71 to approve ADGM License" />
          <Step title="Hub71 to sign the Incentive Agreement" />
          <Step title="Sign the Incentive Agreement" />
        </Steps>
      </div>
    </div>
  )
}

export default OnboardingProgressStepper;
