import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectCohorts } from 'redux/selectors/cohort';
import { selectStartups } from 'redux/selectors/workspace'
import { selectWalletFilters } from 'redux/selectors/wallet';
import { getCohorts } from 'redux/actions/cohort';
import { getStartups } from 'redux/actions/workspace';
import { setWalletFilters } from 'redux/actions/wallet'

const mapStateToProps = (state: StateType) => ({
  cohorts: selectCohorts(state),
  startups: selectStartups(state),
  filters: selectWalletFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getCohorts,
    getStartups,
    setWalletFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
