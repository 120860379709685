import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';

import {
  selectIsLoading,
  selectAngelInvestorCount,
  selectAngelInvestors,
  selectAngelInvestorFilters,
  selectAngelInvestorFiltersActive,
} from 'redux/selectors/angelInvestors';

import {
  getAngelInvestors,
  getAngelInvestorCount,
  setAngelInvestorFilters,
} from 'redux/actions/angelInvestors';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  angelInvestors: selectAngelInvestors(state),
  angelInvestorsCount: selectAngelInvestorCount(state),
  filters: selectAngelInvestorFilters(state),
  filtersActive: selectAngelInvestorFiltersActive(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAngelInvestors,
    getAngelInvestorCount,
    setAngelInvestorFilters,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
