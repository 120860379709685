import React from 'react';
import { Spin } from 'antd';

import { ProfileProps } from 'types/user';
import { ROLES } from 'utils';
import {
  StartupUser, SPPartnerUser, Hub71Admin, TechnicalLicense,
} from './components';

import './MyProfile.scss';

const UserMyProfile = (props: ProfileProps) => {
  const { userType, isLoading } = props;

  switch (userType) {
    case ROLES.technicalLicense:
      return (
        <div className="content-container">
          <Spin spinning={isLoading}>
            <TechnicalLicense />
          </Spin>
        </div>
      );
    case ROLES.angelInvestorAdmin:
    case ROLES.angelInvestorFounder:
    case ROLES.angelInvestorMember:
    case ROLES.startupAdmin:
    case ROLES.startupFounder:
    case ROLES.startupTeamMember: {
      return (
        <div className="content-container">
          <Spin spinning={isLoading}>
            <StartupUser />
          </Spin>
        </div>
      );
    }
    case ROLES.serviceProvider:
    case ROLES.partner:
    case ROLES.superAdmin: {
      return (
        <div className="content-container">
          <Spin spinning={isLoading}>
            <SPPartnerUser />
          </Spin>
        </div>
      )
    }
    case ROLES.admin: {
      return (
        <div className="content-container">
          <Spin spinning={isLoading}>
            <Hub71Admin />
          </Spin>
        </div>
      )
    }
    default: {
      return null;
    }
  }
}

export default UserMyProfile;
