import { AxiosRequestConfig } from 'axios';

import httpClient, { ApiResponseType, FORM_DATA_CONFIG, queryBuilder } from '../../http_client';
import {
  TLHADGMLicenseType,
  TLHCreateADGMLicenseApiResponseType,
  TLHCreateADGMLicensePayload,
  TLHResponseADGMLicenseType,
  TLHUpdateAdgmLicenseByTechnicalLicenseIdApiResponseType,
} from '../types';
import {
  convertCreateADMGLicenseRequestPayload,
  convertGetAdGMLicenseResponse,
  convertCreateTechnicalLicenseApiId,
  convertEditAdgmLicenseByTechnicalLicenseIdRequestPayload,
  TECHNICALLICENSE_ADGM_LICENSE,
} from '../utils';

const createTechnicalLicenseADGMLicenseApi = (payload: TLHCreateADGMLicensePayload) => {
  const url = TECHNICALLICENSE_ADGM_LICENSE;
  const requestPayload = convertCreateADMGLicenseRequestPayload(payload);
  return httpClient.post<ApiResponseType<TLHCreateADGMLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const getTechnicalLicenseADGMLicenseByTechnicalLicenseIdApi = (id: number, isPreload: boolean) => {
  const url = `${TECHNICALLICENSE_ADGM_LICENSE}`;
  const params = queryBuilder({ technical_license_id: id });
  if (isPreload) {
    params.preload();
  }
  const config: AxiosRequestConfig = { params: params.toObject() };
  return httpClient.get<ApiResponseType<TLHResponseADGMLicenseType>>(url, config);
}

const updateAdgmLicenseByTechnicalLicenseIdApi = (id: number, payload: TLHADGMLicenseType) => {
  const url = `${TECHNICALLICENSE_ADGM_LICENSE}/${id}`;
  const requestPayload = convertEditAdgmLicenseByTechnicalLicenseIdRequestPayload(payload);
  return httpClient.put<ApiResponseType<TLHUpdateAdgmLicenseByTechnicalLicenseIdApiResponseType>>(url, requestPayload);
};

export const updateAdgmLicenseByTechnicalLicenseId = async (id: number, payload: TLHADGMLicenseType) => {
  const response = await updateAdgmLicenseByTechnicalLicenseIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getTechnicalLicenseADGMLicenseByTechnicalLicenseId = async (id: number, isPreload: boolean): Promise<
  ApiResponseType<TLHADGMLicenseType>> => {
  const response = await getTechnicalLicenseADGMLicenseByTechnicalLicenseIdApi(id, isPreload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAdGMLicenseResponse(data?.result?.at(-1)) };
};

export const createTechnicalLicenseADGMLicense = async (payload: TLHCreateADGMLicensePayload) => {
  const response = await createTechnicalLicenseADGMLicenseApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertCreateTechnicalLicenseApiId(data?.id) };
}
