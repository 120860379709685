import React, { useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { LabeledValue } from 'antd/lib/select';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { identity } from 'utils';
import { CreateStartupPayload } from 'packages/startup_repository';
import { FormBuilder } from 'components/components';
import { AddStartupDrawerProps } from 'types/startups/addStartup';

import {
  defaultValues,
  addStartupFounderFields,
  addStartupFormFields,
  AddStartupsFieldsCreatorParams,
  useAddStartupHook,
} from './utils';
import Title from '../components/TitleWithActions/components/Title';

import './AddStartupDrawer.scss'

const AddStartupDrawer = (props: AddStartupDrawerProps) => {
  const {
    cohorts,
    sectorOptions,
    stageOptions,
    investmentStageOptions,
    actions: {
      createStartup,
      getCohorts,
      getStartupSectors,
      getStartupStages,
      getStartupInvestmentStages,
    },
  } = props;

  const [form] = Form.useForm();
  const {
    state, setFilteredSectorOptions,
  } = useAddStartupHook();

  const { filteredSectorOptions } = state;

  useEffect(() => {
    if (identity.isEmptyArray(cohorts)) {
      getCohorts();
    }
    if (identity.isEmptyArray(sectorOptions)) {
      getStartupSectors();
    }
    if (identity.isEmptyArray(stageOptions)) {
      getStartupStages();
    }
    if (identity.isEmptyArray(investmentStageOptions)) {
      getStartupInvestmentStages();
    }
  }, []);

  const handleSubmit = (data: CreateStartupPayload) => {
    const addDataValues = {
      ...data,
    };

    createStartup({ data: addDataValues });
  }

  const beforeLogoUpload = (file: RcFile) => {
    form.setFieldValue('logo', file as Blob);
    return false;
  }

  const beforeAcceptanceLetterUpload = (file: RcFile) => {
    form.setFieldValue('acceptanceLetter', file as Blob);
    return false;
  }

  const cohortsOptions: LabeledValue[] = useMemo(() => (
    cohorts.map((item) => ({ label: item.name, value: item.id }))), [cohorts]);

  const onPrimarySectorChange = (data: number) => {
    const options = sectorOptions.filter((option) => option.value !== data);
    setFilteredSectorOptions({ data: options });

    if (data === form.getFieldValue('secondarySector')) {
      form.resetFields(['secondarySector']);
    }
  }

  const secondarySectorOptions = useMemo(() => (identity.isFullArray(filteredSectorOptions)
    ? filteredSectorOptions : sectorOptions), [sectorOptions, filteredSectorOptions]);

  const startupFieldsCreateData: AddStartupsFieldsCreatorParams = {
    cohortsOptions,
    sectorOptions,
    secondarySectorOptions,
    stageOptions,
    investmentStageOptions,
    beforeLogoUpload,
    beforeAcceptanceLetterUpload,
    onPrimarySectorChange,
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="add-startup-drawer form-standalone"
      initialValues={defaultValues}
      onFinishFailed={scrollToFirstError}
    >
      <Title title="Startup Details" size="m" />
      <FormBuilder formFragment fields={addStartupFormFields(startupFieldsCreateData)} />

      <Title title="Founder Details" size="m" />
      <FormBuilder formFragment fields={addStartupFounderFields} />

      <DrawerButton>Add startup</DrawerButton>
    </Form>
  )
}

export default AddStartupDrawer;
