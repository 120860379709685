import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  getStartupById, getStartupTeamMembers, getStartupServiceProviders, updateStartupById,
} from 'redux/actions/startups';
import { updateMemberById } from 'redux/actions/people';
import { StateType } from 'types';
import {
  selectStartupName,
  selectAboutStartup,
  selectStatCards,
  selectStartupTeamMembers,
  selectStartupDetails,
  selectStartupDetailsRequests,
  selectIsLoading,
  selectPartnersStatCards,
  selectStartupServiceProviders,
  selectStartups,
} from 'redux/selectors/startups';
import { getRequestsByOwnerId } from 'redux/actions/request';
import { selectUserStartupId, selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  startupName: selectStartupName(state),
  aboutStartup: selectAboutStartup(state),
  statCards: selectStatCards(state),
  partnersStatCards: selectPartnersStatCards(state),
  teamMembers: selectStartupTeamMembers(state),
  info: selectStartupDetails(state),
  requests: selectStartupDetailsRequests(state),
  userType: selectUserType(state),
  isLoading: selectIsLoading(state),
  userStartupId: selectUserStartupId(state),
  startupServiceProviders: selectStartupServiceProviders(state),
  startups: selectStartups(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getStartupById,
    getStartupTeamMembers,
    getRequestsByOwnerId,
    getStartupServiceProviders,
    updateStartupById,
    updateMemberById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
