import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Form } from 'antd'

import { identity, isAdmin, isSuperAdmin } from 'utils';
import { Input } from 'components/components'
import { ADGMLicenseDetailsDrawerProps } from 'types/technicalLicenses/editTechnicalLicense';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getTechnicalLicenseFile } from '../../utils';
import { DetailsDrawerRow, TLHADGMLicenseUploadForm } from '..';

import './ADGMLicenseDetailsDrawer.scss';

const TLHADGMLicenseDetailsDrawer = (props: ADGMLicenseDetailsDrawerProps) => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {
    adgm, userTechnicalLicenseId, id, info, actions, userType,
  } = props;

  const {
    getADGMLicenseByTechnicalLicenseId,
    updateTechnicalLicenseById,
    setIsDrawerOpen,
  } = actions;

  const { currentADGMLicense, aDGMApprovedStatus, currentADGMLicenseID } = info;

  const isAdminOrSuperAdmin = isAdmin(userType) || isSuperAdmin(userType);

  const isCreateOrEditMode = useMemo(() => (
    !identity.isTruthyString(adgm?.aDGMLicenseUrl)
    || isEdit
  ), [adgm, isEdit, aDGMApprovedStatus])

  const technicalLicenseId = useMemo(
    () => (identity.isTruthyNumber(userTechnicalLicenseId) ? userTechnicalLicenseId : id),
    [id, userTechnicalLicenseId],
  );

  const handleSubmit = ({
    status: statusAdgm,
    reason,
  }: {
    status: number,
    reason: string
  }) => {
    updateTechnicalLicenseById({
      id,
      technicalLicense: {
        currentADGMLicenseID,
        aDGMApprovedStatus: statusAdgm,
        adgmRejectReason: reason,
      },
    });

    setIsDrawerOpen(false);
  }

  const handleCancel = () => {
    form.resetFields();
    setIsDrawerOpen(false);
  }

  useEffect(() => {
    getADGMLicenseByTechnicalLicenseId({ id: technicalLicenseId });
  }, [technicalLicenseId]);

  if (isCreateOrEditMode) {
    return (
      <TLHADGMLicenseUploadForm
        adgm={adgm}
        technicalLicenseId={technicalLicenseId}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    );
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      className="adgm-license-details-drawer"
    >
      <div className="adgm-license-details-drawer">
        <DetailsDrawerRow
          label="ADGM License No."
          item={adgm.aDGMLicenseNo || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Issue Date"
          item={
            identity
              .isTruthyString(adgm.issueDate) ? moment(adgm.issueDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <DetailsDrawerRow
          label="ADGM Expiry Date"
          item={
            identity
              .isTruthyString(adgm.expiryDate) ? moment(adgm.expiryDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <Input.DraggerUpload
          previewType="image"
          defaultFileList={[...getTechnicalLicenseFile(adgm?.aDGMLicenseUrl!)]}
          isRoundableButton
        />
      </div>

      {isAdminOrSuperAdmin && currentADGMLicense && (
        form.isFieldsTouched(true) && (
          <div className="drawer-buttons" style={{ marginTop: 16 }}>
            <Button
              type="primary"
              className="primary-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        ))}
    </Form>
  )
}

export default TLHADGMLicenseDetailsDrawer;
