import produce from 'immer';

import { DashboardStateType } from 'types/reduxTypes/StateTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { DashboardActionType } from 'types/reduxTypes/ActionTypes/Dashboard';
import { EventType } from 'packages/events_repository';
import { ResponseRequestType } from 'packages/service_request_repository';
import { types } from '../../actions/dashboard';
import { defaultDashboardState } from '../../utils';

const defaultState: DashboardStateType = defaultDashboardState;

const handleSetDashboardEvents = (state: DashboardStateType, payload: DataPayload<EventType[]>) => {
  state.events = payload.data;
  return state;
}

const handleSetDashboardRequests = (state: DashboardStateType, payload: DataPayload<ResponseRequestType[]>) => {
  state.requests = payload.data;
  return state;
}

const handleSetIsLoading = (state: DashboardStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetCount = (
  state: DashboardStateType,
  payload: DataPayload<number>,
  field: 'startups' | 'events' | 'requests' | 'members' | 'angelInvestor' | 'technicalLicense',
) => {
  state.count[field] = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: DashboardStateType = defaultState, action: DashboardActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_DASHBOARD_EVENTS: {
      return handleSetDashboardEvents(state, <DataPayload<EventType[]>>payload);
    }
    case types.SET_DASHBOARD_REQUESTS: {
      return handleSetDashboardRequests(state, <DataPayload<ResponseRequestType[]>>payload);
    }
    case types.SET_DASHBOARD_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_DASHBOARD_STARTUPS_COUNT: {
      return handleSetCount(state, <DataPayload<number>>payload, 'startups');
    }
    case types.SET_DASHBOARD_REQUESTS_COUNT: {
      return handleSetCount(state, <DataPayload<number>>payload, 'requests');
    }
    case types.SET_DASHBOARD_EVENTS_COUNT: {
      return handleSetCount(state, <DataPayload<number>>payload, 'events');
    }
    case types.SET_DASHBOARD_TEAM_MEMBERS_COUNT: {
      return handleSetCount(state, <DataPayload<number>>payload, 'members');
    }
    case types.SET_DASHBOARD_ANGEL_INVESTOR_COUNT: {
      return handleSetCount(state, <DataPayload<number>>payload, 'angelInvestor');
    }
    case types.SET_DASHBOARD_TECHNICAL_LICENSE_COUNT: {
      return handleSetCount(state, <DataPayload<number>>payload, 'technicalLicense');
    }
    case types.RESET_DASHBOARD: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
