import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  GetWalletQueryParams,
  GetWalletTransactionsQueryParams,
  getWallet,
  getWalletTransactions,
  getWalletTransactionsCount, getBudget, GetAdminWalletQueryParams,
} from 'packages/wallet_repository'
import { identity } from 'utils';
import showNotification from 'services/utils/showNotification';
import {
  getWalletCount,
  getWalletTransactions as getWalletTransactionsList, setBudget,
  setIsLoading,
  setWallet,
  setWalletCount,
  setWalletTransactions,
  types,
} from '../../actions/wallet';

import { setIsLoading as setIsLoadingDashboard } from '../../actions/dashboard';

function * handleGetWallet(action: PayloadAction<GetWalletQueryParams>) {
  yield put(setIsLoading({ isLoading: true }));
  const queryParams = action.payload;

  const { data: wallet, error, httpStatus } = yield call(getWallet, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet information: ${error?.message}`, true, httpStatus);
  } else if (identity.isObjWithChildren(wallet)) {
    yield all([
      put(setWallet({ wallet })),
      put(getWalletTransactionsList({
        wallet_id: wallet.id,
        $preload: 1,
        $limit: queryParams.limit,
        $offset: queryParams.offset,
        $order: '-1',
      })),
      put(getWalletCount({ wallet_id: wallet.id })),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetAdminWallet(action: PayloadAction<GetAdminWalletQueryParams>) {
  yield put(setIsLoading({ isLoading: true }));
  yield put(setIsLoadingDashboard({ isLoading: true }));
  const queryParams = action.payload;

  const { data: budget, error, httpStatus } = yield call(getBudget, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet information: ${error?.message}`, true, httpStatus);
  } else if (identity.isObjWithChildren(budget)) {
    yield put(setBudget({ budget }));
  }

  yield put(setIsLoading({ isLoading: false }));
  yield put(setIsLoadingDashboard({ isLoading: false }));
}

function * handleGetWalletTransactions(action: PayloadAction<GetWalletTransactionsQueryParams>) {
  yield put(setIsLoading({ isLoading: true }));

  const queryParams = action.payload;
  queryParams.$preload = 1;
  const { data: walletTransactions, error, httpStatus } = yield call(getWalletTransactions, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet transactions: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setWalletTransactions({ walletTransactions }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetWalletCount(action: PayloadAction<GetWalletTransactionsQueryParams>) {
  const queryParams = action.payload;
  const { data, error, httpStatus } = yield call(getWalletTransactionsCount, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet transactions count: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setWalletCount({ data }));
  }
}

export default function * walletSagas() {
  yield all([
    takeLatest(types.GET_WALLET, handleGetWallet),
    takeLatest(types.GET_ADMIN_WALLET, handleGetAdminWallet),
    takeLatest(types.GET_WALLET_TRANSACTIONS, handleGetWalletTransactions),
    takeLatest(types.GET_WALLET_COUNT, handleGetWalletCount),
  ]);
}
