import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import {
  // getMedicalInsurancePlan,
  getOfficeType,
  getSpecialServiceType,
  isSpecialServiceRequest,
} from 'components/RequestsOverview/utils/helpers';
import {
  SpecialServiceRequestDetailsProps,
  // TypeOfMedicalInsurance,
  TypeOfSpecialService,
} from 'types/requests';
import { identity } from 'utils';
import { formatDashDMY } from 'components/utils/constants/formatters';
import { RenderIf } from 'components/components';
import CardRow from '../CardRow';
import AgreementField from '../../../AgreementField';

const SpecialServiceRequestDetails = (props: SpecialServiceRequestDetailsProps) => {
  const {
    details,
    service,
    requestDocuments,
    serviceRequestId,
  } = props;
  const { getServiceRequestDocuments } = props.actions;

  const isDisplayRequestDetails: boolean = useMemo(() => (
    isSpecialServiceRequest(service) && identity.isObjWithChildren(details)
  ), [service, details]);
  const serviceCategory: TypeOfSpecialService = useMemo(() => getSpecialServiceType(service?.categoryId), [service]);

  useEffect(() => {
    if (isDisplayRequestDetails && identity.isTruthyNumber(serviceRequestId) && serviceCategory === 'insurance') {
      getServiceRequestDocuments({ service_request_id: serviceRequestId });
    }
  }, [serviceRequestId, isDisplayRequestDetails, serviceCategory]);

  const memberName = useMemo(() => {
    const firstName = details?.teamMember?.firstName;
    const lastName = details?.teamMember?.lastName;

    if (!firstName || !lastName) {
      return '';
    }

    return `${firstName} ${lastName}`.trim();
  }, [details]);

  const labelMemberName = useMemo(() => {
    if ((details?.teamMember?.technicalLicenseId ?? 0) > 0) {
      return 'Technical License Holder Member Name';
    }

    return 'Startup Member Name';
  }, [details])

  if (!isDisplayRequestDetails) {
    return null;
  }

  return (
    <>
      <h4 className="request-item-card__sub-title">Additional Information</h4>
      <CardRow label={labelMemberName} value={memberName} />
      <RenderIf condition={identity.isTruthyString(details?.teamMember?.gender)}>
        <CardRow label="Gender" value={details?.teamMember?.gender!} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.teamMember?.nationality)}>
        <CardRow label="Nationality" value={details?.teamMember?.nationality!} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.teamMember?.phoneNumber)}>
        <CardRow label="Phone Number" value={details?.teamMember?.phoneNumber!} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.teamMember?.company)}>
        <CardRow label="Company" value={details?.teamMember?.company!} />
      </RenderIf>
      <RenderIf condition={serviceCategory === 'housing'}>
        <>
          <CardRow label="Accommodation Type" value={details?.accommodationType!} />
          <CardRow label="Furnished" value={details?.furnished ? 'Yes' : 'No'} />
        </>
      </RenderIf>
      <RenderIf condition={serviceCategory === 'office space'}>
        <CardRow label="Office Type" value={getOfficeType(details?.officeType!)} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.emiratesIDNo)}>
        <CardRow label="Emirates ID" value={details?.emiratesIDNo!} />
      </RenderIf>
      <RenderIf condition={identity.isTruthyString(details?.passportNo)}>
        <CardRow label="Passport No" value={details?.passportNo!} />
      </RenderIf>
      <RenderIf condition={serviceCategory === 'insurance'}>
        <>
          <CardRow label="Insurance Plan" value={details?.plan!} />
          <CardRow label="Applying for Dependent" value={details?.isApplyingForDependant ? 'Yes' : 'No'} />
          {details?.isApplyingForDependant ? (
            <>
              <CardRow label="Dependent Name" value={details?.dependantFullName! || 'Not Specified'} />
              <CardRow label="Dependent DoB" value={moment(details?.dependantDateOfBirth!).format(formatDashDMY) || 'Not Specified'} />
              <CardRow label="Dependent UAE Resident" value={details?.isDependantUAE === 'yes' ? 'Yes' : 'No'} />
              <CardRow label="Relation To Startup Member" value={details?.relationToTeamMember === 'spouse' ? 'Spouse' : 'Child'} />
              <RenderIf condition={identity.isFullArray(requestDocuments)}>
                <div className="request-item-card__row">
                  <p className="request-item-card__row__label">Dependent Documents:</p>
                </div>
              </RenderIf>
              {requestDocuments?.map((document) => (
                <AgreementField fileUrl={document.document as string} fileName={document.name} key={document.id} />
              ))}
            </>
          ) : (
            <>
              <RenderIf condition={identity.isTruthyString(details?.teamMember?.dateOfBirth!)}>
                <CardRow label="Date of Birth" value={moment(details?.teamMember?.dateOfBirth).format(formatDashDMY)} />
              </RenderIf>
              <RenderIf condition={identity.isTruthyString(details?.teamMember?.passport)
                  || identity.isTruthyString(details?.teamMember?.emiratesId)}
              >
                <div className="request-item-card__row">
                  <p className="request-item-card__row__label">Startup Member Documents:</p>
                </div>
              </RenderIf>
              <AgreementField fileName="Startup Member Passport" fileUrl={details?.teamMember?.passport as string} />
              <AgreementField fileName="Startup Member Emirates ID" fileUrl={details?.teamMember?.emiratesId as string} />
              <RenderIf condition={!identity.isEmptyArray(requestDocuments)}>
                {requestDocuments?.map((document) => (
                  <AgreementField fileUrl={document.document as string} fileName={document.name} key={document.id} />
                ))}
              </RenderIf>
            </>
          )}
        </>
      </RenderIf>
    </>
  );
};

export default SpecialServiceRequestDetails;
