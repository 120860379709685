import { UserRoleType } from 'types/auth';
import { ROLES } from '../constants';

// Super admin
const isSuperAdmin = (userType: UserRoleType) => userType === ROLES.superAdmin;

const isAdmin = (userType: UserRoleType) => userType === ROLES.admin;

// Startup
const isStartupAdmin = (userType: UserRoleType) => userType === ROLES.startupAdmin;

const isStartupFounder = (userType: UserRoleType) => (
  userType === ROLES.startupFounder || isStartupAdmin(userType)
);

const isStartupTeamMember = (userType: UserRoleType) => userType === ROLES.startupTeamMember;

// Angel Investor
const isAngelInvestorAdmin = (userType: UserRoleType) => userType === ROLES.angelInvestorAdmin;

const isAngelInvestorFounder = (userType: UserRoleType) => (
  userType === ROLES.angelInvestorFounder || isAngelInvestorAdmin(userType)
);

const isAngelInvestorMember = (userType: UserRoleType) => userType === ROLES.angelInvestorMember;

// Helpers
const isTechnicalLicense = (userType: UserRoleType) => userType === ROLES.technicalLicense;

const isFounder = (userType: UserRoleType) => (
  isStartupFounder(userType) || isAngelInvestorFounder(userType) || isTechnicalLicense(userType)
);

const isMember = (userType: UserRoleType) => (
  isStartupTeamMember(userType) || isAngelInvestorMember(userType)
);

const isStartup = (userType: UserRoleType) => (
  isStartupFounder(userType) || isStartupTeamMember(userType) || isStartupAdmin(userType)
);

const isAngelInvestor = (userType: UserRoleType) => (
  isAngelInvestorFounder(userType) || isAngelInvestorMember(userType) || isAngelInvestorAdmin(userType)
);

const isEitherAdmin = (userType: UserRoleType) => (
  isAdmin(userType) || isSuperAdmin(userType)
);

const isServiceProvider = (userType: UserRoleType) => userType === ROLES.serviceProvider;

const isPartner = (userType: UserRoleType) => userType === ROLES.partner;

export {
  isSuperAdmin,
  isEitherAdmin,
  isAdmin,
  isStartupFounder,
  isStartupTeamMember,
  isAngelInvestorAdmin,
  isAngelInvestorFounder,
  isAngelInvestorMember,
  isStartup,
  isAngelInvestor,
  isServiceProvider,
  isPartner,
  isTechnicalLicense,
  isFounder,
  isMember,
}
