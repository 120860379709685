import { API_ROOT } from 'packages/http_client';
import ROLES from 'utils/constants/roles';

export const PERFORMANCE_API = `${API_ROOT}/startupperformance`;

export const REPORT_STATUS = {
  draft: 'Draft',
  rejected: 'Rejected',
  approved: 'Approved',
  submitted: 'Submitted',
  pending: 'Pending',
}

export const NUMBER_TO_REPORT_STATUS: { [key: string]: string } = {
  1: REPORT_STATUS.draft,
  2: REPORT_STATUS.submitted,
  3: REPORT_STATUS.approved,
  4: REPORT_STATUS.rejected,
}

export const REPORT_STATUS_TO_NUMBER = {
  [REPORT_STATUS.draft]: 1,
  [REPORT_STATUS.pending]: 2,
  [REPORT_STATUS.submitted]: 2,
  [REPORT_STATUS.approved]: 3,
  [REPORT_STATUS.rejected]: 4,
}

export const REPORT_STATUS_ORIGINAL_NAMES = {
  [REPORT_STATUS.pending]: REPORT_STATUS.submitted,
}

export const REPORT_STATUS_ALTERNATIVE_NAMES_BY_ROLE = {
  [ROLES.admin]: {
    [REPORT_STATUS.submitted]: REPORT_STATUS.pending,
  },
  [ROLES.superAdmin]: {
    [REPORT_STATUS.submitted]: REPORT_STATUS.pending,
  },
  [ROLES.startupAdmin]: {},
  [ROLES.startupFounder]: {},
  [ROLES.startupTeamMember]: {},
}
