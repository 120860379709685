import React from 'react';
import { Tag } from 'antd';

export type TagTableProps = {
  text: string
  color: 'error' | 'warning' | 'success' | 'gold'
}

export const TagTable = ({ text, color }: TagTableProps) => (
  <Tag
    color={color}
    style={{
      textTransform: 'uppercase',
      textAlign: 'center',
      width: '120px',
    }}
  >
    { text }
  </Tag>
)

export default TagTable;
