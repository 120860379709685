import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import { UserRoleType } from 'types/auth';
import { isStartup, isEitherAdmin } from 'utils';
import ROLES from 'utils/constants/roles';
import { PriceType } from 'types/services';
import { CamelCasePriceType, RequestDetailsTabs, TabsCamelCaseType } from 'types/requests';
import { PARTNER_REQUEST_STATUS_TYPES } from 'packages/partner_repository';

export const TABS: {[key in TabsCamelCaseType]: RequestDetailsTabs} = {
  overview: 'Overview',
  discussionBoard: 'Discussion board',
  documents: 'Documents',
};

export const PRICE_TYPES: {[key in CamelCasePriceType]: PriceType} = {
  perHour: 'per-hour',
  perDay: 'per-day',
  perWeek: 'per-week',
  perMonth: 'per-month',
  perYear: 'per-year',
}

export const PRICE_TYPE_TAG = {
  [PRICE_TYPES.perHour]: 'Hourly',
  [PRICE_TYPES.perDay]: 'Daily',
  [PRICE_TYPES.perMonth]: 'Monthly',
  [PRICE_TYPES.perWeek]: 'Weekly',
  [PRICE_TYPES.perYear]: 'Yearly',
}

export const PRICE_QUANTITY = {
  [PRICE_TYPES.perHour]: 'hours',
  [PRICE_TYPES.perDay]: 'days',
  [PRICE_TYPES.perMonth]: 'months',
  [PRICE_TYPES.perWeek]: 'weeks',
  [PRICE_TYPES.perYear]: 'years',
}

const drawerTitleClass = 'drawer-title';

export const STATUS_COLOR_CLASS = (userType?: UserRoleType) => ({
  [REQUEST_STATUS.inProgress]: `${drawerTitleClass}--in-progress`,
  [REQUEST_STATUS.pendingHUB71]: `${drawerTitleClass}--info-required`,
  [REQUEST_STATUS.new]: `${drawerTitleClass}--${isStartup(userType!) || isEitherAdmin(userType!) ? 'pending' : 'new'}`,
  [REQUEST_STATUS.quoted]: `${drawerTitleClass}--to-be-signed`,
  [REQUEST_STATUS.delivered]: `${drawerTitleClass}--delivered`,
  [REQUEST_STATUS.rejected]: `${drawerTitleClass}--rejected`,
  [REQUEST_STATUS.pendingQuotation]: `${drawerTitleClass}--pending`,
  [REQUEST_STATUS.unsent]: `${drawerTitleClass}--info-required`,
  [PARTNER_REQUEST_STATUS_TYPES.rejected]: `${drawerTitleClass}--rejected`,
  [PARTNER_REQUEST_STATUS_TYPES.pendingHUB71Approval]: `${drawerTitleClass}--info-required`,
  [PARTNER_REQUEST_STATUS_TYPES.inProgress]: `${drawerTitleClass}--in-progress`,
  [PARTNER_REQUEST_STATUS_TYPES.completed]: `${drawerTitleClass}--delivered`,
});

export const INSUFFICIENT_FUND_TEXT_BY_ROLE = {
  [ROLES.startupAdmin]: 'Your startup does not have sufficient funds to cover this request.',
  [ROLES.startupFounder]: 'Your startup does not have sufficient funds to cover this request.',
  [ROLES.startupTeamMember]: 'Your startup does not have sufficient funds to cover this request.',
  [ROLES.angelInvestorAdmin]: 'Your Angel investor does not have sufficient funds to cover this request.',
  [ROLES.angelInvestorFounder]: 'Your Angel investor does not have sufficient funds to cover this request.',
  [ROLES.angelInvestorMember]: 'Your Angel investor does not have sufficient funds to cover this request.',
  [ROLES.superAdmin]: 'User has consumed more than 90% of the allocated wallet amount. Do you still wish to confirm this request?',
  [ROLES.admin]: 'User has consumed more than 90% of the allocated wallet amount. Do you still wish to confirm this request?',
}
