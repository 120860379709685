import React, { useEffect, useState } from 'react';

import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { Input } from 'components/components';
import { OfficeColumnsProps } from 'types/services/requestService';
import { identity, positiveNumbersOnly } from 'utils';
import { officeTypeOptions } from 'components/RequestServiceDrawer/utils/constants';

type DataPayload<DataType> = {
  data: DataType
}

const OfficeColumns = (props: OfficeColumnsProps) => {
  const {
    setOfficeType,
    setQuantity,
    setTeamMember,
    setAITeamMember,
    setThlTeamMember,
    member,
    aIMember,
    thlMember,
    isStartupUser,
    isAngelInvestorUser,
    isTechnicalLicenseUser,
    teamMembersOptions,
    state,
    getMemberById,
    getAngelInvestorMemberById,
    getTechnicalLicenseMemberById,
    setNoOfDesk,
    setTotalPrice,
  } = props;

  const {
    teamMember,
    officeType,
    quantity,
    noOfDesk,
    service,
  } = state.request;

  const { servicePrice } = service;

  const [teamMemberId, setTeamMemberId] = useState<number>(0);

  useEffect(() => {
    setQuantity({ data: 1 });
    setNoOfDesk({ data: 1 });
    setOfficeType({ data: '' });
    setTeamMember({ data: undefined })
    setAITeamMember({ data: undefined })
    setThlTeamMember({ data: undefined });
  }, []);

  useEffect(() => {
    if (noOfDesk && servicePrice?.toPrice && quantity) {
      const finalPrice = noOfDesk * servicePrice.toPrice * quantity
      const multipliedTotalPrice = { data: finalPrice } as DataPayload<number>

      setTotalPrice(multipliedTotalPrice)
    }
  }, [noOfDesk, quantity])

  useEffect(() => {
    setTeamMember({ data: member })
  }, [member]);

  useEffect(() => {
    setAITeamMember({ data: aIMember })
  }, [aIMember]);

  useEffect(() => {
    setThlTeamMember({ data: thlMember })
  }, [thlMember]);

  useEffect(() => {
    if (identity.isTruthyNumber(teamMemberId)) {
      if (isStartupUser) {
        getMemberById({ id: teamMemberId });
      }

      if (isAngelInvestorUser) {
        getAngelInvestorMemberById({ id: teamMemberId });
      }

      if (isTechnicalLicenseUser) {
        getTechnicalLicenseMemberById({ id: teamMemberId })
      }
    } else {
      setTeamMember({ data: undefined });
      setAITeamMember({ data: undefined });
      setThlTeamMember({ data: undefined });
    }
  }, [teamMemberId, isStartupUser, isAngelInvestorUser, isTechnicalLicenseUser]);

  return (
    <div className="overview-columns">
      <DataColumn label="Choose team member" className="input">
        <Input.Select
          options={teamMembersOptions}
          value={identity.isTruthyNumber(teamMember?.id) ? teamMember?.id : 0}
          onChange={(data) => {
            setTeamMemberId(data)
          }}
        />
      </DataColumn>

      <Input label="Office type" className="overview-column" required layout="vertical">
        <Input.Select
          options={officeTypeOptions}
          value={identity.isTruthyString(officeType) ? officeType : ''}
          onChange={(data) => setOfficeType({ data })}
        />
      </Input>

      <Input rules={[positiveNumbersOnly]} label="Number of Desk" className="overview-column" required layout="vertical">
        <Input.InputNumber
          min={1}
          value={noOfDesk}
          placeholder="Enter Quantity"
          onChange={(e) => setNoOfDesk({ data: e! })}
        />
      </Input>

      <Input rules={[positiveNumbersOnly]} label="Number of Months" className="overview-column" required layout="vertical">
        <Input.InputNumber
          min={1}
          value={quantity}
          placeholder="Enter Months"
          onChange={(e) => setQuantity({ data: e! })}
        />
      </Input>
    </div>
  );
}

export default OfficeColumns;
