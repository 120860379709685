import { UserRoleType } from 'types/auth';

type RoleKeys = 'employee' | 'startupFounder' | 'startupTeamMember' | 'serviceProvider'
  | 'superAdmin' | 'admin' | 'partner' | 'technicalLicense' | 'startupAdmin'
  | 'angelInvestorAdmin' | 'angelInvestorFounder' | 'angelInvestorMember'

const ROLES: {[key in RoleKeys]: UserRoleType} = {
  superAdmin: 'Super admin',
  admin: 'Admin',
  startupAdmin: 'Startup admin',
  startupFounder: 'Startup founder',
  startupTeamMember: 'Startup team member',
  angelInvestorAdmin: 'Angel investor admin',
  angelInvestorFounder: 'Angel investor founder',
  angelInvestorMember: 'Angel investor member',
  serviceProvider: 'Service provider',
  employee: 'Employee',
  partner: 'Partner',
  technicalLicense: 'Technical license',
};

export default ROLES;
