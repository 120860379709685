import { ColumnsType } from 'antd/es/table';
import { LabeledValue } from 'antd/lib/select';

import { serviceProviderTypeOptions } from 'components/AddServiceProviderDrawer/utils/constants';
import requiredValidation from '../../../../utils/constants/validationRules';
import { inputTypes, getFormFieldBuilder } from '../../../components/FormBuilder/utils';

export const OfferPriceValues = [
  {
    value: 'fixed',
    title: 'Fixed',
    key: 'fixed',
  },
  {
    value: 'range',
    title: 'Range',
    key: 'range',
  },
];

export const enterYourPriceMessage = 'Please enter your price';

export const OptionValues = [
  {
    value: 'per-day',
    label: 'Per day',
  },
  {
    value: 'per-week',
    label: 'Per week',
  },
  {
    value: 'per-month',
    label: 'Per month',
  },
  {
    value: 'per-hour',
    label: 'Per hour',
  },
  {
    value: 'per-year',
    label: 'Per year',
  },
];

export const defaultValues = {
  type: '',
  name: '',
  about: '',
  priceTo: '',
  priceFrom: '',
  category:[],
  offerPrice: 'fixed',
  offerDocLink: '',
  additionalOptionName: '',
  additionalOptionPrice: '',
  active: false,
  allowForPreCommunity: false,
  isAvailableForAICommunity: false,
  pricingOptions: [
    {
      id: 0,
      amount: '',
      option: 'per-month',
    },
  ],
}

export const ServiceDetailFormFields = [
  {
    fields: [
      getFormFieldBuilder('type', 'Service type', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(serviceProviderTypeOptions)
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Service name')
        .withRules([requiredValidation])
        .withPlaceholder('eg. UX Design')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('about', 'Description', inputTypes.textArea)
        .withRows(5)
        .withPlaceholder('write something...')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('offerPrice', 'Offer Price', inputTypes.radio)
        .withRadioValues(OfferPriceValues)
        .withRules([requiredValidation])
        .build(),
    ],
  },
];

export const subCategoryColumns: ColumnsType<LabeledValue> = [
  {
    key: 'name',
    title: 'Sub Category',
    dataIndex: 'name',
  },
];

export const resetFiltersEvent = 'service-form-reset';

export type ServiceDetailsParams = {
  serviceProviders: LabeledValue[],
  serviceProviderType: LabeledValue[],
  categories: LabeledValue[],
  offerPriceOptions: { value: string, key: string, title: string}[],
}

export const AdminServiceDetailFormFields = ({
  serviceProviders, serviceProviderType, categories,
}: ServiceDetailsParams) => [
  {
    fields: [
      getFormFieldBuilder('serviceProviderId', 'Service provider', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(serviceProviders)
        .withPlaceholder('Select Service Provider')
        .required()
        .build(),
      getFormFieldBuilder('type', 'Service Type', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(serviceProviderType)
        .withPlaceholder('Select Service Type')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('category', 'Select Category', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(categories)
        .withPlaceholder('Select Service Category')
        .required()
        .build(),
      getFormFieldBuilder('name', 'Service Name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Service Name')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('about', 'Service Description', inputTypes.textArea)
        .withRows(5)
        .withPlaceholder('Enter Service Description')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('offerPrice', 'Price Type', inputTypes.radio)
        .withRadioValues(OfferPriceValues)
        .withRules([requiredValidation])
        .build(),
    ],
  },
];
