import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectBudget, selectWalletFilters } from 'redux/selectors/wallet';
import { getAdminWallet } from 'redux/actions/wallet';

const mapStateToProps = (state: StateType) => ({
  budget: selectBudget(state),
  filters: selectWalletFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAdminWallet,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
