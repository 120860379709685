import React from 'react';
import { identity, DRAWERS } from 'utils';
import { AboutTLHProps } from 'types/componentTypes/AboutProps';
import { RenderIf } from 'components/components';
import { ViewUploadButton } from 'components/EditTechnicalLicense/components';

import './About.scss';

const AboutTLH = (props: AboutTLHProps) => {
  const {
    title,
    isTechnicalLicense,
    technicalLicenseDetails,
  } = props;

  const handleOpenDrawer = (drawerBody: string) => {
    props.actions.setIsDrawerOpen({ isDrawerOpen: true });
    props.actions.setDrawerBody({ drawerBody });
  }

  return (
    <div className="about">
      <div className="about__container">
        <RenderIf condition={identity.isTruthyString(title)}>
          <div className="about__header">
            <h3 className="about__title">{title}</h3>
          </div>
        </RenderIf>

        {/* ADGM License Button */}
        <RenderIf condition={isTechnicalLicense}>
          <div className="about__details">
            <div className="details_row">
              <h4>ADGM License:</h4>
              <div>
                <ViewUploadButton
                  userType="Technical license"
                  name="ADGM License"
                  onClick={() => handleOpenDrawer(DRAWERS.tLHAdgmLicenseDetails)}
                  condition
                  status={technicalLicenseDetails?.aDGMApprovedStatus}
                />
              </div>
            </div>
          </div>
        </RenderIf>
      </div>
    </div>
  );
};

export default AboutTLH;
