import React from 'react';

import { AdminBudgetProps } from 'types/componentTypes/Dashboard/AdminBudget';
import classnames from 'classnames';
import { TitleWithActions } from '../../../components';
import { Amount, WalletGraph } from '../../../components/StartupWallet/components';
import { parseAmountPrice, parseNumberToPrice } from '../../../../utils'
import { FILTERS_MAP } from '../../../components/SearchBar/utils/constants';

export const AdminBudget = ({
  filtersActive, balance, blocked, consumed, total, type,
}: AdminBudgetProps) => {
  const data = [
    { id: 'balance', label: 'Balance', value: parseAmountPrice(balance) },
    { id: 'consumedAmount', label: 'Consumed', value: parseAmountPrice(consumed) },
    { id: 'blockedAmount', label: 'Blocked', value: parseAmountPrice(blocked) },
  ]

  const walletData = {
    balance: parseNumberToPrice(parseAmountPrice(balance), 'AED'),
    total: parseNumberToPrice(parseAmountPrice(total), 'AED'),
    consumed: parseNumberToPrice(parseAmountPrice(consumed), 'AED'),
    blocked: parseNumberToPrice(parseAmountPrice(blocked), 'AED'),
  }

  return (
    <div className="startup-wallet">
      <div className="startup-wallet__header">
        <TitleWithActions
          title="Budget"
          size="s"
          showSearchBar
          showFilterButton={type === 'startup'}
          filterButtonType="dropdown"
          filtersActive={filtersActive}
          filterDropdownElements={FILTERS_MAP.dashboardBudgetStartups}
          showFilterByUserType={false}
        />
      </div>

      <div className="startup-wallet__container">
        <div className={classnames('startup-wallet__amount-info', { 'startup-wallet__amount-info--profile-view': true })}>
          <Amount name="balance" amount={walletData.balance} />
          <Amount name="total" amount={walletData.total} />
          <Amount name="consumed" amount={walletData.consumed} />
          <Amount name="blocked" amount={walletData.blocked} />
        </div>

        <WalletGraph
          data={data}
          wallet={{
            balance,
            totalWalletAmount: total,
            consumedAmount: consumed,
            blockedAmount: blocked,
          }}
        />
      </div>
    </div>
  );
}

export default AdminBudget;
