import React, { useEffect } from 'react';

import { RenderIf, TitleWithActions } from 'components/components';
import { RecentRequestsProps } from 'types/componentTypes/Dashboard';
import { RequestRow } from 'components/RequestsOverview/components';
import routes from 'routes';
import { identity, isAngelInvestor, isTechnicalLicense } from 'utils';

import './RecentRequests.scss';

const RecentRequests = (props: RecentRequestsProps) => {
  const {
    requests,
    userRequests,
    filters,
    userType,
  } = props;
  const { getRequests } = props.actions

  useEffect(() => {
    if (isAngelInvestor(userType) || isTechnicalLicense(userType)) {
      getRequests({ data: { ...filters } });
    }
  }, [filters]);

  return (
    <div className="recent-requests">
      <TitleWithActions
        title="Recent requests"
        size="xs"
        showButton
        buttonTitle="View all"
        link={routes.requestsUrl}
      />
      <div className="requests-overview">
        <div className="requests-container">
          <RenderIf condition={!isAngelInvestor(userType) && !isTechnicalLicense(userType)}>
            {identity.isFullArray(requests)
              ? requests.map((request, index) => <RequestRow request={request} key={`request-${request.id || index}`} />)
              : 'No requests'}
          </RenderIf>

          <RenderIf condition={isAngelInvestor(userType) || isTechnicalLicense(userType)}>
            {identity.isFullArray(userRequests)
              ? userRequests.map((request, index) => <RequestRow request={request} key={`request-${request.id || index}`} />).slice(0, 3)
              : 'No requests'}
          </RenderIf>
        </div>
      </div>
    </div>
  );
}

export default RecentRequests;
