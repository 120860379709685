import React, { useEffect, useState } from 'react';

import DataColumn from 'components/components/DataColumns/components/DataColumn';
import { Input } from 'components/components';
import { HousingColumnsProps } from 'types/services/requestService';
import { identity } from 'utils';
import { LabeledValue } from 'types';

const HousingColumns = (props: HousingColumnsProps) => {
  const {
    setFurnished,
    setAccommodationType,
    setTeamMember,
    setAITeamMember,
    setThlTeamMember,
    isStartupUser,
    isAngelInvestorUser,
    isTechnicalLicenseUser,
    member,
    aIMember,
    thlMember,
    getMemberById,
    getAngelInvestorMemberById,
    getTechnicalLicenseMemberById,
    setQuantity,
  } = props;

  const { teamMember, furnished, accommodationType } = props.state.request;

  const [teamMemberId, setTeamMemberId] = useState(0);

  useEffect(() => {
    setQuantity({ data: 1 });
    setTeamMember({ data: undefined });
    setAITeamMember({ data: undefined });
    setThlTeamMember({ data: undefined });
    setAccommodationType({ data: '' });
  }, []);

  useEffect(() => {
    setTeamMember({ data: member })
  }, [member]);

  useEffect(() => {
    setAITeamMember({ data: aIMember })
  }, [aIMember]);

  useEffect(() => {
    setThlTeamMember({ data: thlMember })
  }, [thlMember]);

  useEffect(() => {
    if (identity.isTruthyNumber(teamMemberId)) {
      if (isStartupUser) {
        getMemberById({ id: teamMemberId });
      }

      if (isAngelInvestorUser) {
        getAngelInvestorMemberById({ id: teamMemberId });
      }

      if (isTechnicalLicenseUser) {
        getTechnicalLicenseMemberById({ id: teamMemberId })
      }
    } else {
      setTeamMember({ data: undefined });
      setAITeamMember({ data: undefined });
      setThlTeamMember({ data: undefined });
    }
  }, [teamMemberId, isStartupUser, isAngelInvestorUser, isTechnicalLicenseUser]);

  const accommodationTypeOptions: LabeledValue[] = [
    { value: '', label: 'Select...' },
    { value: 'studio', label: 'Studio' },
    { value: '1BHK', label: '1 BHK' },
    { value: '2BHK', label: '2 BHK' },
    { value: 'villa', label: 'Villa' },
    { value: 'other', label: 'Other' },
  ];

  return (
    <div className="overview-columns">
      <Input className="overview-column" label="Choose team member" required layout="vertical">
        <Input.Select
          options={props.teamMembersOptions}
          value={identity.isTruthyNumber(teamMember?.id) ? teamMember?.id : 0}
          onChange={(data: number) => setTeamMemberId(data)}
        />
      </Input>
      <DataColumn label="Do you want the housing to be furnished?" className="input">
        <Input.Switch checked={furnished} onChange={(data) => setFurnished({ data })} />
      </DataColumn>
      <Input className="overview-column" label="Accommodation type" required layout="vertical">
        <Input.Select
          options={accommodationTypeOptions}
          value={identity.isTruthyString(accommodationType) ? accommodationType : ''}
          onChange={(data) => setAccommodationType({ data })}
        />
      </Input>
    </div>
  );
}

export default HousingColumns;
