import React, { useMemo } from 'react';
import { Redirect } from 'react-router';
import { Layout } from 'antd';
import { Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import PartnerDetails from 'components/PartnerDetails';
import {
  isCommunity,
  isProduction,
  ROLES as USER_ROLES,
  identity,
  isExpired,
  isTechnicalLicense,
  isAdmin,
  isSuperAdmin,
  isStartup,
  isAngelInvestor,
} from 'utils';
import { LayoutWithSidebarType } from 'types/layoutWithSidebar/LayoutWithSidebarProps';
import routes from 'routes';
import ActivitySidebar from 'components/ActivitySidebar';
import { NoticeModal, RenderIf } from 'components/components'
import Performance from 'components/Performance';
import EditPartner from 'components/EditPartner';
import SingleServiceOverview from 'components/SingleServiceOverview';
import CreatePerformanceReport from 'components/Performance/components/CreatePerformanceReport';
import AIWalletOverview from 'components/AIWalletOverview';
import { PrivateRoute } from './routes';
import { Sidebar, Navbar } from './components';
import CohortOverview from '../CohortOverview';
import CohortDetails from '../CohortDetails';
import EditCohort from '../EditCohort';
import Settings from '../Settings';
import SubmitOverview from '../SubmitOverview';
import StartupsOverview from '../StartupsOverview';
import AngelInvestorsOverview from '../AngelInvestorsOverview';
import TechnicalLicenseDetails from '../TechnicalLicenseDetails';
import EditTechnicalLicense from '../EditTechnicalLicense';
import TechnicalLicenseHoldersOverview from '../TechnicalLicenseHoldersOverview';
import EditStartup from '../EditStartup';
import PeopleOverview from '../PeopleOverview';
import StartupDetails from '../StartupDetails';
import AngelInvestorDetails from '../AngelInvestorDetails';
import EditAngelInvestor from '../EditAngelInvestor';
import EditPeopleProfile from '../EditMemberProfile';
import MemberDetails from '../MemberDetails';
import EditServiceProvider from '../EditServiceProvider';
import ServiceProviderDetails from '../ServiceProviderDetails';
import TeamMembers from '../TeamMembers';
import WalletOverview from '../WalletOverview';
import RequestsOverview from '../RequestsOverview';
import IncentiveRequestDetails from '../RequestDetails/components/IncentiveRequestDetails';
import ServicesOverView from '../ServiceProviderDetails/components/ServicesOverview';
import ChangePassword from '../ChangePassword';
import Events from '../Events';
import PartnersOverview from '../PartnersOverview';
import Dashboard from '../Dashboard';
import OnboardingProgressStepper from '../OnboardingProgressStepper'
import {
  EditMyProfile,
  MyProfile,
  CompanyProfile,
  EditCompanyProfile,
} from '../User';
import HIDDEN_ROUTES from '../../utils/constants/hiddenRoutes';
import StaticNotification from '../components/Notification/StaticNotification';
import ServicesAndServiceProviders from '../ServicesAndServiceProviders';
import ArticleDetails from '../ArticleDetails';
import { NotFound } from '../App/components';

import './LayoutWithSidebar.scss';

const { Content } = Layout;

const LayoutWithSidebar = (props: LayoutWithSidebarType) => {
  const {
    location,
    isAuthenticated,
    isOpen,
    technicalHolderStatus,
    userType,
    isVisible,
    isBlocked,
    startupStatus,
    angelInvestorStatus,
  } = props;
  const isAdminOrSuperAdmin = isAdmin(userType) || isSuperAdmin(userType);

  const startupIsNotCommunity = useMemo(() => (
    identity.isTruthyString(startupStatus) && !isCommunity(startupStatus) && isStartup(userType)
  ), [startupStatus, userType]);

  const angelInvestorIsNotCommunity = useMemo(() => (
    identity.isTruthyString(angelInvestorStatus) && !isCommunity(angelInvestorStatus) && isAngelInvestor(userType)
  ), [angelInvestorStatus, userType]);

  const technicalInvestorIsNotCommunity = useMemo(() => (
    identity.isTruthyString(technicalHolderStatus) && !isCommunity(technicalHolderStatus)
    && isTechnicalLicense(userType)
  ), [technicalHolderStatus, userType]);

  if (location.pathname === '/' || (HIDDEN_ROUTES.includes(location.pathname) && isProduction)) {
    return (<Redirect to={isAuthenticated ? routes.dashboardUrl : routes.loginUrl} />);
  }

  if (isBlocked === null && !isAdminOrSuperAdmin) {
    return null;
  }

  if (isBlocked && !isAdminOrSuperAdmin) {
    return (<Redirect to={routes.forbidden} />);
  }

  const handleHidePopovers = () => {
    if (isOpen) {
      props.actions.toggleNotifications()
    }
    if (isVisible) {
      props.actions.toggleUserProfile()
    }
  }

  const investorTypes = [
    {
      id: 1,
      isNotCommunity: startupIsNotCommunity,
      status: startupStatus,
      message: 'The incentives period for this startup has ended and they no longer can apply for any incentives.',
    },
    {
      id: 2,
      isNotCommunity: angelInvestorIsNotCommunity,
      status: angelInvestorStatus,
      message: 'The incentives period for this Angel investor has ended and they no longer can apply for any incentives.',
    },
    {
      id: 3,
      isNotCommunity: technicalInvestorIsNotCommunity,
      status: technicalHolderStatus,
      message: 'The incentives period for this Technical license has ended and they no longer can apply for any incentives.',
    },
  ];

  return (
    <Layout hasSider className="layout-with-sidebar">
      <RenderIf condition={isAuthenticated}>
        <Sidebar location={location} />
      </RenderIf>

      <Layout>
        <RenderIf condition={isAuthenticated}>
          <Navbar />
        </RenderIf>

        <Content className="content" onClick={handleHidePopovers} onKeyDown={handleHidePopovers}>
          {!(isExpired(startupStatus) || isExpired(angelInvestorStatus) || isExpired(technicalHolderStatus)) && (
            <div className="content-notification">
              <OnboardingProgressStepper />
            </div>
          )}

          {investorTypes.map((item) => (
            <RenderIf
              key={item.id}
              condition={item.isNotCommunity && isAuthenticated && isExpired(item.status)}
            >
              <div className="content-notification">
                <StaticNotification
                  type="warning"
                  message={item.message}
                />
              </div>
            </RenderIf>
          ))}

          <Switch location={location}>
            <PrivateRoute
              path={routes.walletUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
              component={WalletOverview}
            />
            <PrivateRoute
              path={routes.startupWallet}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={WalletOverview}
            />
            <PrivateRoute
              path={routes.angelInvestorWalletUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
              ]}
              component={AIWalletOverview}
            />
            <PrivateRoute
              path={routes.angelInvestorWallet}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={AIWalletOverview}
            />
            <PrivateRoute
              path={routes.editServiceProviderUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.serviceProvider,
              ]}
              component={EditServiceProvider}
            />
            <PrivateRoute
              path={routes.serviceProviderMoreDetailsUrl}
              exact
              component={EditServiceProvider}
              permittedUserTypes={[
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
              ]}
            />
            <PrivateRoute
              exact
              path={routes.settingsOverviewUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
              ]}
              component={Settings}
            />
            <PrivateRoute
              path={routes.cohortsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
              ]}
              component={CohortOverview}
            />
            <PrivateRoute
              path={routes.startupsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.partner,
              ]}
              component={StartupsOverview}
            />
            <PrivateRoute
              path={routes.angelInvestorsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.partner,
              ]}
              component={AngelInvestorsOverview}
            />
            <PrivateRoute
              path={routes.viewAllServicesUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
              component={ServicesOverView}
            />
            <PrivateRoute
              exact
              path={routes.editStartupUrl}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={EditStartup}
            />
            <PrivateRoute
              exact
              path={routes.editAngelInvestorsUrl}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={EditAngelInvestor}
            />
            <PrivateRoute
              path={routes.peopleUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
              ]}
              component={PeopleOverview}
            />
            <PrivateRoute
              path={routes.serviceProvidersUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.technicalLicense,
              ]}
              component={ServicesAndServiceProviders}
            />
            <PrivateRoute
              path={routes.editPeopleProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
              ]}
              component={EditPeopleProfile}
            />
            <PrivateRoute
              exact
              path={routes.editCohortUrl}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={EditCohort}
            />
            <PrivateRoute
              path={routes.cohortsDetailUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
              ]}
              component={CohortDetails}
            />
            <PrivateRoute
              path={routes.submitOverviewUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
              component={SubmitOverview}
            />
            <PrivateRoute
              path={routes.teamMembersUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
              ]}
              component={TeamMembers}
            />
            <PrivateRoute
              path={routes.startupDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.serviceProvider,
                USER_ROLES.partner,
              ]}
              component={StartupDetails}
            />
            <PrivateRoute
              path={routes.angelInvestorsDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.partner,
                USER_ROLES.serviceProvider,
              ]}
              component={AngelInvestorDetails}
            />
            <PrivateRoute
              path={routes.technicalLicenseHolderDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={TechnicalLicenseDetails}
            />
            <PrivateRoute
              path={routes.editTechnicalLicenseHolderDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={EditTechnicalLicense}
            />
            <PrivateRoute
              path={routes.peopleDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
              ]}
              component={MemberDetails}
            />
            <PrivateRoute
              path={routes.serviceProviderDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
              ]}
              component={ServiceProviderDetails}
            />
            <PrivateRoute
              path={routes.requestsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.serviceProvider,
                USER_ROLES.partner,
                USER_ROLES.technicalLicense,
              ]}
              component={RequestsOverview}
            />
            <PrivateRoute
              path={routes.requestMoreDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.serviceProvider,
                USER_ROLES.technicalLicense,
              ]}
              component={IncentiveRequestDetails}
            />
            <PrivateRoute
              component={ChangePassword}
              path={routes.changePasswordUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.serviceProvider,
                USER_ROLES.employee,
              ]}
            />
            <PrivateRoute
              path={routes.servicesUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.serviceProvider,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.technicalLicense,
              ]}
              component={ServicesAndServiceProviders}
            />
            <PrivateRoute
              path={routes.eventsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.partner,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.serviceProvider,
              ]}
              component={Events}
            />
            <PrivateRoute
              component={PartnersOverview}
              path={routes.partnersUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
              ]}
            />
            <PrivateRoute
              component={MyProfile}
              path={routes.myProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
                USER_ROLES.partner,
                USER_ROLES.serviceProvider,
              ]}
            />
            <PrivateRoute
              component={EditMyProfile}
              path={routes.editMyProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
                USER_ROLES.partner,
                USER_ROLES.serviceProvider,
              ]}
            />
            <PrivateRoute
              component={EditCompanyProfile}
              path={routes.editStartupProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
              ]}
            />
            <PrivateRoute
              component={CompanyProfile}
              path={routes.startupProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
            />
            <PrivateRoute
              component={CompanyProfile}
              path={routes.companyProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
                USER_ROLES.serviceProvider,
                USER_ROLES.partner,
              ]}
            />
            <PrivateRoute
              component={EditCompanyProfile}
              exact
              path={routes.editCompanyProfileUrl}
              permittedUserTypes={[
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
                USER_ROLES.serviceProvider,
                USER_ROLES.partner,
              ]}
            />
            <PrivateRoute
              component={Dashboard}
              path={routes.dashboardUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestorAdmin,
                USER_ROLES.angelInvestorFounder,
                USER_ROLES.angelInvestorMember,
                USER_ROLES.technicalLicense,
                USER_ROLES.serviceProvider,
                USER_ROLES.partner,
                USER_ROLES.employee,
              ]}
            />
            <PrivateRoute
              component={EditPartner}
              exact
              path={routes.editPartnerUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
              ]}
            />
            <PrivateRoute
              exact
              path={routes.technicalLicenseHoldersUrl}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={TechnicalLicenseHoldersOverview}
            />
            <PrivateRoute
              exact
              path={routes.servicesAndSpsOverviewUrl}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={ServicesAndServiceProviders}
            />
            <PrivateRoute
              component={PartnerDetails}
              exact
              path={routes.partnerDetailsUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
            />
            <PrivateRoute
              component={Performance}
              exact
              path={routes.performanceUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
            />
            <PrivateRoute
              component={CreatePerformanceReport}
              exact
              path={routes.createPerformanceReportUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
            />
            <PrivateRoute
              component={CreatePerformanceReport}
              exact
              path={routes.editPerformanceReportUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
            />
            <PrivateRoute
              component={SingleServiceOverview}
              exact
              path={routes.serviceOverview}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.startupAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.serviceProvider,
              ]}
            />
            <PrivateRoute
              component={ArticleDetails}
              exact
              path={routes.articlesUrl}
              permittedUserTypes={[
                USER_ROLES.technicalLicense,
              ]}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Content>

        <div className={classNames('layout-with-sidebar__notifications', isOpen ? '' : 'hidden')}>
          <ActivitySidebar />
        </div>

        <NoticeModal />
      </Layout>
    </Layout>
  );
};

export default withRouter(LayoutWithSidebar);
