import { API_ROOT } from 'packages/http_client/utils';
import { Unit } from '../types';

export const API_PREFIX = '/api';

export const SERVICE_PROVIDER_API = `${API_ROOT}/serviceprovider`;

export const SERVICE_API = `${API_ROOT}/service`;

export const SERVICE_LOADER_API = `${API_ROOT}/serviceloader`;

export const SERVICE_PROVIDER_DOWNLOAD_API = '/serviceloader';

export const SERVICE_PRICE_API = `${API_ROOT}/serviceprice`;

export const SERVICE_CATEGORY_API = `${API_ROOT}/servicecategory`;

export const SERVICE_SUB_CATEGORY_API = `${API_ROOT}/servicesubcategory`;

export const UNIT_API = `${API_ROOT}/unit`;

export const NUMBER_TO_UNIT_MAP: {[key: number]: Unit} = {
  1: 'Time',
  2: 'Fixed',
  3: 'Length',
  4: 'Area',
  5: 'Volume',
  6: 'Weight',
};

export const UNIT_TO_NUMBER_MAP: {[key: string]: number} = {
  Time: 1,
  Fixed: 2,
  Length: 3,
  Area: 4,
  Volume: 5,
  Weight: 6,
};
