import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  identity,
  isStartup,
  isAngelInvestor,
  isTechnicalLicense,
  isEitherAdmin,
} from 'utils';
import { composeEditPeopleUrl } from 'routes/routeComposers';
import { peopleUrl, teamMembersUrl } from 'routes/urls';
import { Breadcrumb, RenderIf } from 'components/components';
import routes from 'routes';
import { MemberDetailsProps } from '../../types/memberDetails';
import { Details } from './components';

import './MemberDetails.scss';

const MemberDetails = (props: MemberDetailsProps) => {
  const {
    info,
    selectedMember,
    selectedAIMember,
    userType,
    userId,
    profileId,
  } = props;

  const {
    getMemberById,
    getPartnerById,
    getMyProfileData,
    getAngelInvestorMemberById,
    getStartupById,
    getTechnicalLicenseMemberById,
  } = props.actions;

  const params: { id?: string, type?: string } = useParams();
  const { id, type } = params;
  const memberId = useMemo(() => parseInt(id!, 10), [id]);
  const isPartners = type === 'partners';
  const statusCompany = info.status.id;

  const isShowResendInvitationButton = [3, 4, 5, 6].includes(statusCompany);

  useEffect(() => {
    if (!identity.isTruthyNumber(profileId)) {
      getMyProfileData();
    }
  }, [profileId]);

  useEffect(() => {
    const functionCall = isPartners ? getPartnerById : getMemberById;

    if (identity.isTruthyString(id) && !isAngelInvestor(userType) && !isTechnicalLicense(userType)) {
      functionCall({ id: memberId });
    }

    if (identity.isTruthyString(id) && isAngelInvestor(userType)) {
      getAngelInvestorMemberById({ id: memberId });
    }

    if (identity.isTruthyString(id) && isTechnicalLicense(userType)) {
      getTechnicalLicenseMemberById({ id: memberId });
    }
  }, [id, type, memberId]);

  useEffect(() => {
    if (isStartup(userType)) {
      const startupId = selectedMember?.startupId;

      if (startupId === -1) {
        return;
      }

      getStartupById({
        id: startupId,
        owner: 'startup',
        isUpdate: false,
      });
    }
  }, [selectedMember])

  return (
    <div className="member-details content-container">
      <RenderIf condition={isEitherAdmin(userType)}>
        <Breadcrumb navigateTo={peopleUrl} routeName="People List" />

        <Details
          user={selectedMember}
          isShowResendInvitationButton={isShowResendInvitationButton}
          link={composeEditPeopleUrl(memberId, type || 'startup')}
        />
      </RenderIf>

      <RenderIf condition={isStartup(userType)}>
        <Breadcrumb navigateTo={isStartup(userType) ? teamMembersUrl : peopleUrl} routeName="People List" />

        <Details
          user={selectedMember}
          isShowResendInvitationButton={isShowResendInvitationButton}
          link={
            userId === selectedMember.userId
              ? routes.editMyProfileUrl
              : composeEditPeopleUrl(memberId, isPartners ? type : 'startup')
          }
        />
      </RenderIf>

      <RenderIf condition={isAngelInvestor(userType)}>
        <Breadcrumb navigateTo={teamMembersUrl} routeName="People List" />

        <Details
          user={selectedAIMember}
          isShowResendInvitationButton={isShowResendInvitationButton}
          link={
            userId === selectedAIMember.userId
              ? routes.editMyProfileUrl
              : composeEditPeopleUrl(memberId, 'angel-investor')
          }
        />
      </RenderIf>

      <RenderIf condition={isTechnicalLicense(userType)}>
        <Breadcrumb navigateTo={teamMembersUrl} routeName="People List" />

        <Details
          user={selectedMember}
          isShowResendInvitationButton={isShowResendInvitationButton}
          link={
            userId === selectedMember.userId
              ? routes.editMyProfileUrl
              : composeEditPeopleUrl(memberId, 'technical-licenses')
          }
        />
      </RenderIf>
    </div>
  );
};

export default MemberDetails;
