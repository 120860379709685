import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Button } from 'antd';
import moment from 'moment';
import { LabeledValue } from 'antd/lib/select';
import { ColumnType } from 'antd/lib/table';

import { UserType } from 'packages/user_repository/types/users';
import { TableActions, TableActionChangeUserRole, TagTable } from 'components/components';
import { ServiceCategoryType, ServicesType, ServiceSubCategoryType } from 'types/services';
import { CreateTeamMemberPayload } from 'packages/people_repository';
import { ProgramType } from 'packages/cohort_repository';
import { PartnerType } from 'types/partners';
import { SectorType, StartupType as Startup } from 'packages/startup_repository';
import { AngelInvestorType } from 'packages/angel_investors_repository';
import { ServiceProviderType } from 'types/serviceProviders';
import { ViewIcon } from 'assets/icons';
import { GetPerformanceColumnsType } from 'types/performance';
import { CohortType } from 'types/cohorts/cohortOverview';
import { StartupType } from 'types/startups';
import { WalletTransactionType } from 'types/wallet';
import { AIWalletTransactionType } from 'types/ai_wallet';
import { TemplateType } from 'packages/template_repository';
import { SettingsTableDate } from 'components/Settings/components/TemplatesTable/components';
import {
  getCurrencyAmountString,
  identity,
  isEitherAdmin,
  parseNumberToPrice,
  sortHelper,
} from 'utils';
import { REPORT_STATUS_ALTERNATIVE_NAMES_BY_ROLE, PerformanceType, REPORT_STATUS } from 'packages/performance_repository';
import history from 'history_instance';
import { composeEditPerformanceReportUrl } from 'routes/routeComposers';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes'
import { MemberAttribute, MemberType, AngelInvestorMemberType } from 'types/memberDetails';
import { DataEntryPointType } from 'packages/settings_repository';
import { formatSlashMDY, formatSlashMDYHD } from './formatters';
import TableTitleWithSorter from '../../components/Table/components/TitleWithSorter';
import { WalletTransactionDetailsModal } from '../../WalletOverview/components';
import { AIWalletTransactionDetailsModal } from '../../AIWalletOverview/components';

import '../../StartupsOverview/StartupsOverview.scss';
import '../../Settings/Settings.scss';

const NOT_SPECIFIED = 'Not Specified';
const NO_DATA = 'No Data';

export const templatesTableColumns = (
  deleteAction: (_payload: IdPayload) => void,
  updateAction: (_payload: TemplateType) => void,
): ColumnsType<TemplateType> => ([
  {
    key: 'title',
    title: <TableTitleWithSorter title="Name" />,
    dataIndex: 'title',
    sorter: ({ title: titleA }, { title: titleB }) => sortHelper(titleA, titleB),
    render: (text: string) => <span className="settings__table-name">{text}</span>,
  },
  {
    key: 'createdAt',
    title: <TableTitleWithSorter title="Date created" />,
    dataIndex: 'createdAt',
    sorter: ({ createdAt: createdAtA }, { createdAt: createdAtB }) => sortHelper(createdAtA, createdAtB),
    render: (text: string) => (<SettingsTableDate date={text} />),
  },
  {
    key: 'id',
    title: 'Actions',
    dataIndex: 'actions',
    render: (_, record) => {
      const { id, title } = record;

      return (
        <TableActions
          key={`template-${id}-${title}`}
          onDelete={() => deleteAction({ id })}
          onUpdate={() => updateAction(record)}
          name={title}
        />
      )
    },
  },
]);

export const walletOverviewColumns: ColumnsType<WalletTransactionType> = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    render: (date) => moment(date).format(formatSlashMDY),
  },
  {
    key: 'type',
    title: 'Transaction Type',
    dataIndex: 'type',
    render: (type) => type || NOT_SPECIFIED,
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: 'amount',
    render: (amount) => parseNumberToPrice(amount, 'AED'),
  },
  {
    key: 'id',
    render: (transaction) => (<WalletTransactionDetailsModal transaction={transaction} />),
  },
];

export const aiWalletOverviewColumns: ColumnsType<AIWalletTransactionType> = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    render: (date) => moment(date).format(formatSlashMDY),
  },
  {
    key: 'type',
    title: 'Transaction Type',
    dataIndex: 'type',
    render: (type) => type || NOT_SPECIFIED,
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: 'amount',
    render: (amount) => parseNumberToPrice(amount, 'AED'),
  },
  {
    key: 'id',
    render: (transaction) => (<AIWalletTransactionDetailsModal transaction={transaction} />),
  },
];

export const startupsColumns: ColumnsType<Startup> = [
  {
    key: 'name',
    title: 'startup',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="startups-overview-column">
        <Avatar className="startups-overview-column--avatar" src={imageLink as string} alt={name} />
        <span className="overview-column-name">{identity.isTruthyString(name) ? name : NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'stageName',
    title: 'Stage',
    dataIndex: 'stageName',
    render: (text: string) => (<div className="cell">{identity.isEmptyString(text) ? NOT_SPECIFIED : text}</div>),
  },
  {
    key: 'primarySector',
    title: 'Primary sector',
    dataIndex: 'primarySector',
    render: (primarySector: SectorType) => (<span className="cell">{identity.isEmptyString(primarySector.name) ? NOT_SPECIFIED : primarySector.name}</span>),
  },
  // {
  //   key: 'secondarySector',
  //   title: 'Secondary sector',
  //   dataIndex: 'secondarySector',
  //   render: (secondarySector: SectorType) => (<div className="cell">{identity.isEmptyString(secondarySector.name) ? NOT_SPECIFIED : secondarySector.name}</div>),
  // },
  // {
  //   key: 'location',
  //   title: 'location',
  //   dataIndex: 'location',
  //   render: (text: string) => (<div className="cell">{text === '' ? NOT_SPECIFIED : text}</div>),
  // },
  {
    key: 'founderEmail',
    title: 'Founder Email',
    dataIndex: 'founderEmail',
    render: (text: string) => <div className="table-email-column">{identity.isEmptyString(text) ? NOT_SPECIFIED : text}</div>,
  },
];

export const adminDashboardStartupsColumns: ColumnsType<Startup> = [
  {
    key: 'name',
    title: 'Startup',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="startups-overview-column">
        <Avatar className="startups-overview-column--avatar" src={imageLink as string} alt={name} />
        <span className="overview-column-name">{identity.isTruthyString(name) ? name : NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'cohort',
    title: 'Cohort',
    dataIndex: 'cohort',
    render: (_, { cohort }) => (
      identity.isEmptyString(cohort.name) ? NOT_SPECIFIED : cohort.name
    ),
  },
  {
    key: 'numberOfEmployees',
    title: '# of FTEs',
    dataIndex: 'numberOfEmployees',
    render: (_, { numberOfEmployees }) => (
      identity.isEmptyString(numberOfEmployees) ? NOT_SPECIFIED : numberOfEmployees
    ),
  },
  {
    key: 'startDate',
    title: 'Incentive Agreement Start Date',
    dataIndex: 'startDate',
    render: (_, { currentIncentiveAgreement }) => (
      currentIncentiveAgreement?.startDate
        ? moment(currentIncentiveAgreement?.startDate).format(formatSlashMDY)
        : NO_DATA
    ),
  },
  {
    key: 'endDate',
    title: 'Incentive Agreement End Date',
    dataIndex: 'endDate',
    render: (_, { currentIncentiveAgreement }) => (
      currentIncentiveAgreement?.endDate
        ? moment(currentIncentiveAgreement?.endDate).format(formatSlashMDY)
        : NO_DATA
    ),
  },
  {
    key: 'state',
    title: 'State',
    dataIndex: 'state',
    render: (_, { currentIncentiveAgreement, incentiveAgreementApprovedStatus }) => {
      const endDateStr = currentIncentiveAgreement?.endDate;

      if (identity.isEmptyString(endDateStr)) {
        return NOT_SPECIFIED;
      }

      const now = moment();
      const endDate = moment(endDateStr);

      if (incentiveAgreementApprovedStatus === 0) {
        return <TagTable color="gold" text="Not Signed" />
      }

      if (now.isAfter(endDate)) {
        return <TagTable color="error" text="Expired" />
      }

      const diffInDays = endDate.diff(now, 'days');

      if (diffInDays <= 30) {
        return <TagTable color="warning" text="Expiring Soon" />
      }

      return <TagTable color="success" text="Active" />
    },
  },
]

export const adminDashboardAngelInvestorsColumns: ColumnsType<Startup> = [
  {
    key: 'name',
    title: 'Angel Investors',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="startups-overview-column">
        <Avatar className="startups-overview-column--avatar" src={imageLink as string} alt={name} />
        <span className="overview-column-name">{identity.isTruthyString(name) ? name : NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'startDate',
    title: 'Incentive Agreement Start Date',
    dataIndex: 'startDate',
    render: (_, { currentIncentiveAgreement }) => (
      currentIncentiveAgreement?.startDate
        ? moment(currentIncentiveAgreement?.startDate).format(formatSlashMDY)
        : NO_DATA
    ),
  },
  {
    key: 'endDate',
    title: 'Incentive Agreement End Date',
    dataIndex: 'endDate',
    render: (_, { currentIncentiveAgreement }) => (
      currentIncentiveAgreement?.endDate
        ? moment(currentIncentiveAgreement?.endDate).format(formatSlashMDY)
        : NO_DATA
    ),
  },
  {
    key: 'state',
    title: 'State',
    dataIndex: 'state',
    render: (_, { currentIncentiveAgreement, incentiveAgreementApprovedStatus }) => {
      const endDateStr = currentIncentiveAgreement?.endDate;

      if (identity.isEmptyString(endDateStr)) {
        return NOT_SPECIFIED;
      }

      const now = moment();
      const endDate = moment(endDateStr);

      if (incentiveAgreementApprovedStatus === 0) {
        return <TagTable color="gold" text="Not Signed" />
      }

      if (now.isAfter(endDate)) {
        return <TagTable color="error" text="Expired" />
      }

      const diffInDays = endDate.diff(now, 'days');

      if (diffInDays <= 30) {
        return <TagTable color="warning" text="Expiring Soon" />
      }

      return <TagTable color="success" text="Active" />
    },
  },
]

export const angelInvestorsColumns: ColumnsType<AngelInvestorType> = [
  {
    key: 'angelInvestor',
    title: 'Angel Investor',
    dataIndex: 'name',
    render: (_, { name }: AngelInvestorType) => (
      identity.isEmptyString(name) ? NOT_SPECIFIED : name
    ),
  },
  {
    key: 'fullName',
    title: 'Founder',
    dataIndex: 'contactFirstName',
    render: (_, { contactFirstName, contactLastName }: AngelInvestorType) => (
      (identity.isTruthyString(contactFirstName) || identity.isTruthyString(contactLastName)
      )
        ? `${contactFirstName} ${contactLastName}`
        : NOT_SPECIFIED),
  },
  {
    key: 'founderEmail',
    title: 'Founder Email',
    dataIndex: 'contactEmail',
    render: (_, { contactEmail }: AngelInvestorType) => (
      identity.isEmptyString(contactEmail) ? NOT_SPECIFIED : contactEmail
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (_, { status }: AngelInvestorType) => (
      identity.isEmptyString(status.name) ? NOT_SPECIFIED : status.name
    ),
  },
];

export const technicalLicensesColumns: ColumnsType<any> = [
  {
    key: 'technicalLicense',
    title: 'Tech License Holder',
    dataIndex: 'name',
    render: (_, { name }) => (
      identity.isEmptyString(name) ? NOT_SPECIFIED : name
    ),
  },
  {
    key: 'founder',
    title: 'Founder',
    dataIndex: 'founder',
    render: (_, { contactFirstName, contactLastName }) => (
      identity.isTruthyString(contactFirstName) || identity.isTruthyString(contactLastName) ? `${contactFirstName} ${contactLastName}` : NOT_SPECIFIED
    ),
  },
  {
    key: 'founderEmail',
    title: 'Founder Email',
    dataIndex: 'email',
    render: (_, { contactEmail }: AngelInvestorType) => (
      identity.isEmptyString(contactEmail) ? NOT_SPECIFIED : contactEmail
    ),
  },
  {
    key: 'companySector',
    title: 'Company Sector',
    dataIndex: 'companySector',
    render: (_, { companySector }) => (
      identity.isEmptyString(companySector) ? NOT_SPECIFIED : companySector
    ),
  },
]

export const cohortsColumns: ColumnsType<CohortType> = [
  {
    key: 'name', title: 'cohort name', dataIndex: 'name', render: (value) => value || NOT_SPECIFIED,
  },
  {
    key: 'from',
    title: 'from',
    dataIndex: 'from',
    render: (value) => moment(value).format(formatSlashMDY) || NOT_SPECIFIED,
  },
  {
    key: 'to',
    title: 'to',
    dataIndex: 'to',
    render: (value) => moment(value).format(formatSlashMDY) || NOT_SPECIFIED,
  },
];

export const cohortOverviewColumns: ColumnsType<CohortType> = [
  {
    key: 'name',
    title: 'cohort name',
    dataIndex: 'name',
    render: (value) => value || NOT_SPECIFIED,
  },
  {
    key: 'type',
    title: 'program',
    dataIndex: 'type',
    render: (value) => value || NOT_SPECIFIED,
  },
  {
    key: 'from',
    title: 'from',
    dataIndex: 'from',
    render: (fromDate) => moment(fromDate).format(formatSlashMDY) || NOT_SPECIFIED,
  },
  {
    key: 'to',
    title: 'to',
    dataIndex: 'to',
    render: (toDate) => moment(toDate).format(formatSlashMDY) || NOT_SPECIFIED,
  },
  {
    key: 'amount',
    title: 'amount',
    dataIndex: 'amount',
    render: (amount) => getCurrencyAmountString(amount) || NOT_SPECIFIED,
  },
];

export const addPeopleColumns: ColumnsType<UserType> = [
  {
    key: 'label',
    dataIndex: 'label',
    title: <TableTitleWithSorter title="Name" />,
    render: (_, { firstName, lastName }) => (
      <span>{(identity.isTruthyString(firstName) || identity.isTruthyString(lastName)) ? `${firstName} ${lastName}` : NOT_SPECIFIED}</span>
    ),
    sorter: ({ lastName: nameA }, { lastName: nameB }) => sortHelper(nameA, nameB),
  },
];

export const startupTeamMembersColumns: ColumnsType<CreateTeamMemberPayload> = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    render: (_, { firstName, lastName }) => (
      <span>{(identity.isTruthyString(firstName) || identity.isTruthyString(lastName)) ? `${firstName} ${lastName}` : NOT_SPECIFIED}</span>
    ),
  },
  {
    key: 'jobTitle',
    title: 'Job title',
    dataIndex: 'jobTitle',
    render: (jobTitle) => (
      <div className="service-providers-column">
        <p>{identity.isTruthyString(jobTitle) ? jobTitle : NOT_SPECIFIED}</p>
      </div>
    ),
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (email: string) => (
      <div className="service-providers-column">
        <p>{identity.isTruthyString(email) ? email : NOT_SPECIFIED}</p>
      </div>
    ),
  },
  {
    key: 'role',
    title: 'Role',
    dataIndex: 'role',
    render: (_, { roleId, founder }) => {
      let role = 'Member';

      if (founder) {
        role = 'Founder';
      } else if (roleId === 1) {
        role = 'Admin';
      }

      return (
        <div className="service-providers-column">
          <p>{role}</p>
        </div>
      )
    },
  },
];

export const startupTeamMembersChangeRoleColumns = (
  onChangeRole: ({
    role,
    id,
  }: {
    role: string;
    id: number;
  }) => void,
): ColumnsType<CreateTeamMemberPayload> => ([
  ...startupTeamMembersColumns,
  {
    key: 'action',
    title: 'Change role',
    dataIndex: 'action',
    width: 180,
    render: (_, { memberId, founder, roleId }) => (
      <TableActionChangeUserRole
        onChangeRole={(role) => onChangeRole({ role, id: memberId || 0 })}
        founder={Boolean(founder)}
        roleId={roleId}
      />
    ),
  },
]);

export const technicalLicenseTeamMembersColumn: ColumnsType<CreateTeamMemberPayload> = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    render: (_, { firstName, lastName }) => (
      <span>{(identity.isTruthyString(firstName) || identity.isTruthyString(lastName)) ? `${firstName} ${lastName}` : NOT_SPECIFIED}</span>
    ),
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (email: string) => (
      <div className="service-providers-column">
        <p>{identity.isTruthyString(email) ? email : NOT_SPECIFIED}</p>
      </div>
    ),
  },
];

export const serviceProvidersColumns: ColumnsType<ServiceProviderType> = [
  {
    key: 'name',
    title: 'service provider',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="startups-overview-column">
        <Avatar className="startups-overview-column--avatar" src={imageLink as string} alt={name} />
        <span className="overview-column-name">{identity.isTruthyString(name) ? name : NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (type: string) => <div className="table-email-column">{identity.isEmptyString(type) ? NOT_SPECIFIED : type}</div>,
  },
  {
    key: 'amountBlocked',
    title: 'Amount Blocked',
    dataIndex: 'amountBlocked',
    render: (amountBlocked: string) => (identity.isEmptyString(amountBlocked) ? 'AED 0.00' : amountBlocked),
  },
  {
    key: 'amountConsumed',
    title: 'Amount Consumed',
    dataIndex: 'amountConsumed',
    render: (amountConsumed: string) => (identity.isEmptyString(amountConsumed) ? 'AED 0.00' : amountConsumed),
  },
];

export const serviceProvidersColumnsTLH: ColumnsType<ServiceProviderType> = [
  {
    key: 'name',
    title: 'service provider',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="startups-overview-column">
        <Avatar className="startups-overview-column--avatar" src={imageLink as string} alt={name} />
        <span className="overview-column-name">{identity.isTruthyString(name) ? name : NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (type: string) => <div className="table-email-column">{identity.isEmptyString(type) ? NOT_SPECIFIED : type}</div>,
  },
];

export const startupsInCohortColumns: ColumnsType<Startup> = [
  {
    key: 'name',
    title: 'Startup',
    dataIndex: 'name',
    render: (name) => name || NOT_SPECIFIED,
  },
  {
    key: 'industry',
    title: 'Primary Sector',
    dataIndex: 'industry',
    render: (industry) => (
      <div className="service-providers-column">
        <p>{identity.isTruthyString(industry) ? industry : NOT_SPECIFIED}</p>
      </div>
    ),
  },
  {
    key: 'stageName',
    title: 'stage',
    dataIndex: 'stageName',
    render: (stageName) => (
      <div className="service-providers-column">
        <p>{identity.isTruthyString(stageName) ? stageName : NOT_SPECIFIED}</p>
      </div>
    ),
  },
];

export const peopleOverviewColumns: ColumnsType<MemberType> = [
  {
    key: 'peopleTableName',
    title: 'Name',
    dataIndex: 'lastName',
    render: (_, { lastName, firstName }) => (
      <span>
        {(identity.isTruthyString(lastName) || identity.isTruthyString(firstName))
          ? `${firstName} ${lastName}`
          : NOT_SPECIFIED}
      </span>
    ),
  },
  {
    key: 'peopleTableStartup',
    title: 'Startup name',
    dataIndex: 'company',
    render: (company) => company || NOT_SPECIFIED,
  },
  {
    key: 'role',
    title: 'Role/Title',
    dataIndex: 'role',
    render: (role) => role || NOT_SPECIFIED,
  },
  {
    key: 'nationality',
    title: 'Nationality',
    dataIndex: 'nationality',
    render: (nationality) => nationality || NOT_SPECIFIED,
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (text: string) => <div className="table-email-column">{identity.isEmptyString(text) ? NOT_SPECIFIED : text}</div>,
  },
  {
    key: 'phoneNumber',
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    render: (phoneNumber) => phoneNumber || NOT_SPECIFIED,
  },
  {
    key: 'skills',
    title: 'Skills',
    dataIndex: 'skills',
    render: (skills) => (identity.isFullArray(skills) ? skills[0] : NOT_SPECIFIED),
  },
];

export const aIPeopleOverviewColumns: ColumnsType<AngelInvestorMemberType> = [
  {
    key: 'peopleTableName',
    title: 'Name',
    dataIndex: 'lastName',
    render: (_, { lastName, firstName }) => (
      <span>
        {(identity.isTruthyString(lastName) || identity.isTruthyString(firstName))
          ? `${firstName} ${lastName}`
          : NOT_SPECIFIED}
      </span>
    ),
  },
  {
    key: 'role',
    title: 'Role/Title',
    dataIndex: 'role',
    render: (role) => role || NOT_SPECIFIED,
  },
  {
    key: 'nationality',
    title: 'Nationality',
    dataIndex: 'nationality',
    render: (nationality) => nationality || NOT_SPECIFIED,
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (text: string) => <div className="table-email-column">{identity.isEmptyString(text) ? NOT_SPECIFIED : text}</div>,
  },
  {
    key: 'phoneNumber',
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    render: (phoneNumber) => phoneNumber || NOT_SPECIFIED,
  },
];

export const thlPeopleOverviewColumns: ColumnsType<AngelInvestorMemberType> = [
  {
    key: 'peopleTableName',
    title: 'Name',
    dataIndex: 'lastName',
    render: (_, { lastName, firstName }) => (
      <span>
        {(identity.isTruthyString(lastName) || identity.isTruthyString(firstName))
          ? `${firstName} ${lastName}`
          : NOT_SPECIFIED}
      </span>
    ),
  },
  {
    key: 'role',
    title: 'Role/Title',
    dataIndex: 'role',
    render: (role) => role || NOT_SPECIFIED,
  },
  {
    key: 'nationality',
    title: 'Nationality',
    dataIndex: 'nationality',
    render: (nationality) => nationality || NOT_SPECIFIED,
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (text: string) => <div className="table-email-column">{identity.isEmptyString(text) ? NOT_SPECIFIED : text}</div>,
  },
  {
    key: 'phoneNumber',
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    render: (phoneNumber) => phoneNumber || NOT_SPECIFIED,
  },
];

export const nationalitiesColumns: ColumnsType<LabeledValue> = [{
  key: 'nationality',
  title: 'Nationalities',
  dataIndex: 'label',
}];

export const rolesColumns: ColumnsType<LabeledValue> = [{
  key: 'role',
  title: 'Roles',
  dataIndex: 'label',
}];

export const categoriesColumns: ColumnsType<LabeledValue> = [
  {
    key: 'value',
    title: 'Categories',
    dataIndex: 'value',
  },
  {
    key: 'label',
    title: 'Categories',
    dataIndex: 'label',
  },
];

export const startupSelectColumns: ColumnsType<StartupType> = [{
  key: 'name',
  title: 'Name',
  dataIndex: 'name',
  render: (text, { imageLink }) => (
    <div className="add-member__select-startup-option">
      <Avatar src={imageLink} size={20} alt="Startup" />
      <span className="overview-column-name">{text}</span>
    </div>
  ),
}];

export const angelInvestorsSelectColumns: ColumnsType<StartupType> = [{
  key: 'name',
  title: 'Name',
  dataIndex: 'name',
  render: (text, { imageLink }) => (
    <div className="add-member__select-startup-option">
      <Avatar src={imageLink} size={20} alt="Startup" />
      <span className="overview-column-name">{text}</span>
    </div>
  ),
}];

export const skillsSelectColumns: ColumnsType<MemberAttribute> = [{
  key: 'skill',
  title: 'Skills',
  dataIndex: 'label',
}];

export const serviceProvidersOverviewColumns: ColumnsType<ServiceProviderType> = [
  {
    key: 'name',
    title: 'Service Provider',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="service-providers-column">
        <Avatar src={imageLink} alt={name} />
        <span className="overview-column-name">{name || NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'category',
    title: 'Category',
    dataIndex: 'category',
    render: (category) => category || NOT_SPECIFIED,
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (type) => type || NOT_SPECIFIED,
  },
  {
    key: 'about',
    title: 'Description',
    dataIndex: 'about',
    render: (about) => (
      <div className="service-providers-column">
        <p className="service-providers-column--about">{about || NOT_SPECIFIED}</p>
      </div>
    ),
  },
];

export const servicesOverviewColumns: ColumnsType<ServiceProviderType> = [
  {
    key: 'name',
    title: 'Service Provider',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="services-column">
        <Avatar className="services-column--avatar" src={imageLink} alt={name} />
        <span className="overview-column-name">{name || NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'category',
    title: 'Category',
    dataIndex: 'category',
    render: (category) => category || NOT_SPECIFIED,
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (type) => type || NOT_SPECIFIED,
  },
  {
    key: 'about',
    title: 'Description',
    dataIndex: 'about',
    render: (about) => (
      <div className="services-column">
        <p className="services-column--about">{about || NOT_SPECIFIED}</p>
      </div>
    ),
  },
];

export const servicesColumns: ColumnsType<ServicesType> = [
  { key: 'name', title: 'Name', dataIndex: 'name' },
  {
    key: 'category',
    title: 'Category',
    dataIndex: 'category',
    render: (category) => category || NOT_SPECIFIED,
  },
  {
    key: 'about',
    title: 'Description',
    dataIndex: 'about',
    render: (about) => (
      <div className="services-column">
        <p className="services-column--about">{about}</p>
      </div>
    ),
  },
  { key: 'offerPrice', title: 'Price', dataIndex: 'offerPrice' },
];

export const partnersColumns: ColumnsType<PartnerType> = [
  { key: 'name', title: 'Name', dataIndex: 'name' },
  { key: 'type', title: 'Type', dataIndex: 'type' },
  { key: 'location', title: 'Location', dataIndex: 'location' },
  { key: 'website', title: 'Website', dataIndex: 'website' },
];

export const serviceProvidersGridColumns: ColumnsType<ServiceProviderType> = [
  {
    key: 'name',
    title: 'Service Provider',
    dataIndex: 'name',
    render: (_, { name, imageLink }) => (
      <div className="service-providers-column">
        <Avatar src={imageLink} alt={name} />
        <span className="overview-column-name">{name || NOT_SPECIFIED}</span>
      </div>
    ),
  },
  {
    key: 'about',
    title: 'Description',
    dataIndex: 'about',
    render: (about) => (
      <div className="service-providers-column">
        <p className="service-providers-column--about">{about || NOT_SPECIFIED}</p>
      </div>
    ),
  },
  {
    key: 'categories',
    title: 'Category',
    dataIndex: 'categories',
  },
];

export const getProgramTypeColumns = (
  onDelete: (_payload: number) => void,
  onUpdate: (_payload: ProgramType) => void,
): ColumnsType<ProgramType> => ([
  {
    key: 'name',
    title: <TableTitleWithSorter title="Name" />,
    sorter: ({ name: name1 }, { name: name2 }) => sortHelper(name1, name2),
    dataIndex: 'name',
  },
  {
    key: 'amount',
    title: <TableTitleWithSorter title="Amount" />,
    dataIndex: 'amount',
    sorter: ({ amount: amount1 }, { amount: amount2 }) => sortHelper(Number(amount1), Number(amount2)),
    render: (amount) => parseNumberToPrice(amount, 'AED') || NOT_SPECIFIED,
  },
  {
    key: 'createdAt',
    title: 'Date created',
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt).format(formatSlashMDYHD) || NOT_SPECIFIED,
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    render: (about) => (
      <div className="table-column">
        <p className="table-column__description">{about || NOT_SPECIFIED}</p>
      </div>
    ),
  },
  {
    key: 'id',
    title: 'Actions',
    dataIndex: 'id',
    render: (id, record: ProgramType) => (
      <TableActions
        key={`program-type-actions-${id}`}
        name="program type"
        onDelete={() => onDelete(id)}
        onUpdate={() => onUpdate(record)}
      />
    ),
  },
]);

export const MainCategoriesColumns = (
  onDelete: (_payload: number) => void,
  onUpdate: (_payload: ServiceCategoryType) => void,
): ColumnsType<ServiceCategoryType> => ([
  {
    key: 'name',
    title: <TableTitleWithSorter title="Name" />,
    dataIndex: 'name',
    sorter: ({ name: catA }, { name: catB }) => sortHelper(catA, catB),
  },
  {
    key: 'createdAt',
    title: 'Date created',
    dataIndex: 'createdAt',
    render: (dateCreated) => (identity.isTruthyString(dateCreated)
      ? moment(dateCreated).format(formatSlashMDYHD) : NOT_SPECIFIED),
  },
  {
    key: 'id',
    title: 'Actions',
    dataIndex: 'id',
    render: (id, record: ServiceCategoryType) => (
      <TableActions
        key={`program-type-actions-${id}`}
        name="program type"
        onDelete={() => onDelete(id)}
        onUpdate={() => onUpdate(record)}
      />
    ),
  },
]);

export const getSubCategoriesColumns = (
  onDelete: (_payload: number) => void,
  onUpdate: (_payload: ServiceSubCategoryType) => void,
): ColumnsType<ServiceSubCategoryType> => ([
  {
    key: 'name',
    title: <TableTitleWithSorter title="Name" />,
    dataIndex: 'name',
    sorter: ({ name: catA }, { name: catB }) => sortHelper(catA, catB),
  },
  {
    key: 'serviceCategory',
    title: 'Parent category',
    dataIndex: 'serviceCategory',
    render: (serviceCategory) => (
      <div className="services-column">
        <p className="services-column--about">{serviceCategory.name || NOT_SPECIFIED}</p>
      </div>
    ),
  },
  {
    key: 'createdAt',
    title: 'Date created',
    dataIndex: 'createdAt',
    render: (dateCreated) => (identity.isTruthyString(dateCreated)
      ? moment(dateCreated).format(formatSlashMDYHD) : NOT_SPECIFIED),
  },
  {
    key: 'id',
    title: 'Actions',
    dataIndex: 'id',
    render: (id, record: ServiceSubCategoryType) => (
      <TableActions
        key={`program-type-actions-${id}`}
        name="program type"
        onDelete={() => onDelete(id)}
        onUpdate={() => onUpdate(record)}
      />
    ),
  },
]);

export const dataEntryColumns = (
  deleteAction: (_payload: DataPayload<number>) => void,
  updateAction: (_payload: DataEntryPointType) => void,
): ColumnsType<DataEntryPointType> => ([
  {
    key: 'name',
    title: <TableTitleWithSorter title="Name" />,
    dataIndex: 'name',
    sorter: ({ name: nameA }, { name: nameB }) => sortHelper(nameA, nameB),
    render: (name) => name || NOT_SPECIFIED,
  },
  {
    key: 'dateCreated',
    title: <TableTitleWithSorter title="Date Created" />,
    dataIndex: 'createdAt',
    sorter: ({ createdAt: dateA }, { createdAt: dateB }) => sortHelper(dateA, dateB),
    render: (date) => (identity.isTruthyString(date) ? moment(date).format(formatSlashMDYHD) : NOT_SPECIFIED),
  },
  {
    key: 'actions',
    title: 'Actions',
    dataIndex: 'id',
    render: (id, record) => {
      const { name } = record;

      return (
        <TableActions
          key={`data-entry-${id}`}
          name={name}
          onDelete={() => deleteAction({ data: id })}
          onUpdate={() => updateAction(record)}
        />
      )
    },
  },
]);

export const getPerformanceStartupActions = (onDelete: (_payload: number) => void): ColumnType<PerformanceType> => ({
  key: 'actions',
  title: 'Actions',
  dataIndex: 'id',
  render: (id, { quarters, year, status }) => (
    <TableActions
      key={`performance-action-${id}`}
      name={`Q${quarters} ${year} report`}
      onDelete={() => onDelete(id)}
      disabled={status !== REPORT_STATUS.rejected && status !== REPORT_STATUS.draft}
      onUpdate={() => history.push(composeEditPerformanceReportUrl(id))}
    />
  ),
});

export const getPerformanceAdminActions = (
  onReview: (_payload: number) => void,
  onView: (_payload: number) => void,
): ColumnType<PerformanceType> => (
  {
    key: 'actions',
    title: 'Actions',
    dataIndex: 'id',
    render: (id, { status }) => {
      const isPending = status === REPORT_STATUS.submitted;
      return (
        <div className="table-action-buttons">
          <Button
            className="ghost-button"
            icon={<ViewIcon />}
            onClick={isPending ? () => onReview(id) : () => onView(id)}
          >
            {isPending ? 'Review' : 'View'}
          </Button>
        </div>
      )
    },
  })

export const getPerformanceColumns = (args: GetPerformanceColumnsType)
: ColumnsType<PerformanceType> => ([
  {
    key: 'startupName',
    title: 'Startup name',
    dataIndex: 'startup',
    render: (startup: StartupType) => startup.name,
  },
  {
    key: 'year',
    title: 'Year',
    dataIndex: 'year',
  },
  {
    key: 'quarter',
    title: 'Quarter',
    dataIndex: 'quarters',
  },
  {
    key: 'dateSubmitted',
    title: 'Date submitted',
    dataIndex: 'createdAt',
    render: (date: string) => moment(date).format(formatSlashMDYHD),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status: string) => REPORT_STATUS_ALTERNATIVE_NAMES_BY_ROLE[args.userType][status] || status,
  },
  isEitherAdmin(args.userType)
    ? getPerformanceAdminActions(args.onReview, args.onView)
    : getPerformanceStartupActions(args.onDelete),
]);

export const EventAttendeeColumns: ColumnsType<UserType> = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    render: (_, { firstName, lastName }) => (
      <span>{`${firstName} ${lastName}`}</span>
    ),
  },
];
