import { AddTeamMemberFieldsCreatorParams } from 'types/people';
import { genderOptions } from 'utils/constants/inputFieldValues';
import { emailValidation, requiredValidation, positiveNumbersOnly } from 'utils/constants/validationRules';
import { inputTypes, getFormFieldBuilder } from '../../components';

export const addMemberFields = ({
  beforeHeadshotUpload,
  beforeEmirateIdUpload,
  beforeEmployeeContractUpload,
  beforeWorkPermitUpload,
  beforePassportUpload,
  beforeVisaUpload,
  nationalityOptions,
}: AddTeamMemberFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('nationalityId', 'Nationality', inputTypes.select)
        .withOptions(nationalityOptions)
        .build(),
      getFormFieldBuilder('email', 'Email')
        .required()
        .withRules([requiredValidation, emailValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('dateOfBirth', 'Date of birth', inputTypes.datetime)
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('phoneNumber', 'Mobile number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('gender', 'Gender', inputTypes.select)
        .withOptions(genderOptions)
        .withPlaceholder('Select gender')
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('whatsAppNumber', 'WhatsApp number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('jobTitle', 'Job title')
        .required()
        .withRules([requiredValidation])
        .build(),
    ],
  },
  /* {
    fields: [
      createFormField('numberOfKids', 'Number of kids', inputTypes.number),
    ],
  }, */
  {
    fields: [
      getFormFieldBuilder('skills', 'Skills', inputTypes.addButton)
        .withRows(1)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('roleId', 'Assign Admin Role?', inputTypes.switch)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('headshot', 'Headshot', inputTypes.image)
        .required()
        .withRules([requiredValidation])
        .withBeforeUpload(beforeHeadshotUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('bio', 'Biography', inputTypes.textArea)
        .withRows(3)
        .build(),
    ],
  },
  {
    heading: 'Documents',
    fields: [
      getFormFieldBuilder('emirates', 'Emirate ID', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeEmirateIdUpload)
        .build(),
      getFormFieldBuilder('employmentContract', 'Employee Contract', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeEmployeeContractUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('workPermitName', 'Work Permit', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeWorkPermitUpload)
        .build(),
      getFormFieldBuilder('passport', 'Passport', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforePassportUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('visa', 'Visa', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeVisaUpload)
        .build(),
    ],
  },
];

export default null;
