import React from 'react';

import { identity, isEitherAdmin } from 'utils';
import { About, RenderIf, CompanyDeckFile } from 'components/components';
import { ServiceInfoProps } from 'types/startups/startupDetails/serviceProviderOverview';

import './AboutStartup.scss';

const MISSING_ABOUT_TEXT = 'This service provider does not have any description or details about it';
const AboutService = (props: ServiceInfoProps) => {
  const { serviceProvider, userType } = props;
  const {
    agreementDocument, name, about, companyDeck,
  } = serviceProvider;

  const aboutTitle = `About ${name || 'Service Provider'}`;
  const displayCompanyDeck = identity.isTruthyString(companyDeck);
  const displayAgreementDoc = isEitherAdmin(userType!) && identity.isTruthyString(agreementDocument);

  return (
    <div className="about-service">
      <About title={aboutTitle} description={about || MISSING_ABOUT_TEXT}>
        <div className="about-service__company-deck">
          <h3 className="about-service__company-deck__title">Documents</h3>
          <RenderIf condition={!(displayCompanyDeck || displayAgreementDoc)}>
            <p>No documents available for this service provider</p>
          </RenderIf>

          <RenderIf condition={displayCompanyDeck}>
            <CompanyDeckFile fileContent={companyDeck} />
          </RenderIf>

          <RenderIf condition={displayAgreementDoc}>
            <CompanyDeckFile fileContent={agreementDocument!} fileName="Agreement Document.pdf" />
          </RenderIf>
        </div>
      </About>
    </div>
  )
}

export default AboutService;
