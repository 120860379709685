export const myProfileUrl = '/my-profile';
export const serviceProvidersUrl = '/service-providers';
export const membersUrl = '/members';
export const eventsUrl = '/events';
export const cohortsUrl = '/cohorts';
export const resetPasswordUrl = '/reset-password';
export const loginUrl = '/login';
export const addMissingDetailsUrl = '/add-missing-details';
export const settingsOverviewUrl = '/settings-overview';
export const submitOverviewUrl = '/submit-overview';
export const startupsUrl = '/startups';
export const angelInvestorsUrl = '/angel-investors';
export const requestsUrl = '/requests';
export const peopleUrl = '/people';
export const partnersUrl = '/partners';
export const teamMembersUrl = '/team-members';
export const dashboardUrl = '/dashboard';
export const walletUrl = '/wallet';
export const angelInvestorWalletUrl = '/angel-investor-wallet';
export const performanceUrl = '/performance';
export const changePasswordUrl = '/change-password';
export const servicesUrl = '/services';
export const servicesAndSpsOverviewUrl = '/services-overview';
export const companyProfileUrl = '/company-profile';
export const startupProfileUrl = '/startup-profile';
export const articlesUrl = '/articles';
export const technicalLicensesUrl = '/technical-licenses';
export const technicalLicenseProfileUrl = '/technical-license-profile';
export const forbidden = '/forbidden'
export const technicalLicenseHoldersUrl = '/technical-license-holders';

export const cohortUrls = {
  editCohortUrl: `${cohortsUrl}/edit`,
  cohortsDetailUrl: `${cohortsUrl}/details`,
}

export const technicalLicenseHolderUrls = {
  technicalLicenseHolderDetailsUrl: `${technicalLicenseHoldersUrl}/details`,
  editTechnicalLicenseHolderUrl: `${technicalLicenseHoldersUrl}/edit`,
  technicalLicenseHolderRejectedUrl: '/technical-license-rejected',
}

export const startupUrls = {
  startupDetailsUrl: `${startupsUrl}/details`,
  editStartupUrl: `${startupsUrl}/edit`,
  startupRejectedUrl: '/startup-rejected',
}

export const angelInvestorUrls = {
  angelInvestorDetailsUrl: `${angelInvestorsUrl}/details`,
  editAngelInvestorUrl: `${angelInvestorsUrl}/edit`,
  angelInvestorRejectedUrl: '/angel-investor-rejected',
}

export const technicalLicenseUrls = {
  technicalLicenseDetailsUrl: `${technicalLicensesUrl}/details`,
  editTechnicalLicenseUrl: `${technicalLicensesUrl}/edit`,
  technicalLicenseRejectedUrl: '/technical-license-rejected',
}

export const peopleUrls = {
  editPeopleUrl: `${peopleUrl}/edit`,
  peopleDetailsUrl: `${peopleUrl}/details`,
}

export const serviceProvidersUrls = {
  editServiceProviderUrl: `${serviceProvidersUrl}/edit`,
  serviceProviderDetailsUrl: `${serviceProvidersUrl}/details`,
  serviceProviderMoreDetailsUrl: `${serviceProvidersUrl}/more-details`,
  viewAllServicesUrl: `${serviceProvidersUrl}/view-all`,
}

export const requestUrls = {
  requestMoreDetailsUrl: `${requestsUrl}/more-details`,
}

export const partnerUrls = {
  editPartnerUrl: `${partnersUrl}/edit`,
  partnerDetailsUrl: `${partnersUrl}/details`,
}

export const performanceUrls = {
  createPerformanceReportUrl: `${performanceUrl}/create`,
  editPerformanceReportUrl: `${performanceUrl}/edit`,
}

export const profileUrls = {
  myProfileUrl: `${myProfileUrl}`,
  editMyProfileUrl: `${myProfileUrl}/edit`,
  companyProfileUrl: `${companyProfileUrl}`,
  editCompanyProfileUrl: `${companyProfileUrl}/edit`,
  startupProfileUrl: `${startupProfileUrl}`,
  editStartupProfileUrl: `${startupProfileUrl}/edit`,
  technicalLicenseProfileUrl: `${technicalLicenseProfileUrl}`,
  editTechnicalLicenseProfileUrl: `${technicalLicenseProfileUrl}/edit`,
}
