import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects'

import showNotification from 'services/utils/showNotification'
import {
  getEvents,
  getEventsCount,
  getEventsForUser,
  getEventsRSVPCount,
} from 'packages/events_repository'
import {
  getStartupsCountApi,
  getStartupsCountWorkingWithIdCount,
} from 'packages/startup_repository';
import { getAngelInvestorsCountApi } from 'packages/angel_investors_repository';
import { getTechnicalLicenseCountApi } from 'packages/technical_holder_repository'
import { orderByDescending, ParamsPayload } from 'packages/http_client'
import { UserRoleType } from 'types/auth'
import {
  identity, isAngelInvestor, isEitherAdmin, isStartup, ROLES,
} from 'utils'
import { getRequests, getRequestsCount } from 'packages/service_request_repository/api'
import { getAngelInvestorMemberCount, getStartupTeamMemberCount } from 'packages/people_repository'
import {
  setDashboardAngelInvestorsCount,
  setDashboardTechnicalLicenseCount,
  setDashboardEvents,
  setDashboardEventsCount,
  setDashboardRequests,
  setDashboardRequestsCount,
  setDashboardStartupsCount,
  setDashboardTeamMembersCount,
  setIsLoading,
  types,
} from '../../actions/dashboard'
import {
  selectIsAuthenticated,
  selectPartnerId,
  selectUserAngelInvestorId,
  selectUserId,
  selectUserServiceProviderId,
  selectUserStartupId,
  selectUserType,
} from '../../selectors/auth'
import { NUMBER_TO_REQUEST_STATUS_KEY_MAP } from '../../../packages/service_request_repository'

function * handleGetDashboardRequests() {
  const userType: UserRoleType = yield select(selectUserType);
  const params: ParamsPayload = { limit: 3, order: orderByDescending('application_date') };
  switch (userType) {
    case ROLES.partner: {
      params.partnerId = yield select(selectPartnerId);
      break;
    }
    case ROLES.serviceProvider: {
      params.serviceProviderId = yield select(selectUserServiceProviderId);
      break;
    }
    case ROLES.startupAdmin:
    case ROLES.startupTeamMember:
    case ROLES.startupFounder: {
      params.startupId = yield select(selectUserStartupId);
      break;
    }
    default: {
      break;
    }
  }
  const { data, error, httpStatus } = yield call(getRequests, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDashboardRequests({ data }));
  }
}

function * handleGetDashboardEvents() {
  const userType: UserRoleType = yield select(selectUserType);
  const userId: number = yield select(selectUserId);
  let params: ParamsPayload = { eventStatus: 1, limit: 6 };
  let apiCall = getEvents;
  if (!isEitherAdmin(userType)) {
    params = { ...params, userId, eventResponse: [1, 2] };
    apiCall = getEventsForUser;
  }
  const { data, error, httpStatus } = yield call(apiCall, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDashboardEvents({ data }));
  }
}

function * handleGetStartupsCount() {
  const userType: UserRoleType = yield select(selectUserType);
  let query = {};
  let apiCall = getStartupsCountWorkingWithIdCount;

  switch (userType) {
    case 'Partner': {
      const partnerId: number = yield select(selectPartnerId);
      query = { partnerId, fieldSelection: ['startup_id'] };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      query = { serviceProviderId, fieldSelection: ['startup_id'] };
      break;
    }
    default: {
      apiCall = getStartupsCountApi;
      break;
    }
  }
  const { data, error, httpStatus } = yield call(apiCall, query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  }

  yield put(setDashboardStartupsCount({ data }));
}

function * handleGetAngelInvestorsCount() {
  const userType: UserRoleType = yield select(selectUserType);

  if (!isEitherAdmin(userType)) {
    return;
  }

  const query = {};
  const { data, error, httpStatus } = yield call(getAngelInvestorsCountApi, query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  }

  yield put(setDashboardAngelInvestorsCount({ data }));
}

function * handleGetTechnicalLicenseCount() {
  const userType: UserRoleType = yield select(selectUserType);

  if (!isEitherAdmin(userType)) {
    return;
  }

  const query = {};
  const { data, error, httpStatus } = yield call(getTechnicalLicenseCountApi, query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  }

  yield put(setDashboardTechnicalLicenseCount({ data }));
}

function * handleGetEventsCount() {
  const userType: UserRoleType = yield select(selectUserType);
  const userId: number = yield select(selectUserId);
  let params: ParamsPayload = { eventStatus: 1 };
  let apiCall = getEventsCount;
  if (!isEitherAdmin(userType)) {
    params = { ...params, userId, eventResponse: [1, 2] };
    apiCall = getEventsRSVPCount;
  }
  const { data, error, httpStatus } = yield call(apiCall, 'all', params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDashboardEventsCount({ data }));
  }
}

function * handleGetRequestsCount() {
  const userType: UserRoleType = yield select(selectUserType);
  const params: ParamsPayload = { status: [4] };

  switch (userType) {
    case ROLES.partner: {
      params.partnerId = yield select(selectPartnerId);
      params.status = [2];
      break;
    }
    case ROLES.serviceProvider: {
      params.serviceProviderId = yield select(selectUserServiceProviderId);
      params.status = [2];
      break;
    }
    case ROLES.startupAdmin:
    case ROLES.startupTeamMember:
    case ROLES.startupFounder: {
      params.startupId = yield select(selectUserStartupId);
      params.status = [3];
      break;
    }
    case ROLES.angelInvestorAdmin:
    case ROLES.angelInvestorFounder:
    case ROLES.angelInvestorMember: {
      params.angelInvestorId = yield select(selectUserAngelInvestorId);
      params.status = [3];
      break;
    }
    default: {
      break;
    }
  }

  const { data, error, httpStatus } = yield call(getRequestsCount, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    const requestKey = (params.status as number[])[0];
    yield put(setDashboardRequestsCount({ data: data[NUMBER_TO_REQUEST_STATUS_KEY_MAP[requestKey]] }));
  }
}

function * handleAIGetTeamMembersCount() {
  const angelInvestorId: number = yield select(selectUserAngelInvestorId);
  const { data, error, httpStatus } = yield call(getAngelInvestorMemberCount, { angelInvestorId });

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDashboardTeamMembersCount({ data: data[0].id__count }));
  }
}

function * handleGetTeamMembersCount() {
  const startupId: number = yield select(selectUserStartupId);
  const { data, error, httpStatus } = yield call(getStartupTeamMemberCount, { startupId });

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setDashboardTeamMembersCount({ data: data[0].id__count }));
  }
}

function * handleGetDashboardCount() {
  const isAuthenticated: boolean = yield select(selectIsAuthenticated);

  if (isAuthenticated) {
    const userType: UserRoleType = yield select(selectUserType);

    yield call(handleGetEventsCount);
    yield call(handleGetRequestsCount);

    if (isStartup(userType)) {
      yield call(handleGetTeamMembersCount);
    }

    if (isAngelInvestor(userType)) {
      yield call(handleAIGetTeamMembersCount)
    }

    if (isEitherAdmin(userType)) {
      yield call(handleGetStartupsCount);
      yield call(handleGetAngelInvestorsCount);
      yield call(handleGetTechnicalLicenseCount);
    }
  }
}

function * handleGetDashboardData() {
  const isAuthenticated: boolean = yield select(selectIsAuthenticated);
  if (isAuthenticated) {
    yield put(setIsLoading({ isLoading: true }));
    yield call(handleGetDashboardEvents);
    yield call(handleGetDashboardRequests);
    yield put(setIsLoading({ isLoading: false }));
  }
}

export default function * dashboardSagas() {
  yield all([
    takeLatest(types.GET_DASHBOARD_COUNT, handleGetDashboardCount),
    takeLatest(types.GET_DASHBOARD_DATA, handleGetDashboardData),
  ]);
}
