import React from 'react';
import { Modal } from 'antd';

import { CloseIcon } from 'assets/icons';
import { ConfirmModalProps } from 'types/componentTypes';

import './ConfirmModal.scss';

const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    isOpen,
    onCancel,
    onConfirm,
    title,
    text,
    okText,
  } = props;

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      onOk={onConfirm}
      okText={okText}
      cancelText="Cancel"
      closeIcon={<CloseIcon />}
      title={title}
      className="confirm-modal"
    >
      {text}
    </Modal>
  );
}

export default ConfirmModal;
