import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import { CohortType } from 'types/cohorts';
import { WalletDropdownFiltersProps } from 'types/wallet';
import { Input } from 'components/components';

const DashboardBudgetStartupsDropdownFilters = (props: WalletDropdownFiltersProps) => {
  const {
    setShowDropdown,
    startups,
    cohorts,
    filters,
    actions: {
      getStartups,
      getCohorts,
      setWalletFilters,
    },
  } = props;

  const [startupIDs, setStartupIDs] = useState<number[]>(filters.startups || []);
  const [cohortIDs, setCohortIDs] = useState<number[]>(filters.cohorts || []);

  const onClose = () => {
    setStartupIDs(filters.startups || []);
    setCohortIDs(filters.cohorts || []);
    setShowDropdown(false);
  };

  const onClearAll = () => {
    setStartupIDs([]);
    setCohortIDs([]);
    setWalletFilters({ startups: [], cohorts: [] });
    onClose();
  };

  const onFilter = () => {
    setWalletFilters({ startups: startupIDs, cohorts: cohortIDs });
    onClose();
  };

  const startupOptions = useMemo(() => startups.map(
    ({ name: label, id: value }: CohortType) => ({ label, value }),
  ), [startups]);

  const cohortOptions = useMemo(() => cohorts.map(
    ({ name: label, id: value }: CohortType) => ({ label, value }),
  ), [cohorts]);

  useEffect(() => {
    getStartups();
    getCohorts();
  }, [getStartups, getCohorts]);

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>

        <Button className="filters-buttons--button" onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>

      <Input
        label="Startup"
        layout="vertical"
      >
        <Input.Select
          showSearch
          options={startupOptions}
          value={startupIDs}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setStartupIDs(data)}
        />
      </Input>

      <Input label="Cohorts" layout="vertical">
        <Input.Select
          showSearch
          options={cohortOptions}
          value={cohortIDs}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setCohortIDs(data)}
        />
      </Input>

      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default DashboardBudgetStartupsDropdownFilters;
