import { convertGetServiceProviderResponse, convertServiceCategoriesToString } from 'packages/service_repository';
import { convertGetServiceRequestResponse } from 'packages/service_request_repository';
import { identity, parseNumberToPrice } from 'utils'
import {
  GetAmountSumsBe,
  GetAmountSumsResponse,
  BudgetType,
  ResponseWalletTransactionType,
  WalletTransactionType, BudgetTypeResponse,
} from '../types'
import { NUMBER_TO_WALLET_TRANSACTION_TYPE_MAP } from './constants';

export const convertGetWalletTransactionResponse = (transaction?: ResponseWalletTransactionType)
: WalletTransactionType => {
  let category: string = '';
  if (identity.isObjWithChildren(transaction?.serviceProvider)) {
    const serviceProviderCategories = convertServiceCategoriesToString(transaction?.serviceProvider?.categories);
    category = identity.isTruthyString(serviceProviderCategories) ? serviceProviderCategories.split(',').shift()! : '';
  }

  return {
    id: transaction!.id,
    date: identity.isObjWithChildren(transaction?.serviceRequest) ? transaction?.serviceRequest?.applicationDate! : '',
    amount: transaction?.amount!,
    type: NUMBER_TO_WALLET_TRANSACTION_TYPE_MAP[transaction?.type!],
    category,
    details: {
      serviceProvider: convertGetServiceProviderResponse(transaction?.serviceProvider),
      serviceRequest: convertGetServiceRequestResponse(transaction?.serviceRequest)!,
    },
  };
};

export const convertGetWalletTransactionListResponse = (transactions?: ResponseWalletTransactionType[]):
WalletTransactionType[] => {
  if (identity.isEmptyArray(transactions)) {
    return [];
  }

  return transactions!.map(((transaction) => convertGetWalletTransactionResponse(transaction)));
};

export const convertGetAmountSumsResponse = (data?: GetAmountSumsBe[]): GetAmountSumsResponse | undefined => {
  if (identity.isEmptyArray(data)) {
    return undefined;
  }

  let amountBlocked = 'AED 0';
  let amountConsumed = 'AED 0';

  for (const i in data) {
    if (data![Number(i)].type === 1) {
      amountConsumed = parseNumberToPrice(data![Number(i)].amount__sum, 'AED');
    }
    if (data![Number(i)].type === 3) {
      amountBlocked = parseNumberToPrice(data![Number(i)].amount__sum, 'AED');
    }
  }

  return { serviceProviderId: 0, amountConsumed, amountBlocked };
}

export const convertGetBudgetSumsResponse = (
  data: BudgetTypeResponse | undefined,
): BudgetType => {
  if (!data) {
    return {
      blocked: 0, consumed: 0, total: 0, balance: 0,
    };
  }
  const { blocked_amount__sum = 0, consumed_amount__sum = 0, total_wallet_amount__sum = 0 } = data;

  return {
    blocked: blocked_amount__sum,
    consumed: consumed_amount__sum,
    total: total_wallet_amount__sum,
    balance: blocked_amount__sum + consumed_amount__sum,
  };
};
