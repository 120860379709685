import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  resendInvitation,
} from 'redux/actions/people';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    resendInvitation,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
