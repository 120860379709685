import StartupsDropdownFilters from 'components/StartupsOverview/components/StartupsDropdownFilters';
import RequestFiltersDropdown from 'components/RequestsOverview/components/RequestFiltersDropdown';
import ServicesDropdownFilters from 'components/ServicesAndServiceProviders/components/ServicesDropdownFilters';
import PeopleDropdownFilters from 'components/PeopleOverview/components/PeopleDropdownFilters';
import AngelInvestorsFilters from 'components/AngelInvestorsOverview/components/AngelInvestorsDropdownFilters';
import TechnicalLicenseHoldersDropdownFilters from 'components/TechnicalLicenseHoldersOverview/components/TechnicalLicenseHoldersDropdownFilters';
import DashboardBudgetStartupsDropdownFilters from 'components/Dashboard/components/Filters/BudgetStartups';

export const items = [
  {
    label: 'Incentive',
    value: 'Incentive',
  },
];

export const allCategories = [
  {
    label: 'User interface',
    value: 'User interface',
  },
  {
    label: 'Strategy',
    value: 'Strategy',
  },
  {
    label: 'Design',
    value: 'Design',
  },
];

export const FILTERS_MAP: {
  [key: string]: 'people' | 'requests' | 'services' | 'startups' | 'service-providers'
    | 'angel-investors' | 'technical-license-holder' | 'dashboard-budget-startups'
} = {
  requests: 'requests',
  services: 'services',
  serviceProviders: 'service-providers',
  startups: 'startups',
  people: 'people',
  angelInvestors: 'angel-investors',
  technicalLicenseHolder: 'technical-license-holder',
  dashboardBudgetStartups: 'dashboard-budget-startups',
}

export const filtersDropdown = {
  [FILTERS_MAP.requests]: {
    body: RequestFiltersDropdown,
  },
  [FILTERS_MAP.services]: {
    body: ServicesDropdownFilters,
  },
  [FILTERS_MAP.startups]: {
    body: StartupsDropdownFilters,
  },
  [FILTERS_MAP.angelInvestors]: {
    body: AngelInvestorsFilters,
  },
  [FILTERS_MAP.technicalLicenseHolder]: {
    body: TechnicalLicenseHoldersDropdownFilters,
  },
  [FILTERS_MAP.dashboardBudgetStartups]: {
    body: DashboardBudgetStartupsDropdownFilters,
  },
};

export const filtersDropdownGlobal = {
  [FILTERS_MAP.people]: {
    body: PeopleDropdownFilters,
  },
};
