import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { FormBuilder, Input, RenderIf } from 'components/components';
import {
  EditServiceDrawerProps,
  EditServiceFormType,
  PricingOption,
} from 'types/services';
import { identity, isEitherAdmin } from 'utils';
import { UnitType } from 'packages/service_repository';
import { AddPricingOptions, RangeOptions } from '../../../AddNewServiceDrawer';
import { editServiceDetailFields, getServiceFiles, useEditServiceHook } from '../../utils';

import './EditServiceDrawer.scss';

const EditServiceDrawer = (props: EditServiceDrawerProps) => {
  const {
    service,
    userType,
    actions: { updateServiceById, getServiceUnitsList },
    units,
  } = props;
  const [form] = Form.useForm();
  const offerPriceStatus = Form.useWatch('offerPrice', form);

  const {
    state, setPriceUnit, setPricingOptionsError, setPricingOptions, setOfferDoc, setServiceActive,
  } = useEditServiceHook();

  const {
    pricingOptions, priceUnit, pricingOptionsError, offerDoc, isActive,
  } = state;

  useEffect(() => {
    if (identity.isEmptyArray(units)) {
      getServiceUnitsList();
    }
  }, []);

  const onDeletePricingOption = (id: number) => {
    const data = pricingOptions.filter((recipient: PricingOption) => recipient.id !== id);
    setPricingOptions({ data });
  };

  const onAddPricingOption = (pricingOption: PricingOption) => {
    const id = pricingOptions.length + 1;
    setPricingOptions({ data: [...pricingOptions, { ...pricingOption, id }] });
    setPricingOptionsError({ data: [...pricingOptionsError, false] });
  };

  useEffect(() => {
    form.resetFields();
    setOfferDoc(service?.offerDocLink);
    setServiceActive({ data: service?.active });
    form.setFieldsValue(service);
    form.setFieldValue('type', { label: service?.type, value: service?.type });
    if (service.offerPrice === 'range') {
      setPricingOptions({ data: [{ id: 1, amount: service.priceFrom, option: '' }, { id: 2, amount: service.priceTo, option: '' }] });
    } else {
      setPricingOptions({ data: [{ id: 1, amount: service.priceFrom, option: '' }] });
    }
    if (identity.isFullArray(units)) {
      const unit = units.find((item: UnitType) => identity.isObjWithChildren(service.servicePrice)
        && item.id === service.servicePrice.unitID) || units[0];
      setPriceUnit(unit);
      form.setFieldValue('unit', unit.id);
    }
  }, [service, form, units]);

  useEffect(() => {
    if (offerPriceStatus === '-') {
      form.setFieldValue('offerPrice', 'fixed');
      form.setFieldValue('amount', pricingOptions[0].amount);
    }
    if (offerPriceStatus === 'fixed') {
      form.setFieldValue('amount', pricingOptions[0].amount);
    } else {
      form.setFieldValue('priceFrom', pricingOptions[0].amount);
    }
  }, [offerPriceStatus, pricingOptions]);

  const onSubmit = (formData: EditServiceFormType) => {
    formData.offerDoc = offerDoc || service?.offerDocLink;
    const serviceUpdate: EditServiceFormType = {
      ...formData,
      unit: priceUnit,
      pricingOptions,
      servicePriceId: service.servicePrice.id,
      id: service.id,
      active: isActive,
    };

    updateServiceById({ service: serviceUpdate, id: service?.id });
  }

  const beforeUpload = (file: RcFile) => {
    setOfferDoc({ data: file as Blob });
    return false;
  }

  return (
    <Form
      form={form}
      className="form-standalone"
      onFinish={onSubmit}
    >
      <div className="edit-service-drawer">
        <FormBuilder
          formFragment
          layout="vertical"
          fields={editServiceDetailFields(userType)}
          defaultValues={service}
        />
        <RenderIf condition={offerPriceStatus === 'range' && isEitherAdmin(userType)}>
          <RangeOptions setPriceUnit={setPriceUnit} units={units} selectedUnit={priceUnit.id} />
        </RenderIf>
        <RenderIf condition={(offerPriceStatus === 'fixed' || offerPriceStatus === '-') && isEitherAdmin(userType)}>
          <AddPricingOptions
            errors={pricingOptionsError}
            pricingOptions={pricingOptions}
            onDeletePricingOption={onDeletePricingOption}
            onAddPricingOption={onAddPricingOption}
            units={units}
          />
        </RenderIf>
        <Input label="Offer upload" layout="vertical">
          <Input.DraggerUpload
            accept="document"
            defaultFileList={getServiceFiles(offerDoc as string)}
            beforeUpload={beforeUpload}
            isRoundableButton
          />
        </Input>
        <Input label="Active Status" layout="vertical">
          <Input.Switch checked={isActive} onChange={(data) => setServiceActive({ data })} />
        </Input>
        <div className="edit-service-drawer__buttons">
          <Button className="primary-button" htmlType="submit">Edit service</Button>
        </div>
      </div>
    </Form>
  );
}

export default EditServiceDrawer;
