import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Viewer } from '@react-pdf-viewer/core';
import { RcFile } from 'antd/lib/upload/interface';
import { identity } from 'utils';
import { DocumentFieldsProps } from 'types/requests';
import { getServiceRequestDocument } from 'components/Services/utils';
import showNotification from 'services/utils/showNotification';
import { Modal, RenderIf, Input } from 'components/components';
import { downloadButton } from 'components/utils/constants/buttons';

import './DocumentFields.scss';
import { updateServiceRequestDocuments } from 'packages/service_request_repository/api';

const DocumentFields = (props: DocumentFieldsProps) => {
  const {
    altText,
    fileUrl,
    fileName,
    id,
    serviceRequestId,
    editDocumentIndex,
    setEditDocumentIndex,
    getServiceRequestDocuments,
    isEditing,
  } = props;

  const [open, setOpen] = useState(false);
  const [editDocument, setEditDocument] = useState<boolean>(false);
  const [newFile, setNewFile] = useState<string | Blob>();
  const [newFileName, setNewFileName] = useState<string>(fileName!);

  const handleBeforeUpload = (file: RcFile) => {
    setNewFile(file);
    return false;
  }

  const handleUpdateDocumentFile = async () => {
    try {
      const result = await updateServiceRequestDocuments({
        document: newFile!,
        name: newFileName,
        id: id!,
      })
      if (result.data?.status === 'ok' && result.httpStatus === 200 && result.error === undefined) {
        setEditDocument(false)
        getServiceRequestDocuments({ service_request_id: serviceRequestId });
        showNotification('Document Updated Successfully.', false, result.httpStatus);
      } else if (result.error && result.error !== undefined) {
        showNotification(`Something went wrong: ${result.error}`, true, result.httpStatus);
      }
    } catch (error) {
      if (error instanceof Error) {
        showNotification(`Something went wrong: ${error?.message!}`, true, (error as any).code);
      } else {
        showNotification(`Something went wrong: ${error}`, true, 500);
      }
    }
  }

  const handleOpenEditDocument = () => {
    setEditDocument(true)
    setEditDocumentIndex(id)
  }

  const handleCloseEditDocument = () => {
    setEditDocument(false)
    setNewFileName(fileName!)
  }

  useEffect(() => {
    if (id !== editDocumentIndex) {
      handleCloseEditDocument()
    }
  }, [editDocumentIndex])

  if (!identity.isTruthyString(fileUrl)) {
    return (
      <RenderIf condition={identity.isTruthyString(altText)}>
        <div className="document-field">
          <p>{altText}</p>
        </div>
      </RenderIf>
    );
  }

  return (
    <div className="document-field">
      <RenderIf condition={editDocument}>
        <Input.InputField
          name="filename"
          defaultValue={fileName}
          onChange={(e) => setNewFileName(e.target.value)}
          className="input-field"
          placeholder="Document Name"
        />
      </RenderIf>
      <RenderIf condition={!editDocument}>
        <span>{identity.isTruthyString(fileName) ? fileName : 'Document.pdf'}</span>
      </RenderIf>
      <RenderIf condition={editDocument}>
        <div className="upload-document">
          <Input.DraggerUpload
            defaultFileList={getServiceRequestDocument(fileUrl as string)}
            allowChange
            accept=".pdf"
            beforeUpload={handleBeforeUpload}
            isRoundableButton
          />
        </div>
      </RenderIf>
      <div className="action-buttons">
        <RenderIf condition={editDocument}>
          <Button type="ghost" className="discard-btn" onClick={handleCloseEditDocument}>Discard</Button>
        </RenderIf>
        <RenderIf condition={!editDocument}>
          <Button type="ghost" className="view-btn" onClick={() => setOpen(true)}>View</Button>
        </RenderIf>
        <RenderIf condition={editDocument}>
          <Button
            type="ghost"
            className="save-btn"
            onClick={handleUpdateDocumentFile}
            disabled={!newFileName}
          >
            Save
          </Button>
        </RenderIf>

        <RenderIf condition={!editDocument && isEditing}>
          <Button type="ghost" className="edit-btn" onClick={handleOpenEditDocument}>Edit</Button>
        </RenderIf>
      </div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        title={fileName || 'Document'}
        buttons={downloadButton(fileUrl as string, 'pdf')}
      >
        <div className="al-pdf-preview__container">
          <Viewer fileUrl={fileUrl!} />
        </div>
      </Modal>
    </div>
  )
}

export default DocumentFields;
