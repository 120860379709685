import produce from 'immer';

import { WalletStateType } from 'types/reduxTypes/StateTypes';
import {
  WalletActionType,
  SetWalletTransactionsPayload,
  SetWalletPayload,
  SetBudgetPayload,
  SetWalletFiltersPayload,
} from 'types/reduxTypes/ActionTypes/WalletTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { WalletFiltersType } from 'types/wallet';
import { defaultWalletState } from '../../utils';
import { types } from '../../actions/wallet';

const defaultState: WalletStateType = defaultWalletState;

const handleSetWallet = (state: WalletStateType, payload: SetWalletPayload) => {
  state.wallet = payload.wallet;
  return state;
}

const handleSetBudget = (state: WalletStateType, payload: SetBudgetPayload) => {
  state.budget = payload.budget;
  return state;
}

const handleSetWalletTransactions = (state: WalletStateType, payload: SetWalletTransactionsPayload) => {
  state.walletTransactions = payload.walletTransactions;
  return state;
}

const handleSetIsLoading = (state: WalletStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
};

const handleSetWalletCount = (state: WalletStateType, payload: DataPayload<number>) => {
  state.count = payload.data;
  return state;
}

const handleSetWalletFilters = (state: WalletStateType, payload: WalletFiltersType) => {
  state.filters = payload;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: WalletStateType = defaultState, action: WalletActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_WALLET: {
      return handleSetWallet(state, <SetWalletPayload>payload);
    }
    case types.SET_BUDGET: {
      return handleSetBudget(state, <SetBudgetPayload>payload);
    }
    case types.SET_WALLET_TRANSACTIONS: {
      return handleSetWalletTransactions(state, <SetWalletTransactionsPayload>payload);
    }
    case types.SET_WALLET_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload> payload);
    }
    case types.SET_WALLET_COUNT: {
      return handleSetWalletCount(state, <DataPayload<number>>payload);
    }
    case types.SET_WALLET_FILTERS: {
      return handleSetWalletFilters(state, <SetWalletFiltersPayload>payload);
    }
    case types.RESET_WALLET: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
