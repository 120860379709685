import React, { useEffect } from 'react';
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { identity, arrayToString } from 'utils';
import { CreateTeamMemberType } from 'packages/people_repository';
import { AddEmployeeDrawerProps } from 'types/people';
import {
  memberTypeOptions,
  permissionLevelOptions,
  MEMBER_TYPES,
  PERMISSION_LEVEL_TYPES,
} from './utils/constants';
import { Input } from '../components';
import { AddEmployeeForm, AddStartupMemberForm, AddAngelInvestorMemberForm } from './components';

import './AddNewMemberDrawer.scss';

const AddNewMemberDrawer = (props: AddEmployeeDrawerProps) => {
  const {
    startups, angelInvestors, involvementTypes, nationalities, actions,
  } = props;

  const [form] = Form.useForm();
  const memberType = Form.useWatch('memberType', form);
  const permissionLevel = Form.useWatch('permissionLevel', form);

  const isHub71 = memberType === MEMBER_TYPES.hub71;
  const isStartup = memberType === MEMBER_TYPES.startup;
  const isAngelInvestor = memberType === MEMBER_TYPES.angelInvestor;

  const beforeUpload = (field: string, file: RcFile) => {
    form.setFieldValue(field, file as Blob);
    return false;
  }

  useEffect(() => {
    if (identity.isEmptyArray(startups)) {
      actions.getStartups({
        filters: {
          excludeStatus: [8, 9, 10],
        },
      });
    }
    if (identity.isEmptyArray(angelInvestors)) {
      actions.getAngelInvestors({
        filters: {
          excludeStatus: [8, 9, 10],
        },
      });
    }
    if (identity.isEmptyArray(involvementTypes)) {
      actions.getInvolvementTypes();
    }
    if (identity.isEmptyArray(nationalities)) {
      actions.getNationalities();
    }
  }, [])

  const onSubmit = (data: CreateTeamMemberType) => {
    const fnAddMember = isStartup ? actions.addMember : actions.addAIMember;

    if (isHub71) {
      actions.addHub71Employee({
        ...data,
        roleId: permissionLevel === PERMISSION_LEVEL_TYPES.admin ? 1 : 0,
      });
    } else {
      fnAddMember({
        ...data,
        dateOfBirth: moment(data?.dateOfBirth).format('YYYY-MM-DD HH:mm:ss'),
        skills: arrayToString(data?.skills!),
        hobbies: arrayToString(data?.hobbies!),
        jobFunctions: arrayToString(data?.jobFunctions!),
        memberType: isStartup ? 'startup' : isAngelInvestor ? 'angel-investor' : 'hub71',
        founder: permissionLevel === PERMISSION_LEVEL_TYPES.founder ? 1 : 0,
        roleId: permissionLevel === PERMISSION_LEVEL_TYPES.admin ? 1 : 0,
      });
    }
  };

  const permissionLevelOptionsRefactored = permissionLevelOptions.filter(
    ({ value }) => !(isHub71 && value === PERMISSION_LEVEL_TYPES.founder),
  );

  return (
    <Form
      className="form-builder add-new-member-form"
      initialValues={{
        memberType: MEMBER_TYPES.startup,
        permissionLevel: PERMISSION_LEVEL_TYPES.member,
      }}
      onFinish={onSubmit}
      form={form}
      onFinishFailed={scrollToFirstError}
    >
      <div className="form-builder__row">
        <Input
          name="memberType"
          label="Member type"
          layout="vertical"
          required
        >
          <Input.Select options={memberTypeOptions} />
        </Input>
      </div>

      <div className="form-builder__row">
        <Input
          name="permissionLevel"
          label="Permission Level"
          layout="vertical"
          required
        >
          <Input.Select options={permissionLevelOptionsRefactored} />
        </Input>
      </div>

      {isHub71 && (
        <AddEmployeeForm
          beforeUpload={beforeUpload}
          isAdmin
        />
      )}

      {isStartup && (
        <AddStartupMemberForm
          form={form}
          startups={startups}
          involvementTypes={involvementTypes}
          nationalities={nationalities}
          beforeUpload={beforeUpload}
        />
      )}

      {isAngelInvestor && (
        <AddAngelInvestorMemberForm
          form={form}
          angelInvestors={angelInvestors}
          involvementTypes={involvementTypes}
          nationalities={nationalities}
          beforeUpload={beforeUpload}
        />
      )}

      <DrawerButton>Add member</DrawerButton>
    </Form>
  )
};

export default AddNewMemberDrawer;
