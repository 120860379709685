import { orderByDescending } from 'packages/http_client';
import { GetPerformanceParams, REPORT_STATUS, REPORT_STATUS_TO_NUMBER } from 'packages/performance_repository';
import { getPrimitiveLabeledValueObject } from 'utils/helpers/getLabelValueObject';
import { getLabelValueObject } from 'utils';
import ROLES from 'utils/constants/roles';
import { yearOptions } from '../components/CreatePerformanceReport/utils/formFields';

const adminTabs = ['All', REPORT_STATUS.rejected, REPORT_STATUS.pending, REPORT_STATUS.approved];
const startupTabs = ['All', REPORT_STATUS.draft, REPORT_STATUS.rejected, REPORT_STATUS.submitted, REPORT_STATUS.approved];

export const TABS_BY_ROLE = {
  [ROLES.admin]: adminTabs,
  [ROLES.superAdmin]: adminTabs,
  [ROLES.startupAdmin]: startupTabs,
  [ROLES.startupFounder]: startupTabs,
  [ROLES.startupTeamMember]: startupTabs,
}

const defaultStatusNumbers = [
  REPORT_STATUS_TO_NUMBER[REPORT_STATUS.approved],
  REPORT_STATUS_TO_NUMBER[REPORT_STATUS.rejected],
  REPORT_STATUS_TO_NUMBER[REPORT_STATUS.submitted],
];

export const startupStatusNumbers = [
  REPORT_STATUS_TO_NUMBER[REPORT_STATUS.draft],
  ...defaultStatusNumbers,
];

export const REPORT_STATUS_NUMBERS_BY_ROLE = {
  [ROLES.admin]: defaultStatusNumbers,
  [ROLES.superAdmin]: defaultStatusNumbers,
  [ROLES.startupAdmin]: startupStatusNumbers,
  [ROLES.startupFounder]: startupStatusNumbers,
  [ROLES.startupTeamMember]: startupStatusNumbers,
}

export const staticParams: GetPerformanceParams = {
  $preload: 1,
  $order: orderByDescending('created_at'),
}

export const quarterOptions = [
  getLabelValueObject('All', 'All'),
  getLabelValueObject('Q1', '1'),
  getLabelValueObject('Q2', '2'),
  getLabelValueObject('Q3', '3'),
  getLabelValueObject('Q4', '4'),
];

export const performanceYearOptions = [
  getPrimitiveLabeledValueObject('All', 0),
  ...yearOptions,
];
