import {
  all,
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { getFullPhotoUrl, identity } from 'utils';
import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import {
  CreateServiceRequestDocumentType,
  CreateServiceRequestItemResponse,
  CreateServiceRequestItemType,
  CreateServiceRequestResponse,
  CreateServiceRequestType,
  GetRequestListResponse,
  GetServiceRequestItemsListResponse,
  GetServiceRequestItemsParams,
  GetServiceRequestsParams,
  ServiceRequestItemDetailsType,
  ServiceRequestItemType,
  UpdateServiceRequestItemResponse,
  UpdateServiceRequestItemType,
  UpdateServiceRequestType,
  REQUEST_STATUS_TO_NUMBER_MAP,
  GetServiceRequestsDocumentsParams,
} from 'packages/service_request_repository';
import {
  createServiceRequest,
  createServiceRequestComment,
  createServiceRequestItem,
  createServiceRequestDocument,
  deleteServiceRequestById,
  deleteServiceRequestItemById,
  deleteServiceRequestItems,
  getRequestsCount,
  getServiceRequestCommentsList,
  getServiceRequestItems,
  getRequests as getRequestsApi,
  updateServiceRequest,
  updateServiceRequestItem,
  getServiceRequestById,
  getServiceRequestDocuments,
  getServiceRequestByAngelInvestorId,
  getServiceRequestByStartupId, getServiceRequestByTechnicalLicenseId,
} from 'packages/service_request_repository/api'
import { AngelInvestorType } from 'packages/angel_investors_repository';
import { StartupType } from 'packages/startup_repository';
import { orderByDescending } from 'packages/http_client';
import history from 'history_instance';
import {
  isStartup,
  isServiceProvider,
  isPartner,
  isAngelInvestor,
} from 'utils/helpers/roles';
import { DataPayload, IdPayload } from 'types/reduxTypes/ActionTypes';
import showNotification from 'services/utils/showNotification';
import {
  ChangeRequestStatusPayload,
  SendNewRequestCommentPayload,
  ChangePartnerRequestStatusPayload,
  RequestCommentsPayload,
  UpdateServiceRequestPayload,
  UpdatePartnerRequestPayload,
  GetServiceRequestByOwnerId,
} from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { isRejected } from 'components/RequestsOverview/utils/helpers';
import { RequestFiltersState, RequestType } from 'types/requests'
import { UserType } from 'types/auth';
import { CreateServiceRequestCommentType, GetServiceRequestCommentsListResponse, GetServiceRequestCommentsParams } from 'packages/service_request_repository/types/service_request_comment';
import routes from 'routes';
import { setPartnerRequestById, setPartnerRequestStatusChange } from 'redux/actions/partners';
import { updatePartnerRequest, UpdatePartnerRequestType } from 'packages/partner_repository';
import { getServicesList, StatusType } from 'packages/service_repository';
import {
  addNewRequestComment,
  deleteDraftRequest,
  getRequestItemsByRequestId,
  getRequests,
  sendNewRequestComment,
  setActiveTab,
  setComments,
  setCommentsLoading,
  setCurrentTab,
  setIsLoading,
  setRequestById,
  setRequestItems,
  setRequestStatus,
  setRequests,
  setUnsentRequests,
  types,
  setRequestsCount,
  setHighlightRequest,
  setServicesBySPId,
  getUnsentRequests,
  setServiceRequestDocuments,
  setShowInvoiceFields, setServiceRequests,
} from '../../actions/request'
import { selectIsOpen } from '../../selectors/workspace';
import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from '../../actions/workspace';
import {
  selectUnsentRequests,
  selectPartnerRequestId,
  selectCurrentTab,
  selectServiceRequestId,
  selectRequestOverviewFilters,
  selectCurrentUserTab,
} from '../../selectors/request';
import { setServiceProviderRequestStatus } from '../../actions/serviceProviders';
import { setRequestStatusChange } from '../../actions/startups';
import {
  selectUser,
  selectUserStartup,
  selectUserStartupId,
  selectUserAngelInvestor,
  selectUserAngelInvestorId,
} from '../../selectors/auth';

function * handleGetRequestById(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;
  const isDrawerOpen: boolean = yield select(selectIsOpen);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  }

  const { data: request, error, httpStatus } = yield call(getServiceRequestById, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setRequestById({ request }));
  }

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: false }));
  }
}

function * handleChangeRequestStatus(action: PayloadAction<ChangeRequestStatusPayload>) {
  const {
    status, id, component, reason,
  } = action.payload;

  const isDrawerOpen: boolean = yield select(selectIsOpen);
  const filters: RequestFiltersState = yield select(selectRequestOverviewFilters);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  }

  const payload: UpdateServiceRequestType = { status, reason };
  const { error, httpStatus } = yield call(updateServiceRequest, id, payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    showNotification(`Request ${status}`);
    switch (component) {
      case 'startup': {
        yield put(setRequestStatusChange({ status, id, component }));
        break;
      }
      case 'service-provider': {
        yield put(setServiceProviderRequestStatus({ status, id, component }));
        break;
      }
      case 'requests': {
        yield put(setRequestStatus({ status, id, component }));
        break;
      }
      default: {
        break;
      }
    }

    if (identity.isTruthyString(reason)) {
      yield put(sendNewRequestComment({ text: reason! }));
    }

    if (isRejected(status) && isDrawerOpen) {
      yield all([
        put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
        put(setIsDrawerOpen({ isDrawerOpen: false })),
      ]);
    }
    /** Commented out until the comments integration */
    // if ([REQUEST_STATUS.pendingHUB71].includes(status) && isDrawerOpen) {
    //   const user: UserType = yield select(selectUser);
    //   const comment: RequestComment = {
    //     id: Math.floor(Math.random() * 1000),
    //     person: {
    //       name: user.userName,
    //       imageLink: '',
    //       companyName: 'Company',
    //     },
    //     text: reason as string,
    //     date: (new Date()).toISOString(),
    //     systemMessage: true,
    //     newStatus: status,
    //   }

    //   yield put(setActiveTab({ tab: TABS.discussionBoard }));
    //   yield put(addNewRequestComment({ comment }));
    // }
  }

  yield call(handleGetRequests, { type: '', payload: { data: filters } });
  yield call(handleGetRequestsCount);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: false }));
  }
}

function * handleGetRequests(action: PayloadAction<DataPayload<GetServiceRequestsParams>>) {
  yield put(setIsLoading({ isLoading: true }));
  const { data: filters } = action.payload;

  const user: UserType = yield select(selectUser);
  const isDrawerOpen: boolean = yield select(selectIsOpen);
  const {
    startupId,
    serviceProviderId,
    partnerId,
    userType,
    angelInvestorId,
  } = user;
  const currentTab: StatusType | 'all' = yield select(selectCurrentTab);
  const statusAll = isPartner(user.userType) ? [2, 4, 5, 6, 7, 8] : [2, 3, 4, 5, 6, 7, 8];

  const params: GetServiceRequestsParams = {
    preload: true,
    order: orderByDescending('application_date'),
    startupId: (isStartup(userType) && identity.isTruthyNumber(startupId)) ? startupId : filters.startupId,
    angelInvestorId: (isAngelInvestor(userType) && identity.isTruthyNumber(angelInvestorId))
      ? angelInvestorId
      : filters.angelInvestorId,
    serviceProviderId: (isServiceProvider(userType) && identity.isTruthyNumber(serviceProviderId))
      ? serviceProviderId
      : filters.serviceProviderId,
    partnerId: (isPartner(userType) && identity.isTruthyNumber(partnerId)) ? partnerId : filters.partnerId,
    status: currentTab === 'all' ? statusAll : [REQUEST_STATUS_TO_NUMBER_MAP[currentTab]],
    limit: filters.limit || 100,
    offset: filters.offset,
    from: filters?.from,
    to: filters?.to,
    type: filters?.type,
  };

  const { data, error, httpStatus } = yield call(getRequestsApi, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setRequests({ data }));
  }

  yield put(setIsLoading({ isLoading: false }));
  if (isDrawerOpen) {
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }
}

function * handleGetRequestsCount() {
  const user: UserType = yield select(selectUser);
  const currentUserTab: 'angel-investor' | 'startup' | 'all' = yield select(selectCurrentUserTab);
  const {
    startupId,
    serviceProviderId,
    partnerId,
    angelInvestorId,
  } = user;
  const filters: RequestFiltersState = yield select(selectRequestOverviewFilters);
  const params: GetServiceRequestsParams = {
    startupId: (isStartup(user.userType) && identity.isTruthyNumber(startupId)) ? startupId : filters.startupId,
    angelInvestorId: (isAngelInvestor(user.userType) && identity.isTruthyNumber(angelInvestorId))
      ? angelInvestorId
      : filters.angelInvestorId,
    serviceProviderId: (isServiceProvider(user.userType) && identity.isTruthyNumber(serviceProviderId))
      ? serviceProviderId
      : filters.serviceProviderId,
    partnerId: (isPartner(user.userType) && identity.isTruthyNumber(partnerId)) ? partnerId : filters.partnerId,
    status: [2, 3, 4, 5, 6, 7, 8],
    from: filters?.from,
    to: filters?.to,
    type: currentUserTab !== 'all' ? currentUserTab.replace('-', '_') : filters?.type,
  };

  const { data, error, httpStatus } = yield call(getRequestsCount, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  }

  yield put(setRequestsCount({ data }));
}

function * handleSendNewRequestComment(action: PayloadAction<SendNewRequestCommentPayload>) {
  const user: UserType = yield select(selectUser);
  const startup: StartupType = yield select(selectUserStartup);
  const angelInvestor: AngelInvestorType = yield select(selectUserAngelInvestor);
  const serviceRequestId: number = yield select(selectServiceRequestId);
  const partnerRequestId: number = yield select(selectPartnerRequestId);

  yield put(setCommentsLoading({ isLoading: true }));

  let organization: string;

  if (isStartup(user.userType)) {
    organization = startup?.name
  } else if (isAngelInvestor(user.userType)) {
    organization = angelInvestor?.name
  } else {
    organization = user.serviceProvider?.name!
  }

  const payload: CreateServiceRequestCommentType = {
    message: action.payload.text,
    createdById: Number(user.userId),
    organization,
  }

  if (isPartner(user.userType) || identity.isTruthyNumber(partnerRequestId)) {
    payload.partnerRequestId = partnerRequestId;
  }
  if (!identity.isTruthyNumber(partnerRequestId)) {
    payload.serviceRequestId = serviceRequestId;
  }
  const { error, httpStatus, data: id } = yield call(createServiceRequestComment, payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    const comment = {
      id,
      text: action.payload.text,
      date: (new Date()).toISOString(),
      person: {
        name: user.userName,
        companyName: organization!,
        imageLink: user.photo!,
      },
    };
    yield put(addNewRequestComment({ comment }));
    yield put(setCommentsLoading({ isLoading: false }));
  }
}

function * handleGetCommentsByRequestId(action: PayloadAction<RequestCommentsPayload>) {
  const { id, request } = action.payload;
  const isDrawerOpen: boolean = yield select(selectIsOpen);
  const user: UserType = yield select(selectUser);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  }

  const params: GetServiceRequestCommentsParams = {
    $preload: 1,
  }
  if (isPartner(user.userType) || request === 'partner-request') {
    params.partner_request_id = id;
  }
  if (request === 'service-request') {
    params.service_request_id = id;
  }

  const { error, httpStatus, data }:
  GetServiceRequestCommentsListResponse = yield call(getServiceRequestCommentsList, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    const comments = data!.map((item) => ({
      id: item.id,
      text: item.message,
      date: item.createdAt,
      person: {
        name: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
        companyName: item.organization,
        imageLink: getFullPhotoUrl(item.createdBy.photo!),
      },
    }))

    yield put(setComments({ comments }));
  }

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: false }));
  }
}

function * handleAddRequest(action: PayloadAction<DataPayload<RequestType>>) {
  const { data } = action.payload;
  const isDrawerOpen: boolean = yield select(selectIsOpen);
  const user: UserType = yield select(selectUser);
  const { angelInvestorId, startupId, technicalLicenseId } = user;

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  }

  const {
    service: { id: serviceId, serviceProvider: { id: serviceProviderId }, servicePrice },
    totalPrice,
    rate,
    quantity,
    officeType,
    accommodationType,
    teamMember,
    dependantDateOfBirth,
    dependantFullName,
    dependantGender,
    plan,
    relationToTM,
    isUserUAEResident,
    isDependantUAE,
    isApplyingForDependant,
    furnished,
    emiratesIDNo,
    passportNo,
    noOfDesk,
  } = data;

  const requestParams: GetServiceRequestsParams = {
    startupId, angelInvestorId, technicalLicenseId, status: [1],
  };

  const { error: requestError, httpStatus: requestHttpStatus, data: requestsResponse }:
    GetRequestListResponse = yield call(getRequestsApi, requestParams);

  if (identity.isObjWithChildren(requestError)) {
    showNotification(requestError?.message as string, true, requestHttpStatus);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else {
    let serviceRequestId: number = 0;

    if (identity.isFullArray(requestsResponse) && identity.isObjWithChildren(requestsResponse![0].serviceRequest)) {
      serviceRequestId = requestsResponse![0].serviceRequest?.id!;
    } else {
      const requestPayload: CreateServiceRequestType = {
        angelInvestorId,
        startupId,
        technicalLicenseId,
        totalPrice,
        status: REQUEST_STATUS.unsent,
        serviceProviderId,
      }

      const {
        error: createRequestError,
        data: requestResponse,
        httpStatus: requestHttpResponse,
      }:
        CreateServiceRequestResponse = yield call(createServiceRequest, requestPayload);

      if (identity.isObjWithChildren(createRequestError)) {
        showNotification(createRequestError?.message as string, true, requestHttpResponse);
        yield put(setIsDrawerLoading({ isLoading: false }));
      } else {
        serviceRequestId = requestResponse!;
      }
    }

    const details: ServiceRequestItemDetailsType = {};
    if (identity.isObjWithChildren(teamMember)) {
      details.teamMember = teamMember;
    }
    if (identity.isTruthyString(officeType)) {
      details.officeType = officeType;
    }
    if (identity.isTruthyString(accommodationType)) {
      details.accommodationType = accommodationType;
    }
    if (identity.isTruthyString(dependantDateOfBirth)) {
      details.dependantDateOfBirth = dependantDateOfBirth;
    }
    if (identity.isTruthyString(dependantFullName)) {
      details.dependantFullName = dependantFullName;
    }
    if (identity.isTruthyString(plan)) {
      details.plan = plan;
    }
    if (identity.isTruthyString(relationToTM)) {
      details.relationToTeamMember = relationToTM;
    }
    if (identity.isTruthyString(isUserUAEResident)) {
      details.isUserUAEResident = isUserUAEResident;
    }
    if (identity.isTruthyString(isDependantUAE)) {
      details.isDependantUAE = isDependantUAE;
    }
    if (identity.isTruthyString(dependantGender)) {
      details.gender = dependantGender;
    }
    if (identity.isTruthyString(emiratesIDNo)) {
      details.emiratesIDNo = emiratesIDNo;
    }
    if (identity.isTruthyString(passportNo)) {
      details.passportNo = passportNo;
    }
    if (identity.isTruthyNumber(noOfDesk) && identity.isTruthyString(officeType)) {
      details.noOfDesk = noOfDesk;
    }
    details.isApplyingForDependant = isApplyingForDependant;
    details.furnished = furnished;

    const requestItemPayload: CreateServiceRequestItemType = {
      serviceRequestId,
      serviceId,
      price: totalPrice,
      rate,
      servicePriceId: servicePrice?.id,
      quantity,
      details,
    };

    const { error: requestItemError, httpStatus }:
      CreateServiceRequestItemResponse = yield call(createServiceRequestItem, requestItemPayload);

    if (identity.isObjWithChildren(requestItemError)) {
      showNotification(requestItemError?.message as string, true, httpStatus);
      yield put(setIsDrawerLoading({ isLoading: false }));
    } else {
      yield all([
        put(setCurrentTab({ currentTab: REQUEST_STATUS.unsent })),
        put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
        put(setIsDrawerOpen({ isDrawerOpen: false })),
        put(setIsDrawerLoading({ isLoading: false })),
      ]);

      const fullDocumentsArray: CreateServiceRequestDocumentType[] = [];
      const convertGetStartupOptionsResponse = (
        document: CreateServiceRequestDocumentType,
      ): CreateServiceRequestDocumentType => ({
        serviceRequestId,
        name: document.name,
        document: document.document,
      });

      if (identity.isObjWithChildren(data.documents)) {
        data.documents?.map((doc: CreateServiceRequestDocumentType) => {
          const uploadedDocument = convertGetStartupOptionsResponse(doc);
          fullDocumentsArray.push(uploadedDocument);
          return fullDocumentsArray;
        })
      }

      for (let i = 0; i < data.documents?.length!; i += 1) {
        const { error: requestDocumentError, httpStatus: requestDocumentHttpStatus }:
          CreateServiceRequestItemResponse = yield call(createServiceRequestDocument, fullDocumentsArray[i]);

        if (identity.isObjWithChildren(requestDocumentError)) {
          showNotification(requestDocumentError?.message as string, true, requestDocumentHttpStatus);
          yield put(setIsDrawerLoading({ isLoading: false }));
          return;
        }
      }

      const getRequestItemsParams: GetServiceRequestItemsParams = {
        service_request_id: serviceRequestId,
        $preload: 1,
        $order: orderByDescending('id'),
      }

      yield all([
        call(handleGetServiceRequestItems, getRequestItemsParams),
        put(setCurrentTab({ currentTab: 'unsent' })),
      ]);

      history.push(routes.requestsUrl);
    }
  }
}

function * handleGetServiceRequestDocuments(action: PayloadAction<GetServiceRequestsDocumentsParams>) {
  const { data, error, httpStatus } = yield call(getServiceRequestDocuments, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification('Unable to fetch request documents', error, httpStatus);
  } else {
    yield put(setServiceRequestDocuments({ data }));
  }
}

function * handleSendRequest(action: PayloadAction<IdPayload & { totalPrice?: number }>) {
  const { id, totalPrice } = action.payload;
  const payload: UpdateServiceRequestType = { status: REQUEST_STATUS.new, totalPrice };
  const { error, httpsStatus } = yield call(updateServiceRequest, id, payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpsStatus);
  } else {
    yield call(handleGetRequestsCount);

    yield all([
      put(getRequests()),
      put(setUnsentRequests({ data: [] })),
      put(setCurrentTab({ currentTab: 'all' })),
      put(setHighlightRequest()),
    ]);
  }
}

function * handleDeleteDraftRequest() {
  const unsentRequestItems: ServiceRequestItemType[] = yield select(selectUnsentRequests);
  yield put(setIsLoading({ isLoading: true }));
  if (identity.isFullArray(unsentRequestItems)) {
    const { serviceRequestId } = unsentRequestItems[0];

    const deleteParams = { service_request_id: serviceRequestId };
    const { itemsError, itemsHttpStatus } = yield call(deleteServiceRequestItems, deleteParams);

    if (identity.isObjWithChildren(itemsError)) {
      showNotification(itemsError?.message, true, itemsHttpStatus);
    } else {
      const { error, httpStatus } = yield call(deleteServiceRequestById, serviceRequestId);

      if (identity.isObjWithChildren(error)) {
        showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
        yield put(setIsLoading({ isLoading: false }));
      } else {
        yield put(setUnsentRequests({ data: [] }));
      }
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleDeleteDraftRequestItem(action: PayloadAction<Partial<IdPayload>>) {
  const { id } = action.payload;
  const unsentRequestItems: ServiceRequestItemType[] = yield select(selectUnsentRequests);
  yield put(setIsLoading({ isLoading: true }));

  if (unsentRequestItems.length === 1) {
    yield put(deleteDraftRequest());
  } else {
    const { error, httpStatus } = yield call(deleteServiceRequestItemById, id!);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
    } else {
      yield put(getUnsentRequests());
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetServiceRequestItems(params: GetServiceRequestItemsParams) {
  const { error, data, httpStatus }: GetServiceRequestItemsListResponse = yield call(getServiceRequestItems, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message as string}`, true, httpStatus);
  } else {
    yield put(setUnsentRequests({ data: data || [] }));
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetUnsentRequest() {
  const startupId: number = yield select(selectUserStartupId);
  const angelInvestorId: number = yield select(selectUserAngelInvestorId);

  const requestParams: GetServiceRequestsParams = { startupId, angelInvestorId, status: [1] };
  const { error, httpStatus, data }: GetRequestListResponse = yield call(getRequestsApi, requestParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else if (identity.isFullArray(data) && identity.isObjWithChildren(data![0].serviceRequest)) {
    const getRequestItemsParams: GetServiceRequestItemsParams = {
      $preload: 1,
      service_request_id: data![0].serviceRequest?.id!,
      $order: orderByDescending('id'),
    }
    yield call(handleGetServiceRequestItems, getRequestItemsParams)
  }
}

function * handleGetRequestItemsByRequestId(action: PayloadAction<IdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id } = action.payload;
  const params: GetServiceRequestItemsParams = { service_request_id: id, $preload: 1 };
  const { error, httpStatus, data }: GetServiceRequestItemsListResponse = yield call(getServiceRequestItems, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setRequestItems({ data: data! }));
  }
  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleUpdateRequestItemById(
  action: PayloadAction<DataPayload<IdPayload & UpdateServiceRequestItemType & { servicePrice: number }>>,
) {
  const {
    id,
    price,
    rate,
    serviceRequestId,
    details,
    isAdnic,
  } = action.payload.data;

  if (rate || isAdnic) {
    yield put(setIsDrawerLoading({ isLoading: true }));

    const payload: UpdateServiceRequestItemType = { rate };
    const { error, httpStatus }: UpdateServiceRequestItemResponse = yield call(updateServiceRequestItem, id, payload);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
    } else {
      yield put(getRequestItemsByRequestId({ id: serviceRequestId! }));
    }

    yield put(setIsDrawerLoading({ isLoading: false }));

    return;
  }

  if (identity.isTruthyString(details?.officeType!)) {
    if (price! > action.payload.data.servicePrice * Number(details?.noOfDesk!) * action.payload.data.quantity!) {
      showNotification('Modified price cannot be greater than requested amount.', true);
    } else {
      yield put(setIsDrawerLoading({ isLoading: true }));
      const payload: UpdateServiceRequestItemType = { price };
      const { error, httpStatus }: UpdateServiceRequestItemResponse = yield call(updateServiceRequestItem, id, payload);

      if (identity.isObjWithChildren(error)) {
        showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
      } else {
        yield put(getRequestItemsByRequestId({ id: serviceRequestId! }));
      }
      yield put(setIsDrawerLoading({ isLoading: false }));
    }
  }

  if (!identity.isTruthyString(details?.officeType!)) {
    if (price! > action.payload.data.servicePrice * action.payload.data.quantity!) {
      showNotification('Modified price cannot be greater than requested amount.', true);
    } else {
      yield put(setIsDrawerLoading({ isLoading: true }));
      const payload: UpdateServiceRequestItemType = { price };
      const { error, httpStatus }: UpdateServiceRequestItemResponse = yield call(updateServiceRequestItem, id, payload);

      if (identity.isObjWithChildren(error)) {
        showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
      } else {
        yield put(getRequestItemsByRequestId({ id: serviceRequestId! }));
      }
      yield put(setIsDrawerLoading({ isLoading: false }));
    }
  }
}

function * handleChangePartnerRequestStatus(action: PayloadAction<ChangePartnerRequestStatusPayload>) {
  const { status, id } = action.payload;

  const isDrawerOpen: boolean = yield select(selectIsOpen);

  if (isDrawerOpen) {
    yield put(setIsDrawerLoading({ isLoading: true }));
  }

  const payload: UpdatePartnerRequestType = { status };
  const { error, httpStatus } = yield call(updatePartnerRequest, id, payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    showNotification(`Request ${status}`);
    if (!identity.isEmptyString(action.payload?.reason)) {
      yield put(sendNewRequestComment({ text: action.payload?.reason! }))
    }

    yield all([
      put(setPartnerRequestStatusChange({ status, id })),
      put(setCurrentTab({ currentTab: REQUEST_STATUS.inProgress })),
      put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true })),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
      put(setIsDrawerLoading({ isLoading: false })),
    ]);
  }
}

function * handleAcknowledgePartnerRequestById(action: PayloadAction<UpdatePartnerRequestPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id, partnerRequest } = action.payload;
  const { error, httpStatus } = yield call(updatePartnerRequest, id, partnerRequest);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else {
    yield all([
      put(setPartnerRequestById({ data: partnerRequest })),
      put(setIsDrawerLoading({ isLoading: false })),
      put(setActiveTab({ tab: 'Discussion board' })),
    ]);
  }
}

function * handleGetServicesBySpId(action: PayloadAction<IdPayload>) {
  const { id } = action.payload;

  const { data, error, httpStatus } = yield call(getServicesList, { limit: 6 }, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setServicesBySPId({ data }));
  }
}

function * handleUpdateServiceRequest(action: PayloadAction<UpdateServiceRequestPayload>) {
  const status = REQUEST_STATUS.delivered;
  const { id } = action.payload;
  const { error, httpStatus } = yield call(updateServiceRequest, id, { ...action.payload, status });

  yield put(setIsDrawerLoading({ isLoading: true }));

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification(`Request ${status}`);
    yield all([
      put(setShowInvoiceFields({ data: false })),
      put(getRequestItemsByRequestId({ id })),
      put(getRequests({ data: { limit: 10, offset: 0 } })),
      put(setCurrentTab({ currentTab: 'all' })),
      call(handleGetRequestsCount),
    ]);
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetServiceRequestsByOwnerId(action: PayloadAction<GetServiceRequestByOwnerId>) {
  const { id, owner } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  let getServiceRequest = null;

  switch (owner) {
    case 'angel-investor':
      getServiceRequest = getServiceRequestByAngelInvestorId;
      break;

    case 'startup':
      getServiceRequest = getServiceRequestByStartupId;
      break;

    case 'technical-license':
      getServiceRequest = getServiceRequestByTechnicalLicenseId;
      break;

    default:
      return;
  }

  if (!getServiceRequest) {
    yield put(setIsLoading({ isLoading: false }));
    return;
  }

  try {
    const { data } = yield call(getServiceRequest, id, true);

    yield put(setServiceRequests({ data }))
  } catch (error) {
    console.error('Error fetching service requests:', error);
  } finally {
    yield put(setIsLoading({ isLoading: false }));
  }
}

export default function * requestSagas() {
  yield all([
    takeLatest(types.ACKNOWLEDGE_PARTNER_REQUEST_BY_ID, handleAcknowledgePartnerRequestById),
    takeLatest(types.CHANGE_REQUEST_STATUS, handleChangeRequestStatus),
    takeLatest(types.GET_REQUEST_BY_ID, handleGetRequestById),
    takeLatest(types.GET_REQUESTS, handleGetRequests),
    takeLatest(types.SEND_NEW_REQUEST_COMMENT, handleSendNewRequestComment),
    takeLatest(types.GET_COMMENTS_BY_REQUEST_ID, handleGetCommentsByRequestId),
    takeLatest(types.ADD_NEW_REQUEST, handleAddRequest),
    takeLatest(types.SEND_REQUEST, handleSendRequest),
    takeLatest(types.DELETE_DRAFT_REQUEST, handleDeleteDraftRequest),
    takeLatest(types.DELETE_DRAFT_REQUEST_ITEM, handleDeleteDraftRequestItem),
    takeLatest(types.GET_UNSENT_REQUESTS, handleGetUnsentRequest),
    takeLatest(types.GET_REQUEST_ITEMS_BY_REQUEST_ID, handleGetRequestItemsByRequestId),
    takeLatest(types.UPDATE_REQUEST_ITEM_BY_ID, handleUpdateRequestItemById),
    takeLatest(types.CHANGE_PARTNER_REQUEST_STATUS, handleChangePartnerRequestStatus),
    takeLatest(types.GET_REQUESTS_COUNT, handleGetRequestsCount),
    takeLatest(types.GET_SERVICES_BY_SP_ID, handleGetServicesBySpId),
    takeLatest(types.GET_SERVICE_REQUEST_DOCUMENTS, handleGetServiceRequestDocuments),
    takeLatest(types.UPDATE_SERVICE_REQUEST, handleUpdateServiceRequest),
    takeLatest(types.GET_SERVICE_REQUESTS_BY_OWNER_ID, handleGetServiceRequestsByOwnerId),
  ]);
}
