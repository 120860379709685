import React, { useEffect } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import { TechnicalLicensesDropdownFiltersProps } from 'types/technicalLicenses';
import { Input } from 'components/components';
import { useTechnicalLicenseFiltersHook } from '../../utils';
import { identity } from '../../../../utils'

const TechnicalLicenseHoldersDropdownFilters = ({
  setShowDropdown,
  filters,
  sectorOptions,
  actions: {
    setTechnicalLicenseFilters,
    getTechnicalLicenseSectors,
  },
}: TechnicalLicensesDropdownFiltersProps) => {
  useEffect(() => {
    if (identity.isEmptyArray(sectorOptions)) {
      getTechnicalLicenseSectors();
    }
  }, []);

  const {
    state,
    initialState,
    resetFilters,
    setFilters,
    setSector,
  } = useTechnicalLicenseFiltersHook();

  const { sector } = state;

  const onClose = () => {
    setFilters(filters);
    setShowDropdown(false);
  }

  const onClearAll = () => {
    resetFilters();
    setTechnicalLicenseFilters(initialState);
    onClose();
  };

  const onFilter = () => {
    setTechnicalLicenseFilters(state);
    onClose();
  };

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  return (
    <div className="filters-dropdown" style={{ minHeight: '300px' }}>
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>

      <Input label="Status" layout="vertical">
        <Input.Select
          showSearch
          options={sectorOptions}
          value={sector}
          mode="multiple"
          placeholder="Select service providers"
          maxTagCount="responsive"
          onChange={(data) => setSector({ data })}
        />
      </Input>

      <div className="filters-dropdown--divider" />

      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default TechnicalLicenseHoldersDropdownFilters;
