import {
  CreateAngelInvestorPayload,
  StageType,
  InvestmentStageType,
  AngelInvestorQuery,
  CreateADGMLicensePayload,
  IncentiveAgreementType, UpdateAdgmLicenseByAngelInvestorIdPayload,
} from 'packages/angel_investors_repository'
import {
  SetAngelInvestorsPayload,
  GetProviderServicesWithAngelInvestorPayload,
  SetAngelInvestorCountPayload,
  SetAngelInvestorById,
  SetAngelInvestorForReviewPayload,
  UpdateAngelInvestorPayload, GetAngelInvestorByIdPayload,
} from 'types/reduxTypes/ActionTypes/AngelInvestorsTypes'
import {
  DataPayload,
  GetAllQueryPayload,
  IdPayload,
  IsLoadingPayload,
} from 'types/reduxTypes/ActionTypes';
import { ChangeRequestStatusPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { GetTeamMembersPayload, SetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import { ADGMLicenseType } from 'packages/angel_investors_repository/types/adgm_license';
import { PeopleFilters } from 'types/people';
import {
  CreateIncentiveAgreementPayload,
  AngelInvestorFiltersType,
  UpdateIncentiveAgreementPayload,
} from 'types/angelInvestors';
import { ParamsPayload } from 'packages/http_client';
import { ServiceProviderType } from 'types/serviceProviders';
import { GetAmountSumsResponse } from 'packages/wallet_repository';
import { SectorType } from '../../../packages/startup_repository'

export const types = {
  CREATE_ANGEL_INVESTOR_ADGM_LICENSE: 'CREATE_ANGEL_INVESTOR_ADGM_LICENSE',
  GET_INCENTIVE_AGREEMENT_BY_ID: 'GET_INCENTIVE_AGREEMENT_BY_ID',
  SET_ANGEL_INVESTOR_INCENTIVE_AGREEMENT_BY_ID: 'SET_ANGEL_INVESTOR_INCENTIVE_AGREEMENT_BY_ID',
  GET_ANGEL_INVESTORS: 'GET_ANGEL_INVESTORS',
  SET_ANGEL_INVESTORS: 'SET_ANGEL_INVESTORS',
  SET_ANGEL_INVESTORS_COUNT: 'SET_ANGEL_INVESTORS_COUNT',
  GET_ANGEL_INVESTOR_BY_ID: 'GET_ANGEL_INVESTOR_BY_ID',
  SET_ANGEL_INVESTOR_BY_ID: 'SET_ANGEL_INVESTOR_BY_ID',
  GET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID: 'GET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID',
  SET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID: 'SET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID',
  SET_ANGEL_INVESTOR_REQUESTS: 'SET_ANGEL_INVESTOR_REQUESTS',
  SET_REQUEST_STATUS_CHANGE: 'SET_REQUEST_STATUS_CHANGE',
  CREATE_ANGEL_INVESTOR: 'CREATE_ANGEL_INVESTOR',
  GET_ANGEL_INVESTOR_MEMBERS: 'GET_ANGEL_INVESTOR_MEMBERS',
  SET_ANGEL_INVESTOR_MEMBERS: 'SET_ANGEL_INVESTOR_MEMBERS',
  UPDATE_ANGEL_INVESTOR_BY_ID: 'UPDATE_ANGEL_INVESTOR_BY_ID',
  UPDATE_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID: 'UPDATE_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID',
  SET_ANGEL_INVESTOR_SERVICE_PROVIDER_BY_ID: 'SET_ANGEL_INVESTOR_SERVICE_PROVIDER_BY_ID',
  GET_PROVIDER_SERVICES_WITH_ANGEL_INVESTOR: 'GET_PROVIDER_SERVICES_WITH_ANGEL_INVESTOR',
  GET_ANGEL_INVESTORS_BY_OWNER_ID: 'GET_ANGEL_INVESTORS_BY_OWNER_ID',
  GET_ANGEL_INVESTORS_GET_SECTORS: 'GET_ANGEL_INVESTORS_GET_SECTORS',
  GET_ANGEL_INVESTORS_STAGES: 'GET_ANGEL_INVESTORS_STAGES',
  GET_INVESTMENT_STAGES: 'GET_INVESTMENT_STAGES',
  SET_ANGEL_INVESTOR_SECTOR_OPTIONS: 'SET_ANGEL_INVESTOR_SECTOR_OPTIONS',
  SET_ANGEL_INVESTOR_STAGES_OPTIONS: 'SET_ANGEL_INVESTOR_STAGES_OPTIONS',
  SET_INVESTMENT_STAGE_OPTIONS: 'SET_INVESTMENT_STAGE_OPTIONS',
  SET_ANGEL_INVESTORS_IS_LOADING: 'SET_ANGEL_INVESTORS_IS_LOADING',
  GET_ANGEL_INVESTORS_COUNT: 'GET_ANGEL_INVESTORS_COUNT',
  GET_ANGEL_INVESTOR_TEAM_MEMBERS_COUNT: 'GET_ANGEL_INVESTOR_MEMBERS_COUNT',
  SET_ANGEL_INVESTOR_TEAM_MEMBERS_COUNT: 'SET_ANGEL_INVESTOR_MEMBERS_COUNT',
  SET_ANGEL_INVESTOR_ID: 'SET_ANGEL_INVESTOR_ID',
  CHANGE_ANGEL_INVESTOR_DOCUMENT_STATUS: 'CHANGE_ANGEL_INVESTOR_DOCUMENT_STATUS',
  SET_ANGEL_INVESTOR_FOR_REVIEW: 'SET_ANGEL_INVESTOR_FOR_REVIEW',
  SET_ANGEL_INVESTOR_FILTERS: 'SET_ANGEL_INVESTOR_FILTERS',
  RESET_ANGEL_INVESTORS: 'RESET_ANGEL_INVESTORS',
  GET_ANGEL_INVESTOR_SERVICE_PROVIDERS: 'GET_ANGEL_INVESTOR_SERVICE_PROVIDERS',
  SET_ANGEL_INVESTOR_SERVICE_PROVIDERS: 'SET_ANGEL_INVESTOR_SERVICE_PROVIDERS',
  SET_SUMS_FOR_SERVICE_PROVIDERS: 'SET_SUMS_FOR_SERVICE_PROVIDERS',
  CREATE_INCENTIVE_AGREEMENT: 'CREATE_INCENTIVE_AGREEMENT',
  UPDATE_INCENTIVE_AGREEMENT: 'UPDATE_INCENTIVE_AGREEMENT',
};

export const setSumsForServiceProviders = (payload: DataPayload<GetAmountSumsResponse[]>) => ({
  type: types.SET_SUMS_FOR_SERVICE_PROVIDERS,
  payload,
});

export const setAngelInvestorServiceProviders = (payload: DataPayload<ServiceProviderType[]>) => ({
  type: types.SET_ANGEL_INVESTOR_SERVICE_PROVIDERS,
  payload,
});

export const getAngelInvestorServiceProviders = (payload: GetAllQueryPayload<ParamsPayload>) => ({
  type: types.GET_ANGEL_INVESTOR_SERVICE_PROVIDERS,
  payload,
});

export const resetAngelInvestors = (payload: object = {}) => ({
  type: types.RESET_ANGEL_INVESTORS,
  payload,
});

export const updateADGMLicenseByAngelInvestorId = (
  payload: UpdateAdgmLicenseByAngelInvestorIdPayload,
) => ({
  type: types.UPDATE_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID,
  payload,
});

export const getADGMLicenseByAngelInvestorId = (payload: IdPayload) => ({
  type: types.GET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID,
  payload,
});

export const setADGMLicenseByAngelInvestorId = (payload: DataPayload<ADGMLicenseType>) => ({
  type: types.SET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID,
  payload,
});

export const setTeamMembersCount = (payload: DataPayload<number>) => ({
  type: types.SET_ANGEL_INVESTOR_TEAM_MEMBERS_COUNT,
  payload,
});
export const getTeamMembersCount = (payload: PeopleFilters) => ({
  type: types.GET_ANGEL_INVESTOR_TEAM_MEMBERS_COUNT,
  payload,
});

export const getAngelInvestorCount = (payload: object = {}) => ({
  type: types.GET_ANGEL_INVESTORS_COUNT,
  payload,
});

export const getAngelInvestors = (payload: AngelInvestorQuery = {}) => ({
  type: types.GET_ANGEL_INVESTORS,
  payload,
});

export const setAngelInvestors = (payload: SetAngelInvestorsPayload) => ({
  type: types.SET_ANGEL_INVESTORS,
  payload,
});

export const setAngelInvestorsCount = (payload: SetAngelInvestorCountPayload) => ({
  type: types.SET_ANGEL_INVESTORS_COUNT,
  payload,
});

export const getAngelInvestorById = (payload: GetAngelInvestorByIdPayload) => ({
  type: types.GET_ANGEL_INVESTOR_BY_ID,
  payload,
});

export const setAngelInvestorById = (payload: SetAngelInvestorById) => ({
  type: types.SET_ANGEL_INVESTOR_BY_ID,
  payload,
});

export const setRequestStatusChange = (payload: ChangeRequestStatusPayload) => ({
  type: types.SET_REQUEST_STATUS_CHANGE,
  payload,
});

export const createAngelInvestor = (payload: DataPayload<CreateAngelInvestorPayload>) => ({
  type: types.CREATE_ANGEL_INVESTOR,
  payload,
});

export const getAngelInvestorMembers = (payload: GetTeamMembersPayload) => ({
  type: types.GET_ANGEL_INVESTOR_MEMBERS,
  payload,
});

export const setAngelInvestorMembers = (payload: SetTeamMembersPayload) => ({
  type: types.SET_ANGEL_INVESTOR_MEMBERS,
  payload,
});

export const updateAngelInvestorById = (payload: UpdateAngelInvestorPayload) => ({
  type: types.UPDATE_ANGEL_INVESTOR_BY_ID,
  payload,
});

export const getProviderServicesWithAngelInvestor = (payload: GetProviderServicesWithAngelInvestorPayload) => ({
  type: types.GET_PROVIDER_SERVICES_WITH_ANGEL_INVESTOR,
  payload,
});

export const getAngelInvestorSectors = (payload: object = {}) => ({
  type: types.GET_ANGEL_INVESTORS_GET_SECTORS,
  payload,
});

export const getAngelInvestorStages = (payload: object = {}) => ({
  type: types.GET_ANGEL_INVESTORS_STAGES,
  payload,
});

export const getAngelInvestorInvestmentStages = (payload: object = {}) => ({
  type: types.GET_INVESTMENT_STAGES,
  payload,
});

export const setSectorOptions = (payload: DataPayload<SectorType[]>) => ({
  type: types.SET_ANGEL_INVESTOR_SECTOR_OPTIONS,
  payload,
});

export const setStageOptions = (payload: DataPayload<StageType[]>) => ({
  type: types.SET_ANGEL_INVESTOR_STAGES_OPTIONS,
  payload,
});

export const setInvestmentStageOptions = (payload: DataPayload<InvestmentStageType[]>) => ({
  type: types.SET_INVESTMENT_STAGE_OPTIONS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_ANGEL_INVESTORS_IS_LOADING,
  payload,
});

export const setAngelInvestorId = (payload: DataPayload<number>) => ({
  type: types.SET_ANGEL_INVESTOR_ID,
  payload,
});

export const setAngelInvestorForReview = (payload: SetAngelInvestorForReviewPayload) => ({
  type: types.SET_ANGEL_INVESTOR_FOR_REVIEW,
  payload,
});

export const createAngelInvestorADGMLicense = (payload: CreateADGMLicensePayload) => ({
  type: types.CREATE_ANGEL_INVESTOR_ADGM_LICENSE,
  payload,
});

export const getIncentiveAgreementById = (payload: object = {}) => ({
  type: types.GET_INCENTIVE_AGREEMENT_BY_ID,
  payload,
});

export const setIncentiveAgreementById = (payload: DataPayload<IncentiveAgreementType>) => ({
  type: types.SET_ANGEL_INVESTOR_INCENTIVE_AGREEMENT_BY_ID,
  payload,
});

export const setAngelInvestorFilters = (payload: AngelInvestorFiltersType) => ({
  type: types.SET_ANGEL_INVESTOR_FILTERS,
  payload,
});

export const createIncentiveAgreement = (payload: CreateIncentiveAgreementPayload) => ({
  type: types.CREATE_INCENTIVE_AGREEMENT,
  payload,
});

export const updateIncentiveAgreement = (payload: UpdateIncentiveAgreementPayload) => ({
  type: types.UPDATE_INCENTIVE_AGREEMENT,
  payload,
});
