import { UploadProps } from 'antd'

import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components'
import {
  emailValidation, positiveNumbersOnly, requiredValidation, validateWebsiteURL,
} from 'utils/constants/validationRules'

export type AddTechnicalLicenseHolderFieldsCreatorParams = {
  beforeLogoUpload: UploadProps['beforeUpload'];
}

export const addTechnicalLicenseHolderNumberFormFields = [
  {
    fields: [
      getFormFieldBuilder('technicalLicenseNumber', 'Technical License Number')
        .withPlaceholder('Enter Technical License Number')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
]

export const addTechnicalLicenseHolderFormFields = (
  {
    beforeLogoUpload,
  }: AddTechnicalLicenseHolderFieldsCreatorParams,
) => [
  {
    fields: [
      getFormFieldBuilder('logo', 'Logo', inputTypes.image)
        .withBeforeUpload(beforeLogoUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Name')
        .required()
        .build(),
      getFormFieldBuilder('website', 'Website')
        .withRules([validateWebsiteURL])
        .withPlaceholder('Enter Website URL')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withPlaceholder('Enter Description')
        .withRows(4)
        .build(),
    ],
  },
]

export const addTechnicalLicenseHolderFounderFormFields = [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter First Name')
        .required()
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .withPlaceholder('Enter Last Name')
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email')
        .withRules([requiredValidation, emailValidation])
        .withPlaceholder('Enter Email')
        .required()
        .build(),
      getFormFieldBuilder('phoneNumber', 'Phone number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .withPlaceholder('Enter Phone')
        .build(),
    ],
  },
]
