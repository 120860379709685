import { AddEmployeeFieldsCreatorParams, AddStartupMemberFieldsCreatorParams, AddAngelInvestorMemberFieldsCreatorParams } from 'types/people';
import { genderOptions } from 'utils/constants/inputFieldValues';
import { requiredValidation, emailValidation, positiveNumbersOnly } from 'utils/constants/validationRules';
import { inputTypes, getFormFieldBuilder } from '../../components';

export const MEMBER_TYPES = {
  startup: 'Startup Team Member',
  angelInvestor: 'Angel Investor Team Member',
  hub71: 'HUB71 Team Member',
}

export const PERMISSION_LEVEL_TYPES = {
  founder: 'Founder',
  admin: 'Admin',
  member: 'Member',
}

export const memberTypeOptions = [
  {
    label: MEMBER_TYPES.startup,
    value: MEMBER_TYPES.startup,
  },
  {
    label: MEMBER_TYPES.angelInvestor,
    value: MEMBER_TYPES.angelInvestor,
  },
  {
    label: MEMBER_TYPES.hub71,
    value: MEMBER_TYPES.hub71,
  },
];

export const permissionLevelOptions = [
  {
    label: PERMISSION_LEVEL_TYPES.founder,
    value: PERMISSION_LEVEL_TYPES.founder,
  },
  {
    label: PERMISSION_LEVEL_TYPES.admin,
    value: PERMISSION_LEVEL_TYPES.admin,
  },
  {
    label: PERMISSION_LEVEL_TYPES.member,
    value: PERMISSION_LEVEL_TYPES.member,
  },
]

export const defaultValues = {
  [MEMBER_TYPES.startup]: {
    memberType: MEMBER_TYPES.startup,
    firstName: '',
    lastName: '',
    email: '',
    designation: '',
    title: '',
    employeeCode: '',
    permissions: [],
    profileImage: undefined,
    permissionLevel: PERMISSION_LEVEL_TYPES.member,
  },
  [MEMBER_TYPES.angelInvestor]: {
    memberType: MEMBER_TYPES.angelInvestor,
    firstName: '',
    lastName: '',
    email: '',
    gender: 'Male',
    profileImage: undefined,
    skills: [],
    hobbies: [],
    jobFunctions: [],
    startupId: [],
    permissionLevel: PERMISSION_LEVEL_TYPES.member,
  },
  [MEMBER_TYPES.hub71]: {
    memberType: MEMBER_TYPES.hub71,
    firstName: '',
    lastName: '',
    email: '',
    gender: 'Male',
    profileImage: undefined,
    skills: [],
    hobbies: [],
    jobFunctions: [],
    startupId: [],
    permissionLevel: PERMISSION_LEVEL_TYPES.member,
  },
}

export const addEmployeeFields = ({ beforeHeadshotUpload }: AddEmployeeFieldsCreatorParams) => [
  {
    heading: 'General Info',
    fields: [
      getFormFieldBuilder('headshot', 'Headshot', inputTypes.image)
        .withBeforeUpload(beforeHeadshotUpload)
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('firstName', 'First Name')
        .required()
        .withPlaceholder('Enter First Name')
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('lastName', 'Last Name')
        .withPlaceholder('Enter Last Name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    heading: 'Contact Info',
    fields: [
      getFormFieldBuilder('email', 'Email', inputTypes.email)
        .withPlaceholder('Enter Email')
        .withRules([requiredValidation, emailValidation])
        .required()
        .build(),
    ],
  },
  {
    heading: 'Employment Info',
    fields: [
      getFormFieldBuilder('jobTitle', 'Job Title')
        .withPlaceholder('Enter Job Title')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
];

export const addStartupMemberFields = ({
  beforeHeadshotUpload,
  beforeEmirateIdUpload,
  beforeEmployeeContractUpload,
  beforeWorkPermitUpload,
  beforePassportUpload,
  beforeVisaUpload,
  involvementTypes,
  nationalityOptions,
  startupDrawerProps,
}: AddStartupMemberFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('startupId', 'Startup', 'selectDrawer')
        .withRules([requiredValidation])
        .withProps(startupDrawerProps)
        .withPlaceholder('startup')
        .required()
        .build(),
    ],
  },
  {
    heading: 'General Info',
    fields: [
      getFormFieldBuilder('headshot', 'Headshot', inputTypes.image)
        .required()
        .withRules([requiredValidation])
        .withBeforeUpload(beforeHeadshotUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .required()
        .withPlaceholder('Enter First Name')
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .required()
        .withPlaceholder('Enter Last Name')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('dateOfBirth', 'Date of birth', inputTypes.datetime)
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('gender', 'Gender', inputTypes.select)
        .required()
        .withPlaceholder('Select gender')
        .withRules([requiredValidation])
        .withOptions(genderOptions)
        .build(),
    ],
  },
  {
    fields:[
      getFormFieldBuilder('nationalityId', 'Nationality', inputTypes.selectWithSearch)
        .withOptions(nationalityOptions)
        .withPlaceholder('Select Nationality')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('bio', 'Biography', inputTypes.textArea)
        .withRows(3)
        .withPlaceholder('Enter Nationality')
        .build(),
    ],
  },
  {
    heading: 'Contact Info',
    fields: [
      getFormFieldBuilder('email', 'Email')
        .required()
        .withPlaceholder('Enter Email')
        .withRules([requiredValidation, emailValidation])
        .build(),
      getFormFieldBuilder('phoneNumber', 'Mobile number', inputTypes.number)
        .min(0)
        .withPlaceholder('Enter Mobile number')
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    heading: 'Employment Info',
    fields: [
      getFormFieldBuilder('jobTitle', 'Job title')
        .withPlaceholder('Enter Job Title')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('involvementId', 'Employment Type', inputTypes.select)
        .required()
        .withPlaceholder('Enter Employment Type')
        .withRules([requiredValidation])
        .withOptions(involvementTypes)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('jobFunctions', 'Job functions', inputTypes.addButton)
        .withRows(1)
        .build(),
      getFormFieldBuilder('skills', 'Skills', inputTypes.addButton)
        .withRows(1)
        .build(),
    ],
  },
  {
    heading: 'Documents',
    fields: [
      getFormFieldBuilder('emirates', 'Emirate ID', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeEmirateIdUpload)
        .build(),
      getFormFieldBuilder('employmentContract', 'Employee Contract', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeEmployeeContractUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('workPermitName', 'Work Permit', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeWorkPermitUpload)
        .build(),
      getFormFieldBuilder('passport', 'Passport', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforePassportUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('visa', 'Visa', inputTypes.dragger)
        .withAccept('.pdf')
        .withBeforeUpload(beforeVisaUpload)
        .build(),
    ],
  },
];

export const addAngelInvestorMemberFields = ({
  beforeHeadshotUpload,
  nationalityOptions,
  angelInvestorsDrawerProps,
}: AddAngelInvestorMemberFieldsCreatorParams) => [
  {
    fields: [
      getFormFieldBuilder('angelInvestorId', 'Angel Investor', 'selectDrawer')
        .withRules([requiredValidation])
        .withProps(angelInvestorsDrawerProps)
        .withPlaceholder('Angel Investor')
        .required()
        .build(),
    ],
  },
  {
    heading: 'General Info',
    fields: [
      getFormFieldBuilder('headshot', 'Headshot', inputTypes.image)
        .required()
        .withRules([requiredValidation])
        .withBeforeUpload(beforeHeadshotUpload)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .required()
        .withPlaceholder('Enter First Name')
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .required()
        .withPlaceholder('Enter Last Name')
        .withRules([requiredValidation])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('dateOfBirth', 'Date of birth', inputTypes.datetime)
        .required()
        .withRules([requiredValidation])
        .build(),
      getFormFieldBuilder('gender', 'Gender', inputTypes.select)
        .required()
        .withPlaceholder('Select gender')
        .withRules([requiredValidation])
        .withOptions(genderOptions)
        .build(),
    ],
  },
  {
    fields:[
      getFormFieldBuilder('nationalityId', 'Nationality', inputTypes.selectWithSearch)
        .withOptions(nationalityOptions)
        .withPlaceholder('Select Nationality')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('bio', 'Biography', inputTypes.textArea)
        .withRows(3)
        .withPlaceholder('Enter Nationality')
        .build(),
    ],
  },
  {
    heading: 'Contact Info',
    fields: [
      getFormFieldBuilder('email', 'Email')
        .required()
        .withPlaceholder('Enter Email')
        .withRules([requiredValidation, emailValidation])
        .build(),
      getFormFieldBuilder('phoneNumber', 'Mobile number', inputTypes.number)
        .min(0)
        .withPlaceholder('Enter Mobile number')
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    heading: 'Employment Info',
    fields: [
      getFormFieldBuilder('jobTitle', 'Job title')
        .withPlaceholder('Enter Job Title')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
];
