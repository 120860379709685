import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import moment from 'moment'

import { ADGMLicense, addMissingDetailsHook } from 'components/AddTechnicalHolderDetails';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ADGMLicenseUploadFormProps } from 'types/technicalLicenses';
import { identity } from 'utils';

import './ADGMLicenseUploadForm.scss';

import { TLHADGMLicenseType } from '../../../../packages/technical_holder_repository'
import { formatDateDashYMDTimeHMS } from '../../../utils/constants/formatters'

const TLHADGMLicenseUploadForm = (props: ADGMLicenseUploadFormProps) => {
  const [form] = Form.useForm();
  const {
    isEdit,
    setIsEdit,
    adgm,
    technicalLicenseId,
    actions: {
      createTechnicalLicenseADGMLicense,
      updateADGMLicenseByTechnicalLicenseId,
      setIsDrawerOpen,
      updateTechnicalLicenseById,
    },
  } = props;

  const {
    state,
    setHasLicense,
    setFileName,
    setADGMAll,
    setADGMField,
  } = addMissingDetailsHook();

  const {
    adgmLicense,
    hasLicense,
    fileName,
  } = state;

  const handleSubmit = (values: TLHADGMLicenseType) => {
    const adgmLicensePayload = {
      ...values,
      expiryDate: moment(values.expiryDate).format(formatDateDashYMDTimeHMS),
      issueDate: moment(values.issueDate).format(formatDateDashYMDTimeHMS),
      aDGMLicenseUrl: adgmLicense.aDGMLicenseUrl,
      technicalLicenseID: adgm.technicalLicenseID,
      approvedRejectedStatus: adgm.approvedRejectedStatus,
      id: adgm.id,
    }

    if (hasLicense) {
      if (isEdit) {
        updateTechnicalLicenseById({
          id: technicalLicenseId,
          technicalLicense: {
            aDGMApprovedStatus: 0,
          },
        })

        updateADGMLicenseByTechnicalLicenseId({
          id: adgm.id,
          technicalLicenseId,
          adgmLicense: adgmLicensePayload,
        });
      } else {
        createTechnicalLicenseADGMLicense({
          ...adgmLicensePayload,
          technicalLicenseId,
        });
      }
    }
  };

  const onSetHasLicense = (value: DataPayload<boolean>) => {
    setHasLicense(value.data);
  }

  const onClose = () => (isEdit ? setIsEdit(false) : setIsDrawerOpen(false))

  const initialValues = {
    ...adgm,
    issueDate: identity.isTruthyString(adgm?.issueDate) ? moment(adgm?.issueDate) : null,
    expiryDate: identity.isTruthyString(adgm?.expiryDate) ? moment(adgm?.expiryDate) : null,
    acceptanceDate: identity.isTruthyString(adgm?.acceptanceDate) ? moment(adgm?.acceptanceDate) : null,
  }

  useEffect(() => {
    if (identity.isObjWithChildren(adgm) && identity.isTruthyString(adgm?.aDGMLicenseUrl)) {
      setFileName(adgm.aDGMLicenseUrl);
      setADGMAll(adgm);
      setHasLicense(true);
    }
  }, [adgm]);

  return (
    <div className="adgm-license-upload-form__container">
      <Form
        scrollToFirstError
        validateTrigger="onChange"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ADGMLicense
          fileName={fileName as string}
          hasLicense={hasLicense}
          adgmLicense={adgmLicense}
          setADGMField={setADGMField}
          isAddMissingDetails
          setHasLicense={onSetHasLicense}
          setADGMAll={setADGMAll}
        />

        <div className="drawer-buttons" style={{ marginTop: 16, width: '100%' }}>
          <Button
            type="default"
            className="secondary-button"
            onClick={onClose}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>

          <div className="submit-button">
            <Button
              type="primary"
              htmlType="submit"
              disabled={!hasLicense}
              className="primary-button"
              style={{ width: isEdit ? 180 : 160 }}
            >
              {`${isEdit ? 'Update' : 'Add'} ADGM License`}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TLHADGMLicenseUploadForm;
