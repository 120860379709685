import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux'

import { StateType } from 'types';
import {
  selectUserType,
  selectStartupStatus,
  selectAngelInvestorStatus,
} from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  startupStatus: selectStartupStatus(state),
  angelInvestorStatus: selectAngelInvestorStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setDrawerBody,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
