import React from 'react'

import { getLabelValueObject } from 'utils';
import { AddStartupMemberFieldsCreatorParams, AddStartupMemberFormProps } from 'types/people';
import { RcFile } from 'antd/lib/upload'
import { addStartupMemberFields, defaultValues } from '../../utils/constants';
import { FormBuilder } from '../../../components';
import { startupSelectColumns } from '../../../utils/constants/columns';
import StartupSelectTitle from '../StartupSelectTitle';

const AddStartupMemberForm = (props: AddStartupMemberFormProps) => {
  const {
    form, startups, involvementTypes, beforeUpload, nationalities,
  } = props;

  const nationalityOptions = nationalities?.map(
    (country) => getLabelValueObject(country.name, country.id),
  );

  const memberFieldsCreateData: AddStartupMemberFieldsCreatorParams = {
    involvementTypes,
    beforeHeadshotUpload: (file: RcFile) => beforeUpload('headshot', file),
    beforeEmirateIdUpload: (file: RcFile) => beforeUpload('emirates', file),
    beforeEmployeeContractUpload: (file: RcFile) => beforeUpload('employmentContract', file),
    beforeWorkPermitUpload: (file: RcFile) => beforeUpload('workPermitName', file),
    beforePassportUpload: (file: RcFile) => beforeUpload('passport', file),
    beforeVisaUpload: (file: RcFile) => beforeUpload('visa', file),
    nationalityOptions,
    startupDrawerProps: {
      form,
      columns: startupSelectColumns,
      dataSource: startups,
      options: startups.map((item) => ({ label: item.name || 'Not specified', value: item.id })),
      drawerTitle: <StartupSelectTitle startup title="Startups" />,
      filterBy: 'name',
      rowKeyName: 'id',
    },
  };

  return (
    <FormBuilder
      formFragment
      fields={addStartupMemberFields(memberFieldsCreateData)}
      defaultValues={defaultValues}
    />
  )
}

export default AddStartupMemberForm;
