import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { TechnicalLicenseHolderOverviewProps } from 'types/technicalLicenseHolders/technicalLicenseHolderOverview'
import {
  DRAWERS, getOffset, getRowKey, identity,
  isEitherAdmin,
  isPartner,
  useTableHook,
} from 'utils'
import {
  composeTechnicalLicenseDetailsUrl,
} from '../../routes/routeComposers'
import { technicalLicensesColumns } from '../utils/constants/columns'

import {
  Table,
  RenderIf,
  gridPaginationOptions,
  paginationOptions,
  GridTable,
} from '../components'
import { StartupType } from '../../types/startups';

const TechnicalLicenseHoldersOverviewView = ({
  userType,
  technicalLicenses,
  technicalLicensesCount,
  isRowTableLayout,
  filters,
  isLoading,
  sectorOptions,
  actions: {
    getTechnicalLicenses,
    getTechnicalLicenseCount,
    getTechnicalLicenseSectors,
    setDrawerBody,
    setIsDrawerOpen,
    setTableLayout,
    setTechnicalLicenseFilters,
    setTechnicalLicenseId,
  },
}: TechnicalLicenseHolderOverviewProps) => {
  const { state, setLimit, setCurrentPage } = useTableHook();
  const { limit, currentPage } = state;
  const history = useHistory();

  const openTechnicalLicenseHolderDetails = (
    record: StartupType,
  ) => history.push(composeTechnicalLicenseDetailsUrl(record.id));

  const onAddButtonClick = () => {
    setDrawerBody({ drawerBody: DRAWERS.addTechnicalLicenseHolder });
    setIsDrawerOpen({ isDrawerOpen: true });
  }

  const openGetInTouchDrawer = (record: StartupType) => {
    setDrawerBody({ drawerBody: DRAWERS.getInTouchDrawer });
    setIsDrawerOpen({ isDrawerOpen: true });
    setTechnicalLicenseId({ data: record.id });
  }

  const setSearchString = (searchString: string) => {
    setTechnicalLicenseFilters({ ...filters, searchString });
    setCurrentPage(1);
  }

  useEffect(() => {
    if (!isLoading && identity.isEmptyArray(sectorOptions)) {
      getTechnicalLicenseSectors();
    }
  }, [isLoading]);

  useEffect(() => {
    let limitDefault;

    if (isRowTableLayout && !isPartner(userType)) {
      limitDefault = gridPaginationOptions.includes(limit) ? 10 : limit;
    } else {
      limitDefault = paginationOptions.includes(limit) ? 12 : limit;
    }

    setLimit(limitDefault);
    setCurrentPage(1);
  }, [isRowTableLayout]);

  useEffect(() => {
    if (
      (isRowTableLayout && !isPartner(userType) && paginationOptions.includes(limit))
      || (gridPaginationOptions.includes(limit))
    ) {
      getTechnicalLicenses({
        isPreload: true,
        limit,
        offset: getOffset(limit, currentPage),
        filters,
      })

      getTechnicalLicenseCount({ filters });
    }
  }, [limit, currentPage, filters, isRowTableLayout]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    setTableLayout({ isRowTableLayout: false })
  }, []);

  return (
    <div className="content-container technical-license-holders-overview">
      <RenderIf condition={!isPartner(userType)}>
        <Table
          searchString={filters.searchString}
          isLoading={isLoading}
          onRow={(record) => ({ onClick: () => openTechnicalLicenseHolderDetails(record) })}
          onGridCardClick={openTechnicalLicenseHolderDetails}
          showSearchBar
          showSearchField
          leftContent={isEitherAdmin(userType) ? 'add-button' : undefined}
          addButtonText="tech license holder"
          placeholder="tech license holder"
          dataSource={technicalLicenses}
          columns={technicalLicensesColumns}
          showCustomPagination
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
          total={technicalLicensesCount}
          showDisplayButtons
          tableLocation="technical-license-holder"
          onAddButtonClick={onAddButtonClick}
          rowKey={getRowKey(technicalLicenses, 'technicalLicense', 'id')}
          setSearchString={setSearchString}
          component="technical-license-holder"
          showExportButton={isEitherAdmin(userType)}
          exportDataModel="technicallicense"
          isFilterButtons={false}
        />
      </RenderIf>

      <RenderIf condition={isPartner(userType)}>
        <GridTable
          isLoading={isLoading}
          dataSource={technicalLicenses}
          columns={technicalLicensesColumns}
          rowKey={getRowKey(technicalLicenses, 'startup', 'id')}
          onGridCardClick={() => null}
          firstButtonTitle="View profile"
          secondButtonTitle="Get in touch"
          firstButtonAction={(record: StartupType) => history.push(composeTechnicalLicenseDetailsUrl(record?.id!))}
          secondButtonAction={(record: StartupType) => openGetInTouchDrawer(record)}
          hasDeleteOption={false}
          showCustomPagination
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLimit={setLimit}
          total={getTechnicalLicenseCount}
          component="technical-license-holder"
        />
      </RenderIf>
    </div>
  )
};

export default TechnicalLicenseHoldersOverviewView;
