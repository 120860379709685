import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Button, Form } from 'antd';
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  isTechnicalLicense,
} from 'utils';

import {
  TitleWithActions,
  Input,
  Breadcrumb,
  EditProfileImage,
} from 'components/components';

import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { EdiTechnicalLicenseProps, TechnicalLicenseType } from 'types/technicalLicenses';
import routes from 'routes';
import {
  composeTechnicalLicenseDetailsUrl,
} from 'routes/routeComposers'
import { useEditTechnicalLicenseHook, editTechnicalLicenseFormElements } from './utils';

import './EditTechnicalLicense.scss';

const EditTechnicalLicense = (props: EdiTechnicalLicenseProps) => {
  const {
    selectedTechnicalLicense,
    userTechnicalLicenseId,
    userType,
  } = props;

  const {
    getTechnicalLicenseById,
    updateTechnicalLicenseById,
  } = props.actions;

  const [form] = Form.useForm();

  const params: { id?: string } = useParams();
  const { id } = params;

  const technicalLicenseId = useMemo(
    () => (identity.isTruthyNumber(userTechnicalLicenseId) ? userTechnicalLicenseId : parseInt(id!, 10)),
    [id],
  );

  const { state, setAll, setField } = useEditTechnicalLicenseHook();

  const {
    imageLink,
  } = state;

  useEffect(() => {
    getTechnicalLicenseById({ id: technicalLicenseId, owner: 'technical-license', isUpdate: false });
  }, [id]);

  useEffect(() => {
    setAll({ technicalLicense: selectedTechnicalLicense });
  }, [selectedTechnicalLicense]);

  useEffect(() => {
    form.setFieldsValue(state);
  }, [state]);

  const handleSubmit = (values: TechnicalLicenseType) => {
    updateTechnicalLicenseById({
      id: technicalLicenseId,
      technicalLicense: {
        ...values,
        imageLink: state.imageLink,
      },
    });
  }

  const beforeLogoUpload = (file: RcFile, field: 'imageLink') => {
    setField({ field, value: file as Blob });

    return false;
  }

  return (
    <div className="edit-technicallicense__container content-container">
      <Breadcrumb
        navigateTo={
          isTechnicalLicense(userType)
            ? routes.companyProfileUrl
            : composeTechnicalLicenseDetailsUrl(technicalLicenseId)
        }
        routeName="Technical license Details"
      />

      <TitleWithActions title={selectedTechnicalLicense?.name} />

      <Form
        onFinish={handleSubmit}
        onFinishFailed={scrollToFirstError}
        validateTrigger="onSubmit"
        form={form}
        initialValues={state}
      >
        <div className="edit-technicallicense__fields">
          <div className="edit-technicallicense__section">
            <EditProfileImage imageLink={imageLink} beforeLogoUpload={beforeLogoUpload} />
          </div>

          <div className="edit-technicallicense__section">
            {editTechnicalLicenseFormElements.map((element) => {
              if (element.type === 'heading') {
                return <Input.Heading key={element.name} title={element.title} />
              }

              if (element.type === 'field' && element.component) {
                return (
                  <Input
                    key={element.name}
                    label={element.label}
                    name={element.name}
                    rules={element.rules}
                    required={element.required}
                  >
                    {element.component}
                  </Input>
                )
              }

              return null;
            })}
          </div>

          <Button type="primary" htmlType="submit" className="primary-button">
            Update Technical license
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditTechnicalLicense;
