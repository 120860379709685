import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { TechnicalLicenseFiltersType } from 'types/technicalLicenses';
import {
  resetFilters as resetFiltersAC,
  setFilters as setFiltersAC,
  setSector as setSectorAC,
} from './actions';
import { reducer, initialState } from './reducer';

export const useTechnicalLicenseFiltersHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setFilters = (payload: TechnicalLicenseFiltersType) => {
    dispatch(setFiltersAC(payload));
  }

  const resetFilters = () => {
    dispatch(resetFiltersAC());
  }

  const setSector = (payload: DataPayload<number[]>) => {
    dispatch(setSectorAC(payload));
  }

  return {
    state,
    initialState,
    resetFilters,
    setFilters,
    setSector,
  };
}

export default useTechnicalLicenseFiltersHook;
