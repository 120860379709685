import React, { useState } from 'react';
import { Button, Switch } from 'antd';
import classnames from 'classnames';

import { SearchBarProps } from 'types/componentTypes';
import { identity } from 'utils';
import { SearchIcon } from 'assets/icons';
import { FilterUserTabTypeTab } from 'types/requests';
import {
  LeftContent, DisplayButtons, TypeButton, FilterButton, AllCategoriesButton,
} from './components'
import RenderIf from '../RenderIf'
import Input from '../Input'
import ExportDataButton from './components/ExportDataButton'
import { filtersDropdown } from './utils/constants'

import './SearchBar.scss'

const SearchBar = (props: SearchBarProps) => {
  const userTypeTabs = ['startup', 'angel-investors'];

  const tabTitles: any = {
    startup: 'Startup',
    'angel-investors': 'Angel Investors',
  };

  const {
    leftContent,
    showSearchBar,
    showSearchField,
    showFilterButton,
    showTypeButton,
    showDisplayButtons,
    showAllCategories,
    filtersActive,
    searchString,
    showExportButton,
    exportDataModel,
    filterButtonType,
    filterDropdownElements,
    showFilterByUserType,
    setCurrentUserTypeTab,
  } = props;

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const UpdatedBody = filtersDropdown[filterDropdownElements!]?.body;

  const handleOnSwitchChange = (isToggled: boolean, value: FilterUserTabTypeTab) => {
    if (setCurrentUserTypeTab !== undefined) {
      if (!isToggled) return setCurrentUserTypeTab({ currentUserTab: 'all' });
      setCurrentUserTypeTab({ currentUserTab: value })
    }
  }
  if (!showSearchBar) {
    return null;
  }

  const handleFilterClick = (value: boolean) => {
    if (!props.actions) return setShowDropdown(value)
    return props.actions.setFilterShown({ isFilterShown: value })
  }

  return (
    <div
      className={classnames('search-bar', {
        'search-bar-no-button': identity.isFalsy(leftContent),
        'search-bar-only-filter': [
          identity.isFalsy(leftContent),
          !showSearchField,
          !showTypeButton,
          !showDisplayButtons,
          !showAllCategories,
        ].every(Boolean),
      })}
    >
      {showDisplayButtons && (
        <LeftContent
          leftContent={leftContent}
          addButtonText={props.addButtonText}
          onAddButtonClick={props.onAddButtonClick}
          infoText={props.infoText}
        />
      )}

      <div
        className={classnames('filter-container', { 'filter-container-no-input': identity.isFalsy(showSearchField) })}
      >
        <RenderIf condition={showSearchField}>
          <Input className="search-bar__search-field" layout="vertical">
            <Input.InputField
              prefix={<Button className="button-icon" icon={<SearchIcon />} type="text" />}
              placeholder="Search..."
              bordered={false}
              type="text"
              defaultValue={searchString}
              onChange={(e) => props.setSearchString!(e.target.value)}
            />
          </Input>
        </RenderIf>

        <ExportDataButton
          showExportButton={showExportButton}
          exportDataModel={exportDataModel}
        />

        <TypeButton showTypeButton={showTypeButton} />
        <DisplayButtons showDisplayButtons={showDisplayButtons} />
        <AllCategoriesButton showAllCategories={showAllCategories} />

        {showFilterButton && (
          <>
            <RenderIf condition={filterButtonType === 'default'}>
              <FilterButton
                showFilterButton={showFilterButton}
                onClickFilterButton={props.onClickFilterButton}
                filtersActive={filtersActive}
              />
            </RenderIf>

            <RenderIf condition={filterButtonType === 'dropdown'}>
              {filterDropdownElements && ['service', 'people'].includes(filterDropdownElements.toString()) ? (
                <div
                  className={classnames('filters-dropdown__container', {
                    'filters-dropdown__container--shown': props.isFilterShown,
                  })}
                >
                  <RenderIf condition={!props.isFilterShown}>
                    <FilterButton onClickFilterButton={() => handleFilterClick(true)} filtersActive={filtersActive} />
                  </RenderIf>

                  <RenderIf condition={props.isFilterShown && identity.isTruthyString(filterDropdownElements)}>
                    {UpdatedBody && <UpdatedBody setShowDropdown={setShowDropdown} />}
                  </RenderIf>
                </div>
              ) : (
                <div
                  className={classnames('filters-dropdown__container', {
                    'filters-dropdown__container--shown': showDropdown,
                  })}
                >
                  <RenderIf condition={!showDropdown}>
                    <FilterButton onClickFilterButton={() => setShowDropdown(true)} filtersActive={filtersActive} />
                  </RenderIf>

                  {UpdatedBody && (
                    <RenderIf condition={showDropdown && !!filterDropdownElements}>
                      {UpdatedBody && <UpdatedBody setShowDropdown={setShowDropdown} />}
                    </RenderIf>
                  )}
                </div>
              )}
            </RenderIf>
          </>
        )}

        {showFilterByUserType && (
          <div className="toggle-user-type__container">
            <p>Show:</p>

            {userTypeTabs.map((tab: any) => (
              <div className="toggle-user-type-switch__container" key={tab}>
                <div className="toggle-switch__item">
                  <Switch onChange={(value: boolean) => handleOnSwitchChange(value, tab)} />
                  <p>{tabTitles[tab]}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

SearchBar.defaultProps = {
  showDisplayButtons: false,
  leftContent: undefined,
  addButtonText: '',
  showSearchBar: false,
  title: '',
  showSearchField: false,
  showFilterButton: true,
  filtersActive: false,
  searchString: '',
  setSearchString: () => null,
  filterDropdownElements: '',
  showFilterByUserType: false,
  isFilterShown: false,
  actions: {
    setFilterShown: () => null,
  },
}

export default SearchBar
