import React, { useState } from 'react';
import { RcFile } from 'antd/lib/upload';

import { ServiceSubCategoryType } from 'types/services';
import { ServiceCategoryFormProps } from 'types/services/addService';
import { identity } from 'utils';
import { Input, RenderIf } from '../../../components';
import Row from '../../../components/FormBuilder/components/Row';
import AddButtonDrawer from '../../../components/AddButtonDrawer';
import { resetFiltersEvent, subCategoryColumns } from '../../utils';

const ServiceCategoryForm = (props: ServiceCategoryFormProps) => {
  const {
    subCategories,
    form,
    setOfferDocLink,
  } = props;

  const [serviceCategory] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAllowForPreCommunity, setIsAllowForPreCommunity] = useState<boolean>(false);
  const [isAvailableForAI, setIsAvailableForAI] = useState<boolean>(false);

  const handleIsAvailableForAICommunity = (data: boolean) => {
    setIsAvailableForAI(data);
  };

  const handleIsAllowForPreCommunity = (data: boolean) => {
    setIsAllowForPreCommunity(data);
  };

  const beforeUpload = (file: RcFile) => {
    setOfferDocLink!(file as Blob);
    return false;
  }

  return (
    <div>
      <RenderIf condition={identity.isTruthyNumber(serviceCategory) && identity.isFullArray(subCategories)}>
        <Row oneColumn>
          <AddButtonDrawer
            form={form}
            drawerTitle="Sub Categories"
            columns={subCategoryColumns}
            dataSource={subCategories!}
            options={subCategories?.map((category: ServiceSubCategoryType) => (
              { value: category.id!, label: category.name! }
            ))!}
            filterBy="name"
            placeholder="sub categories"
            name="subCategories"
            rowKeyName="id"
            label="Sub Categories"
            buttonText="Sub Categories"
            eventName={resetFiltersEvent}
          />
        </Row>
      </RenderIf>

      <Row>
        <Input
          label="Active Status"
          layout="vertical"
          name="active"
        >
          <Input.Switch
            onChange={setIsActive}
            checked={isActive}
          />
        </Input>

        <Input
          label="Available pre-Community"
          layout="vertical"
          name="allowForPreCommunity" // _allow_for_pre_community
        >
          <Input.Switch
            onChange={handleIsAllowForPreCommunity}
            checked={isAllowForPreCommunity}
          />
        </Input>
      </Row>

      <Row oneColumn>
        <Input
          label="Available For Angel Investors / Tech License Holders"
          layout="vertical"
          name="isAvailableForAICommunity"
        >
          <Input.Switch
            onChange={handleIsAvailableForAICommunity}
            checked={isAvailableForAI}
          />
        </Input>
      </Row>

      <Row>
        <Input
          label="Offer Document"
          layout="vertical"
          name="offerDocLink"
        >
          <Input.DraggerUpload
            accept=".pdf"
            name="offerDocLink"
            beforeUpload={beforeUpload}
            isRoundableButton
          />
        </Input>
      </Row>
    </div>
  );
};

export default ServiceCategoryForm;
