import { Modal } from 'antd';

import { ServicesType } from 'types/services';
import { identity } from 'utils';
import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes';
import { ServiceRequestItemType } from 'packages/service_request_repository';
import { DeleteServicePayload } from 'types/reduxTypes/ActionTypes/ServicesType';

export const disableRequestButton = (data: ServicesType, unsentRequests: ServiceRequestItemType[]) => {
  const hasUnsentRequests = identity.isFullArray(unsentRequests);
  if (hasUnsentRequests) {
    const requestedHousingOfficeInsurance = [1, 2, 3].includes(unsentRequests[0].service?.categoryId || 0);
    const notRequestedServiceProvider = data.serviceProviderId !== unsentRequests[0].service?.serviceProviderId;
    const isHousingOfficeInsurance = [1, 2, 3].includes(data.categoryId);

    return requestedHousingOfficeInsurance || (notRequestedServiceProvider || isHousingOfficeInsurance)
  }

  return hasUnsentRequests;
};

export const showRequestedButton = (id: number, unsentRequests: ServiceRequestItemType[]) => (
  identity.isFullArray(unsentRequests)
  && identity.isObjWithChildren(unsentRequests!.find((item) => item.service?.id === id))
);

export const disableDeleteServiceButton = (data: ServicesType) => ([
  REQUEST_STATUS.inProgress,
  REQUEST_STATUS.pendingQuotation,
  REQUEST_STATUS.pendingHUB71,
  REQUEST_STATUS.quoted,
].includes(data.serviceStatus));

export const showDeleteConfirm = (data: ServicesType, onDeleteService: (_payload: DeleteServicePayload) => void) => {
  Modal.confirm({
    title: `Are you sure you want to delete ${data.name} service?`,
    content: 'Once deleted, the service cannot be recovered.',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      onDeleteService!(data)
    },
    onCancel() {},
  });
}
