import React from 'react';

import {
  DashboardIcon,
  PeopleIcon,
  PartnersIcon,
  ServicesIcon,
  EventIcon,
  RequestIcon,
  CohortsIcon,
  WalletIcon,
  PerformanceIcon,
  StartupIconV2,
  AngelInvestorsIcon,
} from 'assets/icons';
import { SideBarOption } from 'types/layoutWithSidebar/LayoutWithSidebarProps';
import routes from 'routes';
import { isProduction, ROLES } from 'utils';
import {
  cohortsUrl,
  dashboardUrl,
  eventsUrl,
  partnersUrl,
  peopleUrl,
  performanceUrl,
  requestsUrl,
  servicesAndSpsOverviewUrl,
  servicesUrl,
  settingsOverviewUrl,
  startupsUrl,
  angelInvestorsUrl,
  teamMembersUrl,
  walletUrl,
  angelInvestorWalletUrl,
  technicalLicenseHoldersUrl,
} from 'routes/urls'
import IconWrapper from '../IconWrapper';

const sidebarOptionsByRole: SideBarOption[] = [
  {
    key: dashboardUrl.replace('/', ''),
    icon: <IconWrapper><DashboardIcon /></IconWrapper>,
    label: 'Dashboard',
    path: routes.dashboardUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
      ROLES.partner,
      ROLES.serviceProvider,
      ROLES.technicalLicense,
    ],
  },
  {
    key: teamMembersUrl.replace('/', ''),
    icon: <IconWrapper><PeopleIcon /></IconWrapper>,
    label: 'Team Members',
    path: routes.teamMembersUrl,
    acceptedTypes: [
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
      ROLES.technicalLicense,
    ],
  },
  {
    key: cohortsUrl.replace('/', ''),
    icon: <IconWrapper><CohortsIcon /></IconWrapper>,
    label: 'Cohorts',
    path: routes.cohortsUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
    ],
  },
  {
    key: peopleUrl.replace('/', ''),
    icon: <IconWrapper><PeopleIcon /></IconWrapper>,
    label: 'People',
    path: routes.peopleUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
    ],
  },
  {
    key: startupsUrl.replace('/', ''),
    icon: <IconWrapper><StartupIconV2 /></IconWrapper>,
    label: 'Startups',
    path: routes.startupsUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.partner,
    ],
  },
  {
    key: angelInvestorsUrl.replace('/', ''),
    icon: <IconWrapper><AngelInvestorsIcon /></IconWrapper>,
    label: 'Angel Investors',
    path: routes.angelInvestorsUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.partner,
    ],
  },
  {
    key: partnersUrl.replace('/', ''),
    icon: <IconWrapper><PartnersIcon /></IconWrapper>,
    label: 'Partners',
    path: routes.partnersUrl,
    acceptedTypes: isProduction ? [] : [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
    ],
  },
  {
    key: technicalLicenseHoldersUrl.replace('/', ''),
    icon: <IconWrapper><ServicesIcon /></IconWrapper>,
    label: 'Tech License Holders',
    path: routes.technicalLicenseHoldersUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
    ],
  },
  {
    key: servicesAndSpsOverviewUrl.replace('/', ''),
    icon: <IconWrapper><ServicesIcon /></IconWrapper>,
    label: 'Service providers',
    path: routes.servicesAndSpsOverviewUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
    ],
  },
  {
    key: servicesUrl.replace('/', ''),
    icon: <IconWrapper><ServicesIcon /></IconWrapper>,
    label: 'Services',
    path: routes.servicesUrl,
    acceptedTypes: [
      ROLES.serviceProvider,
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.technicalLicense,
    ],
  },
  {
    key: requestsUrl.replace('/', ''),
    icon: <IconWrapper><RequestIcon /></IconWrapper>,
    label: 'Requests',
    path: routes.requestsUrl,
    acceptedTypes: [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
      ROLES.partner,
      ROLES.serviceProvider,
      ROLES.technicalLicense,
    ],
  },
  {
    key: eventsUrl.replace('/', ''),
    icon: <IconWrapper><EventIcon /></IconWrapper>,
    label: 'Events',
    path: routes.eventsUrl,
    acceptedTypes: isProduction ? [] : [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
      ROLES.partner,
      ROLES.serviceProvider,
    ],
  },
  {
    key: walletUrl.replace('/', ''),
    icon: <IconWrapper><WalletIcon /></IconWrapper>,
    label: 'Wallet',
    path: routes.walletUrl,
    acceptedTypes: [
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
    ],
  },
  {
    key: angelInvestorWalletUrl.replace('/', ''),
    icon: <IconWrapper><WalletIcon /></IconWrapper>,
    label: 'Wallet',
    path: routes.angelInvestorWalletUrl,
    acceptedTypes: [
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
    ],
  },
  {
    key: performanceUrl.replace('/', ''),
    icon: <IconWrapper><PerformanceIcon /></IconWrapper>,
    label: 'Performance',
    path: routes.performanceUrl,
    acceptedTypes: isProduction ? [] : [
      ROLES.superAdmin,
      ROLES.admin,
      ROLES.startupAdmin,
      ROLES.startupFounder,
      ROLES.startupTeamMember,
      ROLES.angelInvestorAdmin,
      ROLES.angelInvestorFounder,
      ROLES.angelInvestorMember,
    ],
  },
  {
    key: settingsOverviewUrl.replace('/', ''),
    icon: <IconWrapper><ServicesIcon /></IconWrapper>,
    label: 'Settings Overview',
    path: routes.settingsOverviewUrl,
    acceptedTypes: [
      ROLES.superAdmin,
    ],
  },
];

export default sidebarOptionsByRole;
