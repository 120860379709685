import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Button, Form, Alert } from 'antd';

import {
  identity, isAdmin, isSuperAdmin, isAngelInvestorFounder, requiredValidation,
} from 'utils';
import { Input, Text } from 'components/components';
import { ADGMLicenseDetailsDrawerProps } from 'types/angelInvestors/editAngelInvestor';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getAngelInvestorFile } from '../../utils';
import { DetailsDrawerRow, AIADGMLicenseUploadForm } from '..';

import './ADGMLicenseDetailsDrawer.scss';
import { EditIcon } from '../../../../assets/icons'
import approvedRejectedOptions from '../../../AngelInvestorsOverview/utils/constants'

const AIADGMLicenseDetailsDrawer = (props: ADGMLicenseDetailsDrawerProps) => {
  const [form] = Form.useForm();
  const statusFieldValue = Form.useWatch('status', form);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {
    adgm, userAngelInvestorId, id, info, actions, userType,
  } = props;

  const {
    getADGMLicenseByAngelInvestorId,
    updateAngelInvestorById,
    setIsDrawerOpen,
  } = actions;

  const { currentADGMLicense, aDGMApprovedStatus, currentADGMLicenseID } = info;

  const isAdminOrSuperAdmin = isAdmin(userType) || isSuperAdmin(userType);

  const isCreateOrEditMode = useMemo(() => (
    !identity.isTruthyString(adgm?.aDGMLicenseUrl)
    // || (identity.isZero(adgm?.approvedRejectedStatus) && identity.isTruthyString(adgm?.approvedRejectedDate))
    // || (identity.isTruthyString(adgm?.aDGMLicenseUrl) && aDGMApprovedStatus === 2)
    || isEdit
  ), [adgm, isEdit, aDGMApprovedStatus])

  const angelInvestorId = useMemo(
    () => (identity.isTruthyNumber(userAngelInvestorId) ? userAngelInvestorId : id),
    [id, userAngelInvestorId],
  );

  const status = useMemo(() => {
    let message = ''

    if (!currentADGMLicense) {
      message = 'Waiting for the ADGM License';
    }

    if (currentADGMLicense && aDGMApprovedStatus === 0) {
      message = 'Waiting for Hub71 review';
    }

    if (currentADGMLicense && aDGMApprovedStatus === 1) {
      message = 'Approved';
    }

    if (currentADGMLicense && aDGMApprovedStatus === 2) {
      message = 'Rejected. Waiting for the ADGM License.';
    }

    return message;
  }, [currentADGMLicense])

  const handleSubmit = ({
    status: statusAdgm,
    reason,
  }: {
    status: number,
    reason: string
  }) => {
    updateAngelInvestorById({
      id,
      angelInvestor: {
        currentADGMLicenseID,
        aDGMApprovedStatus: statusAdgm,
        adgmRejectReason: reason,
      },
    });
  }

  const handleCancel = () => {
    form.resetFields();
    setIsDrawerOpen(false);
  }

  const options = [
    ...approvedRejectedOptions.map((
      { title, value },
    ) => ({ label: title, value })),
  ]

  useEffect(() => {
    getADGMLicenseByAngelInvestorId({ id: angelInvestorId });
  }, [angelInvestorId]);

  if (isCreateOrEditMode) {
    return (
      <AIADGMLicenseUploadForm
        adgm={adgm}
        angelInvestorId={angelInvestorId}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    )
  }

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={{
        status: aDGMApprovedStatus || undefined,
        reason: '',
      }}
      className="adgm-license-details-drawer"
      validateTrigger="onChange"
    >
      <div className="adgm-license-details-drawer adgm-license-details-drawer__status">
        {isAdminOrSuperAdmin && currentADGMLicense && !isEdit ? (
          <Input
            name="status"
            label="Status"
            layout="horizontal"
          >
            <Input.Select
              labelInValue
              options={options}
              placeholder={status}
              allowClear
            />
          </Input>
        ) : (
          <Text text={`Status: ${status}`} weight={400} />
        )}
      </div>

      {
        ((isAdminOrSuperAdmin && currentADGMLicense?.approvedRejectedReason)
          || (!isAdminOrSuperAdmin && aDGMApprovedStatus === 2 && currentADGMLicense?.approvedRejectedReason))
        && (
          <Alert
            message={`Reason: ${currentADGMLicense?.approvedRejectedReason}`}
            description={isAdminOrSuperAdmin ? '' : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Click
                <Button
                  type="link"
                  style={{ padding: '0 5px' }}
                  onClick={() => setIsEdit(true)}
                >
                  Edit
                </Button>
                to update ADGM info
              </div>
            )}
            type="warning"
            showIcon
          />
        )
      }

      <div className="adgm-license-details-drawer">
        {aDGMApprovedStatus !== 1 && isAngelInvestorFounder(userType) && (
          <div className="adgm-license-details-drawer__action">
            <Button className="ghost-button" icon={<EditIcon />} onClick={() => setIsEdit(true)}>
              Edit
            </Button>
          </div>
        )}

        <DetailsDrawerRow
          label="ADGM License No."
          item={adgm.aDGMLicenseNo || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Name"
          item={adgm.legalADGMName || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Signatory Name"
          item={adgm.legalSignatoryName || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Signatory Title"
          item={adgm.legalSignatoryTitle || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Legal Signatory Email"
          item={adgm.legalSignatoryEmail || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="Registered WeWork Address"
          item={adgm.address || NOT_SPECIFIED}
        />

        <DetailsDrawerRow
          label="ADGM Issue Date"
          item={
            identity
              .isTruthyString(adgm.issueDate) ? moment(adgm.issueDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <DetailsDrawerRow
          label="ADGM Expiry Date"
          item={
            identity
              .isTruthyString(adgm.expiryDate) ? moment(adgm.expiryDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <DetailsDrawerRow
          label="HUB71 Acceptance Letter Date"
          item={
            identity
              .isTruthyString(adgm.acceptanceDate) ? moment(adgm.acceptanceDate)
                .format(formatSlashMDY) : NOT_SPECIFIED
          }
        />

        <Input.DraggerUpload
          previewType="image"
          defaultFileList={[...getAngelInvestorFile(adgm?.aDGMLicenseUrl!)]}
          isRoundableButton
        />
      </div>

      {isAdminOrSuperAdmin && currentADGMLicense && !isEdit && (
        form.isFieldsTouched(true) && (
          <>
            {statusFieldValue === 2 && (
              <Input
                name="reason"
                label="Rejection Reason"
                layout="vertical"
                required
                requiredMark
                rules={[requiredValidation]}
              >
                <Input.InputField.TextArea
                  size="large"
                  rows={4}
                  placeholder="Enter Rejection Reason"
                />
              </Input>
            )}

            <div className="drawer-buttons" style={{ marginTop: 16 }}>
              <Button
                type="default"
                className="secondary-button"
                onClick={handleCancel}
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                className="primary-button"
                onClick={form.submit}
              >
                Save
              </Button>
            </div>
          </>
        ))}
    </Form>
  );
}

export default AIADGMLicenseDetailsDrawer;
