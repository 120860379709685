import React, { useEffect } from 'react';

import { AdminAngelInvestorWalletProps } from 'types/componentTypes/Dashboard/AdminAngelInvestorWallet';
import AdminBudget from '../AdminBudget';

export const AdminAngelInvestorWallet = ({
  budget,
  filters,
  actions: {
    getAdminWallet,
  },
}: AdminAngelInvestorWalletProps) => {
  const {
    blocked, consumed, total, balance,
  } = budget;

  useEffect(() => {
    getAdminWallet({
      fieldSelection: [
        { field: 'blocked_amount', aggregation: 'sum' },
        { field: 'consumed_amount', aggregation: 'sum' },
        { field: 'total_wallet_amount', aggregation: 'sum' },
      ],
      type: 'angel-investor',
      filters,
    });
  }, [filters])

  return (
    <AdminBudget
      blocked={blocked}
      consumed={consumed}
      total={total}
      balance={balance}
      type="angel-investor"
    />
  )
}

export default AdminAngelInvestorWallet;
