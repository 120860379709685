import React from 'react';
import { Button } from 'antd';

// import { DRAWERS } from 'utils';
import { DocumentRequestRowButtonsProps } from 'types/cohorts/cohortDetails';
import { composeStartupDetailsUrl } from '../../../../../../../../routes/routeComposers'

const DocumentRequestRowButton = (props: DocumentRequestRowButtonsProps) => {
  const { startup } = props;
  // const { setDrawerBody, setIsDrawerOpen, setStartupForReview } = props.actions;

  // const onViewDetails = () => {
  //   setStartupForReview({ startup });
  //
  //   switch (documentType) {
  //     case 'adgmLicense': {
  //       setDrawerBody({ drawerBody: DRAWERS.adgmLicense });
  //       break;
  //     }
  //     case 'acceptanceLetter': {
  //       setDrawerBody({ drawerBody: DRAWERS.acceptanceLetter });
  //       break;
  //     }
  //     case 'incentiveAgreement': {
  //       setDrawerBody({ drawerBody: DRAWERS.incentiveAgreement });
  //       break;
  //     }
  //     default: {
  //       break;
  //     }
  //   }
  //
  //   setIsDrawerOpen({ isDrawerOpen: true });
  // }

  const openStartupDetails = () => {
    window.open(composeStartupDetailsUrl(startup.id), '_blank');
  };

  return (
    <div className="request-row__buttons-container">
      <Button
        className="secondary-button md"
        target="_blank"
        onClick={openStartupDetails}
      >
        View details
      </Button>
    </div>
  );
}

export default DocumentRequestRowButton;
