import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUnsentRequests, selectIsLoading, selectHighlightUnsentRequest } from 'redux/selectors/request';
import { setSelectedServiceProvider } from 'redux/actions/services';
import { removeHighlight, setCurrentTab } from 'redux/actions/request';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  unsentRequests: selectUnsentRequests(state),
  highlightRequest: selectHighlightUnsentRequest(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ setCurrentTab, setSelectedServiceProvider, removeHighlight }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
