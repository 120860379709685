import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import {
  addService, addServiceBulk, getServiceCategories, getServiceUnitsList,
} from '../../redux/actions/services';
import { selectServiceCategories, selectServiceTimeUnits, selectServiceBulkErrors } from '../../redux/selectors/services';
import { selectIsLoading } from '../../redux/selectors/workspace';

const mapStateToProps = (state: StateType) => ({
  categories: selectServiceCategories(state),
  units: selectServiceTimeUnits(state),
  bulkErrors: selectServiceBulkErrors(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addService,
    addServiceBulk,
    getServiceCategories,
    getServiceUnitsList,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
