import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { setIsDrawerOpen } from 'redux/actions/workspace';
import { createTechnicalLicenseADGMLicense, updateADGMLicenseByTechnicalLicenseId, updateTechnicalLicenseById } from 'redux/actions/technicalLicense';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createTechnicalLicenseADGMLicense,
    updateADGMLicenseByTechnicalLicenseId,
    setIsDrawerOpen,
    updateTechnicalLicenseById,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
