import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectError, selectIsLoading } from 'redux/selectors/technicalLicenses';
import { createTechnicalLicenseHolderByNumber } from 'redux/actions/technicalLicense';

const mapStateToProps = (state: StateType) => ({
  error: selectError(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createTechnicalLicenseHolderByNumber,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
