import { createSelector } from 'reselect';

import { StateType } from '../../../types';
import { WalletStateType } from '../../../types/reduxTypes/StateTypes';

const selectWalletState = (state: StateType) => state.walletReducer;

export const selectWallet = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.wallet,
);

export const selectBudget = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.budget,
);

export const selectWalletTransactions = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.walletTransactions,
);

export const selectIsLoading = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.isLoading,
);

export const selectWalletCount = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.count,
);

export const selectWalletFilters = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.filters,
);

export const selectWalletFiltersActive = createSelector(
  selectWalletState,
  (state: WalletStateType) => state.filtersActive,
);
