import React from 'react';
import { Button, Col, Row } from 'antd';

import { AddPricingOptionsProps } from 'types/services/addService';
import { PricingOption } from 'types/services';
import { AddIcon } from 'assets/icons';
import PricingOptionForm from './components/PricingOptionForm';
import { OptionValues } from '../../utils';
import { RenderIf } from '../../../components';

import './AddPricingOptions.scss';

const AddPricingOptions = (props: AddPricingOptionsProps) => {
  const {
    pricingOptions,
    onDeletePricingOption,
    onAddPricingOption,
    units,
    errors,
  } = props;

  const initialValues: PricingOption = {
    id: pricingOptions.length,
    amount: '',
    option: OptionValues[2].value,
  }

  return (
    <Row>
      <Col span={24}>
        <PricingOptionForm
          hideDeleteOption
          key={`pricing-option-${pricingOptions[0].id}`}
          error={errors[0]}
          pricingOption={pricingOptions[0]}
          onDeletePricingOption={onDeletePricingOption}
          units={units}
        />

        {/* Hiding functionality to add multiple prices until entire service request flow completed */}
        <RenderIf condition={false}>
          <Button onClick={() => onAddPricingOption(initialValues)} className="secondary-button" type="text">
            <AddIcon />
            Add pricing option
          </Button>
        </RenderIf>
      </Col>
    </Row>
  );
}

export default AddPricingOptions;
