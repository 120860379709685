import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectServiceDetails } from 'redux/selectors/services';
import { selectMemberDetails as selectAIMemberDetails } from 'redux/selectors/angelInvestorMembers';
import { selectMemberDetails } from 'redux/selectors/members';
import { selectTechnicalLicenseTeamMembers } from 'redux/selectors/technicalLicenses';
import { getAngelInvestorMemberById } from 'redux/actions/angelInvestorMemberDetails';
import { getMemberById } from 'redux/actions/memberDetails';
import { getTechnicalLicenseMemberById } from 'redux/actions/technicalLicenseMemberDetails';

const mapStateToProps = (state: StateType) => ({
  details: selectServiceDetails(state),
  member: selectMemberDetails(state),
  aIMember: selectAIMemberDetails(state),
  thlMember: selectTechnicalLicenseTeamMembers(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getMemberById,
    getAngelInvestorMemberById,
    getTechnicalLicenseMemberById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
