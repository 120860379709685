import produce from 'immer';

import { ServicesStateType } from 'types/reduxTypes/StateTypes';
import {
  SetServicesPayload,
  ServicesActionType,
  SetServiceByIdPayload,
  ServiceCountPayload,
  SetCurrentServicesTabPayload,
  SetCurrentSubCategoryTabPayload,
  SetServiceTimeUnitsPayload,
} from 'types/reduxTypes/ActionTypes/ServicesType';
import { DataPayload, IdPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { ServiceAndSpActiveTabPayload, ServiceBulkErrorsType, ServiceFiltersType } from 'types/services'
import { identity, checkServicesFiltersActive } from 'utils';
import { ServiceCategoryType, ServiceSubCategoryType } from 'packages/service_repository';
import { defaultServices } from '../../utils';
import { types } from '../../actions/services';

const defaultState: ServicesStateType = defaultServices;

const handleSetServices = (state: ServicesStateType, payload: SetServicesPayload) => {
  state.services = payload.services;
  state.filtersActive = checkServicesFiltersActive(state.filters);
  return state;
}

const handleSetIsLoading = (state: ServicesStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetServiceById = (state: ServicesStateType, payload: SetServiceByIdPayload) => {
  state.details.service = payload.service;
  return state;
}

const handleSetSelectedServiceProvider = (state: ServicesStateType, payload: IdPayload) => {
  state.selectedServiceProvider = payload.id;
  return state;
}

const handleSetServiceCategories = (state: ServicesStateType, payload: DataPayload<ServiceCategoryType[]>) => {
  state.categories = payload.data;
  return state;
}

const handleSetServiceSubCategories = (state: ServicesStateType, payload: DataPayload<ServiceSubCategoryType[]>) => {
  state.subCategories = payload.data;
  return state;
}

const handleSetCurrentServicesTab = (state: ServicesStateType, payload: SetCurrentServicesTabPayload) => {
  state.servicesFilterTab = payload.servicesFilterTab;
  return state;
}

const handleSetCurrentSubCategoryTab = (state: ServicesStateType, payload: SetCurrentSubCategoryTabPayload) => {
  state.servicesSubCategoryFilterTab = payload.servicesSubCategoryFilterTab;
  return state;
}

const handleSetServiceAndSpActiveTab = (state: ServicesStateType, payload: ServiceAndSpActiveTabPayload) => {
  state.activeTab = payload.activeTab;
  return state;
}

const handleSetTabCount = (state: ServicesStateType, payload: ServiceCountPayload) => {
  const { counter } = state;
  if (identity.isZero(payload.servicesCount) || identity.isTruthyNumber(payload.servicesCount)) {
    counter.servicesCount = payload?.servicesCount!;
  }
  state.counter = counter;
  return state;
}

const handleSetAllServiceSubCategories = (state: ServicesStateType, payload: DataPayload<ServiceSubCategoryType[]>) => {
  state.subCategories = payload.data;
  return state;
}

const handleSetServiceCategoryDetails = (state: ServicesStateType, payload: DataPayload<ServiceCategoryType>) => {
  state.serviceCategory = payload.data;
  return state;
}

const handleSetServiceSubCategoryDetails = (state: ServicesStateType, payload: DataPayload<ServiceSubCategoryType>) => {
  state.serviceSubCategory = payload.data;
  return state;
}

const handleSetHasMainCategory = (state: ServicesStateType, payload: DataPayload<boolean>) => {
  state.details.hasMainCategory = payload.data;
  return state;
}

const handleSetCategoryID = (state: ServicesStateType, payload: DataPayload<number>) => {
  state.categoryID = payload.data;
  return state;
}

const handleSetServiceUnitsList = (state: ServicesStateType, payload: SetServiceTimeUnitsPayload) => {
  state.units = payload.units;
  return state;
}

const handleSetServiceFilters = (state: ServicesStateType, payload: ServiceFiltersType) => {
  state.filters = payload;
  return state;
}

const handleSetServiceBulkError = (state: ServicesStateType, payload: ServiceBulkErrorsType) => {
  console.log(payload)

  state.bulkErrors = payload.errors;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: ServicesStateType = defaultState, action: ServicesActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SERVICES_FILTERS: {
      return handleSetServiceFilters(state, <ServiceFiltersType>payload);
    }
    case types.SET_SERVICES: {
      return handleSetServices(state, <SetServicesPayload>payload);
    }
    case types.SET_SERVICES_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_SERVICE_BY_ID: {
      return handleSetServiceById(state, <SetServiceByIdPayload>payload);
    }
    case types.SET_SELECTED_SERVICE_PROVIDER: {
      return handleSetSelectedServiceProvider(state, <IdPayload>payload);
    }
    case types.SET_SERVICE_CATEGORIES: {
      return handleSetServiceCategories(state, <DataPayload<ServiceCategoryType[]>>payload);
    }
    case types.SET_SERVICE_SUB_CATEGORIES: {
      return handleSetServiceSubCategories(state, <DataPayload<ServiceSubCategoryType[]>>payload);
    }
    case types.SET_SERVICE_SP_ACTIVE_TAB: {
      return handleSetServiceAndSpActiveTab(state, <ServiceAndSpActiveTabPayload>payload);
    }
    case types.SET_SERVICE_COUNT: {
      return handleSetTabCount(state, <ServiceCountPayload>payload);
    }
    case types.SET_CURRENT_SERVICES_TAB: {
      return handleSetCurrentServicesTab(state, <SetCurrentServicesTabPayload>payload);
    }
    case types.SET_ALL_SERVICE_SUB_CATEGORIES: {
      return handleSetAllServiceSubCategories(state, <DataPayload<ServiceSubCategoryType[]>>payload);
    }
    case types.SET_CURRENT_SUB_CATEGORY_TAB: {
      return handleSetCurrentSubCategoryTab(state, <SetCurrentSubCategoryTabPayload>payload);
    }
    case types.SET_SERVICE_CATEGORY_DETAILS: {
      return handleSetServiceCategoryDetails(state, <DataPayload<ServiceCategoryType>>payload);
    }
    case types.SET_SERVICE_SUB_CATEGORY_DETAILS: {
      return handleSetServiceSubCategoryDetails(state, <DataPayload<ServiceSubCategoryType>>payload);
    }
    case types.SET_IS_MAIN_CATEGORY: {
      return handleSetHasMainCategory(state, <DataPayload<boolean>>payload);
    }
    case types.SET_CATEGORY_ID: {
      return handleSetCategoryID(state, <DataPayload<number>>payload);
    }
    case types.SET_SERVICE_UNITS_LIST: {
      return handleSetServiceUnitsList(state, <SetServiceTimeUnitsPayload>payload);
    }
    case types.SET_SERVICE_BULK_ERRORS: {
      return handleSetServiceBulkError(state, <ServiceBulkErrorsType>payload);
    }
    case types.RESET_SERVICES: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
