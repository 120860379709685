import { PayloadAction } from '@reduxjs/toolkit';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { TechnicalLicenseFiltersType } from 'types/technicalLicenses';
import { types } from './actions';

const initialState: TechnicalLicenseFiltersType = {
  capitalRaised: '',
  fieldSelection: [''],
  incentiveAmount: '',
  locations: [],
  searchString: '',
  stage: 0,
  sector: [],
}

const handleSetSector = (state: TechnicalLicenseFiltersType, payload: DataPayload<number[]>) => ({
  ...state,
  sector: payload.data,
});

const handleSetFilters = (state: TechnicalLicenseFiltersType, payload: TechnicalLicenseFiltersType) => payload;

const reducer = (
  state: TechnicalLicenseFiltersType,
  action: PayloadAction<DataPayload<number[] | number | string> | TechnicalLicenseFiltersType | object>,
) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_SECTOR: {
      return handleSetSector(state, <DataPayload<number[]>>payload);
    }
    case types.SET_FILTERS: {
      return handleSetFilters(state, <TechnicalLicenseFiltersType>payload);
    }
    case types.RESET_FILTERS: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export { initialState, reducer };
