import React from 'react';
import { Button } from 'antd';

import { identity } from 'utils';
import { SearchFilterButtonProps } from 'types/componentTypes';
import RenderIf from '../../../RenderIf';

const FilterButton = (props: SearchFilterButtonProps) => {
  const { onClickFilterButton, showFilterButton, filtersActive } = props;

  if (!showFilterButton) {
    return null;
  }

  const onClick = () => {
    if (!identity.isFalsy(onClickFilterButton)) {
      onClickFilterButton!();
    }
  }

  return (
    <div className="table-filter-button__container">
      <Button
        className="secondary-button"
        onClick={onClick}
      >
        Filter
      </Button>
      <RenderIf condition={filtersActive}>
        <div className="filter-dot" />
      </RenderIf>
    </div>
  );
}

FilterButton.defaultProps = {
  showFilterButton: true,
  filtersActive: false,
}

export default FilterButton;
