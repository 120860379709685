import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { getServiceUnitsList, updateServiceById } from 'redux/actions/services';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { selectServiceDetails, selectServiceTimeUnits } from 'redux/selectors/services';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  service: selectServiceDetails(state),
  userType: selectUserType(state),
  units: selectServiceTimeUnits(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    updateServiceById,
    setIsDrawerOpen,
    getServiceUnitsList,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
