import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { setIsDrawerOpen } from 'redux/actions/workspace';
import { createStartupADGMLicense, updateADGMLicenseByStartupId, updateStartupById } from 'redux/actions/startups';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createStartupADGMLicense,
    updateADGMLicenseByStartupId,
    setIsDrawerOpen,
    updateStartupById,
  }, dispatch),
});

export default connect(null, mapDispatchToProps);
