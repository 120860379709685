import React, { useMemo } from 'react';
import { Card, Row, Col } from 'antd';
import classnames from 'classnames';

import { ServiceCardProps } from 'types/componentTypes/CardProps';
import { identity, showRequestedButton, disableRequestButton } from 'utils';
import { AddImageIcon } from 'assets/icons';
import StaticNotification from 'components/components/Notification/StaticNotification';
import RenderIf from '../RenderIf';
import { CardInfoRow, DescriptionBox } from '../Card';
import { CardButtons } from './components';

import './ServiceCard.scss';

const ServiceGridCard = (props: ServiceCardProps) => {
  const {
    columns,
    dataSource,
    onGridCardClick,
    numOfCard,
    unsentRequests,
    displayLogoImage,
  } = props;

  const sliceNumber = numOfCard || dataSource?.length;
  const isService = useMemo(() => columns!.length > 3 && columns![3].key === 'range', [columns]);

  const secondTitle = (id: number) => {
    const isRequestedService = props.secondButtonTitle === 'request service';
    return isRequestedService && showRequestedButton(id, unsentRequests!) ? 'Service requested' : props.secondButtonTitle;
  }

  return (
    <Row gutter={16}>
      {dataSource?.slice(0, sliceNumber).map((data) => (
        <Col xs={16} sm={14} md={10} xl={8} xxl={6} key={`${data.name}-${data.category}-${data.id}`}>
          <div className="service-card-container">
            <Card
              bordered
              className="service-card category-container"
              onClick={() => onGridCardClick(data)}
            >
              <div className={classnames({ 'service-inactive': identity.isObjWithChildren(data.serviceProvider) && !data.active })}>
                <div className="service-card-container__title-container">
                  <p className={classnames(
                    'title',
                    { 'no-image-title' : identity.isEmptyString(data.imageLink) },
                  )}
                  >
                    {identity.isEmptyString(data.name) ? 'Not specified' : data.name}
                  </p>

                  <RenderIf condition={!identity.isFalsy(displayLogoImage)}>
                    {identity.isTruthyString(data.imageLink)
                      ? (
                        <img
                          alt={data.name}
                          src={data.imageLink}
                          className="card-image"
                        />
                      )
                      : (
                        <div className="empty-image">
                          <AddImageIcon />
                        </div>
                      )}
                  </RenderIf>
                </div>

                <RenderIf condition={identity.isObjWithChildren(data.serviceProvider)}>
                  <p className="subtitle">
                    {`by ${identity.isEmptyString(data.serviceProvider?.name) ? 'Not specified' : data.serviceProvider?.name}`}
                  </p>
                </RenderIf>

                <DescriptionBox
                  title="Description"
                  description={identity.isTruthyString(data.about) ? data.about : 'No description specified for this service'}
                />

                {columns?.map((column) => (
                  <div className="category-container" key={column?.key}>
                    <CardInfoRow data={data} infoRowKey={identity.isTruthyString(column?.key) ? column?.key : 'n/a'} />
                  </div>
                ))}
              </div>
              <RenderIf condition={identity.isTruthyString(props.firstButtonTitle)
                || identity.isTruthyString(props.secondButtonTitle)}
              >
                <CardButtons
                  firstButtonAction={props.firstButtonAction!}
                  firstButtonTitle={props.firstButtonTitle}
                  secondButtonAction={props.secondButtonAction!}
                  secondButtonTitle={secondTitle(data.id)}
                  isFirstButtonDisabled={props.isFirstButtonDisabled}
                  isSecondButtonDisabled={isService ? (disableRequestButton(data, unsentRequests!)
                  || showRequestedButton(data.id, unsentRequests!))
                    : props.isSecondButtonDisabled}
                  className={classnames(
                    'primary-button md',
                    { 'requested-service-button': showRequestedButton(data.id, unsentRequests!) },
                  )}
                  data={data}
                />
              </RenderIf>

              {data.status !== undefined && !data.status && (
                <div style={{ margin: '5px 0 0' }}>
                  <StaticNotification
                    type="warning"
                    message="Service Provider deactivated"
                  />
                </div>
              )}
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  );
}

ServiceGridCard.defaultProps = {
  displayLogoImage: true,
}

export default ServiceGridCard;
