import React, { useEffect } from 'react';

import { AdminStartupLifecycleProps } from 'types/componentTypes/Dashboard/AdminStartupLifecycle';
import {
  getOffset,
  getRowKey,
  useTableHook,
} from 'utils'

import {
  gridPaginationOptions,
  paginationOptions,
  RowTable,
  CustomPagination,
} from '../../../components/Table'

import { TitleWithActions } from '../../../components'
import { adminDashboardStartupsColumns } from '../../../utils/constants/columns'
import { StartupType } from '../../../../types/startups'
import { composeStartupDetailsUrl } from '../../../../routes/routeComposers'

export const AdminStartupLifecycle = ({
  startups,
  startupCount,
  isLoading,
  filters,
  actions: {
    getStartups,
    getStartupCount,
  },
}: AdminStartupLifecycleProps) => {
  const { state, setLimit, setCurrentPage } = useTableHook();
  const { limit, currentPage } = state;

  const openStartupDetails = (record: StartupType) => (
    window.open(composeStartupDetailsUrl(record.id), '_blank')
  );

  useEffect(() => {
    if (
      paginationOptions.includes(limit)
      || (gridPaginationOptions.includes(limit))
    ) {
      getStartups({
        isPreload: true,
        limit,
        offset: getOffset(limit, currentPage),
        filters,
      });

      getStartupCount({ filters });
    }
  }, [limit, currentPage, filters]);

  return (
    <div className="startup-wallet">
      <div className="startup-wallet__header">
        <TitleWithActions title="Lifecycle Details" size="s" />
      </div>

      <RowTable
        isLoading={isLoading}
        columns={adminDashboardStartupsColumns}
        dataSource={startups}
        rowKey={getRowKey(startups, 'startup', 'id')}
        onRow={(record) => ({ onClick: () => openStartupDetails(record) })}
      />

      <CustomPagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={startupCount}
      />
    </div>
  )
}

export default AdminStartupLifecycle;
