import React, { useState, useEffect } from 'react';
import {
  Form, Tabs, Alert, Space, Typography, Button,
} from 'antd';

import { UnitType, downloadTemplateServiceBulk } from 'packages/service_repository';
import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import EventEmitter from 'event_emitter';
import { identity, requiredValidation } from 'utils';
import { AddServiceDrawerProps, AddServiceType } from 'types/services/addService';
import { PricingOption } from 'types/services';
import { DownloadOutlined } from '@ant-design/icons';
import {
  ServiceDetailForm,
  ServiceCategoryForm,
  AddPricingOptions,
  AdditionalOptions,
  RangeOptions,
} from './components';
import { defaultValues, resetFiltersEvent } from './utils';
import { Input, RenderIf } from '../components'

import './AddNewServiceDrawer.scss';

const AddNewServiceDrawer = (props: AddServiceDrawerProps) => {
  const [activeTab, setActiveTab] = useState<'manual' | 'bulk'>('manual');
  const {
    categories,
    units,
    bulkErrors,
    isLoading,
    actions: {
      addService, getServiceCategories, getServiceUnitsList, addServiceBulk,
    },
  } = props;

  const [additionalOptionName, setAdditionalOptionName] = useState(defaultValues.additionalOptionName)
  const [additionalOptionPrice, setAdditionalOptionPrice] = useState(defaultValues.additionalOptionPrice)
  const [form] = Form.useForm();
  const [pricingOptions, setPricingOptions] = useState<PricingOption[]>(defaultValues.pricingOptions);
  const [pricingOptionsError, setPricingOptionsError] = useState<boolean[]>([false]);
  const [offerDocLink, setOfferDocLink] = useState<string | Blob>('');
  const [bulkDocLink, setBulkDocLink] = useState<string | Blob>('');
  const [, setPriceUnit] = useState<UnitType>();
  const offerPriceStatus = Form.useWatch('offerPrice', form);

  const handleSubmit = (data: AddServiceType) => {
    form.setFieldValue('additionalOptionName', additionalOptionName);
    form.setFieldValue('additionalOptionPrice', additionalOptionPrice);
    form.setFieldValue('pricingOptions', pricingOptions);
    form.setFieldValue('offerDocLink', offerDocLink);

    if (offerPriceStatus === 'range') {
      const formValues = {
        ...data,
        additionalOptionPrice,
        additionalOptionName,
        pricingOptions,
        offerDocLink,
        unit: units?.find(({ id }: UnitType) => id === Number(data?.unit))?.name,
        unitID: data?.unit,
        unitType: units?.find(({ id }: UnitType) => id === Number(data?.unit))?.type,
      };

      form.resetFields();

      setAdditionalOptionName(defaultValues.additionalOptionName);
      setAdditionalOptionPrice(defaultValues.additionalOptionPrice);

      addService({ data: formValues });
    } else {
      const formValues = {
        ...data,
        additionalOptionPrice,
        additionalOptionName,
        priceFrom: data.amount,
        pricingOptions,
        offerDocLink,
        unit: units?.find(({ id }: UnitType) => id === Number(data?.unit))?.name,
        unitID: data?.unit,
        unitType: units?.find(({ id }: UnitType) => id === Number(data?.unit))?.type,
      };

      form.resetFields();

      EventEmitter.emit(resetFiltersEvent);
      setAdditionalOptionName(defaultValues.additionalOptionName);
      setAdditionalOptionPrice(defaultValues.additionalOptionPrice);

      addService({ data: formValues });
    }
  }

  const handleSubmitBulk = () => {
    const formValues = {
      document: bulkDocLink,
    }

    addServiceBulk({ data: formValues });
  };

  const handleBulkDoc = (file: string | Blob) => {
    setBulkDocLink(file);

    return false;
  };

  const handleSetPriceUnit = (unit: UnitType) => {
    setPriceUnit(unit);
  };

  const handleOfferDoc = (file: string | Blob) => {
    setOfferDocLink(file);
    return false;
  };

  const onDeletePricingOption = (id: number) => {
    const newArray = pricingOptions.filter((recipient: PricingOption) => recipient.id !== id);
    setPricingOptions(newArray);
  };

  const onAddPricingOption = (pricingOption: PricingOption) => {
    const id = pricingOptions.length + 1;
    setPricingOptions([...pricingOptions, { ...pricingOption, id }]);
    setPricingOptionsError([...pricingOptionsError, false]);
  };

  const onHandleDownloadTemplate = () => {
    downloadTemplateServiceBulk();
  }

  useEffect(() => {
    if (identity.isEmptyArray(categories)) {
      getServiceCategories();
    }

    if (identity.isEmptyArray(units)) {
      getServiceUnitsList();
    }
  }, []);

  return (
    <>
      <Tabs
        tabBarGutter={0}
        activeKey={activeTab}
        onChange={(tab) => setActiveTab(tab as 'manual' | 'bulk')}
        className="tab-buttons-lg"
        style={{ marginBottom: '20px' }}
      >
        <Tabs.TabPane tab="Manual Service Creation " key="manual" />

        <Tabs.TabPane tab="Bulk Upload via .csv" key="bulk" />
      </Tabs>

      {activeTab === 'manual' && (
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={defaultValues}
          validateTrigger="onSubmit"
          className="new-service-container"
          onFinishFailed={scrollToFirstError}
        >
          <ServiceDetailForm />

          <RenderIf condition={offerPriceStatus === 'range'}>
            <RangeOptions setPriceUnit={handleSetPriceUnit} units={units} />
          </RenderIf>

          <RenderIf condition={offerPriceStatus === 'fixed'}>
            <AddPricingOptions
              errors={pricingOptionsError}
              pricingOptions={pricingOptions}
              onDeletePricingOption={onDeletePricingOption}
              onAddPricingOption={onAddPricingOption}
              units={units}
            />
          </RenderIf>

          <ServiceCategoryForm form={form} setOfferDocLink={handleOfferDoc} />

          <RenderIf condition={false}>
            {/* Disabling additional options as those will be handled as special case later on */}
            <AdditionalOptions
              onChangeAdditionalOptionName={setAdditionalOptionName}
              onChangeAdditionalOptionPrice={setAdditionalOptionPrice}
              additionalOptionName={additionalOptionName}
              additionalOptionPrice={additionalOptionPrice}
            />
          </RenderIf>

          <DrawerButton>Add service</DrawerButton>
        </Form>
      )}

      {activeTab === 'bulk' && (
        <Form
          form={form}
          onFinish={handleSubmitBulk}
          initialValues={defaultValues}
          validateTrigger="onSubmit"
          onFinishFailed={scrollToFirstError}
        >
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Button
              type="link"
              className="export-button"
              onClick={onHandleDownloadTemplate}
              color="primary"
              style={{
                marginLeft: '-16px',
                fontWeight: 'bold',
              }}
            >
              <DownloadOutlined color="white" />
              Download Template
            </Button>

            <Input
              label="Service Details .csv"
              layout="vertical"
              name="csvFile"
              required
              rules={bulkDocLink ? [] : [requiredValidation]}
            >
              <Input.DraggerUpload
                accept=".csv"
                name="csvFile"
                beforeUpload={handleBulkDoc}
                allowChange
                isRoundableButton
              />
            </Input>

            {Boolean(bulkErrors?.length) && (
              <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                <Typography.Text type="danger">
                  File upload failed due to the following errors:
                </Typography.Text>

                {bulkErrors.map((error: string) => (
                  <Alert key={error} message={error} type="error" showIcon />
                ))}
              </Space>
            )}

            <DrawerButton
              isLoading={isLoading}
              style={{ textTransform: 'none' }}
            >
              Add service(s)
            </DrawerButton>
          </Space>
        </Form>
      )}
    </>
  )
}

export default AddNewServiceDrawer
