import httpClient, { ApiResponseType } from '../../http_client';
import { convertGetSectorsListResponse, SECTOR_API } from '../utils';
import { GetSectorsApiResponseType, GetSectorsListResponse } from '../types'

const getSectorsApi = () => {
  const url = `${SECTOR_API}`;
  return httpClient.get<ApiResponseType<GetSectorsApiResponseType>>(url);
}

export const getSectorsList = async (): Promise<GetSectorsListResponse> => {
  const response = await getSectorsApi();
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertGetSectorsListResponse(data) };
}

export default null;
