import { LabeledValue } from 'antd/lib/select';
import { RcFile } from 'antd/lib/upload';

import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { requiredValidation, emailValidation, positiveNumbersOnly } from 'utils/constants/validationRules';

export const defaultRecipients = [
  {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
  },
];

export const defaultValues = {
  industry: '',
  name: '',
  stage: '',
  website: '',
  noOfEmployees: '',
  type: '',
  cohort: '',
  description: '',
  adgmLicense: false,
  aDGMLicenseNo: '',
  logoUrl: '',
  activationLinkRecipient: defaultRecipients,
}

export type AddStartupsFieldsCreatorParams = {
  cohortsOptions: LabeledValue[],
  sectorOptions: LabeledValue[],
  secondarySectorOptions: LabeledValue[],
  stageOptions: LabeledValue[],
  investmentStageOptions: LabeledValue[],
  beforeLogoUpload: (_file: RcFile) => boolean,
  beforeAcceptanceLetterUpload: (_file: RcFile) => boolean,
  onPrimarySectorChange: Function,
}

export const addStartupFormFields = (
  {
    cohortsOptions,
    sectorOptions,
    secondarySectorOptions,
    stageOptions,
    investmentStageOptions,
    onPrimarySectorChange,
    beforeLogoUpload,
  }: AddStartupsFieldsCreatorParams,
) => [
  {
    fields: [
      getFormFieldBuilder('logo', 'Logo', inputTypes.image)
        .withBeforeUpload(beforeLogoUpload)
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('name', 'Startup Name')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('website', 'Website')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('primarySector', 'Primary sector', inputTypes.select)
        .withRules([requiredValidation])
        .withOnChange(onPrimarySectorChange)
        .withOptions(sectorOptions)
        .required()
        .build(),
      getFormFieldBuilder('secondarySector', 'Secondary sector', inputTypes.select)
        .withOptions(secondarySectorOptions)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('stage', 'Startup Stage', inputTypes.select)
        .withRules([requiredValidation])
        .withOptions(stageOptions)
        .required()
        .build(),
      getFormFieldBuilder('investmentStage', 'Investment stage', inputTypes.select)
        .withOptions(investmentStageOptions)
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('cohort', 'Cohort', inputTypes.select)
        .withOptions(cohortsOptions)
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('numberOfEmployees', 'Number of Employees', inputTypes.number)
        .withRules([requiredValidation, positiveNumbersOnly])
        .min(0)
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withRules([requiredValidation])
        .required()
        .withRows(4)
        .build(),
    ],
  },
];

export const addStartupFounderFields = [
  {
    fields: [
      getFormFieldBuilder('firstName', 'First name')
        .withRules([requiredValidation])
        .required()
        .build(),
      getFormFieldBuilder('lastName', 'Last name')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('email', 'Email')
        .withRules([requiredValidation, emailValidation])
        .required()
        .build(),
      getFormFieldBuilder('phoneNumber', 'Phone number', inputTypes.number)
        .min(0)
        .withRules([positiveNumbersOnly])
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('jobTitle', 'Job title')
        .withRules([requiredValidation])
        .required()
        .build(),
    ],
  },
];
