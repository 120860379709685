import React, { useEffect } from 'react';

import { AdminAngelInvestorLifecycleProps } from 'types/componentTypes/Dashboard/AdminAngelInvestorLifecycle';
import {
  getOffset,
  getRowKey,
  useTableHook,
} from 'utils';

import {
  gridPaginationOptions,
  paginationOptions,
  RowTable,
  CustomPagination,
} from '../../../components/Table';

import { TitleWithActions } from '../../../components';
import { adminDashboardAngelInvestorsColumns } from '../../../utils/constants/columns';
import { StartupType } from '../../../../types/startups';
import { composeAngelInvestorDetailsUrl } from '../../../../routes/routeComposers';

export const AdminAngelInvestorLifecycle = ({
  angelInvestors,
  angelInvestorsCount,
  isLoading,
  filters,
  actions: {
    getAngelInvestors,
    getAngelInvestorCount,
  },
}: AdminAngelInvestorLifecycleProps) => {
  const { state, setLimit, setCurrentPage } = useTableHook();
  const { limit, currentPage } = state;

  const openAngelInvestorDetails = (record: StartupType) => (
    window.open(composeAngelInvestorDetailsUrl(record.id), '_blank')
  );

  useEffect(() => {
    if (
      paginationOptions.includes(limit)
      || (gridPaginationOptions.includes(limit))
    ) {
      getAngelInvestors({
        isPreload: true,
        limit,
        offset: getOffset(limit, currentPage),
        filters,
      });

      getAngelInvestorCount({ filters });
    }
  }, [limit, currentPage, filters]);

  return (
    <div className="startup-wallet">
      <div className="startup-wallet__header">
        <TitleWithActions title="Lifecycle Details" size="s" />
      </div>

      <RowTable
        isLoading={isLoading}
        columns={adminDashboardAngelInvestorsColumns}
        dataSource={angelInvestors}
        rowKey={getRowKey(angelInvestors, 'angel-investors', 'id')}
        onRow={(record) => ({ onClick: () => openAngelInvestorDetails(record) })}
      />

      <CustomPagination
        limit={limit}
        setLimit={setLimit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={angelInvestorsCount}
      />
    </div>
  )
}

export default AdminAngelInvestorLifecycle;
