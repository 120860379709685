import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { createCohort, getPrograms } from '../../redux/actions/cohort';
import { getEmailTemplates, getIncentiveTemplates } from '../../redux/actions/settingsOverview';
import { StateType } from '../../types';
import {
  selectEmailTemplates,
  selectIncentiveTemplates,
} from '../../redux/selectors/settingsOverview'
import { selectPrograms } from '../../redux/selectors/cohort';

const mapStateToProps = (state: StateType) => ({
  emailTemplates: selectEmailTemplates(state),
  incentiveTemplates: selectIncentiveTemplates(state),
  programs: selectPrograms(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    createCohort,
    getEmailTemplates,
    getIncentiveTemplates,
    getPrograms,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
