import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { setDestroyDrawerOnClose, setIsDrawerLoading, setIsDrawerOpen } from 'redux/actions/workspace';
import config from 'assets/config';
import {
  createAngelInvestor,
  createAngelInvestorADGMLicense,
  CreateAngelInvestorPayload,
  CreateAngelInvestorResponse,
  getInvestmentStagesList,
  getStagesList,
  getAngelInvestorsCountApi,
  getAngelInvestorsList,
  getAngelInvestorById,
  AngelInvestorType,
  updateAngelInvestor,
  getAngelInvestorADGMLicenseByAngelInvestorId,
  updateAdgmLicenseByAngelInvestorId,
  AngelInvestorQuery,
  getAngelInvestorsCountWorkingWithIdCount,
  getAngelInvestorIncentiveAgreementById,
  createAngelInvestorIncentiveAgreement,
  updateAngelInvestorIncentiveAgreement,
  IncentiveAgreementType,
  getSectorsList,
} from 'packages/angel_investors_repository';
import {
  createAngelInvestorTeamMember,
  CreateTeamMemberPayload,
  getAngelInvestorMemberCount,
  getAngelInvestorMembers,
  GetTeamMembersParams,
} from 'packages/people_repository';
import {
  identity,
  isEitherAdmin,
  isPartner,
  isAngelInvestor,
  ROLES,
} from 'utils';
import { CreateADGMLicensePayload } from 'packages/angel_investors_repository/types/adgm_license';
import {
  selectIncentiveAgreement,
  selectAngelInvestorDetails,
  selectAngelInvestorForReview,
} from 'redux/selectors/angelInvestors';
import {
  UpdateAngelInvestorPayload,
  GetAngelInvestorByIdPayload,
  UpdateAdgmLicenseByAngelInvestorIdPayload,
} from 'types/reduxTypes/ActionTypes/AngelInvestorsTypes';
import { GetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import showNotification from 'services/utils/showNotification';
import {
  DataPayload,
  GetAllQueryPayload,
} from 'types/reduxTypes/ActionTypes';
import sleep from 'components/utils/mocks/sleep';
import { PeopleFilters } from 'types/people';
import { UserRoleType } from 'types/auth';
import { ParamsPayload } from 'packages/http_client';
import { getAngelInvestorSpsList } from 'packages/service_repository';
import { getAmountSumsBySpAndAngelInvestorApi, GetAmountSumsResponse } from 'packages/ai_wallet_repository';
import { ServiceProviderType } from 'types/serviceProviders';
import {
  CreateIncentiveAgreementPayload,
  UpdateIncentiveAgreementPayload,
} from 'types/angelInvestors';
import { setAngelInvestorDetails } from '../../actions/serviceProviders';
import {
  setAngelInvestorById,
  setAngelInvestors,
  types,
  createAngelInvestorADGMLicense as createAngelInvestorADGMLicenseAC,
  setAngelInvestorMembers,
  setStageOptions,
  setInvestmentStageOptions,
  setIsLoading,
  setAngelInvestorsCount,
  setTeamMembersCount,
  getAngelInvestorById as reduxGetAngelInvestorById,
  getAngelInvestors,
  setADGMLicenseByAngelInvestorId,
  setIncentiveAgreementById,
  setAngelInvestorServiceProviders,
  setSumsForServiceProviders,
  updateAngelInvestorById,
  setAngelInvestorForReview,
  setSectorOptions,
} from '../../actions/angelInvestors';
import {
  selectPartnerId,
  selectUserServiceProviderId,
  selectUserAngelInvestor,
  selectUserAngelInvestorId,
  selectUserType,
} from '../../selectors/auth';
import { selectIsRowTableLayout } from '../../selectors/workspace';
import { defaultAngelInvestorIncentiveAgreement } from '../../utils';

function * handleGetAngelInvestors(action: PayloadAction<AngelInvestorQuery>) {
  yield put(setIsLoading({ isLoading: true }));

  const userType: UserRoleType = yield select(selectUserType);
  let query = action.payload;

  switch (userType) {
    case ROLES.partner: {
      const partnerId: number = yield select(selectPartnerId);
      query = { ...query, partnerId, statusID: 7 };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      query = { ...query, serviceProviderId };
      break;
    }
    default: {
      break;
    }
  }

  const { data: angelInvestors, error, httpStatus } = yield call(getAngelInvestorsList, query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch angel investors: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAngelInvestors({ angelInvestors }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetAngelInvestorById(action: PayloadAction<GetAngelInvestorByIdPayload & { forceReload?: boolean }>) {
  yield put(setIsLoading({ isLoading: true }));

  const {
    id, owner, isUpdate, forceReload,
  } = action.payload;
  const selectedAngelInvestor: AngelInvestorType = yield select(selectAngelInvestorDetails);
  const { id: selectedAngelInvestorId } = selectedAngelInvestor;

  if (forceReload || !(id === selectedAngelInvestorId && identity.isFalsy(isUpdate) && owner === 'service-provider')) {
    const {
      data: angelInvestor,
      error,
      httpStatus,
    } = yield call(getAngelInvestorById, id, true, forceReload);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Unable to fetch Angel investor: ${error?.message}`, true, httpStatus);
    } else {
      switch (owner) {
        case 'angel-investor': {
          yield put(setAngelInvestorById({ angelInvestor }));
          break;
        }
        case 'service-provider': {
          yield put(setAngelInvestorDetails({ angelInvestor }));
          break;
        }
        default:
          break;
      }
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleCreateAngelInvestorADGMLicense(action: PayloadAction<CreateADGMLicensePayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { angelInvestorId } = action.payload;
  const { error, httpStatus } = yield call(createAngelInvestorADGMLicense, action.payload);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('AngelInvestor ADGM License uploaded successfully');
    yield put(reduxGetAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleCreateNewAngelInvestor(action: PayloadAction<DataPayload<CreateAngelInvestorPayload>>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { data } = action.payload;

  const {
    error: angelInvestorError,
    data: angelInvestorId,
  }: CreateAngelInvestorResponse = yield call(createAngelInvestor, data);

  if (identity.isObjWithChildren(angelInvestorError) || identity.isFalsy(angelInvestorId)) {
    showNotification(angelInvestorError?.message || 'Error creating a Angel investor.', true);
    yield put(setIsDrawerLoading({ isLoading: false }));
  } else {
    const {
      firstName,
      lastName,
      email,
      jobTitle,
      adgmLicense,
      hasLicense,
      phoneNumber,
    } = data;

    const createADGMLicensePayload: CreateADGMLicensePayload = {
      ...adgmLicense,
      angelInvestorId: angelInvestorId as number,
    };

    const createTeamMemberPayload: CreateTeamMemberPayload = {
      angelInvestorId: angelInvestorId as number,
      founder: 1,
      firstName,
      lastName,
      email,
      jobTitle,
      phoneNumber,
    }

    const { error } = yield call(createAngelInvestorTeamMember, createTeamMemberPayload);

    if (hasLicense) {
      yield put(createAngelInvestorADGMLicenseAC(createADGMLicensePayload));
    }

    if (error) {
      showNotification(error.message, true);
    }

    showNotification('Angel investor Created successfully');

    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
    yield put(setIsDrawerLoading({ isLoading: false }));
    const userType: UserRoleType = yield select(selectUserType);
    const isRowTableLayout: boolean = yield select(selectIsRowTableLayout);

    const angelInvestorQuery: AngelInvestorQuery = {
      limit: (isRowTableLayout && !isPartner(userType)) ? config.TABLE_DEFAULT_LIMIT : config.GRID_TABLE_DEFAULT_LIMIT,
      offset: 0,
      isPreload: true,
      filters: {},
    };

    yield put(getAngelInvestors(angelInvestorQuery));
  }
}

function * handleGetAngelInvestorMembers(action: PayloadAction<GetTeamMembersPayload>) {
  const {
    id: angelInvestorId, offset, limit, filters,
  } = action.payload;

  yield put(setIsLoading({ isLoading: true }));

  const { data: members, error, httpStatus } = yield call(getAngelInvestorMembers, {
    angelInvestorId, offset, limit, filters,
  });

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch Angel investor team members: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAngelInvestorMembers({ members }));
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetTeamMembersCount(action: PayloadAction<PeopleFilters>) {
  const angelInvestorId: number = yield select(selectUserAngelInvestorId);
  const params: GetTeamMembersParams = { angelInvestorId, filters: action.payload };
  const { data, error, httpStatus } = yield call(getAngelInvestorMemberCount, params);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setTeamMembersCount({ data: data[0].id__count }));
  }
}

const reloadOnSubmit = async () => {
  await sleep(2000)
  window.location.reload();
}

function * handleUpdateAngelInvestorById(action: PayloadAction<UpdateAngelInvestorPayload>) {
  const { id, angelInvestor, reload } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateAngelInvestor, id, angelInvestor);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error updating Angel investor: ${error.message}`, true, httpStatus);
  } else {
    showNotification(`Angel investor ${id} updated successfully`);

    yield all([
      put(reduxGetAngelInvestorById({
        id, owner: 'angel-investor', isUpdate: true, forceReload: true,
      })),
      put(setAngelInvestors({ angelInvestors: [] })),
    ])
  }

  yield put(setIsLoading({ isLoading: false }));

  if (reload && httpStatus === 200) {
    yield call(reloadOnSubmit);
  }
}

function * handleGetStages() {
  const { data, error, httpStatus } = yield call(getStagesList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setStageOptions({ data }));
  }
}

function * handleGetInvestmentStages() {
  const { data, error, httpStatus } = yield call(getInvestmentStagesList);
  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setInvestmentStageOptions({ data }));
  }
}

function * handleGetAngelInvestorsCount(action: PayloadAction<AngelInvestorQuery>) {
  let params: AngelInvestorQuery = action.payload;

  const userType: UserRoleType = yield select(selectUserType);
  let apiCall = getAngelInvestorsCountWorkingWithIdCount;
  switch (userType) {
    case ROLES.partner: {
      const partnerId: number = yield select(selectPartnerId);
      params = { ...params, partnerId, fieldSelection: ['angel_investor_id'] };
      break;
    }
    case ROLES.serviceProvider: {
      const serviceProviderId: number = yield select(selectUserServiceProviderId);
      params = { ...params, serviceProviderId, fieldSelection: ['angel_investor_id'] };
      break;
    }
    default: {
      apiCall = getAngelInvestorsCountApi;
      break;
    }
  }
  const { data: angelInvestorCount, error, httpStatus } = yield call(apiCall, params);
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch Angel investors count: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAngelInvestorsCount({ angelInvestorCount }));
  }
}

function * handleGetADGMLicenseByAngelInvestorId(action: PayloadAction<GetAngelInvestorByIdPayload>) {
  const { id } = action.payload;

  const { data: adgmLicense, error, httpStatus } = yield call(getAngelInvestorADGMLicenseByAngelInvestorId, id, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to fetch adgmLicense: ${error.message}`, true, httpStatus);
  } else {
    yield put(setADGMLicenseByAngelInvestorId({ data: adgmLicense }));
  }
}

function * handleUpdateADGMLicenseByAngelInvestorId(action: PayloadAction<UpdateAdgmLicenseByAngelInvestorIdPayload>) {
  yield put(setIsDrawerLoading({ isLoading: true }));

  const { id, angelInvestorId, adgmLicense } = action.payload;

  const { error, httpStatus } = yield call(updateAdgmLicenseByAngelInvestorId, id, adgmLicense)
  if (identity.isObjWithChildren(error)) {
    showNotification(`Unable to update adgmLicense: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Angel Investor ADGM License uploaded successfully');
    yield put(setADGMLicenseByAngelInvestorId({ data: adgmLicense }));
    yield put(reduxGetAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: false }));
    yield put(setDestroyDrawerOnClose({ destroyDrawerOnClose: true }));
    yield put(setIsDrawerOpen({ isDrawerOpen: false }));
  }

  yield put(setIsDrawerLoading({ isLoading: false }));
}

function * handleGetIncentiveAgreementById() {
  let angelInvestor: AngelInvestorType;
  const userType: UserRoleType = yield select(selectUserType);

  if (isAngelInvestor(userType)) {
    angelInvestor = yield select(selectUserAngelInvestor);
  } else {
    angelInvestor = yield select(selectAngelInvestorDetails);
  }

  const id: number = angelInvestor.currentIncentiveAgreementID;

  if (identity.isTruthyNumber(id)) {
    const { error, httpStatus, data } = yield call(getAngelInvestorIncentiveAgreementById, id);

    if (identity.isObjWithChildren(error)) {
      showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
    } else {
      yield put(setIncentiveAgreementById({ data }));
    }
  } else {
    yield put(setIncentiveAgreementById({ data: defaultAngelInvestorIncentiveAgreement }));
  }
}

function * handleGetAngelInvestorServiceProviders(action: PayloadAction<GetAllQueryPayload<ParamsPayload>>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data, error, httpStatus } = yield call(getAngelInvestorSpsList, action.payload.query);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching service providers: ${error.message}`, true, httpStatus);
  } else {
    yield all([
      put(setAngelInvestorServiceProviders({ data })),
      call(handleGetSumsForServiceProviders, data, action.payload.query.angelInvestorId as number),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetSumsForServiceProviders(data: ServiceProviderType[], angelInvestorId: number) {
  const spSums: GetAmountSumsResponse[] = [];

  for (const i in data) {
    const { data: sums, error } = yield call(
      getAmountSumsBySpAndAngelInvestorApi,
      { serviceProviderId: data[i].id, angelInvestorId },
    );

    if (identity.isObjWithChildren(error) || !identity.isObjWithChildren(sums)) {
      spSums.push({ serviceProviderId: data[i].id, amountConsumed: 'AED 0.00', amountBlocked: 'AED 0.00' });
    } else {
      spSums.push({ ...sums, serviceProviderId: data[i].id });
    }
  }

  yield put(setSumsForServiceProviders({ data: spSums }));
}

function * handleCreateIncentiveAgreement(action: PayloadAction<CreateIncentiveAgreementPayload>) {
  const { data } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(createAngelInvestorIncentiveAgreement, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Incentive agreement sent for approval.');
    yield all([
      put(reduxGetAngelInvestorById({ id: data.angelInvestorID, owner: 'angel-investor', isUpdate: false })),
      call(handleGetIncentiveAgreementById),
      put(setIsDrawerOpen({ isDrawerOpen: false })),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdateIncentiveAgreement(action: PayloadAction<UpdateIncentiveAgreementPayload>) {
  const { data, id } = action.payload;
  yield put(setIsLoading({ isLoading: true }));

  const { error, httpStatus } = yield call(updateAngelInvestorIncentiveAgreement, data, id);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    const userType: UserRoleType = yield select(selectUserType);
    if (isAngelInvestor(userType)) {
      showNotification('Incentive agreement sent for approval.');
      yield all([
        put(setIsDrawerOpen({ isDrawerOpen: false })),
        put(updateAngelInvestorById({
          id: data.angelInvestorID,
          angelInvestor: { incentiveAgreementApprovedStatus: 0 },
          reload: true,
        })),
      ]);
    }
    yield call(handleGetIncentiveAgreementById);
    if (isEitherAdmin(userType)) {
      const currentIncentiveAgreement: IncentiveAgreementType = yield select(selectIncentiveAgreement);
      const angelInvestor: AngelInvestorType = yield select(selectAngelInvestorForReview);
      yield put(setAngelInvestorForReview({ angelInvestor: { ...angelInvestor, currentIncentiveAgreement } }));
    }
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetSectors() {
  const { data, error, httpStatus } = yield call(getSectorsList);

  if (identity.isObjWithChildren(error)) {
    showNotification(error.message, true, httpStatus);
  } else {
    yield put(setSectorOptions({ data }));
  }
}

export default function * angelInvestorsSagas() {
  yield all([
    takeLatest(types.UPDATE_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID, handleUpdateADGMLicenseByAngelInvestorId),
    takeLatest(types.CREATE_ANGEL_INVESTOR_ADGM_LICENSE, handleCreateAngelInvestorADGMLicense),
    takeLatest(types.GET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID, handleGetADGMLicenseByAngelInvestorId),
    takeLatest(types.GET_ANGEL_INVESTORS, handleGetAngelInvestors),
    takeLatest(types.GET_ANGEL_INVESTORS_COUNT, handleGetAngelInvestorsCount),
    takeLatest(types.GET_ANGEL_INVESTOR_BY_ID, handleGetAngelInvestorById),
    takeLatest(types.CREATE_ANGEL_INVESTOR, handleCreateNewAngelInvestor),
    takeLatest(types.GET_ANGEL_INVESTOR_MEMBERS, handleGetAngelInvestorMembers),
    takeLatest(types.UPDATE_ANGEL_INVESTOR_BY_ID, handleUpdateAngelInvestorById),
    takeLatest(types.GET_ANGEL_INVESTORS_STAGES, handleGetStages),
    takeLatest(types.GET_INVESTMENT_STAGES, handleGetInvestmentStages),
    takeLatest(types.GET_ANGEL_INVESTOR_TEAM_MEMBERS_COUNT, handleGetTeamMembersCount),
    takeLatest(types.GET_INCENTIVE_AGREEMENT_BY_ID, handleGetIncentiveAgreementById),
    takeLatest(types.GET_ANGEL_INVESTOR_SERVICE_PROVIDERS, handleGetAngelInvestorServiceProviders),
    takeLatest(types.CREATE_INCENTIVE_AGREEMENT, handleCreateIncentiveAgreement),
    takeLatest(types.UPDATE_INCENTIVE_AGREEMENT, handleUpdateIncentiveAgreement),
    takeLatest(types.GET_ANGEL_INVESTORS_GET_SECTORS, handleGetSectors),
  ]);
}
