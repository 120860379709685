import produce from 'immer';
import { NotificationType } from 'packages/notification_repository';

import { types } from 'redux/actions/notification';
import { notificationsDefaultState } from 'redux/utils';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { NotificationsActionType } from 'types/reduxTypes/ActionTypes/NotificationsTypes';
import { NotificationsStateType } from 'types/reduxTypes/StateTypes';

const handleToggle = (state: NotificationsStateType) => {
  state.isOpen = !state.isOpen;
  return state;
}

const handleSetIsLoading = (state: NotificationsStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetNotifications = (state: NotificationsStateType, payload: DataPayload<NotificationType[]>) => {
  state.notifications = [...state.notifications, ...payload.data];
  return state;
}

const handleSetNextPage = (state: NotificationsStateType) => {
  state.offset += state.limit;
  return state;
}

const handleResetNotifications = (state: NotificationsStateType) => {
  state.offset = 0;
  state.limit = 3;
  state.notifications = [];
  state.isOpen = false;
  return state;
}

const handleSetOrderBy = (state: NotificationsStateType, payload: DataPayload<string>) => {
  state.offset = 0;
  state.notifications = [];
  state.orderBy = payload.data;
  return state;
}

const handleSetNewNotificationsCount = (state: NotificationsStateType, payload: DataPayload<number>) => {
  state.newCount = payload.data;
  return state;
}

const handleSetLimit = (state: NotificationsStateType, payload: DataPayload<number>) => {
  state.limit = payload.data;
  return state;
}

const handleSetAllFetched = (state: NotificationsStateType, payload: DataPayload<boolean>) => {
  state.allFetched = payload.data;
  return state;
}

const handleChangeTypeNotification = (state: NotificationsStateType) => {
  state.offset = 0;
  state.limit = 3;
  state.notifications = [];
  state.isOpen = true;

  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: NotificationsStateType = notificationsDefaultState, action: NotificationsActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.TOGGLE_NOTIFICATIONS: {
      return handleToggle(state);
    }
    case types.SET_IS_LOADING_NOTIFICATIONS: {
      return handleSetIsLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_NOTIFICATIONS: {
      return handleSetNotifications(state, <DataPayload<NotificationType[]>>payload);
    }
    case types.SET_OFFSET_NOTIFICATIONS: {
      return handleSetNextPage(state);
    }
    case types.RESET_NOTIFICATIONS: {
      return handleResetNotifications(state);
    }
    case types.SET_ORDER_BY_NOTIFICATIONS: {
      return handleSetOrderBy(state, <DataPayload<string>>payload);
    }
    case types.SET_NEW_NOTIFICATIONS_COUNT: {
      return handleSetNewNotificationsCount(state, <DataPayload<number>>payload);
    }
    case types.SET_LIMIT_NOTIFICATIONS: {
      return handleSetLimit(state, <DataPayload<number>>payload);
    }
    case types.SET_ALL_FETCHED: {
      return handleSetAllFetched(state, <DataPayload<boolean>>payload);
    }
    case types.RESET_NOTIFICATION: {
      return notificationsDefaultState;
    }
    case types.CHANGE_TYPE_NOTIFICATION: {
      return handleChangeTypeNotification(state);
    }
    default: {
      return state;
    }
  }
});
