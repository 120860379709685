import { connect } from 'react-redux';

import { StateType } from 'types';
import {
  selectRequestOverviewFilters,
  selectRequestsFiltersActive,
} from 'redux/selectors/request';

const mapStateToProps = (state: StateType) => ({
  filters: selectRequestOverviewFilters(state),
  filtersActive: selectRequestsFiltersActive(state),
});

const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps);
