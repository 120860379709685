import { identity, parseAmountPrice } from 'utils';
import { WalletType } from 'types/wallet';
import { AIWalletType } from 'types/ai_wallet';
import { StartupType } from 'packages/startup_repository';
import { AngelInvestorType } from 'packages/angel_investors_repository';
import { TechnicalHolderType } from 'packages/technical_holder_repository';
import { ServiceProviderType } from 'packages/service_repository';

export const validateStartupApproval = (totalPrice: number, wallet: WalletType) => {
  const { balance } = wallet;
  return totalPrice <= parseAmountPrice(balance);
};

export const validateAngelInvestorApproval = (totalPrice: number, wallet: AIWalletType) => {
  const { balance } = wallet;
  return totalPrice <= parseAmountPrice(balance);
};

export const validateHub71AdminApproval = (wallet: WalletType | AIWalletType) => {
  const { balance, totalWalletAmount } = wallet;
  const remainingAmount = (parseAmountPrice(balance) / parseAmountPrice(totalWalletAmount)) * 100 || 0;

  return remainingAmount > 10;
};

export const getRequestSenderName = (startup?: StartupType | null): string | null => {
  let senderName = null;

  if (identity.isTruthyString(startup?.firstName)) {
    senderName = startup?.firstName!;
    if (identity.isTruthyString(startup?.lastName)) {
      senderName += ` ${startup?.lastName}`;
    }
    if (identity.isTruthyString(startup?.name)) {
      senderName += ` - ${startup?.name}`;
    }
    if (identity.isTruthyString(startup?.founderEmail)) {
      senderName += ` (${startup?.founderEmail})`;
    }
  } else {
    senderName = startup?.name ? startup.name : senderName;
    if (identity.isTruthyString(startup?.founderEmail)) {
      senderName += ` (${startup?.founderEmail})`;
    }
  }

  return senderName;
};

export const getRequestOrgSenderName = (data?: AngelInvestorType | TechnicalHolderType | null): string | null => {
  let senderName = null;

  senderName = data?.name ? data.name : senderName;

  if (identity.isTruthyString(data?.contactEmail)) {
    senderName += ` (${data?.contactEmail})`;
  }

  return senderName;
};

export const getRequestToName = (serviceProvider?: ServiceProviderType | null): string => {
  let toName = 'Not Specified';
  if (identity.isTruthyString(serviceProvider?.name)) {
    toName = serviceProvider?.name ? serviceProvider.name : toName;
    if (identity.isTruthyString(serviceProvider?.email)) {
      toName += ` (${serviceProvider?.email})`;
    }
  }
  return toName;
};
