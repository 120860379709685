import { AxiosRequestConfig } from 'axios';

import httpClient, {
  ApiResponseType,
  queryBuilder,
  ParamsPayload,
} from 'packages/http_client';
import { identity } from 'utils';
import { FORM_DATA_CONFIG } from 'packages/http_client/utils';

import {
  GetTechnicalLicensesWorkingWithIdCountResponse,
  CreateTechnicalLicensePayload,
  CreateTechnicalLicenseApiResponseType,
  GetTechnicalLicenseListCountApiResponseType,
  GetTechnicalLicenseListResponse,
  GetTechnicalLicensesCountResponse,
  TechnicalLicenseQuery,
  TechnicalHolderType,
  GetTechnicalLicenseApiResponseType,
  GetTechnicalLicensesApiResponseType,
  UpdateTechnicalLicenseApiResponseType,
  EditTechnicalLicensePayload,
  CreateTechnicalLicenseHolderPayload,
  CreateTechnicalLicenseHolderByNumberPayload,
} from '../types';

import {
  formatQuery,
  TECHNICALLICENSE_API,
  convertGetTechnicalLicenseResponse,
  convertCreateTechnicalLicenseRequestPayload,
  convertGetTechnicalLicenseListResponse,
  convertEditTechnicalLicenseRequestPayload,
  convertCreateTechnicalLicenseHolderRequestPayload,
  convertCreateTechnicalLicenseHolderByNumberRequestPayload,
  TECHNICALLICENSEAIRTABLE_API,
} from '../utils';

const getTechnicalLicenseApi = (id: number, isPreload: boolean) => {
  const url = `${TECHNICALLICENSE_API}/${id}`;
  const params = queryBuilder();
  if (isPreload) {
    params.preload();
  }
  const config: AxiosRequestConfig = { params: params.toObject() };
  return httpClient.get<ApiResponseType<GetTechnicalLicenseApiResponseType>>(url, config);
};

export const getTechnicalLicenseById = async (
  id: number,
  isPreload: boolean,
): Promise<ApiResponseType<TechnicalHolderType>> => {
  const response = await getTechnicalLicenseApi(id, isPreload);
  const { data: technicalLicense, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetTechnicalLicenseResponse(technicalLicense?.result) };
};

const getTechnicalLicenseListCount = (query: TechnicalLicenseQuery) => {
  const url = `${TECHNICALLICENSE_API}`;
  const config: AxiosRequestConfig = { params: {} };
  const countQuery: TechnicalLicenseQuery = { ...query, filters: { ...query.filters, fieldSelection: [{ field: 'id', aggregation: 'count' }] } };
  config.params = formatQuery(countQuery);
  return httpClient.get<ApiResponseType<GetTechnicalLicenseListCountApiResponseType>>(url, config);
}

export const getTechnicalLicenseCountApi = async (query: TechnicalLicenseQuery):
Promise<GetTechnicalLicensesCountResponse> => {
  const response = await getTechnicalLicenseListCount(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: data?.result![0].id__count };
}

const getTechnicalLicensesListApi = (query: TechnicalLicenseQuery) => {
  const url = `${TECHNICALLICENSE_API}`;
  const config: AxiosRequestConfig = { params: {} };
  config.params = formatQuery(query);
  return httpClient.get<ApiResponseType<GetTechnicalLicensesApiResponseType>>(url, config);
}

const getTechnicalLicensesListCount = (query: TechnicalLicenseQuery) => {
  const url = `${TECHNICALLICENSE_API}`;
  const config: AxiosRequestConfig = { params: {} };
  const countQuery: TechnicalLicenseQuery = { ...query, filters: { ...query.filters, fieldSelection: [{ field: 'id', aggregation: 'count' }] } };
  config.params = formatQuery(countQuery);
  return httpClient.get<ApiResponseType<GetTechnicalLicenseListCountApiResponseType>>(url, config);
}

const getTechnicalLicensesCountWorkingWithIdCountApi = (query: ParamsPayload) => {
  const url = TECHNICALLICENSE_API;
  const params = formatQuery({ ...query, fieldSelection: ['technical_license_id'], distinct: true });
  const config: AxiosRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetTechnicalLicensesWorkingWithIdCountResponse>>(url, config);
}

const createTechnicalLicenseApi = (payload: CreateTechnicalLicensePayload) => {
  const url = TECHNICALLICENSE_API;
  const requestPayload = convertCreateTechnicalLicenseRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateTechnicalLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const createTechnicalLicenseHolderApi = (payload: CreateTechnicalLicenseHolderPayload) => {
  const url = TECHNICALLICENSE_API;
  const requestPayload = convertCreateTechnicalLicenseHolderRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateTechnicalLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const createTechnicalLicenseHolderByNumberApi = (payload: CreateTechnicalLicenseHolderByNumberPayload) => {
  const url = TECHNICALLICENSEAIRTABLE_API;
  const requestPayload = convertCreateTechnicalLicenseHolderByNumberRequestPayload(payload);

  return httpClient.post<ApiResponseType<CreateTechnicalLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const updateTechnicalLicenseApi = (id: number, payload: Partial<EditTechnicalLicensePayload>) => {
  const url = `${TECHNICALLICENSE_API}/${id}`;
  const requestPayload = convertEditTechnicalLicenseRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateTechnicalLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

export const createTechnicalLicense = async (payload: CreateTechnicalLicensePayload) => {
  const response = await createTechnicalLicenseApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const createTechnicalLicenseHolder = async (payload: CreateTechnicalLicenseHolderPayload) => {
  const response = await createTechnicalLicenseHolderApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const createTechnicalLicenseHolderByNumber = async (payload: CreateTechnicalLicenseHolderByNumberPayload) => {
  const response = await createTechnicalLicenseHolderByNumberApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

export const updateTechnicalLicense = async (id: number, payload: Partial<TechnicalHolderType>) => {
  const response = await updateTechnicalLicenseApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getTechnicalLicensesCountApi = async (query: TechnicalLicenseQuery):
Promise<GetTechnicalLicensesCountResponse> => {
  const response = await getTechnicalLicensesListCount(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: data?.result![0].id__count };
}

export const getTechnicalLicensesCountWorkingWithIdCount = async (
  query: ParamsPayload,
): Promise<GetTechnicalLicensesCountResponse> => {
  const response = await getTechnicalLicensesCountWorkingWithIdCountApi(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: identity.isFullArray(data?.result) ? data?.result!.length : 0 };
}

export const getTechnicalLicensesList = async (query?: TechnicalLicenseQuery):
Promise<GetTechnicalLicenseListResponse> => {
  const response = await getTechnicalLicensesListApi(query || {});
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetTechnicalLicenseListResponse(data) };
}
