import produce from 'immer';

import {
  IncentiveAgreementType,
  InvestmentStageType,
  StageType,
} from 'packages/technical_holder_repository';
import { checkTechnicalLicenseFiltersActive, getLabelValueObject, identity } from 'utils';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { TechnicalLicensesStateType } from 'types/reduxTypes/StateTypes';
import {
  TechnicalLicenseActionType,
  SetTechnicalLicensesPayload,
  SetTechnicalLicenseByIdPayload,
  SetTechnicalLicenseServiceProviderPayload,
  SetTechnicalLicenseCountPayload,
  SetTechnicalLicenseForReviewPayload,
} from 'types/reduxTypes/ActionTypes/TechnicalLicensesTypes';
import { ChangeRequestStatusPayload, SetRequestsPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { SetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import { StatusType } from 'types/requests';
import { TLHADGMLicenseType } from 'packages/technical_holder_repository/types/adgm_license';
import { ServiceProviderType } from 'types/serviceProviders';
import { GetAmountSumsResponse } from 'packages/wallet_repository';
import { TechnicalLicenseFiltersType } from 'types/technicalLicenses';
import { changeRequestStatusHelper, technicalLicensesDefaultState } from '../../utils';
import { types } from '../../actions/technicalLicense';
import { SectorType } from '../../../packages/startup_repository'

const defaultState: TechnicalLicensesStateType = technicalLicensesDefaultState;

const handleSetSumsForSP = (state: TechnicalLicensesStateType, payload: DataPayload<GetAmountSumsResponse[]>) => {
  state.details.serviceProviders = state.details.serviceProviders.map((sp) => {
    const serviceProvider = payload.data.find(({ serviceProviderId }) => serviceProviderId === sp.id);

    if (identity.isObjWithChildren(serviceProvider)) {
      sp.amountConsumed = serviceProvider!.amountConsumed;
      sp.amountBlocked = serviceProvider!.amountBlocked;
    }

    return sp;
  });

  return state;
}
const handleSetTechnicalLicenseServiceProviders = (
  state: TechnicalLicensesStateType,
  payload: DataPayload<ServiceProviderType[]>,
) => {
  state.details.serviceProviders = payload.data;
  state.details.serviceProvidersCount = payload.data.length;
  return state;
}

const handleSetTechnicalLicenses = (state: TechnicalLicensesStateType, payload: SetTechnicalLicensesPayload) => {
  state.technicalLicenses = payload.technicalLicenses;
  state.filtersActive = checkTechnicalLicenseFiltersActive(state.filters);
  return state;
}

const handleSetTechnicalLicenseById = (state: TechnicalLicensesStateType, payload: SetTechnicalLicenseByIdPayload) => {
  state.details.data = payload.technicalLicense;
  return state;
}

const handleSetTechnicalLicenseRequests = (state: TechnicalLicensesStateType, payload: SetRequestsPayload) => {
  state.details.requests = payload.requests;
  return state;
}

const handleSetRequestStatusChange = (state: TechnicalLicensesStateType, payload: ChangeRequestStatusPayload) => {
  const { id, status } = payload;
  const { details: { requests } } = state;
  state.details.requests = changeRequestStatusHelper(id, requests, status as StatusType);
  return state;
}

const handleSetTechnicalLicenseMembers = (state: TechnicalLicensesStateType, payload: SetTeamMembersPayload) => {
  state.details.teamMembers = payload.members;
  return state;
}

const handleSetServiceProvider = (
  state: TechnicalLicensesStateType,
  payload: SetTechnicalLicenseServiceProviderPayload,
) => {
  state.details.serviceProvider = payload.serviceProvider;
  return state;
}

const handleSetStageOptions = (state: TechnicalLicensesStateType, payload: DataPayload<StageType[]>) => {
  state.stageOptions = payload.data?.map((stage) => getLabelValueObject(stage.name, stage.id));
  return state;
}

const handleSetError = (state: TechnicalLicensesStateType, payload: { message?: string }) => {
  state.error = payload?.message;
  return state;
}

const handleSetInvestmentStageOptions = (
  state: TechnicalLicensesStateType,
  payload: DataPayload<InvestmentStageType[]>,
) => {
  state.investmentStageOptions = payload.data?.map((stage) => getLabelValueObject(stage.name, stage.id));
  return state;
}

const handleSetLoading = (state: TechnicalLicensesStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetTechnicalLicenseCount = (
  state: TechnicalLicensesStateType,
  payload: SetTechnicalLicenseCountPayload,
) => {
  state.technicalLicenseCount = payload.technicalLicenseCount;
  return state;
}

const handleSetTechnicalLicenseMembersCount = (state: TechnicalLicensesStateType, payload: DataPayload<number>) => {
  state.details.teamMembersCount = payload.data;
  return state;
}

const handleSetADGMLicenseByTechnicalLicenseId = (
  state: TechnicalLicensesStateType,
  payload: DataPayload<TLHADGMLicenseType>,
) => {
  state.adgmLicenseByTechnicalLicenseId = payload.data;
  return state;
}

const handleSetTechnicalLicenseId = (state: TechnicalLicensesStateType, payload: DataPayload<number>) => {
  state.technicalLicenseId = payload.data;
  return state;
}

const handleSetTechnicalLicenseForReview = (
  state: TechnicalLicensesStateType,
  payload: SetTechnicalLicenseForReviewPayload,
) => {
  state.selectedTechnicalLicenseForReview = payload.technicalLicense;
  return state;
}

const handleSetIncentiveAgreementById = (
  state: TechnicalLicensesStateType,
  payload: DataPayload<IncentiveAgreementType>,
) => {
  state.incentiveAgreement = payload.data;
  return state;
}

const handleSetTechnicalLicenseFilters = (state: TechnicalLicensesStateType, payload: TechnicalLicenseFiltersType) => {
  state.filters = payload;
  return state;
}

const handleSetSectorOptions = (state: TechnicalLicensesStateType, payload: DataPayload<SectorType[]>) => {
  state.technicalLicenseSectorOptions = payload.data?.map((sector) => getLabelValueObject(sector.name, sector.id));
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: TechnicalLicensesStateType = defaultState, action: TechnicalLicenseActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_TECHNICAL_LICENSE_SECTOR_OPTIONS: {
      return handleSetSectorOptions(state, <DataPayload<SectorType[]>>payload);
    }
    case types.SET_ADGM_LICENSE_BY_TECHNICAL_LICENSE_ID: {
      return handleSetADGMLicenseByTechnicalLicenseId(state, <DataPayload<TLHADGMLicenseType>>payload);
    }
    case types.SET_TECHNICAL_LICENSES: {
      return handleSetTechnicalLicenses(state, <SetTechnicalLicensesPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSES_COUNT: {
      return handleSetTechnicalLicenseCount(state, <SetTechnicalLicenseCountPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSE_BY_ID: {
      return handleSetTechnicalLicenseById(state, <SetTechnicalLicenseByIdPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSE_REQUESTS: {
      return handleSetTechnicalLicenseRequests(state, <SetRequestsPayload>payload);
    }
    case types.SET_REQUEST_STATUS_CHANGE: {
      return handleSetRequestStatusChange(state, <ChangeRequestStatusPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSE_MEMBERS: {
      return handleSetTechnicalLicenseMembers(state, <SetTeamMembersPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSE_SERVICE_PROVIDER_BY_ID: {
      return handleSetServiceProvider(state, <SetTechnicalLicenseServiceProviderPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSES_IS_LOADING: {
      return handleSetLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSE_STAGES_OPTIONS: {
      return handleSetStageOptions(state, <DataPayload<StageType[]>>payload);
    }
    case types.SET_ERROR_TECHNICAL_LICENSE_HOLDER_BY_NUMBER: {
      return handleSetError(state, <{ message?: string }>payload);
    }
    case types.SET_INVESTMENT_STAGE_OPTIONS: {
      return handleSetInvestmentStageOptions(state, <DataPayload<InvestmentStageType[]>>payload);
    }
    case types.SET_TECHNICAL_LICENSE_TEAM_MEMBERS_COUNT: {
      return handleSetTechnicalLicenseMembersCount(state, <DataPayload<number>>payload);
    }
    case types.SET_TECHNICAL_LICENSE_ID: {
      return handleSetTechnicalLicenseId(state, <DataPayload<number>>payload);
    }
    case types.SET_TECHNICAL_LICENSE_FOR_REVIEW: {
      return handleSetTechnicalLicenseForReview(state, <SetTechnicalLicenseForReviewPayload>payload);
    }
    case types.SET_TECHNICAL_LICENSE_INCENTIVE_AGREEMENT_BY_ID: {
      return handleSetIncentiveAgreementById(state, <DataPayload<IncentiveAgreementType>>payload);
    }
    case types.SET_TECHNICAL_LICENSE_FILTERS: {
      return handleSetTechnicalLicenseFilters(state, <TechnicalLicenseFiltersType>payload);
    }
    case types.SET_TECHNICAL_LICENSE_SERVICE_PROVIDERS: {
      return handleSetTechnicalLicenseServiceProviders(state, <DataPayload<ServiceProviderType[]>>payload);
    }
    case types.SET_SUMS_FOR_SERVICE_PROVIDERS: {
      return handleSetSumsForSP(state, <DataPayload<GetAmountSumsResponse[]>>payload);
    }
    case types.RESET_TECHNICAL_LICENSES: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
