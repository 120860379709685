import React from 'react'

import { getLabelValueObject } from 'utils';
import { AddAngelInvestorMemberFieldsCreatorParams, AddAngelInvestorMemberFormProps } from 'types/people';
import { RcFile } from 'antd/lib/upload'
import { addAngelInvestorMemberFields, defaultValues } from '../../utils/constants';
import { FormBuilder } from '../../../components';
import { angelInvestorsSelectColumns } from '../../../utils/constants/columns';
import StartupSelectTitle from '../StartupSelectTitle';

const AddAngelInvestorMemberForm = (props: AddAngelInvestorMemberFormProps) => {
  const {
    form, angelInvestors, involvementTypes, beforeUpload, nationalities,
  } = props;

  const nationalityOptions = nationalities?.map(
    (country) => getLabelValueObject(country.name, country.id),
  );

  const memberFieldsCreateData: AddAngelInvestorMemberFieldsCreatorParams = {
    involvementTypes,
    beforeHeadshotUpload: (file: RcFile) => beforeUpload('headshot', file),
    nationalityOptions,
    angelInvestorsDrawerProps: {
      form,
      columns: angelInvestorsSelectColumns,
      dataSource: angelInvestors,
      options: angelInvestors.map((item) => ({ label: item.name || 'Not specified', value: item.id })),
      drawerTitle: <StartupSelectTitle angelInvestor title="Angel Investors" />,
      filterBy: 'name',
      rowKeyName: 'id',
    },
  };

  return (
    <FormBuilder
      formFragment
      fields={addAngelInvestorMemberFields(memberFieldsCreateData)}
      defaultValues={defaultValues}
    />
  )
}

export default AddAngelInvestorMemberForm;
