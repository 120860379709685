import React, { useEffect } from 'react'
import { RcFile } from 'antd/lib/upload';
import classnames from 'classnames';
import moment from 'moment';

import { requiredValidation, identity } from 'utils';
import { ADGMLicenseProps } from 'types/addDetails';
import { Input, RenderIf } from 'components/components';
import { formatDateDashYMDTimeHMS, formatSlashMDY } from '../../../utils/constants/formatters';

import './ADGMLicense.scss';

const ADGMLicense = (props: ADGMLicenseProps) => {
  const {
    viewType,
    setHasLicense,
    setADGMField,
    hasLicense,
    title,
    fileName,
    isAddMissingDetails,
  } = props;

  const beforeUpload = (license: RcFile) => {
    setADGMField({ field: 'aDGMLicenseUrl', data: license as Blob });

    return false;
  }

  useEffect(() => {
    setHasLicense({ data : true });
  }, [])

  return (
    <div className="adgm-license">
      <Input.Heading title={title} />
      <RenderIf condition={hasLicense}>
        <>
          <div
            className={classnames('adgm-license__fields', { 'adgm-license__fields__add-missing-details': isAddMissingDetails })}
          >
            <Input
              name="aDGMLicenseNo"
              label="License No."
              layout="vertical"
              required
              rules={[requiredValidation]}
            >
              <Input.InputField placeholder="Enter ADGM License No." />
            </Input>

            <Input
              name="legalADGMName"
              label="ADGM Legal Name"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.InputField placeholder="Enter ADGM Legal Name" />
            </Input>

            <Input
              name="legalSignatoryName"
              label="ADGM Legal Signatory Name"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.InputField placeholder="Enter ADGM Legal Signatory Name" />
            </Input>

            <Input
              name="legalSignatoryTitle"
              label="ADGM Legal Signatory Title"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.InputField placeholder="Enter ADGM Legal Signatory Title" />
            </Input>

            <Input
              name="legalSignatoryEmail"
              label="ADGM Legal Signatory Email"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.InputField placeholder="Enter ADGM Legal Signatory Email" />
            </Input>

            <div className="adgm-license__fields__column-inputs">
              <Input
                name="address"
                label="Registered WeWork Address"
                className="input__address"
                layout="vertical"
                required={isAddMissingDetails}
                rules={isAddMissingDetails ? [requiredValidation] : []}
              >
                <Input.InputField.TextArea
                  size="large"
                  rows={4}
                  placeholder="Enter Registered WeWork Address"
                />
              </Input>
            </div>

            <Input
              name="issueDate"
              label="ADGM Issue Date"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.DatePicker
                format={formatSlashMDY}
                placeholder="Select ADGM Issue Date"
                onChange={(e) => setADGMField({
                  field: 'issueDate',
                  data: moment(e)
                    .format(formatDateDashYMDTimeHMS),
                })}
              />
            </Input>

            <Input
              name="expiryDate"
              label="ADGM Expiry Date"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.DatePicker
                format={formatSlashMDY}
                placeholder="Select ADGM Expiry Date"
                onChange={(e) => setADGMField({
                  field: 'expiryDate',
                  data: moment(e)
                    .format(formatDateDashYMDTimeHMS),
                })}
              />
            </Input>

            <Input
              name="acceptanceDate"
              label="HUB71 Acceptance Letter Date"
              layout="vertical"
              required={isAddMissingDetails}
              rules={isAddMissingDetails ? [requiredValidation] : []}
            >
              <Input.DatePicker
                format={formatSlashMDY}
                placeholder="Select Acceptance Letter Date"
                onChange={(e) => setADGMField({
                  field: 'acceptanceDate',
                  data: moment(e)
                    .format(formatDateDashYMDTimeHMS),
                })}
              />
            </Input>
          </div>

          <Input
            name="documents"
            required={isAddMissingDetails}
            label={`${viewType === 'startup' ? '' : 'Upload'} ADGM License`}
            layout="vertical"
          >
            <Input.DraggerUpload
              defaultFileList={identity.isEmptyString(fileName) ? []
                : [{
                  uid: '1',
                  name: fileName!,
                }]}
              allowChange
              accept=".pdf"
              beforeUpload={beforeUpload}
              isRoundableButton
            />
          </Input>
        </>
      </RenderIf>
    </div>
  );
}

ADGMLicense.defaultProps = {
  viewType: 'missing_details',
  title:'ADGM License',
  isAddMissingDetails: false,
}

export default ADGMLicense;
